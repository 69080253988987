import { useContext, useEffect } from "react";
import { Button, Container, InputGroup, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import Tabela from "../../../Components/tabela";
import { CtxParams } from "../../../Contexts/ctxParams";
import FormRodape from "../../../Components/formularios/FormRodape";
import ModalConfirmacao from "../../../Components/formularios/ModalConfirmacao";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { ETpFiltroEmpresaIntegracao } from "../../../Models/cadEmpresaIntegracao";
import useApp from "../../../Hooks/utils/useApp";
import { IUseEmpresaIntegracao } from "../../../Hooks/Empresa/EmpresaIntegracao";
import { useOutletContext } from "react-router-dom";

const CadEmpresaEmpresaIntegracao = () => {
    const EmpresaIntegracao = useOutletContext<IUseEmpresaIntegracao>();
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();

    useEffect(() => {
        AppParams.setPagina("Integrações da Empresa");
    });

    return (
        <>
            <ModalConfirmacao 
                titulo="Confirmação de Exclusão"
                show={EmpresaIntegracao.ShowModal} 
                onHide={EmpresaIntegracao.setShowModal}
                variant={"danger"}
                onSubmitHandler={() => {
                    EmpresaIntegracao.Excluir(EmpresaIntegracao.Selecionado);
                    EmpresaIntegracao.setShowModal(false);
                }}
            >
                Tem certeza que deseja excluir a integração {EmpresaIntegracao.Selecionado.NOME}?
            </ModalConfirmacao>

            <Container className="verticalCenter">

                <div className="d-flex w-100 m-3 justify-content-around">
                    <InputGroup>
                        <DropdownButton
                            variant="outline-secondary"
                            title={
                                (() => {
                                    switch (AppParams.Filtros.EmpresaIntegracao.tipoPesquisa) {
                                    case ETpFiltroEmpresaIntegracao.Nome:
                                        return 'Nome'
                                    case ETpFiltroEmpresaIntegracao.Tipo:
                                        return 'Tipo'
                                    default:
                                        return 'erro'
                                    }
                                })()
                            }
                            id="TPFiltro"
                        >
                            <Dropdown.Item key={'1'} onClick={() => Filtrar('EmpresaIntegracao', {tipoPesquisa: ETpFiltroEmpresaIntegracao.Nome})}>Nome</Dropdown.Item>
                            <Dropdown.Item key={'2'} onClick={() => Filtrar('EmpresaIntegracao', {tipoPesquisa: ETpFiltroEmpresaIntegracao.Tipo})}>Tipo</Dropdown.Item>
                        </DropdownButton>
                        <Form.Control
                            placeholder="Filtro..."
                            aria-label="Filtro"
                            size="lg"
                            value={AppParams.Filtros.EmpresaIntegracao.pesquisa}
                            onChange={(e : React.ChangeEvent<HTMLInputElement>) => Filtrar('EmpresaIntegracao', {pesquisa: e.target.value}) }
                        />
                    </InputGroup>
                </div>

                <Tabela colunas={EmpresaIntegracao.Grid} dados={EmpresaIntegracao.Lista} handleClick={!UserParams.ENTREGADOR ? EmpresaIntegracao.Abrir : undefined} />
                

                <FormRodape style={UserParams.ENTREGADOR ? {display: 'none'} : {}}>
                    <Button variant="success" size="lg" onClick={() => EmpresaIntegracao.Abrir({CD_INTEGRACAO: -1})}><TiPlus /> Cadastrar</Button>
                </FormRodape>
            </Container>
        </>
    );
};

export default CadEmpresaEmpresaIntegracao;
import { Col, Dropdown, DropdownButton, Form, InputGroup, Row } from "react-bootstrap"
import useApp from "../../Hooks/utils/useApp";
import { ETpFiltroProduto } from "../../Models/cardapio";
import { ICtxAppParams } from "../../Contexts/ctxAppParams";

interface Props {
    AppParams: ICtxAppParams
}

const ProdutoListaFiltro = ({AppParams} : Props) => {
    const { Filtrar } = useApp();

    return (
        <div className="d-flex w-100 mb-3 justify-content-around flex-wrap mx-3">
            <div className="w-100">
                <Row>
                    <Col xs={6}>
                        <Form.Group>
                            <Form.Label>Tipo do Produto:</Form.Label>
                            <Form.Select 
                                name={'FTipo'}
                                value={AppParams.Filtros.Produto.filtroTpProduto}
                                onChange={(e: any) => Filtrar('Produto', {filtroTpProduto: e.target.value})}
                                placeholder="" 
                                size="lg"
                            > 
                                <option key={'T'} value={'T'}>Todos</option>
                                <option key={'P'} value={'P'}>Produto</option>
                                <option key={'I'} value={'I'}>Ingrediente</option>
                            </Form.Select>
                        </Form.Group>  
                    </Col>

                    <Col xs={6}>
                        <Form.Group>
                            <Form.Label>Situação:</Form.Label>
                            <Form.Select 
                                name={'SITUACAO'}
                                value={AppParams.Filtros.Produto.filtroSituacao}
                                onChange={(e: any) => Filtrar('Produto', {filtroSituacao: e.target.value})}
                                placeholder="" 
                                size="lg"
                            > 
                                <option key={'T'} value={'T'}>Todos</option>
                                <option key={'D'} value={'D'}>Disponivel</option>
                                <option key={'I'} value={'I'}>Indisponível</option>
                            </Form.Select>
                        </Form.Group>  
                    </Col>
                </Row>
            </div>

            <div className="w-100 mt-3">
                <InputGroup>
                    <DropdownButton
                        variant="outline-secondary"
                        title={
                            (() => {
                                switch (AppParams.Filtros.Produto.tipoPesquisa) {
                                case ETpFiltroProduto.Nome:
                                    return 'Nome'
                                case ETpFiltroProduto.Tipo:
                                    return 'Tipo'
                                case ETpFiltroProduto.Categoria:
                                    return 'Categoria'
                                case ETpFiltroProduto.Composicao:
                                    return 'Composicao'
                                default:
                                    return 'erro'
                                }
                            })()
                        }
                        id="TPFiltro"
                    >
                        <Dropdown.Item key={'1'} onClick={() => Filtrar('Produto', {tipoPesquisa: ETpFiltroProduto.Nome})}>Nome</Dropdown.Item>
                        <Dropdown.Item key={'2'} onClick={() => Filtrar('Produto', {tipoPesquisa: ETpFiltroProduto.Tipo})}>Tipo</Dropdown.Item>
                        <Dropdown.Item key={'3'} onClick={() => Filtrar('Produto', {tipoPesquisa: ETpFiltroProduto.Categoria})}>Categoria</Dropdown.Item>
                        <Dropdown.Item key={'4'} onClick={() => Filtrar('Produto', {tipoPesquisa: ETpFiltroProduto.Composicao})}>Composicao</Dropdown.Item>
                    </DropdownButton>

                    <Form.Control
                        placeholder="Filtro..."
                        aria-label="Filtro"
                        size="lg"
                        value={AppParams.Filtros.Produto.pesquisa}
                        onChange={(e : any) => Filtrar('Produto', {pesquisa: e.target.value})}
                    />
                </InputGroup>
            </div>
        </div>
    )
}

export default ProdutoListaFiltro;
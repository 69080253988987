import usePedidoUtil from "../../Hooks/Pedido/util";
import useMask from "../../Hooks/utils/useMask";
import { IPedido, IPedidoItem } from "../../Models/pedido";

const ImpressaoPedido = (Pedido: IPedido, Colunas: number) => {
    const { setMaskMoney, getEnderecoString, setDate } = useMask();
    const pedidoUtil = usePedidoUtil();
    const _margem = 10;
    const SIZE = (TamCampo: number) : number => {
        return Math.trunc((TamCampo * (Colunas - _margem)) / 100)
    }
    // comando para abrir o negrito '\x1b[1m' e para fechar '\x1b[0m'
    const N = '\x1b[1m'
    const n = '\x1b[0m'
    const BREAK_ITEM = (Texto: string, TamMax: number = 70) : string => {
        const maxsize = SIZE(TamMax);
        let text = "";
    
        if (Texto && Texto !== "") {
            for (let i = 0; i < Texto.length; i++) {
                if (i > 0 && i % maxsize === 0) {
                    text += `\n| ${' '.padStart(SIZE(5), ' ')} | ${Texto.slice(i - maxsize, i).padEnd(maxsize, ' ')} | ${' '.padStart(SIZE(20), ' ')} |`;
                }
            }
            // Adiciona o restante do texto, se houver
            if (Texto.length % maxsize !== 0) {
                text += `\n| ${' '.padStart(SIZE(5), ' ')} | ${Texto.slice(Texto.length - (Texto.length % maxsize)).padEnd(maxsize, ' ')} | ${' '.padStart(SIZE(20), ' ')} |`;
            }
        } else {
            text = Texto;
        }
        return text;
    };

    const cabecalho = 
`${' '.padEnd(SIZE(30), ' ')}${`**** FROTA DELIVERY ****`}

${`Cliente: ${Pedido.NOME_CLIENTE}`.padStart((_margem), ' ')}
${`Celular: ${Pedido.CLIENTE.CELULAR}`.padStart((_margem), ' ')}
${Pedido.ENTREGA && Pedido.ENTREGA.CD_ENTREGA !== undefined ? `Endereço: ${getEnderecoString(Pedido.ENTREGA.ENDERECO)}` : Pedido.NR_MESA ? `Mesa: ${Pedido.NR_MESA}` : `Sem entrega`.padStart((_margem), ' ')}
`

    const itens = 
`
${`${setDate(new Date(Pedido.DT_ABERTURA!), {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}, false, false)}`}
${`Pedido: #${Pedido.CD_PEDIDO}`}
__${'_'.padEnd(SIZE(10), '_')}__${'_'.padEnd(SIZE(70), '_')}__${'_'.padEnd(SIZE(7), '_')}___
| ${' '.padEnd(SIZE(98), ' ')}|
| ${' '.padEnd(SIZE(45), ' ')}${'ITENS'.padEnd(SIZE(35), ' ')}${' '.padEnd(SIZE(19), ' ')} |
|_${'_'.padEnd(SIZE(10), '_')}___${'_'.padEnd(SIZE(70), '_')}__${'_'.padEnd(SIZE(7), '_')}_|
${
Pedido.ITENS
? Pedido.ITENS.map((item, index) => {
    const complementos = pedidoUtil.agruparComplementosPorComposição([item]); 
    return (
`${index !== 0 ? '\n' : ''}| ${String(item.QTD_PRODUTO).padStart(SIZE(5), ' ')} | ${String(item.NOME).padEnd(SIZE(60), ' ')} | ${setMaskMoney((item.VLRUN_PRODUTO*item.QTD_PRODUTO), 'R$', ',').padStart(SIZE(20), ' ')} |`+
`${item.VARIACAO ? BREAK_ITEM(item.VARIACAO?.NOME ?? "", 60) : ''}`+
`${BREAK_ITEM(item.OBS_PRODUTO ?? "", 60)}`
// COMPLEMENTOS
+ `${complementos.length > 0 ? complementos.map((compl) => `\n| ${' '.padStart(SIZE(5), ' ')} | ${`${compl.COMPLEMENTO}:`.padEnd(SIZE(60), ' ')} | ${' '.padStart(SIZE(20), ' ')} |
${compl.ITENS.map((complItem: IPedidoItem, indexComplItem: number) => `${indexComplItem !== 0 ? '\n' : ''}| ${' '.padStart(SIZE(5), ' ')} | ${`(${complItem.QTD_PRODUTO}x) ${complItem.NOME}`.padEnd(SIZE(60), ' ')} | ${`${complItem.COMPOSICAO ? '' : setMaskMoney(complItem.VLRUN_PRODUTO, 'R$')}`.padStart(SIZE(20), ' ')} |`).join('')}`).join('') : ''}` +
`${`|${'___'.padEnd(SIZE(5), '_')}_|_${'_'.padEnd(SIZE(60), '_')}_|_${'_'.padEnd(SIZE(20), '_')}_|`}`
    )
}).join('')
: ''
}
|_${'_'.padEnd(SIZE(5), '_')}_|_${'_'.padEnd(SIZE(60), '_')}_|_${'_'.padEnd(SIZE(20), '_')}_|
| ${'*'.padStart(SIZE(5), ' ')}   ${'SUBTOTAL'.padEnd(SIZE(60), '.')}...${setMaskMoney(Number(Pedido.TOTAL ?? 0) - Number(Pedido.ENTREGA?.TAXA ?? 0), 'R$', ',').padStart(SIZE(20), '.')} |
| ${'*'.padStart(SIZE(5), ' ')}   ${'ENTREGA'.padEnd(SIZE(60), '.')}...${setMaskMoney(Pedido.ENTREGA?.TAXA ?? 0, 'R$', ',').padStart(SIZE(20), '.')} |
| ${'*'.padStart(SIZE(5), ' ')}   ${'TOTAL'.padEnd(SIZE(60), '.')}...${setMaskMoney(Pedido.TOTAL ?? 0, 'R$', ',').padStart(SIZE(20), '.')} |
|_${'_____________________________________________'.padEnd(SIZE(20), '_')}|
| ${'*'.padStart(SIZE(5), ' ')}   ${`PAGAMENTO: ${Pedido.FORMA_PAGTO ?? '-'}`.padEnd(SIZE(60), '.')}...${setMaskMoney(Number(Pedido.TOTAL ?? 0) + Number(Pedido.VLR_TROCO ?? 0), 'R$', ',').padStart(SIZE(20), '.')} |
| ${'*'.padStart(SIZE(5), ' ')}   ${'TROCO'.padEnd(SIZE(60), '.')}...${setMaskMoney(Number(Pedido.VLR_TROCO ?? 0), 'R$', ',').padStart(SIZE(20), '.')} |
|_${'_'.padEnd(SIZE(5), '_')}___${'_'.padEnd(SIZE(60), '_')}___${'_'.padEnd(SIZE(20), '_')}_|
`
    return {
        cabecalho,
        itens
    }
}

export default ImpressaoPedido;
import { createContext } from 'react';
import { ETpFiltro } from '../Models/cadEntrega';
import { ICtxAppParams_Filtros, ICtxAppParams_Modal, ICtxAppParams_ModalInputs } from '../Models/ctxAppParams';
import { ETpFiltroTaxa } from '../Models/cadTaxa';
import { ETpFiltroCliente } from '../Models/cadCliente';
import { ETpFiltroUsuario } from '../Models/cadUsuario';
import { ETpFiltroEmpresaIntegracao } from '../Models/cadEmpresaIntegracao';
import { ETpFiltroProduto } from '../Models/cardapio';
import { IUseBeep } from '../Hooks/utils/useBeep';
import { ETpFiltroEmpresaParametro } from '../Models/EmpresaParametro';

export interface ICtxAppParams {
    Pagina: string;
    setPagina: React.Dispatch<React.SetStateAction<string>>;

    EscondeHeader: boolean;
    setEscondeHeader: React.Dispatch<React.SetStateAction<boolean>>;

    BloqueiaSincronizacao: boolean;
    setBloqueiaSincronizacao: React.Dispatch<React.SetStateAction<boolean>>;

    Filtros: ICtxAppParams_Filtros;
    setFiltros: React.Dispatch<React.SetStateAction<ICtxAppParams_Filtros>>;

    Modal: ICtxAppParams_Modal;
    setModal: React.Dispatch<React.SetStateAction<ICtxAppParams_Modal>>;

    SessaoExpirada: boolean;
    setSessaoExpirada: React.Dispatch<React.SetStateAction<boolean>>;

    Logado: boolean;
    setLogado: React.Dispatch<React.SetStateAction<boolean>>;

    BeepAudio?: IUseBeep;

    Mesa: { Numero: string };
    setMesa: React.Dispatch<React.SetStateAction<{Numero: string}>>;
}

export const CAPDefault_Filtros : ICtxAppParams_Filtros = {
    Entrega: {
        tipoPesquisa: ETpFiltro.Cliente,
        pesquisa: '',
        filtroPagamento: 'T',
        filtroStatus: 'T'
    },
    Cliente: {
        tipoPesquisa: ETpFiltroCliente.Nome,
        pesquisa: ''
    },
    EmpresaIntegracao: {
        tipoPesquisa: ETpFiltroEmpresaIntegracao.Nome,
        pesquisa: ''
    },
    EmpresaParametro: {
        tipoPesquisa: ETpFiltroEmpresaParametro.Parametro,
        pesquisa: ''
    },
    Usuario: {
        tipoPesquisa: ETpFiltroUsuario.Nome,
        pesquisa: ''
    },
    Taxa: {
        tipoPesquisa: ETpFiltroTaxa.Bairro,
        pesquisa: ''
    },
    Cardapio: {
        tipoPesquisa: 'PRODUTOS.NOME',
        pesquisa: ''
    },
    Produto: {
        tipoPesquisa: ETpFiltroProduto.Nome,
        pesquisa: '',
        filtroTpProduto: 'T',
        filtroSituacao: 'T',
    },
}

export const CtxAppParams = createContext<ICtxAppParams>({
    Pagina: '/',
    Logado: false,
    EscondeHeader: false,
    BloqueiaSincronizacao: false,
    Filtros: CAPDefault_Filtros,
    Modal: {
        Visibilidade    : false,
        Titulo          : '',
        Descricao       : '',
        Anexos          : undefined,
        Inputs          : undefined,
        Variante        : 'primary',
        onSubmit        : (Values: ICtxAppParams_ModalInputs) => {}
    },
    Mesa: { Numero: '' },
    SessaoExpirada: false,
} as ICtxAppParams);
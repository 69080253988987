import useAPI from "../utils/useAPI";
import { useMutation, useQuery } from 'react-query';
import useMask from "../utils/useMask";
import { useNavigate } from "react-router";
import { useMemo } from "react";
import { ICadEmpresa } from "../../Models/cadEmpresa";

const useCadEmpresa = () : IUseCadEmpresa => {
    const API = useAPI();
    const formatter = useMask()
    const navigate = useNavigate();

    const qryEmpresa = useQuery(
        'qryEmpresa',
        (   
            () => API.get('empresa/buscar/', '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            cacheTime: 0,
            retry: false,
            enabled: true,
        }
    );

    const dataSource = useMemo<ICadEmpresa>(() => 
        qryEmpresa.isSuccess ? qryEmpresa.data : {
            // CD_EMPRESA: 0,
            NOME: "",
            CELULAR: "",
            EMAIL: "",
            TELEFONE: null,
            CEP: "",
            ENDERECO: "",
            ENDERECO_NUMERO: "",
            ENDERECO_BAIRRO: "",
            ENDERECO_CIDADE: "",
            ENDERECO_UF: "",
            TAXA: 0
        } as ICadEmpresa
    , [qryEmpresa]);

    const mutAltEmpresa = useMutation((data : ICadEmpresa) => API.put(`empresa/alterar/`, data), {
        onSuccess: () => {
            qryEmpresa.refetch();
            navigate('/');
        },
        onError: (error: any) => {alert(error)}
    });

    const confirmar = (data: ICadEmpresa) => {
        const dados = Object.assign({}, data);
        dados.CELULAR = formatter.getPhone(dados.CELULAR);

        mutAltEmpresa.mutate(dados);
    };

    const cancelar = () => {
        navigate('/');
    };

    return {
        dataSource,
        Confirmar: confirmar,
        Cancelar: cancelar
    }
}

export interface IUseCadEmpresa {
    dataSource  : ICadEmpresa;
    Confirmar   : (data: ICadEmpresa) => void;
    Cancelar    : () => void;
}

export default useCadEmpresa;
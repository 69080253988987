import { useEffect, useState } from "react"
import { Dropdown, FormControl } from "react-bootstrap"

const FormAutocomplete = ({ dataSource, indexer, indexerAlt, indexKey, valueKey, onChangeValueKey, onFocusPopup, alternative, notFoundAlt, onChange, ...props } : any) => {
    const privateId : string = `FormAutocomplete${indexKey.toString()}${Math.floor(Math.random() * 1025)}`;
    const [show, setShow] = useState<boolean>(false);
    const [texto, setTexto] = useState<string>(props.value);
    const [fieldFocused, setFieldFocused] = useState<boolean>(false);
    const [itemFocused, setItemFocused] = useState<boolean>(false);
    const dataSourceFiltrados = dataSource.filter((dado : any) => // a pesquisa é só aqui, o resto é para apresentação
        dado[indexer]?.toLowerCase().includes(texto?.toLowerCase()) ||
        (indexerAlt && dado[indexerAlt]?.toLowerCase().includes(texto?.toLowerCase()))
    );

    useEffect(() => {
        if ((itemFocused || fieldFocused)) {
            setShow(true);
        } else {
            setShow(false);
        };
    }, [itemFocused, fieldFocused, setShow])

    useEffect(() => { // Atualizar texto de acordo com o valueKey já passado
        const success = dataSource.filter((dado : any) => String(dado[indexKey]) === String(valueKey)).map((item : any) => setTexto(item[indexer]));
        
        if (success.length === 0) {
            if (!fieldFocused) {
                setTexto("");
            };

            if (!onFocusPopup) {
                setFieldFocused(false);
                setItemFocused(false);
            };
        };
    }, [valueKey, onFocusPopup, dataSource, indexKey, indexer]); //eslint-disable-line

    const handleConfirmItem = (item: any) => {
        setTexto(item[indexer]);
        onChangeValueKey(item[indexKey]);
        setItemFocused(false);
        onChange(item[indexer]);
        (document.activeElement as HTMLElement).blur();
    };

    const testChanges = () : boolean => {
        const result = dataSource.filter((dados: any) => dados[indexKey] === valueKey).map((dado: any) => dado[indexer]?.toLowerCase().includes(texto?.toLowerCase()))
        return result[0] ?? true;
    };

    // if (indexKey === 'CD_CLIENTE_ENDERECO') {
    //     console.log(valueKey);
    // }

    return (
        <Dropdown 
            autoClose={false} 
            onKeyUp={() => texto.length > 2 || onFocusPopup ? setFieldFocused(true) : setFieldFocused(false)} 
            onBlur={() => setFieldFocused(false)}
            show={show}
        > 
            <FormControl 
                autoComplete={'off'} 
                {...props} 
                value={texto}
                onChange={e => setTexto(e.target.value)}
                onKeyUp={() => {
                    if ((!testChanges()) || (texto === '')) {
                        onChangeValueKey(null);
                    } else if (texto.length > 2) {
                        setFieldFocused(true);
                    };
                }}
                onKeyDown={(e) => {
                    if ((show) && (e.key === 'ArrowDown')) {
                        (document.getElementById(privateId)?.firstElementChild as HTMLElement).focus();
                        setFieldFocused(false);
                        setItemFocused(true);
                    };
                }}
                onFocus={() => onFocusPopup && !valueKey ? setFieldFocused(true) : null}
                onBlur={() => dataSource.filter((dado : any) => String(dado[indexKey]) === String(valueKey)).map((item : any) => setTexto(item[indexer]))}
            />
            <Dropdown.Menu 
                id={privateId}
                onFocus={() => setItemFocused(true)}
                onBlur={() => setItemFocused(false)}    
            >
                {   // Caso tiver cadastros, irá aparecer
                    (dataSourceFiltrados.length > 0) && ((dataSourceFiltrados[0][indexKey] ?? '') !== '') ?                  
                        dataSourceFiltrados.map((item: any) => 
                            <Dropdown.Item 
                                key={item[indexKey]} 
                                onKeyUp={(e) => e.key === 'Enter' || e.key === ' ' ? handleConfirmItem(item) : null}
                                onClick={() => handleConfirmItem(item)}
                            >
                                {item[indexer]}
                            </Dropdown.Item>
                        )               
                    :   // Se não tiver, irá mostrar um não há registros (se tiver um notFoundAlt, irá exibi-lo)
                        <Dropdown.Item key={0} onClick={() => setItemFocused(false)}>
                            {notFoundAlt ? notFoundAlt : `Não há registros`}
                        </Dropdown.Item>
                }
                
                {   // Mesma condição do item de cima, caso tiver cadastros irá renderizar uma alternativa
                    (alternative && dataSourceFiltrados.length > 0) && ((dataSourceFiltrados[0][indexKey] ?? '') !== '') ?
                        <Dropdown.Item key={-1} onClick={() => setItemFocused(false)}>
                            {alternative}
                        </Dropdown.Item>  
                    :
                        null
                }
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default FormAutocomplete;
import useAPI from "../utils/useAPI";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { ICadCliente } from "../../Models/cadCliente";
import { useNavigate } from "react-router-dom";
import useMask from "../utils/useMask";

const useCadCliente = (Celular: string, editandoEndereco: boolean = false, fechar: string = '') : IUseCadCliente => {
    const API = useAPI();
    const navigate = useNavigate();
    const [cdCliente, setCdCliente] = useState<number>(0); // -1=novo/0=nada/...=editar
    const [celular, setCelular] = useState<string>(Celular);
    const formatter = useMask();

    const qryCliente = useMutation((xcelular: string) => API.get('clientes/buscar/', xcelular, false), {
        onError: (e: any) => {
            alert(qryCliente.error);
            navigate('/cadCliente');
        }
    })

    useEffect(() => {
        if (qryCliente.isIdle) {
            if (celular !== '') {qryCliente.mutate(celular)};
        };

        if (qryCliente.isSuccess && qryCliente.error === null && celular !== '') {
            setCdCliente(qryCliente.data['CD_CLIENTE'])
        } else { 
            setCdCliente(-1) 
        };
    }, [qryCliente, celular]);

    const dsCliente = useMemo<ICadCliente>(() => 
        qryCliente.isSuccess && celular !== '' ? 
                qryCliente.data
            : 
                {
                    CD_CLIENTE: null,
                    NOME: "",
                    CELULAR: "",
                    CD_ENDERECO_PADRAO: null,
                    ENDERECOS: [{
                        CD_CLIENTE_ENDERECO: null,
                        CEP: '',
                        ENDERECO: '',
                        ENDERECO_BAIRRO: '',
                        ENDERECO_CIDADE: '',
                        ENDERECO_NUMERO: '',
                        ENDERECO_UF: '',
                    }]
                }
    , [qryCliente, celular]);

    const mutCadCliente = useMutation((data : ICadCliente) => API.post('clientes/cadastrar', data), {
        onSuccess: (ret: any) => {
            if (editandoEndereco) { // Carrega os novos dados do cliente
                setCdCliente(ret["Sucesso"]["CD_CLIENTE"]);
                setCelular(ret["Sucesso"]["CELULAR"]); // Só é usado neste caso, para recuperar em endereço
                qryCliente.mutate(ret["Sucesso"]["CELULAR"]);
            } else if (fechar === 'true') {
                window.close();
            }
            else {
                navigate(`/listaCliente`)
            }
        },
        onError: (err: any) => alert(err)
    });

    const mutAltCliente = useMutation((data : ICadCliente) => API.put(`clientes/alterar/${celular}`, data), {
        onSuccess: (ret: any) => {
            if (editandoEndereco) { // Recarrega os dados do cliente
                qryCliente.mutate(celular);
            } else if (fechar === 'true') {
                window.close();
            } else {
                navigate(`/listaCliente`);
            }
        },
        onError: (err: any) => alert(err)
    });

    const Confirmar = useCallback((data: ICadCliente) => {
        const dados = Object.assign({}, data);
        dados.CELULAR = formatter.getPhone(dados.CELULAR);
        
        if (cdCliente === -1) {
            mutCadCliente.mutate(dados);
        } else {
            mutAltCliente.mutate(dados);
        }
    }, [cdCliente, formatter.getPhone, mutCadCliente, mutAltCliente, formatter.getPhone]); //eslint-disable-line


    const Cancelar = () => {
        navigate(-1)
    }

    return {
        qryCliente,
        dsCliente,
        CdCliente: cdCliente,
        Celular: celular,
        setCdCliente: setCdCliente,
        mutCadCliente,
        mutAltCliente,
        Cancelar,
        Confirmar: Confirmar,
    };
};

export interface IUseCadCliente {
    qryCliente      : UseMutationResult<any, any, string, unknown>;
    dsCliente       : ICadCliente;
    CdCliente       : number;
    Celular         : string;
    setCdCliente    : React.Dispatch<React.SetStateAction<number>>;
    mutCadCliente   : UseMutationResult<any, any, ICadCliente, unknown>;
    mutAltCliente   : UseMutationResult<any, any, ICadCliente, unknown>;
    Cancelar        : () => void;
    Confirmar       : (data: ICadCliente) => void;
}

export default useCadCliente;
import * as Yup from 'yup';
import { IClienteEnderecos } from './cadClienteEndereco';
import { NOME, CELULAR, CD_ENDERECO_PADRAO } from './defaultModels'

export interface ICadCliente {
    CD_CLIENTE?: string;
    CD_EMPRESA?: number;
    NOME: string;
    CELULAR: string;
    CD_ENDERECO_PADRAO?: number | null;
    ENDERECO_PADRAO?: string;
    ENDERECOS?: IClienteEnderecos;

    NOME_PEDIDO? : string;
};
export interface ICadClientes extends Array<ICadCliente>{};

export enum ETpFiltroCliente {
    Nome     = 'NOME',
    Celular  = 'CELULAR'
}

export const schemaCadCliente = Yup.object().shape({
    NOME,
    CELULAR,
    CD_ENDERECO_PADRAO
});
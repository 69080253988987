import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import useMask from "../../Hooks/utils/useMask";
import { IUseCadEmpresa } from "../../Hooks/Empresa";
import { ICadEmpresa } from "../../Models/cadEmpresa";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    alterado: boolean;
    setAlterado: Dispatch<SetStateAction<boolean>>;
    customHook: IUseCadEmpresa;
};

const CadEmpresaProvider = (props: IProvider) => {
    const { values, submitForm, errors } = useFormikContext<ICadEmpresa>();
    const { customHook, showModal, setShowModal, alterado, setAlterado } = props;
    const formatter = useMask();
    

    useEffect(() => { // Verifica se houve alterações
        const keys = Object.keys(values) as Array<keyof ICadEmpresa>;

        const hasChanges = keys.some((key) => {
            const initValue = customHook.dataSource[key as keyof ICadEmpresa];
            let value : any = values[key as keyof ICadEmpresa];

            if (key === 'CELULAR') {
                value = formatter.getPhone(value);
            }

            return initValue !== undefined && initValue !== value;
        });

        setAlterado(hasChanges);
    }, [values, customHook.dataSource, formatter, setAlterado]);

    useEffect(() => { // Esconde modal caso não tenha sido alterado ou se tiver erros
        if ((showModal && !alterado) || (Object.keys(errors).length !== 0)) {
            setShowModal(false);
        }
    }, [showModal, alterado, setShowModal, errors])

    const modalConfirmacao = useMemo(() => (
        <ModalConfirmacao 
            titulo="Alterando dados da Empresa"
            show={showModal} 
            onHide={setShowModal}
            variant={"primary"}
            onSubmitHandler={() => {
                submitForm();
                setShowModal(false);
            }}
        >
            Deseja alterar os seguintes dados da empresa?
            <ul>
                {Object.entries(values).map(([key, value]) => {
                    let initValue = customHook.dataSource[key as keyof ICadEmpresa];
    
                    if (key === 'CELULAR') {
                        value = formatter.getPhone(value);
                    }
    
                    if (initValue !== undefined && initValue !== value) {
                        return (
                            <li key={key} className="mt-3">
                                <span><strong>{key}:</strong></span>
                                <br />
                                <span>Antes: {JSON.stringify(initValue)}</span>
                                <br />
                                <span>Agora: {JSON.stringify(value)}</span>
                            </li>                  
                        );
                    } else {
                        return null
                    }
                })}
            </ul>
        </ModalConfirmacao>
    ), [values, customHook.dataSource, formatter, showModal, setShowModal, submitForm]);

    return (
        <>
            {alterado && (Object.keys(errors).length === 0) && modalConfirmacao}

            {props.children}
        </>
    )
}

export default CadEmpresaProvider;
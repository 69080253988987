import { Formik } from 'formik';
import { every } from 'lodash';
import PropTypes from 'prop-types';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import BadgeValidacao from '../../Components/formularios/BadgeValidacao';
import FormRodape from '../../Components/formularios/FormRodape';
import useAutenticacao from '../../Hooks/autenticacao';
import useMask from '../../Hooks/utils/useMask';
import { IConsultaEmpresa, ILogin, schemaConsultaEmpresa, schemaLogin } from '../../Models/autenticacao';
import AutenticacaoProvider from './provider';
import { CtxAppParams } from '../../Contexts/ctxAppParams';
import { v4 as uuidv4 } from 'uuid';

const Autenticacao = ({ setToken } : {setToken : any}) => {
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [consultouEmpresa, setConsultouEmpresa] = useState<boolean>(false);
    const inputSenha = useRef(null);
    const autenticacao = useAutenticacao();
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setEscondeHeader(true)

        return () => {
            AppParams.setEscondeHeader(false)
        };
    })



    return (
        <Container className={"verticalCenterFull"}>
            <div 
                className='w-100 d-flex justify-content-center align-items-end' 
                style={consultouEmpresa ? {height: '30vh'} : {height: '45vh'}}
            >
                <img id='logoLogin' className='logoLoginTo1' style={{height: '9rem', filter: 'drop-shadow(0 0 0.2rem #63636330)'}} src='/favicon.png' alt='logo'/>
            </div>

            <h1 className="text-center mt-3 w-100">Autenticação</h1>

            <Formik
                validationSchema={!consultouEmpresa ? schemaConsultaEmpresa : schemaLogin}
                onSubmit={(e : ILogin) => {
                    const dados = Object.assign({}, e);
                    dados.CELULAR = formatter.getPhone(dados.CELULAR);

                    if (consultouEmpresa) {
                        localStorage.setItem('params', JSON.stringify(
                            {
                                ...JSON.parse(localStorage.getItem('params') ?? '{}'),
                                EMPRESA: autenticacao.dsListaEmpresas.find((emp: IConsultaEmpresa) => Number(emp.CD_EMPRESA) === Number(dados.CD_EMPRESA)).NOME
                            }
                        ))
                        autenticacao.mutLogin.mutate(dados);
                    } else {
                        autenticacao.mutConsultaEmpresa.mutate(dados);
                        setConfirmado(false);
                    };
                }}
                initialValues={{
                    CD_EMPRESA: 0,
                    CELULAR: '',
                    SENHA: '',
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <AutenticacaoProvider
                        customHook={autenticacao}
                        setConsultouEmpresa={setConsultouEmpresa}
                        inputSenha={inputSenha}
                        setToken={setToken}
                    >
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mt-3" style={!consultouEmpresa ? {display: 'none'} : {}}>
                                <Form.Label>Empresa:</Form.Label>
                                <BadgeValidacao validacao={errors.CD_EMPRESA} confirmado={confirmado} />
                                <Form.Select 
                                    id="CD_EMPRESA"
                                    name={'CD_EMPRESA'}
                                    value={values.CD_EMPRESA ?? ""}
                                    isInvalid={every([errors.CD_EMPRESA, confirmado])}
                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setFieldValue("CD_EMPRESA", e.target.value)
                                    }}
                                    size="lg"
                                >
                                    {
                                        autenticacao.dsListaEmpresas && autenticacao.dsListaEmpresas.length > 0 ?
                                            autenticacao.dsListaEmpresas.map((item: any) => {
                                                return <option key={uuidv4()} value={item.CD_EMPRESA}>{item.NOME}</option>
                                            })
                                        :
                                            <option value={'0'}>{''}</option>
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label>Celular:</Form.Label>
                                <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} />
                                <InputGroup>
                                    <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                    <Form.Control 
                                        name={'TELEFONE'}
                                        type={'text'}
                                        value={values.CELULAR}
                                        disabled={consultouEmpresa}
                                        onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                        isInvalid={every([errors.CELULAR, confirmado])}
                                        placeholder="Celular..." 
                                        size="lg"
                                    />
                                </InputGroup>
                            </Form.Group>                    

                            <Form.Group className="mt-3" style={!consultouEmpresa ? {display: 'none'} : {}}>
                                <Form.Label>Senha:</Form.Label>
                                <BadgeValidacao validacao={errors.SENHA} confirmado={confirmado} />
                                <Form.Control 
                                    ref={inputSenha}
                                    autoFocus
                                    name={'SENHA'}
                                    type={'password'}
                                    value={values.SENHA}
                                    onChange={handleChange}
                                    isInvalid={every([errors.SENHA, confirmado])}
                                    placeholder="Senha..." 
                                    size="lg"
                                />
                            </Form.Group>

                            <FormRodape>
                                <Button 
                                    variant="outline-dark" 
                                    size="lg"
                                    style={consultouEmpresa ? {} : {display: 'none'}}
                                    onClick={ () => {
                                        setFieldValue("CELULAR", "");
                                        setConfirmado(false);
                                        setConsultouEmpresa(false);
                                        autenticacao.mutConsultaEmpresa.reset();
                                        document.getElementById('logoLogin')?.classList.remove('logoLoginTo2');
                                        document.getElementById('logoLogin')?.classList.add('logoLoginTo1');
                                    }}
                                >
                                    Voltar
                                </Button>

                                <Button 
                                    variant="success" 
                                    size="lg" 
                                    type="submit" 
                                    onClick={() => {
                                        setConfirmado(true);
                                    }}
                                >
                                    {consultouEmpresa ? 'Entrar': 'Login'}
                                </Button>
                            </FormRodape>
                        </Form>
                    </AutenticacaoProvider>
                )}
            </Formik>
        </Container>
    );
}

export default Autenticacao;

Autenticacao.propTypes = {
    setToken: PropTypes.func.isRequired
}
import { Formik } from "formik";
import { every } from "lodash";
import { useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import FormRodape from "../../Components/formularios/FormRodape";
import useMask from "../../Hooks/utils/useMask";
import { useContext, useEffect } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { ICadEmpresa, schemaAltEmpresa } from "../../Models/cadEmpresa";
import useCadEmpresa from "../../Hooks/Empresa";
import CadEmpresaProvider from "./provider";

const CadEmpresa = () => {
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [alterado, setAlterado] = useState<boolean>(false);
    const Empresa = useCadEmpresa();
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Configurações da Empresa");
    });

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaAltEmpresa}
                onSubmit={(dados : ICadEmpresa) => {
                    Empresa.Confirmar(dados);
                }}
                initialValues={{
                    NOME            : Empresa.dataSource.NOME || "",
                    CELULAR         : formatter.setMaskPhone(Empresa.dataSource.CELULAR) || "",
                    EMAIL           : Empresa.dataSource.EMAIL || "",
                    TELEFONE        : Empresa.dataSource.TELEFONE || "",
                    CEP             : formatter.setMaskCep(Empresa.dataSource.CEP) || "",
                    ENDERECO        : Empresa.dataSource.ENDERECO || "",
                    ENDERECO_NUMERO : Empresa.dataSource.ENDERECO_NUMERO || "",
                    ENDERECO_BAIRRO : Empresa.dataSource.ENDERECO_BAIRRO || "",
                    ENDERECO_CIDADE : Empresa.dataSource.ENDERECO_CIDADE || "",
                    ENDERECO_UF     : Empresa.dataSource.ENDERECO_UF || "",
                    TAXA            : Empresa.dataSource.TAXA || 0,
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <CadEmpresaProvider
                        customHook={Empresa}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        alterado={alterado}
                        setAlterado={setAlterado}
                    >
                        <Form onSubmit={handleSubmit} onReset={() => Empresa.Cancelar()}>                        
                            <Form.Group className="mt-3">
                                <Form.Label>Nome do Estabelecimento:</Form.Label>
                                <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                                <Form.Control 
                                    name={'NOME'}
                                    type={'text'}
                                    value={values.NOME ?? ""}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NOME, confirmado])}
                                    placeholder="Nome..." 
                                    size="lg"
                                    disabled
                                />
                            </Form.Group>  

                            <Row>
                                <Col xs={12}>
                                    <Accordion className="mt-3">
                                        <Accordion.Item eventKey="0" onClick={() => setTimeout(() => {
                                            document.getElementById('EMAIL')?.scrollIntoView()
                                        }, 500)}>
                                            <Accordion.Header>Informações de Contato</Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>Celular:</Form.Label>
                                                    <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                                        <Form.Control 
                                                            id={'CELULAR'}
                                                            name={'CELULAR'}
                                                            type={'text'}
                                                            value={values.CELULAR}
                                                            onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                                            isInvalid={every([errors.CELULAR, confirmado])}
                                                            placeholder="Celular..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>        

                                                <Form.Group className="mt-3">
                                                    <Form.Label>E-Mail:</Form.Label>
                                                    <BadgeValidacao validacao={errors.EMAIL} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            id={'EMAIL'}
                                                            name={'EMAIL'}
                                                            type={'text'}
                                                            value={values.EMAIL}
                                                            onChange={handleChange}
                                                            isInvalid={every([errors.EMAIL, confirmado])}
                                                            placeholder="Email..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                            </Accordion.Body>
                                            </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>           

                            <Row>
                                <Col xs={12}>
                                    <Accordion className="mt-3">
                                        <Accordion.Item eventKey="1" onClick={() => setTimeout(() => {
                                            document.getElementById('UF')?.scrollIntoView()
                                        }, 500)}>
                                            <Accordion.Header>Endereço</Accordion.Header>
                                            <Accordion.Body>
                                                <Form.Group className="mt-3">
                                                    <Form.Label>CEP:</Form.Label>
                                                    <BadgeValidacao validacao={errors.CEP} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            name={'CEP'}
                                                            type={'text'}
                                                            value={values.CEP}
                                                            onChange={ e => setFieldValue("CEP", formatter.setMaskCep(e.target.value)) }
                                                            isInvalid={every([errors.CEP, confirmado])}
                                                            placeholder="CEP..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>   

                                                <Form.Group className="mt-3">
                                                    <Form.Label>Endereço:</Form.Label>
                                                    <BadgeValidacao validacao={errors.ENDERECO} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            id="ENDERECO"
                                                            name={'ENDERECO'}
                                                            type={'text'}
                                                            value={values.ENDERECO}
                                                            onChange={handleChange}
                                                            isInvalid={every([errors.ENDERECO, confirmado])}
                                                            placeholder="Rua, Avenida, Logradouro..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>   

                                                <Form.Group className="mt-3">
                                                    <Form.Label>Bairro:</Form.Label>
                                                    <BadgeValidacao validacao={errors.ENDERECO_BAIRRO} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            name={'ENDERECO_BAIRRO'}
                                                            type={'text'}
                                                            value={values.ENDERECO_BAIRRO}
                                                            onChange={handleChange}
                                                            isInvalid={every([errors.ENDERECO_BAIRRO, confirmado])}
                                                            placeholder="Bairro..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>    

                                                <Form.Group className="mt-3">
                                                    <Form.Label>Número:</Form.Label>
                                                    <BadgeValidacao validacao={errors.ENDERECO_NUMERO} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            name={'ENDERECO_NUMERO'}
                                                            type={'text'}
                                                            value={values.ENDERECO_NUMERO}
                                                            onChange={handleChange}
                                                            isInvalid={every([errors.ENDERECO_NUMERO, confirmado])}
                                                            placeholder="Nº, Bloco..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>   

                                                <Form.Group className="mt-3">
                                                    <Form.Label>Cidade:</Form.Label>
                                                    <BadgeValidacao validacao={errors.ENDERECO_CIDADE} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            name={'ENDERECO_CIDADE'}
                                                            type={'text'}
                                                            value={values.ENDERECO_CIDADE}
                                                            onChange={handleChange}
                                                            isInvalid={every([errors.ENDERECO_CIDADE, confirmado])}
                                                            placeholder="Cidade..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>  

                                                <Form.Group className="mt-3">
                                                    <Form.Label>UF:</Form.Label>
                                                    <BadgeValidacao validacao={errors.ENDERECO_UF} confirmado={confirmado} />
                                                    <InputGroup>
                                                        <Form.Control 
                                                            id={'UF'}
                                                            name={'ENDERECO_UF'}
                                                            type={'text'}
                                                            value={values.ENDERECO_UF}
                                                            onChange={handleChange}
                                                            isInvalid={every([errors.ENDERECO_UF, confirmado])}
                                                            placeholder="UF..." 
                                                            size="lg"
                                                        />
                                                    </InputGroup>
                                                </Form.Group>  
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>      

                            

                            <div className="mb-3" />
                            <FormRodape>
                                <Button variant="outline-danger" size="lg" type="reset">Cancelar</Button>
                                <Button variant="success" size="lg" disabled={!alterado} onClick={ () => {setConfirmado(true); setShowModal(true)} }>Confirmar</Button>
                            </FormRodape>
                        </Form>
                    </CadEmpresaProvider>
                )}
            </Formik>
        </Container>
    )
}

export default CadEmpresa;
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../../utils/useAPI";
import { DeepPartial } from "../../../Models/genericInterfaces";
import { ICadEmpresaIntegracao, ICadEmpresaIntegracoes } from "../../../Models/cadEmpresaIntegracao";
import useControllers from "../../utils/useControllers";
import { useContext } from "react";
import { CtxParams } from "../../../Contexts/ctxParams";

const useEmpresaIntegracaoController = (updateQueryKey?: string, isArray: boolean = true) : IUseEmpresaIntegracaoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const UserParams = useContext(CtxParams);
    const { Update, Delete, Create } = useControllers();

    const primaryKey = 'CD_INTEGRACAO';
    const endpoint = 'empresaintegracao';

    const mutDeletar = useMutation(
        (data : DeepPartial<ICadEmpresaIntegracao>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICadEmpresaIntegracao>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<ICadEmpresaIntegracoes>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutAlterar = useMutation(
        (data : DeepPartial<ICadEmpresaIntegracao>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICadEmpresaIntegracao>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<ICadEmpresaIntegracoes>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<ICadEmpresaIntegracao>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICadEmpresaIntegracao>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<ICadEmpresaIntegracoes>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['Sucesso']);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<ICadEmpresaIntegracao>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: ICadEmpresaIntegracao) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<ICadEmpresaIntegracoes>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !UserParams.ENTREGADOR,
        }
    );    

    return {
        Deletar: mutDeletar,
        Alterar: mutAlterar,
        Cadastrar: mutCadastrar,
        Buscar: mutBuscar,
        Listar: qryListar,
    }
}

interface IUseEmpresaIntegracaoController {
    Deletar     : UseMutationResult<any, any, DeepPartial<ICadEmpresaIntegracao>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<ICadEmpresaIntegracao>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<ICadEmpresaIntegracao>, unknown>;
    Buscar      : UseMutationResult<ICadEmpresaIntegracao, any, DeepPartial<ICadEmpresaIntegracao>, unknown>
    Listar      : UseQueryResult<ICadEmpresaIntegracoes, unknown>;
}

export default useEmpresaIntegracaoController;
import { useCallback } from "react";
import useApp from "../utils/useApp";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IFechamento } from "../../Models/Fechamento";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";
import useMask from "../utils/useMask";
import { IUseFechamentoController } from "./controller";

const useFechamentoModal = (Controller: IUseFechamentoController) : IUseFechamentoModal => {
    const {Modal} = useApp();
    const formatter = useMask();

    const Fechar = useCallback((Dados: DeepPartial<IFechamento>) => {
        const gravar = (Values: ICtxAppParams_ModalInputs) => {
            Controller.Alterar.mutate({CD_FECHAMENTO: Dados.CD_FECHAMENTO, FECHADO: true});
        };

        Modal({
            Visibilidade    : true,
            Titulo          : 'Confirmação de Fechamento',
            Descricao       : '',
            Anexos          : <>
                Tem certeza que deseja fechar o dia:<br/>
                {formatter.setDate(new Date(Dados.DT_COMPETENCIA! as Date), {day: "2-digit", weekday: "long", month: "long", year: "numeric"})}?<br />
                <br />
                Não será mais possível fazer entregas neste dia!
            </>,
            Inputs          : undefined,
            Variante        : 'primary',
            onSubmit        : gravar
        })
    }, [Modal, Controller.Alterar, formatter]);

    const Abrir = useCallback((Dados: DeepPartial<IFechamento>) => {
        const gravar = (Values: ICtxAppParams_ModalInputs) => {
            Controller.Cadastrar.mutateAsync({}).then(() => Controller.Buscar.mutate({DT_COMPETENCIA: ''}));
        };

        Modal({
            Visibilidade    : true,
            Titulo          : 'Confirmação Abertura do Dia',
            Descricao       : '',
            Anexos          : <>
                Deseja abrir o dia: {formatter.setDate(new Date(formatter.getCurrDate('-')), {day: "2-digit", weekday: "long", month: "long", year: "numeric"})}?
            </>,
            Inputs          : undefined,
            Variante        : 'success',
            onSubmit        : gravar
        })
    }, [Modal, Controller.Cadastrar, Controller.Buscar, formatter]);

    return {
        Fechar  : Fechar,
        Abrir   : Abrir 
    }
};

export interface IUseFechamentoModal {
    Fechar  : (Dados: DeepPartial<IFechamento>) => void;
    Abrir   : (Dados: DeepPartial<IFechamento>) => void;
}

export default useFechamentoModal;
import * as Yup from 'yup';

export const CD_EMPRESA = Yup.number()
    .min(1, 'Código da empresa deve ser válido')
    .required('Código da empresa deve ser preenchido')

export const NOME = Yup.string()
    .min(3, 'Nome deve ser válido')
    .max(50, 'Nome está muito longo!')
    .required('Nome precisa ser preenchido')

export const EMAIL = Yup.string()
    .email("Deve ser válido!")
    .max(255, "Muito Longo")
    .required("Precisa ser preenchido")

export const CELULAR = Yup.string()
    .min(15, 'Celular deve estar completo')
    .max(15, 'Celular deve estar completo')
    .required('Celular deve ser preenchido')    

export const SENHA = Yup.string()
    .min(5, 'Senha deve ter no minimo 5 caracteres')
    .max(16, 'Senha deve ter no máximo 16 caracteres')
    .required('Senha deve ser preenchida')    

export const SENHA_CONFIRMA = Yup.string()
    .when('SENHA', {
        is: (SENHA: any) => SENHA && SENHA.length > 0,
        then: Yup.string().required('Deve ser preenchida a confirmação de senha')
    })
    .oneOf([Yup.ref('SENHA'), null], 'Confirmação de senha deve coincidir com a senha')

export const TAXA = Yup.number()
    .min(0, 'Não pode ser negativo')

export const TX_ENTREGA = Yup.number()
    .min(0, 'Não pode ser negativo')    

export const VLR_PEDIDO = Yup.number()
    .min(0, 'Não pode ser negativo')
    .required('Deve ter um valor')

export const VLR_TROCO = Yup.number()
    .min(0, 'Não deve ser negativo')

export const FORMA_PAGTO = Yup.string()
    .required('Forma de pagamento deve ser descrita')

export const STATUS = Yup.string()

export const ENTREGADOR = Yup.boolean()

export const FECHADO = Yup.boolean()

export const PAGO = Yup.boolean()

export const CD_CLIENTE = Yup.number()
    .min(0, 'Código do cliente deve ser válido')
    .required('Deve ser selecionado um cliente já cadastrado')
    .nullable(true);

export const CD_CLIENTE_ENDERECO = Yup.number()
    .min(0, 'Endereço do cliente deve ser válido')
    .required('Deve ser selecionado um endereço já cadastrado')
    .nullable(true);

export const CD_ENDERECO_PADRAO = Yup.number()
    .nullable(true)

export const CEP = Yup.string()
    .min(9, 'CEP deve estar completo')
    .max(9, 'CEP deve ser válido')
    .required('CEP deve ser preenchido')

export const LOGRADOURO = Yup.string()
    .required('Deve ser preenchido')

export const CIDADE = Yup.string()
    .required('Deve ser preenchido')

export const NUMERO = Yup.string()
    .required('Deve ser preenchido')

export const UF = Yup.string()
    .required('Deve ser preenchido')

export const BAIRRO = Yup.string()
    .required('Deve ser preenchido')

export const DT_COMPETENCIA = Yup.date()
    .required('Data de competência deve ser informada')

export const CD_CATEGORIA = Yup.number()
    .min(0, 'Categoria deve ser válida')
    .required('Deve ser selecionada')
    .nullable(false);    

export const CD_CATEGORIA_MESTRE = Yup.number()
    .min(0, 'Categoria mestre deve ser válida')
    .required('Deve ser selecionada')
    .nullable(false);        
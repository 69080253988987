import { useState, useMemo, useContext, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { DeepPartial, EMode, IReactTable_Grid } from "../../../Models/genericInterfaces";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { IEmpresaParametro, IEmpresaParametros } from "../../../Models/EmpresaParametro";
import useEmpresaParametroController from "./controller";

const useEmpresaParametro = (CodigoItem?: string) : IUseEmpresaParametro => {
    const navigate = useNavigate();
    const location = useLocation();

    const { Filtros } = useContext(CtxAppParams);
    const Controller = useEmpresaParametroController('listaEmpresaParametros');
    const [Mode, setMode] = useState<EMode>(EMode.Browse);
    const isSubRender = location.pathname.includes('/empresa');

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<IEmpresaParametro>({} as IEmpresaParametro); //eslint-disable-line

    const dsItem = useMemo<IEmpresaParametro>(() => 
        Controller.Buscar.isSuccess ? 
            Controller.Buscar.data
        : 
            {} as IEmpresaParametro
    , [Controller.Buscar]);

    const dsLista = useMemo<IEmpresaParametros>(() => 
        Controller.Listar.isSuccess ? 
            Controller.Listar.data.filter((data: any) => {
                if (!!data) {
                    return data[Filtros.EmpresaParametro.tipoPesquisa]?.toLowerCase().indexOf(Filtros.EmpresaParametro.pesquisa.toLowerCase()) !== -1
                } else {
                    return data
                }
            })
        : 
            [{} as IEmpresaParametro]
    , [Controller.Listar, Filtros.EmpresaParametro]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "Codigo",
            Header: "Código",
            accessor: "CD_PARAMETRO"
        },
        {
            id: "ATIVO",
            Header: "Situação",
            Cell: (row: any) : any =>
                <Form.Check
                    name="ENTREGADOR"
                    style={{zoom: '1.1'}}
                    id="ENTREGADOR"
                    type="switch"
                    checked={row.row.original['ATIVO'] ?? true}
                    label={row.row.original['ATIVO'] ? 'Ativo' : 'Inativo'}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                    onChange={(e: any) => {
                        e.stopPropagation();
                        Controller.Alterar.mutate({
                            CD_PARAMETRO: row.row.original["CD_PARAMETRO"],
                            CD_EMPRESA: 1,
                            ATIVO: !row.row.original['ATIVO']
                        })
                    }}
                />
        },
        {
            id: "Parametro",
            Header: "Parâmetro",
            accessor: "PARAMETRO_NOME"
        },
        {
            id: "Valor",
            Header: "Valor",
            accessor: "VALOR"
        }
    ], [Controller.Alterar]);

    const salvar = useCallback((EmpresaParametro: DeepPartial<IEmpresaParametro>) => {
            Controller.Alterar.mutate(EmpresaParametro, {onSuccess: () => navigate(`/empresa/parametros/`)});
    }, [Controller.Alterar, navigate]);

    const abrir = useCallback((EmpresaParametro: DeepPartial<IEmpresaParametro>) => {
        const Codigo = EmpresaParametro.CD_PARAMETRO || Number(CodigoItem);

        if (EmpresaParametro.CD_PARAMETRO === 0) {
            navigate(`/empresa/parametros/`);
            return
        };

        if (EmpresaParametro.CD_PARAMETRO !== undefined) { // Se informado, vai mudar a página
            navigate(`/empresa/parametros/cadastro/${EmpresaParametro.CD_PARAMETRO}`);
            return
        };        

        if (Codigo === -1) {
            setMode(EMode.Include);
            Controller.Buscar.reset();
        } else {
            setMode(EMode.Edit);
            Controller.Buscar.mutate({CD_PARAMETRO: Codigo});
        };
    }, [Controller.Buscar, CodigoItem, navigate]);

    useEffect(() => {
        if (CodigoItem !== undefined) {
            abrir({})
        } else {
            setMode(EMode.Browse);
        }
    }, [CodigoItem, Mode]); //eslint-disable-line

    return {
        Salvar: salvar,
        Abrir: abrir,

        Item: dsItem,
        Lista: dsLista,
        Grid: gridLista,

        Modo: Mode,
        SubRender: isSubRender,

        ShowModal: showModal,
        setShowModal: setShowModal,
        Selecionado: selecionado,
    }
}

export interface IUseEmpresaParametro {
    Salvar              : (EmpresaParametro: DeepPartial<IEmpresaParametro>) => void;
    Abrir               : (EmpresaParametro: DeepPartial<IEmpresaParametro>) => void;
    Item                : IEmpresaParametro;
    Lista               : IEmpresaParametros;
    Grid                : IReactTable_Grid;
    Modo                : EMode;
    SubRender           : boolean;
    ShowModal           : boolean;
    setShowModal        : React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado         : IEmpresaParametro;
};

export default useEmpresaParametro;
import { useContext, useEffect } from "react";
import { Button, Container, InputGroup, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import Tabela from "../../../Components/tabela";
import { CtxParams } from "../../../Contexts/ctxParams";
import FormRodape from "../../../Components/formularios/FormRodape";
import useCadUsuarioLista from "../../../Hooks/Empresa/EmpresaUsuario/lista";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { ETpFiltroUsuario } from "../../../Models/cadUsuario";
import ModalConfirmacao from "../../../Components/formularios/ModalConfirmacao";
import useApp from "../../../Hooks/utils/useApp";

const CadUsuarioLista = () => {
    const UsuarioLista = useCadUsuarioLista();
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();

    useEffect(() => {
        AppParams.setPagina("Usuários");
    });

    return (
        <Container className="verticalCenter">
            <ModalConfirmacao 
                titulo="Confirmação de Exclusão"
                show={UsuarioLista.Modal} 
                onHide={UsuarioLista.setModal}
                variant={"danger"}
                onSubmitHandler={() => {
                    UsuarioLista.Excluir.mutate(UsuarioLista.Selecionado);
                    UsuarioLista.setModal(false);
                }}
            >
                Tem certeza que deseja excluir o usuário {UsuarioLista.Selecionado.NOME}?
            </ModalConfirmacao>

            <div className="d-flex w-100 m-3 justify-content-around">
                <InputGroup>
                    <DropdownButton
                        variant="outline-secondary"
                        title={
                            (() => {
                                switch (AppParams.Filtros.Usuario.tipoPesquisa) {
                                case ETpFiltroUsuario.Nome:
                                    return 'Nome'
                                default:
                                    return 'erro'
                                }
                            })()
                        }
                        id="TPFiltro"
                    >
                        <Dropdown.Item key={'1'} onClick={() => Filtrar('Usuario', {tipoPesquisa: ETpFiltroUsuario.Nome})}>Nome</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control
                        placeholder="Filtro..."
                        aria-label="Filtro"
                        size="lg"
                        value={AppParams.Filtros.Usuario.pesquisa}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => Filtrar('Usuario', {pesquisa: e.target.value})}
                    />
                </InputGroup>
            </div>

            <Tabela colunas={UsuarioLista.Grid} dados={UsuarioLista.Lista} handleClick={UsuarioLista.Editar} />
            
            <FormRodape style={UserParams.ENTREGADOR ? {display: 'none'} : {}}>
                <Button variant="success" size="lg" onClick={UsuarioLista.Cadastrar}><TiPlus /> Cadastrar</Button>
            </FormRodape>
        </Container>
    );
};

export default CadUsuarioLista;
import { Outlet, useParams } from "react-router-dom";
import useCadUsuario from "../../../Hooks/Empresa/EmpresaUsuario";

const EmpresaUsuario = () => {
    const { cdUsuario } = useParams();
    const CadastroUsuario = useCadUsuario(typeof cdUsuario === 'undefined' ? '-1' : cdUsuario?.toString());

    return (
        <Outlet context={CadastroUsuario} />
    )
}

export default EmpresaUsuario;
import { useCallback } from "react";
import { DeepPartial } from "../../Models/genericInterfaces";
import useApp from "../utils/useApp";
import { IUseFormaPagamentoController } from "./controller";
import { IFormaPagamento } from "../../Models/FormaPagamento";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";

const useFormaPagamentoModal = (Controller: IUseFormaPagamentoController) : IUseFormaPagamentoModal => {
    const {Modal} = useApp();

    const ExcluirForma = useCallback((Dados: DeepPartial<IFormaPagamento>) => {
        const gravar = (Values: ICtxAppParams_ModalInputs) => {
            Controller.Deletar.mutate({CD_FORMA: Dados.CD_FORMA});
        };

        Modal({
            Visibilidade    : true,
            Titulo          : 'Confirmação de exclusão',
            Descricao       : `Tem certeza que deseja excluir a forma de pagamento ${Dados.NOME}?\nNão será possível reverter a exclusão.`,
            Anexos			: undefined,
            Inputs          : undefined,
            Variante        : 'danger',
            onSubmit        : gravar
        })
    }, [Modal, Controller.Deletar]);

    return {
        ExcluirForma: ExcluirForma
    }
}

export interface IUseFormaPagamentoModal {
    ExcluirForma: (Dados: DeepPartial<IFormaPagamento>) => void;
}

export default useFormaPagamentoModal;
import { useContext, useEffect } from "react";
import { Button, Container} from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import Tabela from "../../Components/tabela";
import { CtxParams } from "../../Contexts/ctxParams";
import FormRodape from "../../Components/formularios/FormRodape";
import useMask from "../../Hooks/utils/useMask";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { useOutletContext } from "react-router-dom";
import { IUseFechamento } from "../../Hooks/Fechamento/lista";
import { IFechamento } from "../../Models/Fechamento";
import FechamentoResumo from "./resumo";

const FechamentoLista = () => {
    const Fechamento = useOutletContext<IUseFechamento>();
    const UserParams = useContext(CtxParams);
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina(UserParams.ENTREGADOR ? "Ranking" : "Fechamento");
    });

    return (
        <Container className="verticalCenter">
            <FechamentoResumo Fechamento={Fechamento} UserParams={UserParams} formatter={formatter} />

            <p className="text-center w-100 mt-3">{`Fechamentos efetuados recentemente (Últimos 60 dias)`}</p>
            
            {
                (Fechamento.Controller.Listar.data?.length === 0 && Fechamento.Lista.length === 1)
                ? 'Ainda não houveram movimentações na empresa'
                : <Tabela 
                    colunas={Fechamento.Grid} 
                    dados={Fechamento.Lista} 
                    pagination={true} 
                    handleClick={(e: IFechamento) => 
                        Fechamento.Controller.Buscar.mutateAsync({DT_COMPETENCIA: e.DT_COMPETENCIA})
                        .then(() => {
                            window.scrollTo({top: 0});

                            if (Fechamento.Controller.ListarItens.isSuccess) {
                                Fechamento.Controller.ListarItens.mutate({DT_COMPETENCIA: String(e.DT_COMPETENCIA)});
                            }
                        })
                    } 
                    estadoInicial={Fechamento.EstadoInicial} 
                />
            }            
            
            <FormRodape style={UserParams.ENTREGADOR ? {display: 'none'} : {}}>
                <Button variant="success" size="lg" onClick={() => Fechamento.Modal.Abrir({})}><TiPlus /> Abrir novo dia</Button>
            </FormRodape>
        </Container>
    );
};

export default FechamentoLista;
import { useMemo } from "react";
import { IUseCondicaoPagamentoController } from "./controller"
import { ICondicaoPagamento, ICondicaoPagamentos } from "../../Models/CondicaoPagamento";

const useCondicaoPagamentoDatasource = (Controller: IUseCondicaoPagamentoController) : IUseCondicaoPagamentoDatasource => {
    const Lista = useMemo<ICondicaoPagamentos>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
            return Controller.Listar.data;
        } else {
            return [{} as ICondicaoPagamento];
        };
    }, [Controller.Listar]);

    return {
        Lista   : Lista
    }
};

export interface IUseCondicaoPagamentoDatasource {
    Lista   : ICondicaoPagamentos;
}

export default useCondicaoPagamentoDatasource;
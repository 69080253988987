import { Badge } from "react-bootstrap";
import { EStatus } from "../../Models/cadEntrega";
import { MdDeliveryDining, MdOutlineTableBar } from "react-icons/md";
import { IoFastFoodOutline } from "react-icons/io5";

const BadgeStatus = ({status, tipo, icone} : {status: EStatus, tipo: "entrega" | "pedido" | "mesa", icone: boolean}) => {
    if (tipo === 'pedido') {
        switch (status) {
            case EStatus.Andamento:
                return <Badge pill className="d-flex align-items-center" bg="info">{icone ? <IoFastFoodOutline style={{zoom: '1.5'}} /> : null} Em Produção</Badge>;
            case EStatus.Cancelada:
                return <Badge pill className="d-flex align-items-center" bg="danger">{icone ? <IoFastFoodOutline style={{zoom: '1.5'}} /> : null} Cancelado</Badge>;
            case EStatus.Finalizada:
                return <Badge pill className="d-flex align-items-center" bg="success">{icone ? <IoFastFoodOutline style={{zoom: '1.5'}} /> : null} Produzido</Badge>;
            case EStatus.Pendente:
                return <Badge pill className="d-flex align-items-center" bg="light" text="dark">{icone ? <IoFastFoodOutline style={{zoom: '1.5'}} /> : null} Pendente</Badge>;
            default:
                return <Badge pill className="d-flex align-items-center" bg="dark">{icone ? <IoFastFoodOutline style={{zoom: '1.5'}} /> : null} Erro</Badge>;
        }
    } else if (tipo === 'mesa') {
        switch (status) {
            case EStatus.Andamento:
                return <Badge pill className="d-flex align-items-center" bg="info">{icone ? <MdOutlineTableBar style={{zoom: '1.5'}} /> : null} Em uso</Badge>;
            case EStatus.Cancelada:
                return <Badge pill className="d-flex align-items-center" bg="danger">{icone ? <MdOutlineTableBar style={{zoom: '1.5'}} /> : null} Cancelado</Badge>;
            case EStatus.Finalizada:
                return <Badge pill className="d-flex align-items-center" bg="success">{icone ? <MdOutlineTableBar style={{zoom: '1.5'}} /> : null} Finalizada</Badge>;
            case EStatus.Pendente:
                return <Badge pill className="d-flex align-items-center" bg="light" text="dark">{icone ? <MdOutlineTableBar style={{zoom: '1.5'}} /> : null} Entrega pendente</Badge>;
            default:
                return <Badge pill className="d-flex align-items-center" bg="dark">{icone ? <MdOutlineTableBar style={{zoom: '1.5'}} /> : null} Erro</Badge>;
        }
    } else { //entrega
        switch (status) {
            case EStatus.Andamento:
                return <Badge pill className="d-flex align-items-center" bg="info">{icone ? <MdDeliveryDining style={{zoom: '1.5'}} /> : null} Em Rota</Badge>;
            case EStatus.Cancelada:
                return <Badge pill className="d-flex align-items-center" bg="danger">{icone ? <MdDeliveryDining style={{zoom: '1.5'}} /> : null} Cancelado</Badge>;
            case EStatus.Finalizada:
                return <Badge pill className="d-flex align-items-center" bg="success">{icone ? <MdDeliveryDining style={{zoom: '1.5'}} /> : null} Entregue</Badge>;
            case EStatus.Pendente:
                return <Badge pill className="d-flex align-items-center" bg="light" text="dark">{icone ? <MdDeliveryDining style={{zoom: '1.5'}} /> : null}Entrega Pendente</Badge>;
            default:
                return <Badge pill className="d-flex align-items-center" bg="dark">{icone ? <MdDeliveryDining style={{zoom: '1.5'}} /> : null} Erro</Badge>;
        }
    }
}

export default BadgeStatus;
import { useContext } from "react";
import useFormaPagamentoIntegracaoController from "../FormaPagamento/controllerIntegracao";
import { IUseMesaController } from "../Mesa/controller";
import { IUsePedidoController } from "../Pedido/controller";
import { IUseEntregaController } from "../cadEntrega/controller";
import useVendaPagamentoController, { IUseVendaPagamentoController } from "./controller";
import useVendaPagamentoModal, { IUseVendaPagamentoModal } from "./modal";
import { CtxAppParams } from "../../Contexts/ctxAppParams";

const useVendaPagamento = (ControllerVenda?: IUsePedidoController | IUseMesaController | IUseEntregaController) : IUseVendaPagamento => {
    const AppParams = useContext(CtxAppParams);

    const Controller    = useVendaPagamentoController('vendaPagamento', true, true);
    const ControllerIntegracao = useFormaPagamentoIntegracaoController('formaPagamentoIntegracao', true, AppParams.Logado);
    const Modal         = useVendaPagamentoModal(Controller, ControllerVenda, ControllerIntegracao);

    return {
        Controller      : Controller,
        Modal           : Modal
    }
}

export interface IUseVendaPagamento {
    Controller          : IUseVendaPagamentoController;
    Modal               : IUseVendaPagamentoModal;
}

export default useVendaPagamento;
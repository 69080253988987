import useAPI from "../utils/useAPI";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from 'react-query';
import { ICadTaxa } from "../../Models/cadTaxa";
import { useNavigate } from "react-router-dom";
import { IEnderecoBuscaCEP } from "../../Models/cadClienteEndereco";
import useCEP from "../utils/useCEP";

const useCadTaxa = (CdTaxa: string) : IUseCadTaxa => {
    const API = useAPI();
    const CEP = useCEP();
    const navigate = useNavigate();
    const [bairroBusca, setBairroBusca] = useState<string>("");

    const qryTaxa = useQuery(
        'qryTaxa',
        (   
            () => API.get('taxas/buscar/', CdTaxa, false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            cacheTime: 0,
            retry: false,
            enabled: false
        }
    );

    useEffect(() => {
        if (!qryTaxa.isError) {
            if (CdTaxa !== '-1') {qryTaxa.refetch()};
        } else { 
            alert(qryTaxa.error);
            navigate('/cadTaxa');
        };
    }, [qryTaxa.isSuccess, qryTaxa.error, qryTaxa.isError, CdTaxa]); // eslint-disable-line

    const dsTaxa = useMemo<ICadTaxa>(() => 
        qryTaxa.isSuccess && CdTaxa !== '' ? qryTaxa.data : {
            CD_TAXA: null,
            TAXA: 0,
            CEP: "",
            BAIRRO: "",
        }
    , [qryTaxa, CdTaxa]);

    const mutBuscaCep = useMutation((cep: string) => CEP.get(cep));

    const buscaCep = (cep: string) => {
        if (cep.length === 8) {
            mutBuscaCep.mutate(cep, {
                onSuccess: (data : IEnderecoBuscaCEP) => {
                    setBairroBusca(data.bairro!);
                },
                onError: (e: any) => console.log(e)
            });
        };
    };

    const mutCadTaxa = useMutation((data : ICadTaxa) => API.post('taxas/cadastrar', data), {
        onSuccess: () => navigate('/listaTaxa'),
        onError: (ret: any) => alert(ret.error)
    });

    const mutAltTaxa = useMutation((data : ICadTaxa) => API.put(`taxas/alterar/${CdTaxa}`, data), {
        onSuccess: () => navigate('/listaTaxa'),
        onError: (ret: any) => alert(JSON.stringify(ret))
    });
    
    const confirmar = (dados: ICadTaxa) => {
        if (CdTaxa === '-1') {
            mutCadTaxa.mutate(dados);
        } else {
            mutAltTaxa.mutate(dados);
        };
    }

    const cancelar = () => {
        navigate(-1);
    };

    return {
        dsTaxa,
        bairroBusca,
        Confirmar: confirmar,
        Cancelar: cancelar,
        BuscaCep: buscaCep,
    }
}

export interface IUseCadTaxa {
    dsTaxa      : ICadTaxa; 
    bairroBusca : string;
    Confirmar   : (dados: ICadTaxa) => void;
    Cancelar    : () => void;
    BuscaCep    : (cep: string) => void;
}

export default useCadTaxa;
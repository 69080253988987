import { useMutation, useQueryClient } from "react-query";
import useAPI from "./useAPI";
import { ICadEmpresaIntegracoes } from "../../Models/cadEmpresaIntegracao";
import { useCallback, useContext, useEffect, useState } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useApp from "./useApp";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";

interface IntegracaoData {
    integracao: string;
    length: number;
    index: number;
    cd_integracao: number;
    slug: string;
}

const useIntegracoes = (
    showErrors: boolean = false,
    refetchEntregas: boolean = false,
    lockTime: number = 10000,
    lockManualSync: boolean = false
) => {
    const API = useAPI();
    const App = useApp();
    const AppParams = useContext(CtxAppParams);
    const queryClient = useQueryClient();
    const { setBloqueiaSincronizacao } = useContext(CtxAppParams);
    const [allResolved, setAllResolved] = useState<boolean>(true);

    useEffect(() => {
        if (allResolved && refetchEntregas) {
            queryClient.invalidateQueries('entregaLista');
        }
    }, [allResolved, queryClient, refetchEntregas, AppParams.Modal.Inputs]);

    const mutAuthIfood = useMutation(
        (data : any) => 
            API.post(`ifood/autenticacao`, data !== undefined ? data : "")
        , {
            // onSuccess: (ret: ) => {
            //     // setItem(ret);
            // },
            onError: (error: any) => {
                // alert(error)
            }
    });

    const integracao = useMutation((data: IntegracaoData) => API.get(`integracao/buscar/${data.integracao}${data.integracao === 'IFOOD' ? '?cd_integracao='+data.cd_integracao : ''}`, "", false), {
        retry: false,
        onSuccess: (ret, data) => {
            if (data.index === data.length) {
                setAllResolved(true)

                if (lockManualSync) {
                    setBloqueiaSincronizacao(false);
                }
            }
        },
        onError: (erro, data) => {
            if (data.integracao === 'IFOOD') {
                authIfood(data.cd_integracao, data.slug);
                // setAllResolved(true)
                
                // if (lockManualSync) {
                //     setBloqueiaSincronizacao(false);
                // }
                // return;
            };

            if (showErrors) {
                alert(`Erro ao buscar os dados da integração ${data.integracao}.
                Erro retornado: ${erro}`);
            };

            if (data.index === data.length) {
                setAllResolved(true)

                if (lockManualSync) {
                    setBloqueiaSincronizacao(false);
                }
            };
        }
    });

    const executeMutations = async (Integracoes: ICadEmpresaIntegracoes) => {
        const Principal = Integracoes.findIndex( ({ ATIVO, TIPO }) => ATIVO === true && TIPO === 'P' );
        if (Integracoes.length > 0 && Integracoes[Principal]) { // Se tiver integrações no array
            const diff = (new Date()).getTime() - (new Date(Integracoes[Principal].ULT_CONSULTA!)).getTime(); // Pega data da ultima consulta do primeiro item e pega a diferença com a data atual
            if ((diff > lockTime)) { // Se a diferença for maior que o tempo padrão de espera
                setAllResolved(false); // Vai setar que pode executar (não resolvido) para iniciar o processo

                if (lockManualSync) {
                    setBloqueiaSincronizacao(true); // Se for sincronização manual, vai bloquear por enquanto
                }

                for (let index = 0; index < Integracoes.length; index++) {
                    if ((Integracoes[index].TIPO === 'P')) { // Só vai executar o que for proprio
                        try {
                            await integracao.mutateAsync({
                                integracao: Integracoes[index].NOME,
                                length: Integracoes.length,
                                index: index + 1,
                                cd_integracao: Number(Integracoes[index].CD_INTEGRACAO),
                                slug: String(Integracoes[index].SLUG ?? '')
                            });
                        } catch (e) {
                            
                        }
                    } else { // Se for de terceiros não vai fazer nada, o código abaixo verifica se é o ultimo item da array, se for, vai solucionar as integrações e tirar o bloqueio manual caso houver
                        if (index === Integracoes.length - 1) {
                            setAllResolved(true)
            
                            if (lockManualSync) {
                                setBloqueiaSincronizacao(false);
                            }
                        }
                    }
                }
            }
        } else if (Integracoes.length === 0 && refetchEntregas) {
            queryClient.invalidateQueries('listaEntrega');
        }
    };

    const authIfood = useCallback((cd_integracao: number, slug: string) => {
        const gravarCodigo = (Values: ICtxAppParams_ModalInputs) => {
            mutAuthIfood.mutate({ID: Values![0].Value, CD_INTEGRACAO: cd_integracao});
        };

        mutAuthIfood.mutate({CD_INTEGRACAO: cd_integracao}, {onSuccess: (ret: string | undefined) => {
            App.Modal({
                Visibilidade    : true,
                Titulo          : 'Autenticação iFood',
                Descricao       : `Aprove a permissão ${slug} para o Frota em: `,
                Anexos			: <a href={ret ? ret : ''} target="_blank" rel="noreferrer">{ret ? ret : 'erro'}</a>,
                Inputs          : [{
                    ID: 'CD_AUTH_IFOOD',
                    Label: 'Código de confirmação:',
                    Placeholder: 'Código gerado na confirmação...',
                    Type: 'text',
                    Value: ''
                }],
                Variante        : 'success',
                onSubmit        : gravarCodigo
            })
        }});
    }, [mutAuthIfood, App]);

    return {
        executeMutations,
        isSuspended: allResolved,
        authIfood: authIfood
    }
};

export default useIntegracoes;
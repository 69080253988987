import { Button, Container, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import FormRodape from "../../Components/formularios/FormRodape";
import Tabela from "../../Components/tabela";
import useCadClienteLista from "../../Hooks/cadCliente/lista";
import { ETpFiltroCliente } from "../../Models/cadCliente";
import { useContext, useEffect } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useApp from "../../Hooks/utils/useApp";

const CadClienteLista = () => {
    const ClienteLista = useCadClienteLista();
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();

    useEffect(() => {
        AppParams.setPagina("Clientes");
    });

    return (
        <Container className="verticalCenter">
            <div className="d-flex w-100 m-3 justify-content-around">
                <InputGroup>
                    <DropdownButton
                        variant="outline-secondary"
                        title={
                            (() => {
                                switch (AppParams.Filtros.Cliente.tipoPesquisa) {
                                case ETpFiltroCliente.Nome:
                                    return 'Nome'
                                case ETpFiltroCliente.Celular:
                                    return 'Celular'
                                default:
                                    return 'erro'
                                }
                            })()
                        }
                        id="TPFiltro"
                    >
                        <Dropdown.Item key={'1'} onClick={() => Filtrar('Cliente', {tipoPesquisa: ETpFiltroCliente.Nome})}>Nome</Dropdown.Item>
                        <Dropdown.Item key={'2'} onClick={() => Filtrar('Cliente', {tipoPesquisa: ETpFiltroCliente.Celular})}>Celular</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control
                        placeholder="Filtro..."
                        aria-label="Filtro"
                        size="lg"
                        value={AppParams.Filtros.Cliente.pesquisa}
                        onChange={(e : React.ChangeEvent<HTMLInputElement>) => Filtrar('Cliente', {pesquisa: e.target.value}) }
                    />
                </InputGroup>
            </div>

            <Tabela colunas={ClienteLista.Grid} dados={ClienteLista.Lista} handleClick={ClienteLista.Editar} pagination={true} />
            
            <FormRodape>
                <Button variant="success" size="lg" onClick={ClienteLista.Cadastrar}><TiPlus /> Cadastrar</Button>
            </FormRodape>
        </Container>
    );
};

export default CadClienteLista;
import { useState, useMemo, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DeepPartial, EMode, IReactTable_Grid } from "../../Models/genericInterfaces";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { EProdutoTipo, ETpFiltroProduto, IEstoqueMovimento, IEstoqueMovimentos, IProduto, IProdutos } from "../../Models/cardapio";
import useProdutoController from "./controller";
import { Badge, Button, Form } from "react-bootstrap";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import useMask from "../utils/useMask";
import useApp from "../utils/useApp";
import useEstoqueMovimentoController, { IUseEstoqueMovimentoController } from "./controllerEstoque";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";

const useProduto = (CodigoEmpresa?: string, CodigoItem?: string) : IUseProduto => {
    const navigate = useNavigate();
    const { setMaskMoney, getCapitalized, setDate } = useMask();
    const App = useApp();
    const CdEmpresa = CodigoEmpresa;
    const {Modal} = useApp();

    const { Filtros } = useContext(CtxAppParams);
    const Controller = useProdutoController('listaProduto');
    const ControllerEstoque = useEstoqueMovimentoController('listaEstoque');
    const [Mode, setMode] = useState<EMode>(EMode.Browse);
    const [continuarCadastro, setContinuarCadastro] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<IProduto>({} as IProduto); //eslint-disable-line

    const salvarImagem = useCallback( async (Produto: DeepPartial<IProduto>) => {
        return await new Promise<boolean> ((resolve, reject) => {
            if (Produto.LOCAL_IMAGEM !== '') {
                let formData = new FormData();
                formData.append('CD_PRODUTO', String(Produto.CD_PRODUTO!));
                formData.append('CAMINHO_IMAGEM', Produto.IMAGEM as Blob);

                Controller.CadastrarImagem.mutateAsync(formData, {
                    onSuccess: () => {
                        resolve(true)
                    },
                    onError : (e) => {
                        reject(e)
                    }
                });
            } else {
                resolve(true)
            }
        })
    }, [Controller.CadastrarImagem]);

    const salvar = useCallback( async (Produto: DeepPartial<IProduto>) => {
        return await new Promise<boolean> ((resolve, reject) => {
            const produto : DeepPartial<IProduto> = {
                ...Produto as DeepPartial<IProduto>,
                LOCAL_IMAGEM: undefined,
                CAMINHO_IMAGEM: undefined,
                IMAGEM: undefined
            };
            if (Mode === EMode.Edit) {
                Controller.Alterar.mutateAsync(produto).then(() => resolve(true)).catch((e) => reject(e));
            } else if (Mode === EMode.Include) {
                Controller.Cadastrar.mutateAsync(produto).then(() => resolve(true)).catch((e) => reject(e));
            } else {
                reject('Operacao invalida')
            }
        })
    }, [Controller.Cadastrar, Controller.Alterar, Mode]);

    const abrir = useCallback((Produto: DeepPartial<IProduto>) => {
        const Codigo = Produto.CD_PRODUTO || Number(CodigoItem);

        if (Produto.CD_PRODUTO === 0) {
            // navigate(`/produto/${CodigoEmpresa}`);
            navigate(-1);
            return
        };

        if (Produto.CD_PRODUTO !== undefined) { // Se informado, vai mudar a página
            navigate(`/produto/${CodigoEmpresa}/cadastro/${Produto.CD_PRODUTO}`);
            return
        };        

        if (Codigo === -1) {
            setMode(EMode.Include);
            Controller.Buscar.reset();
        } else {
            setMode(EMode.Edit);
            Controller.Buscar.mutate({CD_PRODUTO: Codigo});
        };
    }, [Controller.Buscar, CodigoItem, navigate, CodigoEmpresa]);

    const excluir = useCallback((Produto: IProduto) => {
        Controller.Deletar.mutate({
            CD_PRODUTO: Produto.CD_PRODUTO
        });
    }, [Controller]);

    useEffect(() => {
        if (selecionado.CD_PRODUTO !== undefined) {
            App.Modal({
                Visibilidade    : true,
                Titulo          : 'Confirmação de Exclusão',
                Descricao       : `Tem certeza que deseja excluir o produto ${selecionado.NOME}? Nunca mais será possível recupera-lo.`,
                Anexos			: undefined,
                Inputs          : undefined,
                Variante        : 'danger',
                onSubmit        : () => {excluir(selecionado); setSelecionado({} as IProduto)},
                onCancel        : () => setSelecionado({} as IProduto)
            })
        }
    }, [App, selecionado, excluir])

    const dsItem = useMemo<IProduto>(() => 
        Controller.Buscar.isSuccess ? 
            Controller.Buscar.data
        : 
            {NOME: '', VALOR: 0} as IProduto
    , [Controller.Buscar]);

    const dsLista = useMemo<IProdutos>(() => 
        Controller.Listar.isSuccess ? 
            Controller.Listar.data.filter((data: IProduto) => {
                if (!!data) {
                    switch (Filtros.Produto.tipoPesquisa) {
                        case ETpFiltroProduto.Nome:
                            return data.NOME.toLowerCase().indexOf(Filtros.Produto.pesquisa.toLowerCase()) !== -1
                        case ETpFiltroProduto.Tipo:
                            return data.TIPO.toLowerCase().indexOf(Filtros.Produto.pesquisa.toLowerCase()) !== -1
                        case ETpFiltroProduto.Categoria:
                            return data.CATEGORIA.NOME.toLowerCase().indexOf(Filtros.Produto.pesquisa.toLowerCase()) !== -1
                        case ETpFiltroProduto.Composicao:
                            return data.COMPOSICAO && data.COMPOSICAO.NOME.toLowerCase().indexOf(Filtros.Produto.pesquisa.toLowerCase()) !== -1
                        default:
                            return data
                    }
                } else {
                    return data
                }
            }).filter((data) => {
                switch (Filtros.Produto.filtroSituacao) {
                    case 'D':
                        return data.DISPONIVEL === true
                    case 'I':
                        return data.DISPONIVEL === false
                    default:
                        return data
                }
            }).filter((data) => {
                switch (Filtros.Produto.filtroTpProduto) {
                    case 'P':
                        return data.TIPO === EProdutoTipo.Produto
                    case 'I':
                        return data.TIPO === EProdutoTipo.Ingrediente
                    default:
                        return data
                }
            }).sort((a, b) => (a.CD_CATEGORIA ?? 0) - (b.CD_CATEGORIA ?? 0))
        : 
            [{} as IProduto]
    , [Controller.Listar, Filtros.Produto]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "ATIVO",
            Header: "Situação",
            Cell: (row: any) : any =>
                <Form.Check
                    name="SITUACAO"
                    style={{zoom: '1.1'}}
                    id="SITUACAO"
                    type="switch"
                    checked={row.row.original['DISPONIVEL']}
                    label={row.row.original['DISPONIVEL'] ? 'Disponível' : 'Indisponível'}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                    onChange={(e: any) => {
                        e.stopPropagation();
                        Controller.Alterar.mutate({
                            CD_PRODUTO: row.row.original["CD_PRODUTO"],
                            DISPONIVEL: !row.row.original['DISPONIVEL']
                        })
                    }}
                />
        },
        {
            id: "NOME",
            Header: "Produto",
            accessor: "NOME"
        },    
        {
            id: "CATEGORIA",
            Header: "Categoria",
            accessor: "CATEGORIA.NOME"
        },     
        {
            id: "INFO",
            Header: "Informações",
            Cell: (row: any) : any => <>
                {
                    row.row.original['TIPO'] 
                        ? <Badge style={{zoom: '1.1'}} pill bg="dark">{getCapitalized(row.row.original['TIPO'])}</Badge> 
                        : null
                }
                {
                    row.row.original['COMPOSICAO'] 
                        ? <Badge style={{zoom: '1.1'}} pill bg="warning" text="dark">{row.row.original['COMPOSICAO']['NOME']}</Badge> 
                        : null
                }
                {
                    row.row.original['VALOR'] > 0
                        ? <Badge style={{zoom: '1.1'}} pill bg="success">{setMaskMoney(row.row.original['VALOR'], 'R$', ',')}</Badge> 
                        : null
                }
                {
                    row.row.original['MOVIMENTA_ESTOQUE'] > 0
                        ? <Badge style={{zoom: '1.1'}} pill bg="primary">Estoque: {row.row.original['ESTOQUE']}</Badge> 
                        : null
                }
            </>
        },     
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                <Button 
                    variant="danger"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        setSelecionado(row.row.original);
                    }}
                >
                    <FaTrashAlt />
                </Button>
                </div>
        },
    ], [Controller.Alterar, setMaskMoney, getCapitalized]);

    const dsEstoqueMovimento = useMemo<IEstoqueMovimentos>(() => 
        ControllerEstoque.BuscarProduto.isSuccess 
            ? ControllerEstoque.BuscarProduto.data.sort((a, b) => Number(b.CD_MOVIMENTO) - Number(a.CD_MOVIMENTO))
            : [{} as IEstoqueMovimento]
    , [ControllerEstoque.BuscarProduto]);

    const gridEstoqueMovimento = useMemo<IReactTable_Grid>(() => [
        {
            // accessor: 'DT_MOVIMENTO',
            Header: 'Data',
            Cell: (row: any) : any => row.row.original['DT_MOVIMENTO'] ? setDate(new Date(row.row.original['DT_MOVIMENTO']), {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit"}, false, false) : ''
        },
        {
            // accessor: 'MOVIMENTO',
            Header: 'Tipo',
            Cell: (row: any) : any => row.row.original['MOVIMENTO'] === 'S' ? 'Saída' : 'Entrada'
        },
        {
            accessor: 'QUANTIDADE',
            Header: 'Quantidade',
            // Cell: (row: any) : any => row.row.original['QUANTIDADE']
        },
        {
            id: 'acoes',
            Header: <Button 
                variant="success"
                disabled={!dsItem.MOVIMENTA_ESTOQUE}
                onClick={() => {
                    Modal({
                        Visibilidade    : true,
                        Titulo          : 'Adicionar estoque',
                        Descricao       : ``,
                        Anexos			: undefined,
                        Inputs          : [{
                            ID: 'QTD_ESTQ',
                            Label: 'Quantidade entrada:',
                            Placeholder: '',
                            Type: 'number',
                            Value: Number(1)
                        }],
                        Variante        : 'success',
                        onSubmit        : (Values: ICtxAppParams_ModalInputs) => ControllerEstoque.Cadastrar.mutateAsync({
                            CD_PRODUTO: Number(dsItem.CD_PRODUTO), 
                            QUANTIDADE: Number(Values[0].Value ?? 1),
                            MOVIMENTO: 'E'
                        }).then(() => {
                            Controller.Buscar.mutate({CD_PRODUTO: dsItem.CD_PRODUTO})
                            ControllerEstoque.BuscarProduto.mutate({CD_PRODUTO: Number(dsItem.CD_PRODUTO)});
                        })
                    })
                }}
            >
                <FaPlus />
            </Button>,
            Cell: (row: any) : any => {
                return <Button 
                    disabled={row.row.original['CD_PEDIDO']} 
                    variant="danger"
                    onClick={() => {
                        Modal({
                            Visibilidade    : true,
                            Titulo          : 'Confirmação de exclusão',
                            Descricao       : `Tem certeza que deseja excluir o movimento de estoque selecionado? Isso irá refletir na quantidade em estoque do produto.`,
                            Anexos			: undefined,
                            Inputs          : undefined,
                            Variante        : 'danger',
                            onSubmit        : () => ControllerEstoque.Deletar.mutateAsync({CD_MOVIMENTO: row.row.original['CD_MOVIMENTO'], QUANTIDADE: row.row.original['QUANTIDADE']}).then(() => {
                                Controller.Buscar.mutate({CD_PRODUTO: dsItem.CD_PRODUTO});
                                ControllerEstoque.BuscarProduto.mutate({CD_PRODUTO: Number(dsItem.CD_PRODUTO)});
                            })
                        })
                    }}
                >
                    <FaTrashAlt />
                </Button>
            }
        }
    ], [setDate, Controller.Buscar, ControllerEstoque.BuscarProduto, ControllerEstoque.Cadastrar, ControllerEstoque.Deletar, Modal, dsItem]);

    useEffect(() => {
        if (CodigoItem !== undefined) {
            abrir({})
        } else {
            setMode(EMode.Browse);
        }
    }, [CodigoItem, Mode]); //eslint-disable-line

    return {
        Salvar: salvar,
        Abrir: abrir,
        Excluir: excluir,

        Item: dsItem,
        Lista: dsLista,
        Grid: gridLista,

        Modo: Mode,

        ShowModal: showModal,
        setShowModal: setShowModal,
        continuarCadastro: continuarCadastro,
        setContinuarCadastro: setContinuarCadastro,
        Selecionado: selecionado,
        CdEmpresa,
        SalvarImagem: salvarImagem,
        ControllerEstoque,
        EstoqueMovimento: dsEstoqueMovimento,
        gridEstoqueMovimento
    }
}

export interface IUseProduto {
    Salvar              : (IProduto: DeepPartial<IProduto>) => Promise<boolean>;
    Abrir               : (IProduto: DeepPartial<IProduto>) => void;
    Excluir             : (IProduto: IProduto) => void;
    Item                : IProduto;
    Lista               : IProdutos;
    Grid                : IReactTable_Grid;
    Modo                : EMode;
    ShowModal           : boolean;
    setShowModal        : React.Dispatch<React.SetStateAction<boolean>>;
    continuarCadastro   : boolean;
    setContinuarCadastro: React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado         : IProduto;
    CdEmpresa           : string | undefined;
    SalvarImagem        : (Produto: DeepPartial<IProduto>) => Promise<boolean>;
    ControllerEstoque   : IUseEstoqueMovimentoController;
    EstoqueMovimento    : IEstoqueMovimentos;
    gridEstoqueMovimento: IReactTable_Grid;
};

export default useProduto;
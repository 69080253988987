import { UseMutationResult, useMutation } from "react-query";
import useAPI from "../Hooks/utils/useAPI";
import { ILog } from "../Models/genericInterfaces";

const useLogController = () : IUseLogController => {
    const API = useAPI();

    // const primaryKey = 'CD_PEDIDO';
    const endpoint = 'log';

    const cadastrar = useMutation(
        (data : ILog) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any) => {

            },
            onError: (error: any) => {

            }
    });

    const listar = useMutation(
        (data : ILog) => 
            API.get(`${endpoint}/buscarTodos`, "")
        , {
            onSuccess: (ret: ILog[]) => {
            
            },
            onError: (error: any) => {
            
            }
    });

    return {
        Cadastrar   : cadastrar,
        Listar      : listar
    }
}

export interface IUseLogController {
    Cadastrar       : UseMutationResult<any, any, ILog, unknown>;
    Listar          : UseMutationResult<ILog[], any, ILog, unknown>;
}

export default useLogController;
import { useContext, useEffect } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { IUseProduto } from "../../Hooks/Produto";
import { useOutletContext } from "react-router-dom";
import { Button, Container} from "react-bootstrap";
import Tabela from "../../Components/tabela";
import ProdutoListaFiltro from "./listaFiltro";
import FormRodape from "../../Components/formularios/FormRodape";
import { FaPlus } from "react-icons/fa";
import { IProduto } from "../../Models/cardapio";

const ProdutoLista = () => {
    const Produto = useOutletContext<IUseProduto>();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Produtos e Ingredientes");
    })

    return (
        <Container className="verticalCenter">
            <ProdutoListaFiltro AppParams={AppParams} />

            <Tabela 
                colunas={Produto.Grid} 
                dados={Produto.Lista} 
                handleClick={(item : IProduto) => Produto.Abrir({CD_PRODUTO: item.CD_PRODUTO})}
                pagination 
            />

            <FormRodape>
                <Button size="lg" variant="success" onClick={() => Produto.Abrir({CD_PRODUTO: -1})}><FaPlus /> Cadastrar</Button>
            </FormRodape>
        </Container>
    )
}

export default ProdutoLista;
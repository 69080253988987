import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import Tabela from "../../Components/tabela";
import useCadClienteEndereco from "../../Hooks/cadCliente/endereco";
import { useState, useEffect, useContext } from "react";
import useMask from "../../Hooks/utils/useMask";
import { IClienteEndereco, schemaCadClienteEndereco } from "../../Models/cadClienteEndereco";
import { every } from "lodash";
import FormAutocomplete from "../../Components/formularios/FormAutocomplete";
import { CtxParams } from "../../Contexts/ctxParams";

const CadClienteEndereco = ({handleEndereco, handleSubmitForm, canCreate, onConfirmSuccess, celular, handleSetEnderecoPadrao} : any) => {
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const formatter = useMask();
    const ClienteEndereco = useCadClienteEndereco(celular, handleSubmitForm, canCreate, onConfirmSuccess);
    const UserParams = useContext(CtxParams);

    useEffect(() => {
        if (ClienteEndereco.CdEndereco === 0) {
            handleEndereco(false)
        } else { 
            handleEndereco(true);
        };
    }, [ClienteEndereco.CdEndereco, handleEndereco]);

    return (
        <>
            {   
                ClienteEndereco.CdEndereco === 0 ?
                    <Tabela dados={ClienteEndereco.Lista} colunas={ClienteEndereco.Grid} handleClick={handleSetEnderecoPadrao} />
                :
                    <Formik
                        enableReinitialize
                        validationSchema={schemaCadClienteEndereco}
                        onSubmit={(e : IClienteEndereco) => {
                            const dados = Object.assign({}, e);
                            dados.CEP = formatter.getCep(dados.CEP);

                            if (ClienteEndereco.CdEndereco === -1) {
                                ClienteEndereco.mutCadClienteEndereco.mutate(dados);
                            } else {
                                ClienteEndereco.mutAltClienteEndereco.mutate(dados);
                            }
                        }}
                        initialValues={{
                            CD_CLIENTE: ClienteEndereco.CdCliente || 0,
                            CEP:        formatter.setMaskCep(typeof ClienteEndereco.Selecionado.CEP === 'undefined' ? '': ClienteEndereco.Selecionado.CEP) || '',
                            LOGRADOURO: ClienteEndereco.Selecionado.LOGRADOURO || '',
                            BAIRRO:     ClienteEndereco.Selecionado.BAIRRO     || '',
                            CIDADE:     ClienteEndereco.Selecionado.CIDADE     || UserParams.CIDADE,
                            NUMERO:     ClienteEndereco.Selecionado.NUMERO     || '',
                            UF:         ClienteEndereco.Selecionado.UF         || UserParams.UF,
                            COMPLEMENTO:ClienteEndereco.Selecionado.COMPLEMENTO|| '',
                        }}
                    >
                        {({
                            handleSubmit,
                            submitForm,
                            handleChange,
                            setFieldValue,
                            values,
                            errors,
                        }) => (
                            <>
                                <Form.Group className="mt-3">
                                    <Form.Label>Buscar pelo CEP:</Form.Label>
                                    <BadgeValidacao validacao={errors.CEP} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'CEP'}
                                        type={'text'}
                                        value={values.CEP}
                                        maxLength={9}
                                        onBlur={() => ClienteEndereco.BuscaCep(formatter.getCep(values.CEP))}
                                        onChange={ e => setFieldValue("CEP", formatter.setMaskCep(e.target.value)) }
                                        isInvalid={every([errors.CEP, confirmado])}
                                        placeholder="Cep..." 
                                        size="lg"
                                    />
                                </Form.Group>  

                                <Form.Group className="my-3">
                                    <Form.Label>Logradouro:</Form.Label>
                                    <BadgeValidacao validacao={errors.LOGRADOURO} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'LOGRADOURO'}
                                        type={'text'}
                                        value={values.LOGRADOURO}
                                        onChange={handleChange}
                                        isInvalid={every([errors.LOGRADOURO, confirmado])}
                                        placeholder="Rua, Logradouro..." 
                                        size="lg"
                                    />
                                </Form.Group>   

                                <Form.Group className="mt-3">
                                    <Form.Label>Bairro:</Form.Label>
                                    <BadgeValidacao validacao={errors.BAIRRO} confirmado={confirmado} />
                                    <FormAutocomplete 
                                        name={'BAIRRO'}
                                        type={'text'}
                                        value={values.BAIRRO ?? ''}
                                        onChange={handleChange}
                                        isInvalid={every([errors.BAIRRO, confirmado])}
                                        dataSource={ClienteEndereco.dsListaEnderecos}
                                        indexer={'BAIRRO'}
                                        indexKey={'BAIRRO'}
                                        valueKey={values.BAIRRO ?? ''}
                                        onChangeValueKey={ (e : any) => {setFieldValue("BAIRRO", e)}}
                                        size="lg"
                                    />
                                </Form.Group>  

                                <Form.Group className="mt-3">
                                    <Form.Label>Número:</Form.Label>
                                    <BadgeValidacao validacao={errors.NUMERO} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'NUMERO'}
                                        type={'text'}
                                        value={values.NUMERO}
                                        onChange={handleChange}
                                        isInvalid={every([errors.NUMERO, confirmado])}
                                        placeholder="Nº, Bloco..." 
                                        size="lg"
                                    />
                                </Form.Group>  

                                <Form.Group className="mt-3">
                                    <Form.Label>Complemento:</Form.Label>
                                    <Form.Control 
                                        name={'COMPLEMENTO'}
                                        type={'text'}
                                        value={values.COMPLEMENTO || ''}
                                        onChange={handleChange}
                                        placeholder="Ponto de referência..." 
                                        size="lg"
                                    />
                                </Form.Group>  

                                <Form.Group className="mt-3">
                                    <Form.Label>Cidade:</Form.Label>
                                    <BadgeValidacao validacao={errors.CIDADE} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'CIDADE'}
                                        type={'text'}
                                        value={values.CIDADE}
                                        onChange={handleChange}
                                        isInvalid={every([errors.CIDADE, confirmado])}
                                        placeholder="Cidade..." 
                                        size="lg"
                                    />
                                </Form.Group>  

                                <Form.Group className="mt-3">
                                    <Form.Label>Estado (UF):</Form.Label>
                                    <BadgeValidacao validacao={errors.UF} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'UF'}
                                        type={'text'}
                                        value={values.UF}
                                        onChange={handleChange}
                                        isInvalid={every([errors.UF, confirmado])}
                                        placeholder="UF..." 
                                        size="lg"
                                    />
                                </Form.Group>  

                                <div className="w-100 d-flex justify-content-center">
                                    <Button className="m-3" variant="outline-danger" type="reset" onClick={() => ClienteEndereco.setCdEndereco(0)}>Voltar</Button>
                                    <Button 
                                        className="m-3"
                                        variant="primary" 
                                        //type="submit"
                                        onClick={
                                            () => {
                                                setConfirmado(true);
                                                submitForm();
                                            } 
                                        }
                                    >
                                        Adicionar
                                    </Button>
                                </div>
                            </>
                        )}
                    </Formik>
            }
        </>
    );
};

export default CadClienteEndereco;
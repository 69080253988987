import { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { every } from "lodash";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import useMask from "../../Hooks/utils/useMask";
import { ICadCliente, schemaCadCliente } from "../../Models/cadCliente";
import FormRodape from "../../Components/formularios/FormRodape";
import CadClienteEndereco from "./endereco";
import { useParams } from "react-router-dom";
import useCadCliente from "../../Hooks/cadCliente";
import FormAutocomplete from "../../Components/formularios/FormAutocomplete";
import CadClienteProvider from "./provider";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { IClienteEndereco } from "../../Models/cadClienteEndereco";

const CadCliente = () => {
    const { cdCliente, fechar } = useParams();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [editandoEndereco, setEditandoEndereco] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [handleEnderecoPadrao, setHandleEnderecoPadrao] = useState<IClienteEndereco>({} as IClienteEndereco)
    const CadastroCliente = useCadCliente((typeof cdCliente === 'undefined') || (cdCliente === '-1') ? '' : cdCliente?.toString(), editandoEndereco, fechar);
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Cadastro de Cliente");
        AppParams.setEscondeHeader(fechar === 'true');
    });

    return (        
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaCadCliente}
                onSubmit={(e : ICadCliente) => {
                    CadastroCliente.Confirmar(e);
                    setConfirmado(false);
                }}
                initialValues={{
                    NOME: CadastroCliente.dsCliente.NOME || '',
                    CELULAR: formatter.setMaskPhone(CadastroCliente.dsCliente.CELULAR) || '',
                    CD_ENDERECO_PADRAO: CadastroCliente.dsCliente.CD_ENDERECO_PADRAO,
                    ENDERECO_PADRAO: ''
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                    validateForm,
                }) => (
                    <CadClienteProvider 
                        customHook={CadastroCliente} 
                        confirmado={confirmado} 
                        setConfirmado={setConfirmado}
                        editandoEndereco={editandoEndereco}
                        setEditandoEndereco={setEditandoEndereco}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        handleEnderecoPadrao={handleEnderecoPadrao}
                        setHandleEnderecoPadrao={setHandleEnderecoPadrao}
                    >
                        <Form onSubmit={handleSubmit}>                        
                            <Form.Group className="mt-3">
                                <Form.Label>Nome:</Form.Label>
                                <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                                <Form.Control 
                                    name={'NOME'}
                                    type={'text'}
                                    value={values.NOME}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NOME, confirmado])}
                                    placeholder="Nome..." 
                                    size="lg"
                                    disabled={editandoEndereco}
                                />
                            </Form.Group>  

                            <Form.Group className="mt-3">
                                <Form.Label>Celular:</Form.Label>
                                <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} />
                                <InputGroup>
                                    <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                    <Form.Control 
                                        name={'CELULAR'}
                                        type={'text'}
                                        value={values.CELULAR}
                                        onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                        isInvalid={every([errors.CELULAR, confirmado])}
                                        placeholder="Celular..." 
                                        size="lg"
                                        disabled={editandoEndereco}
                                    />
                                </InputGroup>
                            </Form.Group>   

                            <Row className="mb-3">
                                <Col>
                                    <Form.Group className="mt-3" style={{display: 'none'}}>
                                        <Form.Label>Endereço:</Form.Label>
                                        <Form.Control 
                                            name={'CD_ENDERECO_PADRAO'}
                                            type={'number'}
                                            readOnly
                                            value={values.CD_ENDERECO_PADRAO ?? ''}
                                            onChange={ (e : any) => {setFieldValue("CD_ENDERECO_PADRAO", e.target.value)} }
                                            isInvalid={every([errors.CD_ENDERECO_PADRAO, confirmado])}
                                            placeholder="" 
                                            size="lg"
                                            disabled={editandoEndereco}
                                        />
                                    </Form.Group>  
                                </Col>
                                <Col xs={12}>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Endereço Padrão: </Form.Label>
                                        <BadgeValidacao validacao={errors.ENDERECO_PADRAO} confirmado={confirmado} />
                                        <FormAutocomplete 
                                            name={'ENDERECO_PADRAO'}
                                            type={'text'}
                                            value={values.ENDERECO_PADRAO ?? ''}
                                            onChange={handleChange}
                                            isInvalid={every([errors.ENDERECO_PADRAO, confirmado])}
                                            onFocusPopup={true}
                                            dataSource={CadastroCliente.dsCliente.ENDERECOS} // Criar função para transformar objeto de endereço em uma string só
                                            indexer={'ENDERECO'}
                                            indexKey={'CD_CLIENTE_ENDERECO'}
                                            valueKey={values.CD_ENDERECO_PADRAO ?? null}
                                            onChangeValueKey={ (e : any) => {setFieldValue("CD_ENDERECO_PADRAO", e)} }
                                            size="lg"
                                            disabled={editandoEndereco}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* {Enderecos} */}
                            <CadClienteEndereco 
                                celular={CadastroCliente.Celular}
                                canCreate={confirmado && (Object.keys(errors).length === 0)}
                                handleEndereco={setEditandoEndereco}
                                handleSubmitForm={() => { // Faz a validação, o provider se encarrega de submitar
                                    setConfirmado(true);
                                    validateForm();
                                }}
                                handleSetEnderecoPadrao={(Endereco: IClienteEndereco) => {
                                    if (values.CD_ENDERECO_PADRAO !== Endereco.CD_CLIENTE_ENDERECO) {
                                        setHandleEnderecoPadrao(Endereco);
                                        setShowModal(true);
                                    }
                                }}
                                onConfirmSuccess={() => CadastroCliente.qryCliente.mutate(CadastroCliente.Celular)} // Recarrega os dados do cliente ao confirmar cadastro de endereço
                            />

                            <FormRodape style={editandoEndereco ? {display: "none"} : {}}>
                                <Button 
                                    variant="outline-danger" 
                                    size="lg" 
                                    type="reset"
                                    onClick={() => {
                                        CadastroCliente.Cancelar();
                                        if (fechar === 'true') {
                                            window.close();
                                        };
                                    }}
                                >
                                    Cancelar
                                </Button>
                                
                                <Button 
                                    variant="success" 
                                    size="lg" 
                                    type="submit" 
                                    //disabled={editandoEndereco}
                                    onClick={() => {setConfirmado(true)}}
                                >
                                    Confirmar
                                </Button>
                            </FormRodape>
                        </Form>
                    </CadClienteProvider>
                )}
            </Formik>
        </Container>
    );
}

export default CadCliente;
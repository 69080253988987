import { Button, Container, Spinner } from "react-bootstrap"
import FormRodape from "../../Components/formularios/FormRodape"
import { FaPlus } from "react-icons/fa"
import { useNavigate, useOutletContext } from "react-router-dom"
import { IUseFormaPagamento } from "../../Hooks/FormaPagamento"
import Tabela from "../../Components/tabela"
import { useContext, useEffect } from "react"
import { CtxAppParams } from "../../Contexts/ctxAppParams"
import useApp from "../../Hooks/utils/useApp"
import { IFormaPagamento } from "../../Models/FormaPagamento"

const FormaPagamentoLista = () => {
    const FormaPagamento = useOutletContext<IUseFormaPagamento>();
    const AppParams = useContext(CtxAppParams);
    const { RouteManager } = useApp();
    const navigate = useNavigate();

    useEffect(() => {
        AppParams.setPagina(FormaPagamento.Pagina.Description);
    })

    return (
        <Container className="verticalCenter">
            {
                FormaPagamento.Controller.Listar.isFetching
                    ? <Spinner variant="primary" />
                    : !FormaPagamento.Controller.Listar.data || FormaPagamento.Controller.Listar.data.length === 0
                        ? 'Nenhuma forma de pagamento cadastrada.'
                        : FormaPagamento.Controller.Listar.isError
                            ? 'Problemas ao carregar as formas de pagamento cadastradas.'
                            : <Tabela 
                                colunas={FormaPagamento.Grid.Lista} 
                                dados={FormaPagamento.Datasource.Lista} 
                                handleClick={(e: IFormaPagamento) => 
                                    // null
                                    navigate(`cadastro/${e.CD_FORMA}`)
                                    // navigate(RouteManager(FormaPagamento.Pagina.Pages[0].Path, [String(e.CD_FORMA)]))
                                } 
                            />
            }            

            <FormRodape>
                <Button 
                    size="lg" 
                    variant="success" 
                    onClick={() => {
                        FormaPagamento.Controller.Buscar.reset();
                        navigate(RouteManager(FormaPagamento.Pagina.Pages[0].Path, []));
                    }}
                >
                        <FaPlus /> Cadastrar
                </Button>
            </FormRodape>
        </Container>
    )
}

export default FormaPagamentoLista;
import useAPI from "../../utils/useAPI";
import { useMutation, useQuery } from 'react-query';
import { ICadUsuario } from "../../../Models/cadUsuario";
import useMask from "../../utils/useMask";
import { useNavigate } from "react-router";
import { useEffect, useMemo } from "react";

const useCadUsuario = (CdUsuario: string) : IUseCadUsuario => {
    const API = useAPI();
    const formatter = useMask()
    const navigate = useNavigate();

    const qryUsuario = useQuery(
        'qryUsuario',
        (   
            () => API.get('usuarios/buscar/', CdUsuario, false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            cacheTime: 0,
            retry: false,
            enabled: false,
        }
    );

    useEffect(() => {
        if (!qryUsuario.isError) {
            if (CdUsuario !== '-1') {qryUsuario.refetch()};
        } else { 
            alert(qryUsuario.error);
            navigate('/empresa/usuarios/cadastro');
        };
    }, [qryUsuario.isSuccess, qryUsuario.error, qryUsuario.isError, CdUsuario]); // eslint-disable-line

    const dsUsuario = useMemo<ICadUsuario>(() => 
        qryUsuario.isSuccess && CdUsuario !== '-1' ? qryUsuario.data : {
            NOME: '',
            CELULAR: '',
            SENHA: '',
            SENHA_CONFIRMA: '',
            TAXA: 0,
            ENTREGADOR: true
        }
    , [qryUsuario, CdUsuario]);

    const mutCadUsuario = useMutation((data : ICadUsuario) => API.post('usuarios/cadastrar', data), {
        onSuccess: () => {navigate(`/empresa/usuarios`)},
        onError: (error: any) => {alert(error)}
    });

    const mutAltUsuario = useMutation((data : ICadUsuario) => API.put(`usuarios/alterar/${CdUsuario}`, data), {
        onSuccess: () => {navigate(`/empresa/usuarios`)},
        onError: (error: any) => {alert(error)}
    });

    const confirmar = (data: ICadUsuario) => {
        const dados = Object.assign({}, data);
        dados.CELULAR = formatter.getPhone(dados.CELULAR);

        console.log(dados)
        if (CdUsuario === "-1") {
            mutCadUsuario.mutate(dados);
        } else {
            mutAltUsuario.mutate(dados);
        }
    };

    const cancelar = () => {
        navigate(-1);
    };

    return {
        dsUsuario,
        Confirmar: confirmar,
        Cancelar: cancelar,
        CdUsuario
    }
}

export interface IUseCadUsuario {
    dsUsuario   : ICadUsuario;
    Confirmar   : (data: ICadUsuario) => void;
    Cancelar    : () => void;
    CdUsuario   : string;
}

export default useCadUsuario;
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import { DeepPartial } from "../../Models/genericInterfaces";
import useControllers from "../utils/useControllers";
import { IPedido, IPedidos } from "../../Models/pedido";
import { EStatus } from "../../Models/cadEntrega";

const usePedidoController = (updateQueryKey: string, isArray: boolean = true, enableQuery: boolean = false, dataCompetencia: string) : IUsePedidoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Create } = useControllers();

    const primaryKey = 'CD_PEDIDO';
    const endpoint = 'pedido';

    const mutDeletar = useMutation(
        (data : DeepPartial<IPedido>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
        }, {
            onSuccess: (ret: any, data: DeepPartial<IPedido>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IPedidos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                // return Delete(oldData, data);
                                return Update(oldData, {
                                    ...data,
                                    STATUS: EStatus.Cancelada,
                                    PAGO: false
                                }, primaryKey, isArray)
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutAlterar = useMutation(
        (data : DeepPartial<IPedido>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: DeepPartial<IPedido>, data: DeepPartial<IPedido>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IPedidos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, {CD_PEDIDO: data.CD_PEDIDO, ...ret}, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IPedido>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IPedido>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IPedidos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['Sucesso']);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IPedido>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IPedido) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IPedidos>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos/${dataCompetencia}`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 60000,
            refetchIntervalInBackground: true,
        }
    );    

    return {
        Deletar: mutDeletar,
        Alterar: mutAlterar,
        Cadastrar: mutCadastrar,
        Buscar: mutBuscar,
        Listar: qryListar,
    }
}

export interface IUsePedidoController {
    Deletar     : UseMutationResult<any, any, DeepPartial<IPedido>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<IPedido>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IPedido>, unknown>;
    Buscar      : UseMutationResult<IPedido, any, DeepPartial<IPedido>, unknown>
    Listar      : UseQueryResult<IPedidos, unknown>;
}

export default usePedidoController;
import { Formik } from "formik";
import { every } from "lodash";
import { useState, ChangeEvent } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import FormRodape from "../../Components/formularios/FormRodape";
import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { ICadPagtoQr, schemaCadPagtoQR } from "../../Models/cadPagtoQr";
import useCadPagtoQr from "../../Hooks/cadPagtoQr";
import { CtxAppParams } from "../../Contexts/ctxAppParams";

const CadPagtoQr = () => {
    const { cdPagtoQr } = useParams();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const CadastroPagtoQr = useCadPagtoQr(typeof cdPagtoQr === 'undefined' ? '-1' : cdPagtoQr?.toString());
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Cadastro de Pagamento QR Code");
    });

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaCadPagtoQR}
                onSubmit={(dados : ICadPagtoQr) => {
                    CadastroPagtoQr.Confirmar(dados);
                }}
                initialValues={{
                    LOCAL_IMAGEM: "",
                    NOME: "",
                    CAMINHO_IMAGEM: "",
                    IMAGEM: null
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <>
                            {    
                                values.CAMINHO_IMAGEM !== "" ?
                                    <div className="mt-3 w-100 d-flex justify-content-center">
                                        <img style={{height: "40vh"}} src={values.CAMINHO_IMAGEM ?? ""} alt="Pré-Visualização" />   
                                    </div>
                                :
                                    <></>
                            }
                        </>                        

                        <Form.Group className="mt-3">
                            <Form.Label>Nome:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Control 
                                name={'NOME'}
                                type={'text'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.NOME, confirmado])}
                                placeholder="Nome..." 
                                size="lg"
                            />
                        </Form.Group>  

                        <Form.Group className="mt-3">
                            <Form.Label>Imagem:</Form.Label>
                            <BadgeValidacao validacao={errors.CAMINHO_IMAGEM} confirmado={confirmado} />
                            <Form.Control
                                id={'LOCAL_IMAGEM'}
                                name={'LOCAL_IMAGEM'}
                                type="file"
                                required
                                accept="image/*"
                                value={values.LOCAL_IMAGEM ?? ""}
                                onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                    if (!e.target.files) {
                                        return
                                    } 

                                    if (e.target.files[0].size > 1048576) {
                                        alert('Não foi possível carregar a imagem: Está excedendo o tamanho máximo (1Mb)')
                                        return
                                    }

                                    setFieldValue("LOCAL_IMAGEM", e.target.value);
                                    setFieldValue("IMAGEM", e.target.files[0]);
                                    const fileReader = new FileReader();

                                    fileReader.onload = () => {
                                        if (fileReader.readyState === 2) {
                                            setFieldValue("CAMINHO_IMAGEM", fileReader.result);
                                        }
                                    };
                                    fileReader.readAsDataURL(e.target.files[0]);
                                }}
                                isInvalid={every([errors.CAMINHO_IMAGEM, confirmado])}
                                size="lg"
                            />
                        </Form.Group>

                        <FormRodape>
                            {/* <Button variant="outline-danger" size="lg" onClick={() => {
                                const win = window.open('/cadCliente', 'Cadastro de Cliente');
                                const timer = setInterval(() => {
                                    if (win!.closed) {
                                        clearInterval(timer);
                                        // Ação do evento
                                    };
                                }, 500);
                            }}>Cancelar 2</Button> */}
                            <Button variant="outline-danger" size="lg" onClick={CadastroPagtoQr.Cancelar}>Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CadPagtoQr;
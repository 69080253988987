import { useContext, useMemo } from "react";
import ModalConfirmacao from "../formularios/ModalConfirmacao";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { Col, Form, Row } from "react-bootstrap";
import { ICtxAppParams_ModalInput, ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";
import { v4 as uuidv4} from 'uuid';

const ModalGlobal = () => {
    const { Modal, setModal } = useContext(CtxAppParams);

    const Inputs = useMemo(() => {
        if (Modal.Inputs !== undefined) {
            return (
                Modal.Inputs.map((Input: ICtxAppParams_ModalInput, Index: number) => 
                    <Row key={Input.ID}>
                        <Col xs={12}>
                            <Form.Group className="mt-3">
                                <Form.Label>{Input.Label}</Form.Label>
                                {
                                    Input.Type === 'select' ?
                                        <Form.Select
                                            id={Input.ID}
                                            name={Input.ID}
                                            value={Input.Value}
                                            onChange={(e) => {
                                                const UpdatedInputs : ICtxAppParams_ModalInputs = [...Modal.Inputs!];
                                                UpdatedInputs[Index].Value = e.target.value;
                                                setModal({...Modal, Inputs: UpdatedInputs})
                                            }}
                                            placeholder={Input.Placeholder}
                                            required={Input.Required}
                                            disabled={Input.Disabled}
                                            autoComplete="off"
                                            size="lg"
                                        >
                                            {
                                                Input.Options?.map((option) => 
                                                    <option key={uuidv4()} value={option.value}>{option.text}</option>
                                                )
                                            }
                                        </Form.Select>
                                    :
                                        <Form.Control 
                                            id={Input.ID}
                                            name={Input.ID}
                                            type={Input.Type}
                                            value={Input.Value}
                                            onChange={(e) => {
                                                const UpdatedInputs : ICtxAppParams_ModalInputs = [...Modal.Inputs!];
                                                UpdatedInputs[Index].Value = e.target.value;
                                                setModal({...Modal, Inputs: UpdatedInputs})
                                            }}
                                            placeholder={Input.Placeholder}
                                            required={Input.Required}
                                            disabled={Input.Disabled}
                                            autoComplete="off"
                                            step={0.05}
                                            onWheel={(e: any) => Input.Type === 'number' && e.target.blur()}
                                            size="lg"
                                        />
                                }
                                
                            </Form.Group>
                        </Col>
                    </Row>
                )
            )
        } else {
            return (
                <></>
            )
        }
    }, [Modal, setModal])

    return (
        <ModalConfirmacao
            titulo={Modal.Titulo}
            show={Modal.Visibilidade} 
            onHide={(e : any) => {
                if (Modal.onCancel) {
                    Modal.onCancel(e);
                }
                setModal({...Modal, Visibilidade: !Modal.Visibilidade})
            }}
            variant={Modal.Variante}
            onSubmitHandler={() => {
                Modal.onSubmit(Modal.Inputs!);
                setModal({...Modal, Visibilidade: false});
            }}
        >
            {Modal.Descricao}
            {Modal.Anexos}
            {Inputs}
        </ModalConfirmacao>
    )
}

export default ModalGlobal;
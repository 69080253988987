import { Outlet, useParams } from "react-router-dom";
import usePedido from "../../Hooks/Pedido";

const Pedido = () => {
    const { dtCompetencia } = useParams();
    const Pedido = usePedido(dtCompetencia);

    return (
        <Outlet context={Pedido} />
    )
}

export default Pedido;
import { Form, InputGroup, Row } from "react-bootstrap";
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import { every } from "lodash";
import { IUsePedidoOffline } from "../../../Hooks/offline/PedidoOffline";

interface Props {
    pedido: IUsePedidoOffline;
    validacaoErros: any;
    formatter: any;
}

const CarrinhoCliente = ({pedido, validacaoErros, formatter} : Props) => {
    return (
        <>
            <Row>
                <Form.Group>
                    <Form.Label>Nome Completo:</Form.Label>
                    <BadgeValidacao validacao={validacaoErros.NOME} confirmado={validacaoErros.NOME !== null} />
                    <Form.Control 
                        autoComplete="off"
                        name={'NOME'}
                        type={'text'}
                        // value={isMesa && isGarcom ? (pedido.pedidoCliente.NOME === '' ? 'Consumidor' : pedido.pedidoCliente.NOME) : pedido.pedidoCliente.NOME}
                        value={pedido.pedidoCliente.NOME}
                        onChange={ (e) => pedido.setPedidoCliente((prev) => {return {...prev, NOME: e.target.value}}) }
                        isInvalid={every([validacaoErros.NOME, validacaoErros.NOME !== null])}
                        placeholder=""
                        size="lg"
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="mt-3">
                    <Form.Label>Celular:</Form.Label>
                    <BadgeValidacao validacao={validacaoErros.CELULAR} confirmado={validacaoErros.CELULAR !== null} />
                    <InputGroup>
                        <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                        <Form.Control 
                            autoComplete="off"
                            name={'CELULAR'}
                            type={'text'}
                            // value={isMesa && isGarcom ? (pedido.pedidoCliente.CELULAR === '' ? '(99) 99999-9999' : pedido.pedidoCliente.CELULAR) : pedido.pedidoCliente.CELULAR}
                            value={pedido.pedidoCliente.CELULAR}
                            onChange={ (e) => pedido.setPedidoCliente((prev) => {return {...prev, CELULAR: formatter.setMaskPhone(e.target.value)}}) }
                            isInvalid={every([validacaoErros.CELULAR, validacaoErros.CELULAR !== null])}
                            placeholder="Celular..." 
                            size="lg"
                        />
                    </InputGroup>
                </Form.Group>
            </Row>
        </>
    )
}

export default CarrinhoCliente;
import { Nav, Row } from "react-bootstrap";
import { ICtxParams } from "../../Models/ctxParams";
import { ICtxAppParams } from "../../Contexts/ctxAppParams";
import { FaCashRegister } from "react-icons/fa";
import { BiFoodMenu } from "react-icons/bi";
import { IUseApp } from "../../Hooks/utils/useApp";
import { useCallback } from "react";
import useMesaController from "../../Hooks/Mesa/controller";
import { IMesa } from "../../Models/mesa";
import usePedido from "../../Hooks/Pedido";
import BadgeStatus from "../formularios/BadgeStatus";
import Tabela from "../tabela";
import useMask from "../../Hooks/utils/useMask";

interface Props {
    AppParams   : ICtxAppParams;
    UserParams  : ICtxParams;
    App         : IUseApp;
};

export const CardapioHeaderOptions = ({AppParams, UserParams, App}: Props) => {
    const Controller = useMesaController('listaMesas', false, false, '');
    const { GridItens } = usePedido();
    const formatter = useMask();

    const ModalResumo = useCallback(() => {
        Controller.BuscarNumero.mutateAsync({NR_MESA: Number(AppParams.Mesa.Numero)})
        .then((ret : IMesa) => {
            App.Modal({
                Visibilidade    : true,
                Titulo          : 'Resumo (Conta)',
                Descricao       : ``,
                Anexos			: <div className="w-100 d-flex justify-content-center flex-wrap">
                    <div className="d-flex justify-content-center align-items-center w-100 mb-3">
                        <Tabela
                            hideHeader
                            colunas={App.Grid}
                            dados={[
                                {
                                    CHAVE: "Data de Abertura",
                                    VALOR: formatter.setDate(new Date(ret.DT_ABERTURA), {day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit"}, false)
                                },
                                {
                                    CHAVE: "Valor Total",
                                    VALOR: formatter.setMaskMoney(ret.VLR_TOTAL, 'R$')
                                },
                            ]}
                        />
                    </div>

                    <h5>Itens</h5>
                    {
                        ret.PEDIDOS && ret.PEDIDOS.map((pedido) => {
                            return (
                                <div key={pedido.CD_PEDIDO} className="mb-3 w-100">
                                    <Row>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5>Pedido {pedido.CD_PEDIDO}</h5>
                                            <p>{pedido.DT_ABERTURA ? `${formatter.setDate(new Date(pedido.DT_ABERTURA), {day: "2-digit", month: "short", hour: "2-digit", minute: "2-digit"}, false)}` : ""}</p>
                                            <BadgeStatus icone={true} status={pedido.STATUS} tipo={'pedido'} />
                                        </div>
                                    </Row>                                
                                    <Tabela
                                        colunas={GridItens}
                                        dados={pedido.ITENS ? pedido.ITENS : []}
                                    />
                                </div>
                            )
                        })
                    }
                </div>,
                Inputs          : undefined,
                Variante        : 'primary',
                onSubmit        : () => {}
            })
        })
        .catch((e) => {
            alert(e);
        });
    }, [App, AppParams.Mesa.Numero, Controller.BuscarNumero, formatter, GridItens]);

    return (
        <>
            <Nav.Link 
                href="#"
                className="d-flex align-items-center justify-content-start"
            >
                <BiFoodMenu style={{fontSize: '1.8rem', width: '3rem'}} />
                Cardápio
            </Nav.Link>

            {
                AppParams.Mesa.Numero !== '' 
                ? 
                    <Nav.Link 
                        href="#"
                        onClick={() => ModalResumo()} 
                        className="d-flex align-items-center justify-content-start"
                    >
                        <FaCashRegister style={{fontSize: '1.5rem', width: '3rem'}} />
                        Resumo (Conta)
                    </Nav.Link>
                : null
            }
        </>
    );
};
const useImage = () => {
    function textoParaImagem(texto: string, largura: number, altura: number): string {
        const canvas = document.createElement("canvas");
        canvas.width = largura;
        canvas.height = altura;
        const context = canvas.getContext("2d");

        if (!context) {
            throw new Error("O contexto 2D não é suportado neste navegador.");
        }

        context.fillStyle = "white";
        context.fillRect(0, 0, largura, altura);
        context.fillStyle = "black";
        context.font = `bold ${largura/1.5}px Arial`;
        context.textAlign = "center";
        context.textBaseline = "middle";

        context.imageSmoothingQuality = "high";
        context.imageSmoothingEnabled = true;

        context.fillText(texto, (largura/2), (altura/2 + 10));

        return canvas.toDataURL("image/png");
    }

    return {
        textoParaImagem
    }
}

export default useImage;
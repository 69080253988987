import { Outlet, useParams } from "react-router-dom";
import useEmpresaParametro from "../../../Hooks/Empresa/EmpresaParametro";

const EmpresaParametro = () => {
    const { cdParametro } = useParams();
    const EmpresaParametro = useEmpresaParametro(cdParametro);

    return (
        <Outlet context={EmpresaParametro} />
    )
}

export default EmpresaParametro;
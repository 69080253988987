import { useFormikContext } from "formik";
import { IUseProduto } from "../../../Hooks/Produto";
import { EProdutoTipo, ICardapio, IProduto, IProdutoComposicao } from "../../../Models/cardapio";
import { Accordion, Alert, Button, Col, Form, Row } from "react-bootstrap";
import Tabela from "../../../Components/tabela";
import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { FaInfoCircle, FaPlus, FaTrashAlt } from "react-icons/fa";
import { IReactTable_Grid } from "../../../Models/genericInterfaces";
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import { every } from "lodash";
import { IUseCardapio } from "../../../Hooks/Cardapio";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    Produto: IUseProduto;
    Categoria: IUseCardapio;
    Confirmado: boolean;
};

const CardapioProdutoComposicao = (props: IProvider) => {
    const { Produto, Categoria, Confirmado } = props;
    const { values, setFieldValue, errors, handleChange } = useFormikContext<IProduto>();
    const [ingrediente, setIngrediente] = useState<IProdutoComposicao>({
        QUANTIDADE: 1, 
        INGREDIENTE: Produto.Lista.find((item) => item.TIPO === 'INGREDIENTE')?.NOME,
        CD_INGREDIENTE: Produto.Lista.find((item) => item.TIPO === 'INGREDIENTE')?.CD_PRODUTO
    } as IProdutoComposicao);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: 'ingrediente',
            Header: //'Ingredientes',
                <Form.Group id="fldIngrediente">
                    <Form.Label>Ingrediente:</Form.Label>
                    <Form.Select 
                        name={'STATUS'}
                        value={ingrediente.CD_PRODUTO}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setIngrediente({
                                ...ingrediente, 
                                CD_INGREDIENTE: Number(e.target.value),
                                INGREDIENTE: String(e.target.options[e.target.selectedIndex].text)
                            })
                        }}
                        placeholder="" 
                        size="lg"
                    > 
                        <option key={'ingNull'} value={''}></option>
                        {
                            Produto.Lista.map((Item: IProduto, Index: number) => 
                                (Item.TIPO === 'INGREDIENTE') && !(values.INGREDIENTES.find(item => item.CD_INGREDIENTE === Item.CD_PRODUTO)) ?
                                    <option key={String(Index)} value={Number(Item.CD_PRODUTO)}>{Item.NOME}</option>
                                :
                                    null
                            )
                        }
                </Form.Select>
            </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return original['INGREDIENTE']
            }
        },
        {
            id: 'quantidade',
            Header: //'Quantidade',
                <Form.Group style={{width: '5rem', margin: '0'}}>
                    <Form.Label>Qntde:</Form.Label>
                    <Form.Control
                        name={'QUANTIDADE'}
                        type={'number'}
                        min={1}
                        max={20}
                        value={Number(ingrediente.QUANTIDADE)}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setIngrediente({
                                ...ingrediente,
                                QUANTIDADE: Number(e.target.value)
                            })
                        }}
                        size="lg"
                    />
            </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return original['QUANTIDADE']
            }
        },
        {
            id: "acoes",
            Header: 
                <Button 
                    variant="success"
                    disabled={!ingrediente.CD_INGREDIENTE}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        // VALIDAÇÃO: 
                        // OK - Não pode ter dois produtos iguais
                        // Não pode exceder o limite do ingrediente

                        if (!ingrediente.CD_INGREDIENTE) {
                            alert('Selecione um ingrediente válido!');
                            return
                        }

                        if (values.INGREDIENTES.find(item => item.CD_INGREDIENTE === ingrediente.CD_INGREDIENTE)) {
                            return
                        }

                        setFieldValue("INGREDIENTES", [...values.INGREDIENTES!, {
                            CD_PRODUTO: Produto.Item.CD_PRODUTO,
                            CD_INGREDIENTE: ingrediente.CD_INGREDIENTE, 
                            INGREDIENTE: ingrediente.INGREDIENTE, 
                            QUANTIDADE: ingrediente.QUANTIDADE,
                        }])
                    }}
                >
                    <FaPlus />
                </Button>,
            Cell: ({row: {original}}: any) : any => 
                <Button 
                    variant="danger" 
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        const ingredientesNovos = values.INGREDIENTES.filter(item =>
                            item.CD_INGREDIENTE !== original['CD_INGREDIENTE']
                        );
                        setFieldValue("INGREDIENTES", ingredientesNovos);
                    }}
                >
                    <FaTrashAlt />
                </Button>
        },
    ], [Produto.Lista, Produto.Item, values, setFieldValue, ingrediente, setIngrediente]);

    return (
        <Accordion className="mt-3">
            <Accordion.Item eventKey="0" onClick={() => setTimeout(() => {
                document.getElementById('fldIngrediente')?.scrollIntoView()
            }, 500)}>
                <Accordion.Header>{
                    values.TIPO === EProdutoTipo.Produto 
                    ? `Ingredientes que compõe o produto` 
                    : `Composição personalizavel`
                }</Accordion.Header>
                <Accordion.Body>{
                    values.TIPO === EProdutoTipo.Produto
                    ? <Tabela colunas={gridLista} dados={values.INGREDIENTES} />   
                    : <>
                        <Alert variant={'info'} style={{zoom: '0.8'}}>
                            <FaInfoCircle style={{zoom: '1.5'}} /> Caso marcado a composição abaixo, ao incluir o produto no pedido irá aparecer uma janela solicitando ao cliente para selecionar os ingredientes da composição em questão.
                        </Alert>
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Composição:</Form.Label>
                                    <BadgeValidacao validacao={errors.CD_COMPOSICAO} confirmado={Confirmado} />
                                    <Form.Select 
                                        name={'CD_CATEGORIA'}
                                        isInvalid={every([errors.CD_COMPOSICAO, Confirmado])}
                                        value={values.CD_COMPOSICAO}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            setFieldValue("CD_COMPOSICAO", Number(e.target.value))
                                        }}
                                        placeholder="" 
                                        size="lg"
                                    > 
                                        <option key={'catNull'} value={undefined}></option>
                                        {
                                            Categoria.ListaComposicao.map((Item: ICardapio, Index: number) => 
                                                <option key={String(Index)} value={String(Item.CD_CATEGORIA)}>{Item.NOME}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group> 
                            </Col>
                            <Col xs={3}>
                                <Form.Group>
                                    <Form.Label>{`Qtd Min:`}</Form.Label>
                                    <BadgeValidacao validacao={errors.MIN} confirmado={Confirmado} />
                                    <Form.Control 
                                        name={'MIN'}
                                        type={'number'}
                                        min={0}
                                        max={20}
                                        value={values.MIN ?? '0'}
                                        onWheel={(e: any) => e.target.blur()}
                                        onChange={handleChange}
                                        isInvalid={every([errors.MIN, Confirmado])}
                                        size="lg"
                                    />
                                </Form.Group>  
                            </Col>
                            <Col xs={3}>
                                <Form.Group>
                                    <Form.Label>{`Qtd Máx:`}</Form.Label>
                                    <BadgeValidacao validacao={errors.MAX} confirmado={Confirmado} />
                                    <Form.Control 
                                        name={'MAX'}
                                        type={'number'}
                                        min={1}
                                        max={20}
                                        value={values.MAX ?? '1'}
                                        onWheel={(e: any) => e.target.blur()}
                                        onChange={handleChange}
                                        isInvalid={every([errors.MAX, Confirmado])}
                                        size="lg"
                                    />
                                </Form.Group>  
                            </Col>
                        </Row>
                    </>
                }</Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default CardapioProdutoComposicao;
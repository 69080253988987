import useAPI from "../utils/useAPI";
import { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { EStatus, ICadEntrega, IEnderecoEntrega, IEntrega } from "../../Models/cadEntrega";
import { IClienteEndereco } from "../../Models/cadClienteEndereco";
import { CtxParams } from "../../Contexts/ctxParams";
import { ICadCliente, ICadClientes } from "../../Models/cadCliente";
import { ICadTaxas } from "../../Models/cadTaxa";
import { IUseEntregaController } from "./controller";

const useCadEntrega = (CdEntrega: string, Controller: IUseEntregaController) : IUseCadEntrega => {
    const API = useAPI();
    const navigate = useNavigate();
    const UserParams = useContext(CtxParams);
    const [abriu, setAbriu] = useState<boolean>(false);

    const [dsListaClientesEndereco, setDsListaClientesEndereco] = useState<Array<IEnderecoEntrega>>([{ENDERECO: '', CD_CLIENTE_ENDERECO: null, CEP: ''}]);
    const [enderecoPadrao, setEnderecoPadrao] = useState<IEnderecoEntrega>({ENDERECO: '', CD_CLIENTE_ENDERECO: null, CEP: ''});
    const [enderecoAtual, setEnderecoAtual] = useState<IEnderecoEntrega>({ENDERECO: '', CD_CLIENTE_ENDERECO: null, CEP: ''});

    const qryListaTaxas = useQuery(
        'qryListaTaxas',
        (
            () => API.get('taxas/buscarTodos', '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            enabled: abriu
        }
    );

    const dsListaTaxas = useMemo<Partial<ICadTaxas>>(() =>
        qryListaTaxas.isSuccess ? 
            qryListaTaxas.data
        : 
            [{
                TAXA: 0,
                CEP: "",
                BAIRRO: "",
            }]
    , [qryListaTaxas]);

    const qryListaClientes = useQuery(
        'qryListaClientes',
        (
            () => API.get('clientes/buscarTodos', '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            enabled: !UserParams.ENTREGADOR && abriu,
        }
    );

    const dsListaClientes = useMemo<Partial<ICadClientes>>(() =>
        qryListaClientes.isSuccess ? qryListaClientes.data : [{
            CD_CLIENTE: null,
            NOME: "",
            CELULAR: ""
        }]
    , [qryListaClientes]);

    const mutClienteEndereco = useCallback((CdCliente: number) => {
        let Retorno : any = [];
        let EnderPadrao : any = [];

        dsListaClientes.filter!((dado : ICadCliente) => String(dado.CD_CLIENTE) === String(CdCliente)).map((item: any) => { // eslint-disable-line
            if (item.ENDERECOS.length === 0) {
                return [{
                    "CD_CLIENTE_ENDERECO": null,
                    "ENDERECO": "",
                    "CEP": "",
                }];
            }

            Retorno = item.ENDERECOS.map((ender: IClienteEndereco) => {
                return {
                    "CD_CLIENTE_ENDERECO": ender.CD_CLIENTE_ENDERECO,
                    "ENDERECO": `${ender.BAIRRO}, ${ender.LOGRADOURO}, ${ender.NUMERO}`,
                    "CEP": ender.CEP
                }
            });

            EnderPadrao = item.ENDERECOS.filter((x: IClienteEndereco) => x.CD_CLIENTE_ENDERECO || "" === item.CD_ENDERECO_PADRAO)[0];
            setEnderecoPadrao({
                "CD_CLIENTE_ENDERECO": EnderPadrao.CD_CLIENTE_ENDERECO,
                "ENDERECO": `${EnderPadrao.BAIRRO}, ${EnderPadrao.LOGRADOURO}, ${EnderPadrao.NUMERO}`,
                "CEP": EnderPadrao.CEP,       
            });
            return Retorno
        });

        if (JSON.stringify(Retorno) === '[]') {
            Retorno = [{
                "CD_CLIENTE_ENDERECO": null,
                "ENDERECO": "",
                "CEP": "",
            }];
            setEnderecoPadrao({
                "CD_CLIENTE_ENDERECO": null,
                "ENDERECO": "",
                "CEP": "",
            });
        };

        setDsListaClientesEndereco(Retorno);
    }, [dsListaClientes]);

    const qryEntrega = useQuery(
        'qryEntrega',
        (   
            () => API.get('entregas/buscar/', CdEntrega, false)
        ),
        {
            retry: false,
            enabled: false,
            refetchInterval: 120000
        }
    );

    useEffect(() => {
        if (!qryEntrega.isError) {
            if (CdEntrega !== '-1') {qryEntrega.refetch()};
        } else { 
            alert(qryEntrega.error);
            navigate('cadEntrega');
        };
    }, [qryEntrega.isSuccess, qryEntrega.error, qryEntrega.isError, CdEntrega]); // eslint-disable-line

    const dsEntrega = useMemo<IEntrega>(() => 
        qryEntrega.isSuccess && CdEntrega !== '-1' ? 
            qryEntrega.data 
        :
            {
                CD_ENTREGA: null,
                CD_CLIENTE_ENDERECO: null,
                CD_CLIENTE: null,
                CD_USUARIO: null,
                TAXA: 0,
                VLR_PEDIDO: 0,
                VLR_TROCO: 0,
                FORMA_PAGTO: "",
                STATUS: EStatus.Pendente,
                PAGO: false,
                DT_ENTREGA: null,
                OBSERVACOES: "",
                "CLIENTE": {
                    "CD_CLIENTE": null,
                    "CD_EMPRESA": null,
                    "NOME": "",
                    "CELULAR": "",
                    "CD_ENDERECO_PADRAO": null
                },
                "USUARIO": {
                    "CD_USUARIO": null,
                    "NOME": "",
                    "CD_EMPRESA": null,
                    "TAXA": null,
                    "ENTREGADOR": null,
                    "CELULAR": ""
                },
                "ENDERECO": {
                    "CD_CLIENTE_ENDERECO": null,
                    "CD_CLIENTE": null,
                    "CD_EMPRESA": null,
                    "CEP": "",
                    "ENDERECO": "",
                    "ENDERECO_NUMERO": "",
                    "ENDERECO_BAIRRO": "",
                    "ENDERECO_CIDADE": "",
                    "ENDERECO_UF": ""
                }
            }
    , [qryEntrega, CdEntrega]);

    const cancelar = () => {navigate(-1)};
    const abrir = () => {
        setAbriu(true);
        navigate(`cadastro/${CdEntrega === "-1" ? "" : CdEntrega}`)
    }
    const confirmar = (dados: ICadEntrega) => {
        if (CdEntrega === '-1') {
            if ((typeof UserParams.DT_COMPETENCIA !== "undefined") && (UserParams.DT_COMPETENCIA !== null)) {
                const data = Object.assign({}, dados);
                data.DT_COMPETENCIA = UserParams.DT_COMPETENCIA!;

                Controller.Cadastrar.mutateAsync(data)
                    .then(() => navigate(-1))
                    .catch((e) => alert(e))
                // mutCadEntrega.mutate(data);
            } else {
                alert('Não é possível cadastrar uma entrega: Não foi encontrado nenhum dia em aberto');
            }
        } else {
            Controller.Alterar.mutateAsync({CD_ENTREGA: Number(CdEntrega), ...dados})
                .then(() => navigate(-1))
                .catch((e) => alert(e))
        };
    };

    const abrirCadastroCliente = (CdCliente: number | null = -1) => {
        let Cliente: Partial<ICadCliente> | undefined;
        
        if (dsListaClientes !== undefined) {
            Cliente = dsListaClientes.find!((item: ICadCliente) => String(item.CD_CLIENTE) === String(CdCliente));
        };

        const win = window.open(`/cadCliente/${CdCliente === -1 ? "-1" : Cliente?.CELULAR}/true`, 'Cadastro de Cliente');
        const timer = setInterval(() => {
            if (win!.closed) {
                clearInterval(timer);
                qryListaClientes.refetch();
            };
        }, 500);
    };

    return {
        dsListaClientes,
        dsListaClientesEndereco,
        dsListaTaxas,
        enderecoPadrao,
        enderecoAtual,
        setEnderecoAtual,
        mutClienteEndereco,
        qryEntrega,
        dsEntrega,
        Cancelar: cancelar,
        Confirmar: confirmar,
        Abrir: abrir,
        AbrirCadastroCliente: abrirCadastroCliente
    };
};

export interface IUseCadEntrega {
    dsListaClientes         : Partial<ICadClientes>;
    dsListaClientesEndereco : Array<IEnderecoEntrega>;         
    dsListaTaxas            : Partial<ICadTaxas>;         
    enderecoPadrao          : IEnderecoEntrega;            
    enderecoAtual           : IEnderecoEntrega;         
    setEnderecoAtual        : React.Dispatch<React.SetStateAction<IEnderecoEntrega>>;         
    mutClienteEndereco      : (CdCliente: number) => void;         
    qryEntrega              : UseQueryResult<any, unknown>;         
    dsEntrega               : IEntrega;              
    Cancelar                : () => void;
    Confirmar               : (dados: ICadEntrega) => void;
    Abrir                   : () => void;
    AbrirCadastroCliente    : (CdCliente?: number | null) => void;
};

export default useCadEntrega;
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import useControllers from "../utils/useControllers";
import { IFechamento, IFechamentoItem, IFechamentoItems, IFechamentos } from "../../Models/Fechamento";
import { DeepPartial } from "../../Models/genericInterfaces";

const useFechamentoController = (updateQueryKey: string, isArray: boolean = true, enableQuery: boolean = false) : IUseFechamentoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Create } = useControllers();

    const primaryKey = 'CD_FECHAMENTO';
    const endpoint = 'fechamento';

    const mutAlterar = useMutation(
        (data : DeepPartial<IFechamento>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: DeepPartial<IFechamento>, data: DeepPartial<IFechamento>) => {
                queryClient.invalidateQueries('competencia');
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IFechamento>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IFechamento>) => 
            API.get(`${endpoint}/buscar/${data.DT_COMPETENCIA}`, "")
        , {
            onSuccess: (ret: IFechamento) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IFechamento>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            onSuccess: (ret: any, data: DeepPartial<IFechamento>) => {
                queryClient.invalidateQueries('competencia');
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IFechamentos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                if (ret['Criado']) {
                                    return Create(oldData, ret['Criado']);
                                } else {
                                    return Update(oldData, ret['Alterado'], primaryKey, isArray);
                                }
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IFechamentos>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 120000
        }
    );    

    const mutListarItens = useMutation(
        (data : DeepPartial<IFechamentoItem>) => 
            API.get(`${endpoint}/buscarVendaItens/${data.DT_COMPETENCIA}`, "")
        , {
            onSuccess: (ret: IFechamentoItems) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    return {
        Alterar : mutAlterar,
        Buscar  : mutBuscar,
        Listar  : qryListar,
        Cadastrar: mutCadastrar,
        ListarItens: mutListarItens,
    }
}

export interface IUseFechamentoController {
    Alterar     : UseMutationResult<any, any, DeepPartial<IFechamento>, unknown>;
    Buscar      : UseMutationResult<IFechamento, any, DeepPartial<IFechamento>, unknown>
    Listar      : UseQueryResult<IFechamentos, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IFechamento>, unknown>
    ListarItens : UseMutationResult<IFechamentoItems, any, DeepPartial<IFechamentoItem>, unknown>
}

export default useFechamentoController;
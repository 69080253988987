import { Badge } from "react-bootstrap";
import { IUseCardapio } from "../../../Hooks/Cardapio";

interface Props {
    cardapio: IUseCardapio
}

const CarrinhoMesa = ({cardapio} : Props) => {
    return (
        <div>
            <div className="w-100 d-flex justify-content-center flex-wrap my-2">
                <Badge pill style={{fontSize: '1rem'}} bg="light" text="primary">Mesa {cardapio.NrMesa}</Badge>
            </div>
        </div>
    )
}

export default CarrinhoMesa;
import { UseQueryResult, useQuery } from "react-query";
import useAPI from "../Hooks/utils/useAPI";
import { useContext } from "react";
import { CtxParams } from "../Contexts/ctxParams";
import { ICadUsuarios } from "../Models/cadUsuario";

const useQryUsuarios = () : UseQueryResult<ICadUsuarios, unknown> => {
    const API = useAPI();
    const UserParams = useContext(CtxParams);

    return (
        useQuery<ICadUsuarios>(
            'qryListaUsuarios',
            (
                () => API.get('usuarios/buscarTodos', '', false)
            ),
            {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false,
                enabled: !UserParams.ENTREGADOR,
            }
        )
    )
}

export default useQryUsuarios;
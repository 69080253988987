import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions, TableCell } from "pdfmake/interfaces";
import { IPedido, IPedidoItems } from "../Models/pedido";
import useMask from "../Hooks/utils/useMask";
import { favicon } from "./logo";

const ReportPedido = (Pedido: IPedido) => {
    const formatter = useMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const title     : Content = [
		{
			table: {
                widths: ['auto', '*', 'auto'],
				body: [
					[{text: 'Pedido: #'+String(Pedido.CD_PEDIDO), fillColor: '#CCCCCC', bold: true}, {text: 'Frota: Hub de Delivery', fillColor: '#CCCCCC', bold: true}, {text: 'escopo.app', fillColor: '#CCCCCC', bold: true}],
                    [{text: String((JSON.parse(localStorage.getItem('params')!)).EMPRESA ?? ""), colSpan: 3}],
				]
			},
            margin: [40, 30, 40, 5],
            alignment: "center",
		},
        {
			table: {
                widths: ['*', 'auto', 'auto'],
				body: [
                    [{text: Pedido.NOME_CLIENTE}, {text: formatter.setMaskPhone(Pedido.CLIENTE.CELULAR)}, {text: formatter.setDate(new Date(Pedido.DT_ABERTURA!), {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}, false, false)}],
                    [{text: ((Pedido.ENTREGA) && (Pedido.ENTREGA.CD_ENTREGA !== undefined)) ? formatter.getEnderecoString(Pedido.ENTREGA.ENDERECO) : 'Não foram informados dados da entrega', colSpan: 3}],
				]
			},
            margin: [40, 0, 40, 10],
            alignment: "center",
		},
    ];

    const gerarCorpoTabelaItens = (itens: IPedidoItems) => {
            const corpoTabela : TableCell[] = [[
                {text: 'Quantidade', fillColor: '#CCC'}, 
                {text: 'Produto', fillColor: '#CCC'}, 
                {text: 'Subtotal (R$)', fillColor: '#CCC'}
            ]];
        
            if (itens && itens.length > 0) {
            itens.forEach((item) => {
                const subtotal = formatter.setMaskMoney(item.VLRUN_PRODUTO * item.QTD_PRODUTO, '', ',');
                const quantidade = formatter.getQtdCaixaString(Number(item.QTD_PRODUTO ?? 1), item.CAIXA_QTD, item.CAIXA_NOME);

                corpoTabela.push([
                    {text: quantidade === '' ? String(item.QTD_PRODUTO) : `${String(item.QTD_PRODUTO)}\n(${quantidade})`, alignment: "right"}, 
                    {text: String(item.VARIACAO ? item.NOME + ' ' + item.VARIACAO : item.NOME)}, //TODO: observacao e complementos
                    {text: subtotal, alignment: "right"}
                ]);
            });
            }
        
            return corpoTabela;
    };

    const itens   : Content = [
        {
			table: {
                widths: ['auto', 'auto', 'auto', 'auto', '*'],
				body: [
					[
                        {text: 'Subtotal', fillColor: '#CCC'}, 
                        {text: 'Entrega', fillColor: '#CCC'}, 
                        {text: 'Total', fillColor: '#CCC'}, 
                        {text: 'Troco', fillColor: '#CCC'}, 
                        {text: String(Pedido.FORMA_PAGTO ?? 'A Receber'), fillColor: '#CCC'}
                    ],
                    [
                        formatter.setMaskMoney(Number(Pedido.TOTAL ?? 0) - Number(Pedido.ENTREGA?.TAXA ?? 0), 'R$', ','),   //subtotal
                        formatter.setMaskMoney(Pedido.ENTREGA?.TAXA ?? 0, 'R$', ','),                                       //entrega
                        formatter.setMaskMoney(Pedido.TOTAL ?? 0, 'R$', ','),                                               //total
                        formatter.setMaskMoney(Number(Pedido.VLR_TROCO ?? 0), 'R$', ','),                                   //troco
                        formatter.setMaskMoney(Number(Pedido.TOTAL ?? 0) + Number(Pedido.VLR_TROCO ?? 0), 'R$', ',')        //forma pagamento / a receber
                    ]
				]
			},
            margin: [0, 0, 0, 10],
            alignment: "center"
		},
        {
            table: {
                widths: ['auto', '*', 'auto'],
                body: gerarCorpoTabelaItens(Pedido.ITENS!) as any
            }
        }
    ];

    const footer    : Content = [
        {
            image: `data:image/png;base64,${favicon}`,            
            width: 30,
            height: 30,
            alignment: "center",
            opacity: 0.4
        },
        {
            text: `Não é documento fiscal`,
            alignment: 'center',
            fontSize: 8,
            absolutePosition: {x: 0, y: 15}
        }
    ]

    // const dadosJogos = Pedido.ITENS!.map((item, index) : Content => {
    //     return [
    //         {
    //             margin: [0, 0, 0, 0],
    //             table: {
    //                 headerRows: 1,
    //                 widths: ['auto', '*', 'auto'],
    //                 body: [
    //                     [ 
    //                         {columns: [{text: 'Quantidade', alignment: 'right'}], style: 'tableHeader', bold: true},
    //                         {columns: [{text: 'Produto', alignment: 'left'}], style: 'tableHeader', bold: true},
    //                         {columns: [{text: 'SubTotal', alignment: 'right'}], style: 'tableHeader', bold: true}
    //                     ],
    //                     [
    //                         {text: item.QTD_PRODUTO, colSpan: 1, rowSpan: 1},
    //                         {text: item.NOME, colSpan: 1, rowSpan: 1},
    //                         {text: item.VLRUN_PRODUTO * item.QTD_PRODUTO, colSpan: 1, rowSpan: 1},
    //                     ]
    //                 ]
    //             },
    //             layout: { fillColor: function (rowIndex: any, node: any, columnIndex: any) {
    //                 return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
    //             }}
    //         }
    //     ]
    // });

    const docDefinitions : TDocumentDefinitions = {
        // pageSize: {width: 226, height: 200},
        pageMargins: [40, 140, 40 , 60], //left top right bottom

        header: [title],
        content: [itens],
        footer: [footer]
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default ReportPedido;
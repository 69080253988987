import { IPedidoItems } from "../../Models/pedido";

const usePedidoUtil = () => {
    function agruparComplementosPorComposição(itens: IPedidoItems) {
        const grupos = new Map();
        const classificacao: any[] = [];

        for (const item of itens) {
            if (item.COMPLEMENTOS!.some((complemento) => complemento !== null)) {
                const complementos = item.COMPLEMENTOS;
        
                for (const complemento of complementos!) {
                    let grupo = grupos.get(complemento.COMPOSICAO);
            
                    if (!grupo) {
                        grupo = [];
                        grupos.set(complemento.COMPOSICAO, grupo);
                        classificacao.push(complemento.COMPOSICAO ?? 'Adicionais')
                    }
                    
                    grupo.push(complemento);
                }
            }
        }
        
        const agrupados = Array.from(grupos.values());
        const resultado: any[] = [];
        agrupados.map((item, index) => 
            resultado.push({COMPLEMENTO: classificacao[index], ITENS: item})
        )
        return resultado;
    }

    return {
        agruparComplementosPorComposição
    }
}

export default usePedidoUtil;
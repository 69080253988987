import * as Yup from 'yup';
import { NOME, CELULAR, EMAIL, CEP, TAXA, LOGRADOURO, BAIRRO, NUMERO, CIDADE, UF } from './defaultModels';
import { ICadTaxas } from './cadTaxa';

export interface ICadEmpresa {
    CD_EMPRESA?         : number;
    NOME                : string;
    CELULAR             : string
    EMAIL               : string;
    TELEFONE            : string | null;
    CEP                 : string;
    ENDERECO            : string;
    ENDERECO_NUMERO     : string;
    ENDERECO_BAIRRO     : string;
    ENDERECO_CIDADE     : string;
    ENDERECO_UF         : string;
    TAXA                : number;
};

export interface IEmpresaStatus {
    NOME                : string;
	ABERTO              : boolean;
    ENDERECO_CIDADE     : string;
    ENDERECO_UF         : string;
    TAXAS               : ICadTaxas;
    HR_ABERTURA         : string;
    HR_FECHAMENTO       : string;
}

export const schemaAltEmpresa = Yup.object().shape({  
    NOME,
    CELULAR,
    EMAIL,
    CEP,
    LOGRADOURO,
    NUMERO,
    BAIRRO, 
    CIDADE,    
    UF,
    TAXA       
});
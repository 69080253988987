import { useEffect, useCallback, useState } from 'react';
import { AnchorPoint, ParentInstruction } from './interfaces';

const useContextMenu = (context?: any, parent?: ParentInstruction) => {
    const [anchorPoint, setAnchorPoint] = useState<AnchorPoint>(!!parent ? parent.anchor : {x: 0, y: 0});
    const [isShown, setIsShown] = useState<boolean>(false);
    const [internalContext, setInternalContext] = useState<any>();

    const handleClose = useCallback(() => {
        document.dispatchEvent(
            new MouseEvent('mousedown', {
                view: window,
                bubbles: true, 
                cancelable: true
            }))
    },[])

    const handleContextMenu = useCallback((event: MouseEvent) => {
        if (!!context && !(!!parent)) {
            event.preventDefault();
            setAnchorPoint({ x: event.pageX, y: event.pageY });
            setIsShown(true);
            setInternalContext(context);
        }
    }, [setIsShown, setAnchorPoint, context, parent]);

    const handleClick = useCallback(() => {
        if (isShown && !(!!parent)) {
            setIsShown(false);
            setInternalContext(undefined);
        }
    }, [isShown, parent]);

    useEffect(() => { // Controle dos submenus
        if (!!parent) {
            if (anchorPoint.x === 0 && anchorPoint.y === 0) {
                setIsShown(false);
            } else {
                setIsShown(parent.show);                
            }

            setAnchorPoint(parent.anchor);
        }
    }, [parent, anchorPoint])

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('mousedown', handleClick);
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    });

    return { anchorPoint, isShown, internalContext, handleClose };
};

export { useContextMenu };

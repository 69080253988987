import { useContext, useMemo, useCallback } from "react";
import { ICtxAppParams_Filtros, ICtxAppParams_Modal } from "../../Models/ctxAppParams";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { IReactTable_Grid } from "../../Models/genericInterfaces";
import { PiSmileySadBold } from "react-icons/pi";
import { Button } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import FormRodape from "../../Components/formularios/FormRodape";
import useLogController from "../../Data/Log";

const useApp = () : IUseApp => {
    const {setModal, setFiltros} = useContext(CtxAppParams);
    const Log = useLogController();

    const RouteManager = (route: string, params: Array<string>) => {
        return route.replace(/:[^\/\?]+/g, () => params.shift() || ''); //eslint-disable-line
    };

    const isMobile = () : boolean => {
        if (window.innerHeight > window.innerWidth) {
            return true;
        } else {
            return false;
        }
    };

    const showModal = useCallback((Props: ICtxAppParams_Modal) => {
        setModal({
            Visibilidade    : Props.Visibilidade,
            Titulo          : Props.Titulo,
            Descricao       : Props.Descricao,
            Anexos			: Props.Anexos,
            Inputs          : Props.Inputs,
            Variante        : Props.Variante,
            onSubmit        : Props.onSubmit,
            onCancel        : Props.onCancel
        })
    }, [setModal])

    const filtrar = <T extends keyof ICtxAppParams_Filtros>(
        filterKey: T,
        newValue: Partial<ICtxAppParams_Filtros[T]>
    ) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            [filterKey]: {
                ...prevFiltros[filterKey],
                ...newValue,
            },
        }));
    };

    const grid = useMemo<IReactTable_Grid>(() => [
        {
            id: "chave",
            accessor: "CHAVE"
        },
        {
            id: "valor",
            accessor: "VALOR"
        }        
    ], []);

    function Fallback({ error } : any) {
		return (
			<div role="alert" className='h-100' style={{textAlign: 'justify'}}>
                <div style={{height: '3rem', backgroundColor: 'var(--bs-danger)'}} className="w-100 d-flex align-items-end justify-content-between w-100">
                    <h2 className="mx-3" style={{color: 'var(--bs-dark)'}}>Erro no Sistema</h2>
                    <h1><PiSmileySadBold className={"rotacao mx-3"} /></h1>
                </div>
				<p className="mx-1 my-3">Parece que há um problema imprevisto no Frota, por favor entre em contato conosco e nos reporte o problema. Faremos o possível para corrigi-lo o mais rápido possível.</p>
                <FormRodape>
                    <Button 
                        variant="dark"
                        size="lg"
                        onClick={() => {
                            window.location.reload()
                        }}
                    >
                        <HiRefresh /> Recarregar
                    </Button>

                    <Button 
                        variant="primary"
                        size="lg"
                        onClick={() => {
                            Log.Cadastrar.mutate({
                                CD_EMPRESA  : Number(JSON.parse(localStorage.getItem('params') ?? '{}')['CD_EMPRESA'] ?? 0),
                                ORIGEM      : 'F',
                                ERRO        : String(error),
                                INFO        : String(window.location.pathname)
                            })
                            window.open(`https://api.whatsapp.com/send?phone=5527996957014&text=${new URLSearchParams(`Olá, estou reportando o seguinte erro: ${window.location.pathname} -> ${error}`).toString()}&type=phone_number&app_absent=0`);
                        }}
                    >
                        <FaWhatsapp /> Reportar Problema
                    </Button>
                </FormRodape>
				<p className="mx-1 my-3">{`-> Erro retornado no ambiente "${process.env.REACT_APP_AMBIENTE}":`}</p>
				<div className="mx-3" style={{textAlign: 'left'}}>
					<code style={{ color: "white", backgroundColor: 'black', whiteSpace: 'pre-wrap' }}>{`On ${window.location.pathname}: ${error}`}</code>
				</div>
			</div>
		);
	}

    return {
        Filtrar: filtrar,
        Modal: showModal,
        Grid: grid,
        Fallback: Fallback,
        isMobile: isMobile,
        RouteManager: RouteManager,
    }
}

export interface IUseApp {
    Filtrar     : <T extends keyof ICtxAppParams_Filtros>(filterKey: T, newValue: Partial<ICtxAppParams_Filtros[T]>) => void;
    Modal       : (Props: ICtxAppParams_Modal) => void;
    Grid        : IReactTable_Grid;
    Fallback    : any;
    isMobile    : () => boolean;
    RouteManager : (route: string, params: Array<string>) => string;
}

export default useApp;
import { useState, useMemo, useContext, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { DeepPartial, EMode, IReactTable_Grid } from "../../../Models/genericInterfaces";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { ICadEmpresaIntegracao, ICadEmpresaIntegracoes } from "../../../Models/cadEmpresaIntegracao";
import useEmpresaIntegracaoController from "./controller";
import { SiIfood } from "react-icons/si";
import useIntegracoes from "../../utils/useIntegracoes";

const useEmpresaIntegracao = (CodigoItem?: string) : IUseEmpresaIntegracao => {
    const navigate = useNavigate();
    const location = useLocation();

    const { Filtros } = useContext(CtxAppParams);
    const Controller = useEmpresaIntegracaoController('listaEmpresaIntegracoes');
    const [Mode, setMode] = useState<EMode>(EMode.Browse);
    const isSubRender = location.pathname.includes('/empresa');

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<ICadEmpresaIntegracao>({} as ICadEmpresaIntegracao);
    const { authIfood } = useIntegracoes();

    const dsItem = useMemo<ICadEmpresaIntegracao>(() => 
        Controller.Buscar.isSuccess ? 
            Controller.Buscar.data
        : 
            {} as ICadEmpresaIntegracao
    , [Controller.Buscar]);

    const dsLista = useMemo<ICadEmpresaIntegracoes>(() => 
        Controller.Listar.isSuccess ? 
            Controller.Listar.data.filter((data: any) => {
                if (!!data) {
                    return data[Filtros.EmpresaIntegracao.tipoPesquisa]?.toLowerCase().indexOf(Filtros.EmpresaIntegracao.pesquisa.toLowerCase()) !== -1
                } else {
                    return data
                }
            })
        : 
            [{} as ICadEmpresaIntegracao]
    , [Controller.Listar, Filtros.EmpresaIntegracao]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "ATIVO",
            Header: "Situação",
            Cell: (row: any) : any =>
                <Form.Check
                    name="ENTREGADOR"
                    style={{zoom: '1.1'}}
                    id="ENTREGADOR"
                    type="switch"
                    checked={row.row.original['ATIVO'] ?? true}
                    label={row.row.original['ATIVO'] ? 'Ativo' : 'Inativo'}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                    onChange={(e: any) => {
                        e.stopPropagation();
                        Controller.Alterar.mutate({
                            CD_INTEGRACAO: row.row.original["CD_INTEGRACAO"],
                            ATIVO: !row.row.original['ATIVO']
                        })
                    }}
                />
        },
        {
            id: "NOME",
            Header: "Integração",
            accessor: "NOME"
        },
        {
            id: "SLUG",
            Header: "Slug",
            accessor: "SLUG"
        },
        {
            id: "TIPO",
            Header: "Tipo",
            Cell: (row: any) : any => row.row.original['TIPO'] === 'P' ? 'Proprietário' : 'Terceirizado'
        },
        {
            id: "Acoes",
            Header: "Ações",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <div className="d-flex justify-content-around">
                        <div className="mx-1" style={{width: '2.8rem'}}></div>

                        <Button 
                            className="mx-1"
                            variant="danger"
                            style={{width: '2.8rem'}}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                setSelecionado(row.row.original);
                                setShowModal(true);
                            }}
                        >
                            <FaTrashAlt />
                        </Button>

                        {
                            row.row.original['NOME'] === 'IFOOD' && !row.row.original['ID'] ?
                                <Button 
                                    className="mx-1"
                                    variant="outline-danger"
                                    style={{width: '2.8rem'}}
                                    title="Autenticar no ifood"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        authIfood(Number(row.row.original['CD_INTEGRACAO']), String(row.row.original['SLUG'] ?? ''));
                                        // setSelecionado(row.row.original);
                                        // setShowModal(true);
                                    }}
                                >
                                    <SiIfood />
                                </Button>
                            : <div className="mx-1" style={{width: '2.8rem'}}></div>
                        }   
                    </div>                 
                </div>
        },
    ], [Controller.Alterar, authIfood]);

    const salvar = useCallback((EmpresaIntegracao: DeepPartial<ICadEmpresaIntegracao>) => {
        if (Mode === EMode.Edit) {
            Controller.Alterar.mutate(EmpresaIntegracao, {onSuccess: () => navigate(`/empresa/integracoes/`)});
        } else if (Mode === EMode.Include) {
            Controller.Cadastrar.mutate(EmpresaIntegracao, {onSuccess: () => navigate(`/empresa/integracoes/`)});
        };
    }, [Controller.Cadastrar, Controller.Alterar, Mode, navigate]);

    const abrir = useCallback((EmpresaIntegracao: DeepPartial<ICadEmpresaIntegracao>) => {
        const Codigo = EmpresaIntegracao.CD_INTEGRACAO || Number(CodigoItem);

        if (EmpresaIntegracao.CD_INTEGRACAO === 0) {
            navigate(`/empresa/integracoes/`);
            return
        };

        if (EmpresaIntegracao.CD_INTEGRACAO !== undefined) { // Se informado, vai mudar a página
            navigate(`/empresa/integracoes/cadastro/${EmpresaIntegracao.CD_INTEGRACAO}`);
            return
        };        

        if (Codigo === -1) {
            setMode(EMode.Include);
            Controller.Buscar.reset();
        } else {
            setMode(EMode.Edit);
            Controller.Buscar.mutate({CD_INTEGRACAO: Codigo});
        };
    }, [Controller.Buscar, CodigoItem, navigate]);

    const excluir = (EmpresaIntegracao: ICadEmpresaIntegracao) => {
        Controller.Deletar.mutate({
            CD_INTEGRACAO: EmpresaIntegracao.CD_INTEGRACAO
        });
    };

    useEffect(() => {
        if (CodigoItem !== undefined) {
            abrir({})
        } else {
            setMode(EMode.Browse);
        }
    }, [CodigoItem, Mode]); //eslint-disable-line

    return {
        Salvar: salvar,
        Abrir: abrir,
        Excluir: excluir,

        Item: dsItem,
        Lista: dsLista,
        Grid: gridLista,

        Modo: Mode,
        SubRender: isSubRender,

        ShowModal: showModal,
        setShowModal: setShowModal,
        Selecionado: selecionado,
    }
}

export interface IUseEmpresaIntegracao {
    Salvar              : (EmpresaIntegracao: DeepPartial<ICadEmpresaIntegracao>) => void;
    Abrir               : (EmpresaIntegracao: DeepPartial<ICadEmpresaIntegracao>) => void;
    Excluir             : (EmpresaIntegracao: ICadEmpresaIntegracao) => void;
    Item                : ICadEmpresaIntegracao;
    Lista               : ICadEmpresaIntegracoes;
    Grid                : IReactTable_Grid;
    Modo                : EMode;
    SubRender           : boolean;
    ShowModal           : boolean;
    setShowModal        : React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado         : ICadEmpresaIntegracao;
};

export default useEmpresaIntegracao;
import { Container, Nav } from "react-bootstrap";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

const Empresa = () => {
    const navigate = useNavigate();
    let location = useLocation();

    return (
        <Container className="verticalCenter">
            <Nav 
                justify variant="pills" 
                style={{width: '100%'}}
                defaultActiveKey={(() => {
                    switch(location.pathname) {
                        case '/empresa/':
                            return ''
                        case '/empresa/integracoes':
                            return 'integracoes'
                        case '/empresa/usuarios':
                            return 'usuarios'
                        default:
                            return ''
                    }})()
                }
                onSelect={(eventKey) => navigate(`/empresa/${eventKey}`)} 
                fill
            >
                <Nav.Item>
                    <Nav.Link eventKey="">Dados</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="usuarios">Usuários</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="integracoes">Integrações</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="parametros">Parâmetros</Nav.Link>
                </Nav.Item>

                <Outlet />
            </Nav>
        </Container>
    )
}

export default Empresa;
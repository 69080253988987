import { useMemo, useState, useEffect, MouseEvent } from "react";
import { UseMutationResult, useMutation, useQuery } from "react-query";
import useAPI from "../utils/useAPI";
import { FaPencilAlt, FaPlus } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { IClienteEndereco, IClienteEnderecos, IEnderecoBuscaCEP } from "../../Models/cadClienteEndereco";
import useCEP from "../utils/useCEP";
import useMask from "../utils/useMask";
import { IReactTable_Grid } from "../../Models/genericInterfaces";

const useCadClienteEndereco = (Celular: string, handleSubmitForm: any, canCreate: boolean, onConfirmSuccess: any) : IUseCadClienteEndereco => {
    const API = useAPI();
    const CEP = useCEP();
    const formatter = useMask();
    const [cdEndereco, setCdEndereco] = useState<number>(0); // -1=novo/0=nada/...=editar
    const [selecionado, setSelecionado] = useState<IClienteEndereco>({} as IClienteEndereco);

    const qryLista = useMutation((xcelular: any) => API.get('clientes/buscar/', xcelular, false));

    useEffect(() => {
        if (qryLista.isIdle) {
            if (Celular !== '') {qryLista.mutate(Celular)};
        }
    }, [Celular, qryLista]);

    useEffect(() => {
        if (canCreate) {
            setCdEndereco(-1);
        };
    }, [canCreate]);

    const CdCliente = useMemo<number>(() => 
        qryLista.isSuccess && Celular !== '' ? qryLista.data.CD_CLIENTE : -1
    , [qryLista.isSuccess, qryLista.data, Celular]);

    const dsLista = useMemo<IClienteEnderecos>(() => 
        qryLista.isSuccess && Celular !== '' ? qryLista.data.ENDERECOS : [{
            CD_CLIENTE: undefined,
            CEP: '',
            ENDERECO: '',
            ENDERECO_BAIRRO: '',
            ENDERECO_CIDADE: '',
            ENDERECO_NUMERO: '',
            ENDERECO_UF: '',
        }]
    , [qryLista.isSuccess, qryLista.data, Celular]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: 'endereco',
            Header: "Endereços",
            Cell: ({row: {original}}: any) : string => {
                return formatter.getEnderecoString(original)
            }
        },
        {
            id: "acoes",
            Header: 
                <Button 
                    variant="success"
                    size="sm"
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        handleSubmitForm();
                    }}
                >
                    <FaPlus />
                    <>{Celular === '' ? ' Adicionar' : '' }</>
                </Button>,
            Cell: (row : any) => typeof row.row.original['CD_CLIENTE_ENDERECO'] !== 'undefined' ?
                    <Button 
                        variant="warning" 
                        size="sm" 
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            setCdEndereco(row.row.original['CD_CLIENTE_ENDERECO'])
                        }}
                    >
                        <FaPencilAlt />
                    </Button>
                :
                    ''
        },
    ], [Celular, handleSubmitForm, formatter]);

    const qryListaEnderecos = useQuery(
        'qryListaEnderecos',
        (
            () => API.get('taxas/buscarTodos', '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
        }
    );

    const dsListaEnderecos = useMemo<Partial<IClienteEnderecos>>(() =>
        qryListaEnderecos.isSuccess ? 
            qryListaEnderecos.data
        : 
            [{
                TAXA: 0,
                CEP: "",
                BAIRRO: "",
            }]
    , [qryListaEnderecos]);

    const mutCadClienteEndereco = useMutation((data : IClienteEndereco) => API.post('enderecos/cadastrar', data), {
        onSuccess: () => {
            setCdEndereco(0);
            onConfirmSuccess();
            qryLista.mutate(Celular);
        },
        onError: (e: any) => {
            alert(e);
        }
    });

    const mutAltClienteEndereco = useMutation((data : IClienteEndereco) => API.put(`enderecos/alterar/${cdEndereco}`, data), {
        onSuccess: () => {
            setCdEndereco(0);
            onConfirmSuccess();
            qryLista.mutate(Celular);
        },
        onError: (e: any) => {
            alert(e);
        }
    });

    const mutBuscaCep = useMutation((cep: string) => CEP.get(cep));

    useEffect(() => {
        const itemIndex = dsLista.findIndex((item: IClienteEndereco) => item.CD_CLIENTE_ENDERECO === cdEndereco);

        if (itemIndex === -1) {
            setSelecionado({
                CD_CLIENTE: CdCliente,
                CEP: '',
                LOGRADOURO: '',
                BAIRRO: '',
                CIDADE: '',
                NUMERO: '',
                UF: '',
                COMPLEMENTO: '',
            });
        } else {
            setSelecionado(dsLista[itemIndex]);
        }
    }, [cdEndereco, CdCliente, dsLista]);

    const buscaCep = (cep: string) => {
        if (cep.length === 8) {
            mutBuscaCep.mutate(cep, {
                onSuccess: (data : IEnderecoBuscaCEP) => {
                    setSelecionado({
                        CD_CLIENTE: CdCliente,
                        CEP: cep,
                        LOGRADOURO: data.logradouro!,
                        BAIRRO: data.bairro!,
                        CIDADE: data.localidade!,
                        NUMERO: '',
                        UF: data.uf!,
                        COMPLEMENTO: '',
                    });
                }
            });
        };
    };

    return {
        Selecionado: selecionado,
        Lista: dsLista,
        Grid: gridLista,
        CdEndereco: cdEndereco,
        CdCliente,
        setCdEndereco: setCdEndereco,
        BuscaCep: buscaCep,
        mutCadClienteEndereco,
        mutAltClienteEndereco,
        dsListaEnderecos
    };
};

export interface IUseCadClienteEndereco {
    Selecionado             : IClienteEndereco;
    Lista                   : IClienteEnderecos;
    Grid                    : IReactTable_Grid;
    CdEndereco              : number;
    CdCliente               : number;
    setCdEndereco           : React.Dispatch<React.SetStateAction<number>>;
    BuscaCep                : (cep: string) => void;
    mutCadClienteEndereco   : UseMutationResult<any, any, IClienteEndereco, unknown>;
    mutAltClienteEndereco   : UseMutationResult<any, any, IClienteEndereco, unknown>;
    dsListaEnderecos        : Partial<IClienteEnderecos>;
}

export default useCadClienteEndereco;
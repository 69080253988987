export interface IEmpresaParametro {
    CD_PARAMETRO        : number;
    CD_EMPRESA          : number;
    ATIVO               : boolean;
    VALOR               : string;
    PARAMETRO_NOME      : string;
    PARAMETRO_DESCRICAO : string;
    DEFAULT_ATIVO       : boolean;
    DEFAULT_VALOR       : string;
};
export interface IEmpresaParametros extends Array<IEmpresaParametro>{};

export enum ETpFiltroEmpresaParametro {
    Parametro     = 'PARAMETRO_NOME',
};
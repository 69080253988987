import { IUseFechamento } from "../../../Hooks/Fechamento/lista";
import { ICtxParams } from "../../../Models/ctxParams";
import { IUseApp } from "../../../Hooks/utils/useApp";
import Tabela from "../../../Components/tabela";
import useRanking from "../../../Hooks/ranking";

interface Props {
    Fechamento: IUseFechamento;
    formatter : any;
    UserParams: ICtxParams;
    App       : IUseApp;
}

const FechamentoResumoEntrega = ({Fechamento, formatter, UserParams, App} : Props) => {
    const Ranking = useRanking(String(Fechamento.Item.DT_COMPETENCIA));

    return (
        <>
            <Tabela colunas={Ranking.Grid} dados={Ranking.Lista} estadoInicial={Ranking.EstadoInicial} />
            {
                !UserParams.ENTREGADOR && <Tabela 
                    hideHeader
                    horizontal
                    colunas={App.Grid} 
                    dados={[
                        {
                            CHAVE: "Entregas",
                            VALOR: Fechamento.Item.ENTREGAS.QTD_ENTREGAS ?? 0
                        },
                        {
                            CHAVE: "Recebido",
                            VALOR: formatter.setMaskMoney(Fechamento.Item.ENTREGAS.VLR_TOTAL ?? 0, 'R$')
                        },
                        {
                            CHAVE: "Taxas de Entregas",
                            VALOR: formatter.setMaskMoney(Fechamento.Item.ENTREGAS.TAXA_ENTREGAS ?? 0, 'R$')
                        },
                        {
                            CHAVE: "Taxas Adicionais",
                            VALOR: formatter.setMaskMoney(Fechamento.Item.ENTREGAS.VLR_ADICIONAL ?? 0, 'R$')
                        },
                        {
                            CHAVE: "Taxas de Usuários",
                            VALOR: formatter.setMaskMoney(Fechamento.Item.ENTREGAS.TAXA_USUARIOS ?? 0, 'R$')
                        },
                        {
                            CHAVE: "Taxas Totais",
                            VALOR: formatter.setMaskMoney(Fechamento.Item.ENTREGAS.TAXA ?? 0, 'R$')
                        },
                    ]}
                />
            }
        </>
    )
}

export default FechamentoResumoEntrega;
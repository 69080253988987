import { Col, Row, Spinner } from "react-bootstrap";
import Tabela from "../../../Components/tabela";
import { IUseFechamento } from "../../../Hooks/Fechamento/lista";
import { IUseApp } from "../../../Hooks/utils/useApp";
import { ICtxParams } from "../../../Models/ctxParams";
import { IReactTable_Grid } from "../../../Models/genericInterfaces";
import { IFechamentoItem } from "../../../Models/Fechamento";

interface Props {
    Fechamento: IUseFechamento;
    formatter : any;
    UserParams: ICtxParams;
    App       : IUseApp;
}

const FechamentoItens = ({Fechamento, formatter, UserParams, App} : Props) => {
    return (
        Fechamento.Controller.ListarItens.isLoading
        ? <Spinner variant="primary" />
        : <Tabela
            colunas={[
                {
                    Header: "Produto",
                    Cell: (row: any) : any => row.row.original['NOME'] ?? ""
                },
                {
                    Header: "Quantidade",
                    Cell: (row: any) : any => {
                        const item = row.row.original as IFechamentoItem;

                        if (item.QTD_PRODUTO) {
                            const qtd = formatter.getQtdCaixaString(Number(item.QTD_PRODUTO ?? 1), item.CAIXA_QTD, item.CAIXA_NOME);
                            return qtd === '' || item.CAIXA_NOME === '' 
                                ? Number(item.QTD_PRODUTO) 
                                : <Col>
                                    <Row>
                                        <div>{qtd}</div>
                                    </Row>
                                    <Row>
                                        <div style={{fontWeight: 'lighter'}}>{item.QTD_PRODUTO} un</div>
                                    </Row>
                                </Col>
                        } else {
                            return ""
                        }
                    }
                },
                {
                    Header: "Total",
                    Cell: (row: any) : any => row.row.original['VLR_TOTAL'] ? formatter.setMaskMoney(row.row.original['VLR_TOTAL'], "R$") : ""
                }
            ] as IReactTable_Grid} 
            dados={Fechamento.ListaItens}
    />
    )
}

export default FechamentoItens;
import { useNavigate, useOutletContext } from "react-router-dom";
import { IUsePedido } from "../../Hooks/Pedido";
import Tabela from "../../Components/tabela";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import FormRodape from "../../Components/formularios/FormRodape";
import useMask from "../../Hooks/utils/useMask";
import PedidoListaItem from "./listaItem";
import useQryUsuarios from "../../Data/Usuarios";
import useApp from "../../Hooks/utils/useApp";
import { FaPlus } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { CtxParams } from "../../Contexts/ctxParams";
import useVendaPagamento from "../../Hooks/VendaPagamento";
import useReports from "../../Reports";

const PedidoLista = () => {
    const Pedido = useOutletContext<IUsePedido>();
    const Usuarios = useQryUsuarios();
    const AppParams = useContext(CtxAppParams);
    const UserParams = useContext(CtxParams);
    const formatter = useMask();
    const App = useApp();
    const isMobile = App.isMobile();
    const navigate = useNavigate();
    const Pagamento = useVendaPagamento(Pedido.Controller);
    const permiteCadastro = UserParams.DT_COMPETENCIA !== "0" && !UserParams.ENTREGADOR && String(UserParams.DT_COMPETENCIA) === (Pedido.DataCompetencia?.toString() || '');
    const Reports = useReports();

    const Rodape = permiteCadastro
        ? <FormRodape>             
            <Button size="lg" variant="dark" onClick={() => Pedido.Controller.Listar.refetch().then(() => Pedido.Controller.Buscar.reset())} disabled={Pedido.Controller.Listar.isFetching || Pedido.Controller.Buscar.isLoading}><HiRefresh className={Pedido.Controller.Listar.isFetching || Pedido.Controller.Buscar.isLoading ? "rotacao" : ""} /> Sincronizar</Button>
            <Button size="lg" variant="success" onClick={() => navigate(`/cardapio/${UserParams.CD_EMPRESA}/0/false/true`)}><FaPlus /> Pedido</Button>
        </FormRodape>
        : <FormRodape>             
            <Button variant="outline-primary" size="lg" onClick={() => navigate("/fechamento")}>Voltar</Button>
        </FormRodape>

    useEffect(() => {
        AppParams.setPagina(`Pedidos: ${formatter.setDate(new Date(Pedido.DataCompetencia), {day: "2-digit", month: "short", year: "numeric"})}`);
    });

    useEffect(() => {
        function disableScroll(event : any) {
            event.preventDefault();
        };

        document.body.classList.add('stop-scrolling');
        document.body.addEventListener('scroll', disableScroll, { passive: false });


        return () => {
            document.body.classList.remove('stop-scrolling');
            document.body.removeEventListener('scroll', disableScroll);
        }
    });

    if (Pedido.Controller.Listar.isLoading) {
        return (
            <Container className="verticalCenterFull">
                <Spinner variant="primary" />
            </Container>
        )
    } else if (Pedido.Controller.Listar.isFetched && (Pedido.Lista.length === 0 || Pedido.Lista[0].CD_PEDIDO === undefined)) {
        return (
            <Container className="verticalCenterFull">
                Não há pedidos para o dia informado
                {Rodape}
            </Container>
        )
    } else {
        return (
            <Container className={isMobile ? "" : "verticalCenter"}>
                <Col md={4}>
                    {(() => { // Lista
                        if (Pedido.Lista.length > 0 && Pedido.Lista[0].CD_PEDIDO !== undefined) {
                            return (
                                <Row style={{display: 'block', position: 'relative', height: isMobile ? 'auto' : 'calc(100vh - 9rem)', overflowY: 'scroll'}}>
                                    <Tabela 
                                        dados={Pedido.Lista} 
                                        colunas={Pedido.Grid} 
                                        hideHeader={true}
                                        horizontal={isMobile}
                                        sizes={isMobile ? {minWidth: '65vw'} : {}}
                                        estadoInicial={Pedido.EstadoInicial}
                                        selectedKey={{
                                            key: 'CD_PEDIDO',
                                            value: String(Pedido.Item.CD_PEDIDO)
                                        }}
                                        handleClick={(e) => 
                                            Pedido.Buscar(e)
                                        } 
                                    />
                                </Row>
                            )
                        } else if (Pedido.Controller.Listar.isError) {
                            return `Erro ao listar os pedidos: ${Pedido.Controller.Listar.error}`
                        } else {
                            return 'Nenhum pedido encontrado'
                        }
                    })()}
                </Col>

                <Col className={isMobile ? "mt-3 d-flex align-items-center justify-content-center ps-3" : "ps-5"} style={{position: 'static'}} md={8}>
                    <Row style={{display: 'block', position: 'relative', height: isMobile ? 'calc(100vh - 18rem)' : 'calc(100vh - 9rem)', overflowY: 'scroll'}}>
                        {(() => { // Item
                            if (Pedido.Controller.Buscar.isLoading) {
                                return <Spinner />
                            } else if (Pedido.Controller.Buscar.isSuccess && Pedido.Item.CD_PEDIDO !== undefined) {
                                return <PedidoListaItem Pedido={Pedido} formatter={formatter} usuarios={Usuarios} hookApp={App} Pagamento={Pagamento} Reports={Reports} />
                            } else if (Pedido.Controller.Buscar.isError) {
                                return `Erro ao buscar o pedido: ${Pedido.Controller.Buscar.error}`
                            } else {
                                return 'Nenhum pedido selecionado'
                            }
                        })()}
                    </Row>
                </Col>

                {Rodape}
            </Container>
        )
    };
}

export default PedidoLista;
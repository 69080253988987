import { useEffect } from "react";
import { useFormikContext } from "formik";
import { ICadTaxa } from "../../Models/cadTaxa";
import { IUseCadTaxa } from "../../Hooks/cadTaxa";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    customHook: IUseCadTaxa;
};

const CadTaxaProvider = (props: IProvider) => {
    const { values, setFieldValue } = useFormikContext<ICadTaxa>();

    useEffect(() => { // Relação CEP-Bairro
        if (values.BAIRRO === "") {
            setFieldValue(
                "BAIRRO",
                props.customHook.bairroBusca
            );
        };
    }, [props.customHook, setFieldValue]); //eslint-disable-line

    return (
        <>{props.children}</>
    )
}

export default CadTaxaProvider;
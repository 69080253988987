import * as Yup from 'yup';
import { NOME } from './defaultModels'

export interface ICadEmpresaIntegracao {
    CD_INTEGRACAO?  : number | null;
    CD_EMPRESA?     : number;
    NOME            : string;
    ID?             : string;
    USUARIO?        : string;
    SENHA?          : string;
    TOKEN?          : string;
    ENDPOINT?       : string;
    ULT_CONSULTA?   : Date | string | null;
    TIPO?           : string | null;
    ATIVO           : boolean;
    SLUG?           : string;
    ATUALIZA_STATUS : boolean;
};
export interface ICadEmpresaIntegracoes extends Array<ICadEmpresaIntegracao>{};

export enum ETpFiltroEmpresaIntegracao {
    Nome     = 'NOME',
    Tipo     = 'TIPO'
};

export const schemaCadEmpresaIntegracao = Yup.object().shape({
    NOME
});

export const schemaAltEmpresaIntegracao = Yup.object().shape({
    NOME
});
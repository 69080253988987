import { useContext, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import { CtxParams } from "../../Contexts/ctxParams";
import FormRodape from "../../Components/formularios/FormRodape";
import useCadPagtoQrLista from "../../Hooks/cadPagtoQr/lista";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const CadPagtoQrLista = () => {
    const { voltar } = useParams();
    const navigate = useNavigate();
    const PagtoQrLista = useCadPagtoQrLista();
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Pagamentos QR Code");
    });

    return (
        <>
            <ModalConfirmacao 
                titulo="Confirmação de Exclusão"
                show={PagtoQrLista.ShowModal} 
                onHide={PagtoQrLista.setShowModal}
                variant={"danger"}
                onSubmitHandler={() => {
                    PagtoQrLista.Excluir(PagtoQrLista.Selecionado);
                    PagtoQrLista.setShowModal(false);
                }}
            >
                Tem certeza que deseja excluir o QR Code {PagtoQrLista.Selecionado.NOME}?
            </ModalConfirmacao>

            <Container className="verticalCenter">                
                {PagtoQrLista.Grid}

                <FormRodape style={(typeof voltar === "undefined") && (UserParams.ENTREGADOR) ? {display: 'none'} : {}}>
                    <Button style={typeof voltar === "undefined" ? {display: 'none'} : {}} variant="outline-dark" size="lg" onClick={() => typeof voltar !== "undefined" ? navigate(-1) : null}><FaArrowLeft /> Retornar</Button>
                    <Button style={UserParams.ENTREGADOR ? {display: 'none'} : {}} variant="success" size="lg" onClick={PagtoQrLista.Cadastrar}><TiPlus /> Cadastrar</Button>
                </FormRodape>
            </Container>
        </>
    );
};

export default CadPagtoQrLista;
import { Formik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import FormRodape from "../../../Components/formularios/FormRodape";
import { useOutletContext } from "react-router-dom";
import { IUseEmpresaParametro } from "../../../Hooks/Empresa/EmpresaParametro";
import { IEmpresaParametro } from "../../../Models/EmpresaParametro";

const EmpresaParametroCadastro = () => {
    const EmpresaParametro = useOutletContext<IUseEmpresaParametro>();
    // const [confirmado, setConfirmado] = useState<boolean>(false);

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                onSubmit={(dados : IEmpresaParametro) => {
                    EmpresaParametro.Salvar(dados);
                }}
                initialValues={{
                    CD_PARAMETRO        : EmpresaParametro.Item.CD_PARAMETRO,
                    CD_EMPRESA          : EmpresaParametro.Item.CD_EMPRESA,
                    ATIVO               : EmpresaParametro.Item.ATIVO,
                    VALOR               : EmpresaParametro.Item.VALOR,
                    PARAMETRO_NOME      : EmpresaParametro.Item.PARAMETRO_NOME,
                    PARAMETRO_DESCRICAO : EmpresaParametro.Item.PARAMETRO_DESCRICAO,
                    DEFAULT_ATIVO       : EmpresaParametro.Item.DEFAULT_ATIVO,
                    DEFAULT_VALOR       : EmpresaParametro.Item.DEFAULT_VALOR
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => EmpresaParametro.Abrir({CD_PARAMETRO: 0})}>       
                        <Row>
                            <Col lg={3}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Código:</Form.Label>
                                    <Form.Control 
                                        name={'CD_PARAMETRO'}
                                        type={'text'}
                                        value={values.CD_PARAMETRO ?? ""}
                                        onChange={handleChange}
                                        disabled
                                        size="lg"
                                    />
                                </Form.Group>  
                            </Col>

                            <Col lg={9}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Parâmetro:</Form.Label>
                                    <Form.Control 
                                        name={'PARAMETRO_NOME'}
                                        type={'text'}
                                        value={values.PARAMETRO_NOME ?? ""}
                                        onChange={handleChange}
                                        disabled
                                        size="lg"
                                    />
                                </Form.Group>  
                            </Col>
                        </Row>

                        <Form.Group className="mt-3">
                            <Form.Label>Valor:</Form.Label>
                            <Form.Control 
                                name={'VALOR'}
                                type={'text'}
                                value={values.VALOR ?? ""}
                                onChange={handleChange}
                                placeholder="Valor do parâmetro..." 
                                size="lg"
                            />
                        </Form.Group> 

                        <Form.Group className="mt-3">
                            <Form.Label>Situação:</Form.Label>
                            <Form.Check
                                style={{zoom: 1.5}}
                                id="ATIVO"
                                type="switch"
                                checked={values.ATIVO}
                                label={values.ATIVO ? 'Ativado ✅' : 'Desativado 👎'}
                                onChange={handleChange}
                            />
                        </Form.Group> 

                        <Form.Group className="mt-3">
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control 
                                name={'PARAMETRO_DESCRICAO'}
                                as="textarea"
                                value={values.PARAMETRO_DESCRICAO ?? ""}
                                onChange={handleChange}
                                disabled
                                size="lg"
                            />
                        </Form.Group> 

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" type="submit">Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default EmpresaParametroCadastro;
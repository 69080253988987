import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../../utils/useAPI";
import { DeepPartial } from "../../../Models/genericInterfaces";
import useControllers from "../../utils/useControllers";
import { useContext } from "react";
import { CtxParams } from "../../../Contexts/ctxParams";
import { IEmpresaParametro, IEmpresaParametros } from "../../../Models/EmpresaParametro";

const useEmpresaParametroController = (updateQueryKey?: string, isArray: boolean = true) : IUseEmpresaParametroController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const UserParams = useContext(CtxParams);
    const { Update } = useControllers();

    const primaryKey = 'CD_PARAMETRO';
    const endpoint = 'empresaParametro';

    const mutAlterar = useMutation(
        (data : DeepPartial<IEmpresaParametro>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IEmpresaParametro>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IEmpresaParametros>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IEmpresaParametro>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IEmpresaParametro) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IEmpresaParametros>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !UserParams.ENTREGADOR,
        }
    );    

    return {
        Alterar: mutAlterar,
        Buscar: mutBuscar,
        Listar: qryListar,
    }
}

interface IUseEmpresaParametroController {
    Alterar     : UseMutationResult<any, any, DeepPartial<IEmpresaParametro>, unknown>;
    Buscar      : UseMutationResult<IEmpresaParametro, any, DeepPartial<IEmpresaParametro>, unknown>
    Listar      : UseQueryResult<IEmpresaParametros, unknown>;
}

export default useEmpresaParametroController;
import { useFormikContext } from "formik";
import { IUseProduto } from "../../../Hooks/Produto";
import { EProdutoTipo, IProduto } from "../../../Models/cardapio";
import { Accordion, Col, Form, Row, Spinner } from "react-bootstrap";
import Tabela from "../../../Components/tabela";
import { useState } from "react";
import { IUseCardapio } from "../../../Hooks/Cardapio";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    Produto: IUseProduto;
    Categoria: IUseCardapio;
    Confirmado: boolean;
};

const CardapioProdutoEstoque = (props: IProvider) => {
    const { Produto } = props;
    const { values, setFieldValue } = useFormikContext<IProduto>();
    const [carregouEstoque, setCarregouEstoque] = useState<boolean>(false);
    return (
        values.TIPO === EProdutoTipo.Produto ?
            <Accordion className="mt-3">
                <Accordion.Item eventKey="1" onClick={() => {
                    if ((Produto.EstoqueMovimento[0].CD_PRODUTO !== Produto.Item.CD_PRODUTO) && values.CD_PRODUTO) {
                        Produto.ControllerEstoque.BuscarProduto.mutateAsync({CD_PRODUTO: Number(Produto.Item.CD_PRODUTO)})
                        .then(() => setCarregouEstoque(true));
                    } else if (Produto.ControllerEstoque.BuscarProduto.isSuccess && values.CD_PRODUTO) {
                        setCarregouEstoque(true)
                    }

                    setTimeout(() => {
                        document.getElementById('fldQtdEstoque')?.scrollIntoView()
                    }, 500);
                }}>
                    <Accordion.Header>{
                        values.TIPO === EProdutoTipo.Produto 
                        ? `Estoque` 
                        : ``
                    }</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            
                        </Row>

                        <Row className="mb-3">
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Movimenta Estoque:</Form.Label>
                                    {/* <BadgeValidacao validacao={errors.DISPONIVEL} confirmado={confirmado} /> */}
                                    <Form.Check
                                        style={{zoom: 1.4}}
                                        name="MOVIMENTA_ESTOQUE"
                                        id="MOVIMENTA_ESTOQUE"
                                        type="switch"
                                        checked={values.MOVIMENTA_ESTOQUE}
                                        label={values.MOVIMENTA_ESTOQUE ? 'Sim' : 'Não'}
                                        onChange={() => setFieldValue("MOVIMENTA_ESTOQUE", !values.MOVIMENTA_ESTOQUE)}
                                    />
                                </Form.Group>  
                            </Col>

                            <Col xs={8}>
                                <Form.Group>
                                    <Form.Label>Quantidade Atual:</Form.Label>
                                    {/* <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} /> */}
                                    <Form.Control 
                                        id={'fldQtdEstoque'}
                                        name={'ESTOQUE'}
                                        type={'number'}
                                        value={values.ESTOQUE ?? ""}
                                        disabled
                                        size="lg"
                                    />
                                </Form.Group> 
                            </Col>
                        </Row>
                        {
                            carregouEstoque && values.CD_PRODUTO
                                ? <Tabela colunas={Produto.gridEstoqueMovimento} dados={Produto.EstoqueMovimento} pagination={true} />   
                                : values.CD_PRODUTO  
                                    ? <Spinner variant="primary" />                                                            
                                    : null
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        : <div className="mb-5"></div>
    )
}

export default CardapioProdutoEstoque;
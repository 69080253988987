import useAPI from "../utils/useAPI";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router";
import { useEffect, useMemo } from "react";
import { ICadPagtoQr } from "../../Models/cadPagtoQr"

const useCadPagtoQr = (CdPagtoQr: string) : IUseCadPagtoQr => {
    const API = useAPI();
    const navigate = useNavigate();

    const qryPagtoQr = useMutation((xCdPagtoQr: string) => API.get('qrcode/buscar/', xCdPagtoQr, false));

    useEffect(() => {
        if (qryPagtoQr.isIdle) {
            if (CdPagtoQr !== '-1') {qryPagtoQr.mutate(CdPagtoQr)};
        };
    }, [qryPagtoQr, CdPagtoQr]);

    const dsPagtoQr = useMemo<ICadPagtoQr>(() => 
        qryPagtoQr.isSuccess && CdPagtoQr !== '' ? qryPagtoQr.data : {
            CD_IMAGEM: null,
            NOME: "",
            CAMINHO_IMAGEM: null
        }
    , [qryPagtoQr, CdPagtoQr]);

    const mutCadPagtoQr = useMutation((data : FormData) => API.post(`qrcode/cadastrar/`, data, false, false), {
        onSuccess: (ret: any) => {navigate('/listaPagtoQr')},
        onError: (error: any) => {alert(error)}
    });

    const confirmar = (data: ICadPagtoQr) => {
        let formData = new FormData();
        formData.append('CAMINHO_IMAGEM', data.IMAGEM as Blob, data.NOME);
        
        if (CdPagtoQr === "-1") {
            mutCadPagtoQr.mutate(formData);
        };
    };

    const cancelar = () => {
        navigate(-1);
    };

    return {
        dsPagtoQr,
        Confirmar: confirmar,
        Cancelar: cancelar,
        CdPagtoQr
    }
}

export interface IUseCadPagtoQr {
    dsPagtoQr   : ICadPagtoQr;
    Confirmar   : (data: ICadPagtoQr) => void;
    Cancelar    : () => void;
    CdPagtoQr   : string;
}

export default useCadPagtoQr;
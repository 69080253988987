import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import useControllers from "../utils/useControllers";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IFormaPagamentoIntegracao, IFormaPagamentoIntegracaos } from "../../Models/FormaPagamento";

const useFormaPagamentoIntegracaoController = (updateQueryKey?: string, isArray: boolean = true, enableQuery: boolean = false) : IUseFormaPagamentoIntegracaoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Delete, Create } = useControllers();

    const primaryKey = 'CD_PAGTO_INTEGRACAO';
    const endpoint = 'pagtoIntegracao';

    const mutDeletar = useMutation(
        (data : DeepPartial<IFormaPagamentoIntegracao>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
        }, {
            onSuccess: (ret: any, data: DeepPartial<IFormaPagamentoIntegracao>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IFormaPagamentoIntegracaos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutAlterar = useMutation(
        (data : DeepPartial<IFormaPagamentoIntegracao>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IFormaPagamentoIntegracao>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IFormaPagamentoIntegracaos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IFormaPagamentoIntegracao>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IFormaPagamentoIntegracao>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IFormaPagamentoIntegracao>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['Criado']);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IFormaPagamentoIntegracao>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IFormaPagamentoIntegracao) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IFormaPagamentoIntegracaos>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
        }
    );    

    return {
        Deletar: mutDeletar,
        Alterar: mutAlterar,
        Cadastrar: mutCadastrar,
        Buscar: mutBuscar,
        Listar: qryListar,
    }
}

export interface IUseFormaPagamentoIntegracaoController {
    Deletar     : UseMutationResult<any, any, DeepPartial<IFormaPagamentoIntegracao>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<IFormaPagamentoIntegracao>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IFormaPagamentoIntegracao>, unknown>;
    Buscar      : UseMutationResult<IFormaPagamentoIntegracao, any, DeepPartial<IFormaPagamentoIntegracao>, unknown>
    Listar      : UseQueryResult<IFormaPagamentoIntegracaos, unknown>;
}

export default useFormaPagamentoIntegracaoController;
import { useContext, useEffect, useState } from "react";
import { Button, Container, InputGroup, Form, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import { CtxParams } from "../../Contexts/ctxParams";
import FormRodape from "../../Components/formularios/FormRodape";
// import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useApp from "../../Hooks/utils/useApp";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IUseCardapio } from "../../Hooks/Cardapio";
import CardapioList from "../../Components/cardapio";
import { ECategoriaTipo, EProdutoTipo, IProduto } from "../../Models/cardapio";
import { FaCog, FaShoppingCart } from "react-icons/fa";
import useMask from "../../Hooks/utils/useMask";
import CardapioExibeItem from "./exibeItem";
import CardapioCarrinho from "./carrinho";
import usePedidoOffline from "../../Hooks/offline/PedidoOffline";
import { IEmpresaStatus } from "../../Models/cadEmpresa";

const CardapioLista = () => {
    const Cardapio = useOutletContext<IUseCardapio>();
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();
    const [aberto, setAberto] = useState<boolean>(false);
    const [modalContexto, setModalContexto] = useState<IProduto>();
    const [exibeCarrinho, setExibeCarrinho] = useState<boolean>(false);
    const formatter = useMask();
    const PedidoOffline = usePedidoOffline({
        isGarcom: (Cardapio.isGarcom ?? false),
        isSalvar: Cardapio.SalvaDados
    });
    const navigate = useNavigate();
    const [modoGestor, setModoGestor] = useState<boolean>(true);

    useEffect(() => {Cardapio.Controller.EmpresaStatus.remove()}, []) //eslint-disable-line

    useEffect(() => {
        setModoGestor(Cardapio.isGarcom ? false : AppParams.Logado);
    }, [Cardapio.isGarcom, AppParams.Logado]);

    useEffect(() => {
        if (Cardapio.Controller.EmpresaStatus.isFetched && Cardapio.Controller.EmpresaStatus.isSuccess) {
            AppParams.setPagina(`Cardápio: ${Cardapio.Controller.EmpresaStatus.data?.NOME}`);

            if ((Cardapio.Controller.EmpresaStatus.data as IEmpresaStatus).ABERTO === false && !Cardapio.isGarcom && !modoGestor) {
                alert('O Estabelecimento se encontra fechado no momento, não será possível fazer o pedido!')
                setAberto(false);
            } else {
                setAberto(true) 
            }
        }
    }, [modoGestor, Cardapio.Controller.EmpresaStatus.data]) //eslint-disable-line

    useEffect(() => {
        function disableScroll(event : any) {
            event.preventDefault();
        };

        document.body.classList.add('stop-scrolling');
        document.body.addEventListener('scroll', disableScroll, { passive: false });


        return () => {
            document.body.classList.remove('stop-scrolling');
            document.body.removeEventListener('scroll', disableScroll);
        }
    });

    return (
        <>
            <CardapioExibeItem
                modalContexto={modalContexto}
                setModalContexto={setModalContexto}
                pedidoItems={PedidoOffline.pedidoItems}
                setPedidoItems={PedidoOffline.setPedidoItems}
                Cardapio={Cardapio}
            />

            <CardapioCarrinho 
                exibeCarrinho={exibeCarrinho}
                setExibeCarrinho={setExibeCarrinho}
                pedido={PedidoOffline}
                cardapio={Cardapio}
            />

            <Container className="verticalCenter">
                {
                    (Cardapio.Controller.Listar.data && Cardapio.Controller.Listar.data.length >= 1)
                    ? <>
                        <div className="d-flex w-100 m-3 justify-content-around">
                            <InputGroup>
                                <DropdownButton
                                    variant="outline-secondary"
                                    title={
                                        (() => {
                                            switch (AppParams.Filtros.Cardapio.tipoPesquisa) {
                                            case 'PRODUTOS.NOME':
                                                return 'Nome'
                                            default:
                                                return 'erro'
                                            }
                                        })()
                                    }
                                    id="TPFiltro"
                                >
                                    <Dropdown.Item key={'1'} onClick={() => Filtrar('Cardapio', {tipoPesquisa: 'PRODUTOS.NOME'})}>Nome</Dropdown.Item>
                                </DropdownButton>
                                <Form.Control
                                    placeholder="Pesquisar pelo nome do produto..."
                                    aria-label="Filtro"
                                    size="lg"
                                    value={AppParams.Filtros.Cardapio.pesquisa}
                                    onChange={(e : React.ChangeEvent<HTMLInputElement>) => 
                                        Filtrar('Cardapio', {pesquisa: e.target.value})
                                    }
                                />
                            </InputGroup>
                        </div>

                        {
                            Cardapio.Lista[0] !== undefined && Cardapio.Lista[0].CD_CATEGORIA && Cardapio.Lista.length >= 1
                            ?
                                <CardapioList 
                                    Dados={modoGestor ? Cardapio.Lista : Cardapio.Lista.filter((cat) => cat.ATIVO === true)}
                                    logado={modoGestor}
                                    onClickItem={modoGestor ? Cardapio.AbrirProduto : (Produto) => setModalContexto(Produto as IProduto)} 
                                    onClickCategoria={modoGestor ? Cardapio.Abrir : () => {}}
                                    onUpdateItem={Cardapio.Controller.AlterarProduto}
                                    onDeleteItem={Cardapio.Controller.DeletarProduto}
                                    onAddPedidoItem={() => PedidoOffline.setPedido}
                                />
                            :
                                <div>Nenhum produto encontrado</div>
                        }
                        
                    </>
                    : <div>Ainda não há produtos cadastrados</div>
                }
                
                <FormRodape>
                    {
                        UserParams.ENTREGADOR || modoGestor === false ?
                            <>
                                {
                                    (!aberto)
                                    ? 'Estabelecimento está fechado, não é possível fazer pedidos.'
                                    : <div className="container px-3 pt-2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'auto', gap: '0.1rem' }}>
                                        <div className="total" style={{ gridRow: '1', gridColumn: '1/2' }}>
                                            <p style={{ margin: "0", textAlign: 'left', fontSize: '1.1rem', fontWeight: 'bold' }}>
                                                {`${formatter.setMaskMoney(PedidoOffline.pedido?.OFF_TOTAL ?? 0, "R$")}`}
                                            </p>
                                            <p style={{ margin: "0", textAlign: 'left', fontSize: '1.1rem', fontWeight: 'normal' }}>
                                                {`${PedidoOffline.pedidoItems ? PedidoOffline.pedidoItems.length : 0} Itens`}
                                            </p>
                                        </div>
                                        <div className="totalvalue" style={{ gridRow: '1', gridColumn: '3/3' }}>
                                            <Button disabled={!(PedidoOffline.pedidoItems) || !(PedidoOffline.pedidoItems.length > 0)} size="lg" variant="success" onClick={() => setExibeCarrinho(true)}>
                                                <FaShoppingCart/> Ver Carrinho
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </>
                            
                        :
                            <>
                                <Dropdown as={ButtonGroup}>
                                    <Button variant="success" size="lg" onClick={() => Cardapio.AbrirProduto({CD_PRODUTO: -1})}><TiPlus /> Cadastrar</Button>
                                    <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => Cardapio.AbrirProduto({CD_PRODUTO: -1})}>Novo Produto</Dropdown.Item>
                                        <Dropdown.Item onClick={() => Cardapio.AbrirProduto({CD_PRODUTO: -1, TIPO: EProdutoTipo.Ingrediente})}>Novo Ingrediente</Dropdown.Item>
                                        <Dropdown.Item></Dropdown.Item>
                                        <Dropdown.Item onClick={() => Cardapio.Abrir({CD_CATEGORIA: -1})}>Nova Categoria</Dropdown.Item>
                                        <Dropdown.Item onClick={() => Cardapio.Abrir({CD_CATEGORIA: -1, TIPO: ECategoriaTipo.Composicao})}>Nova Composição</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                
                                <Button variant="dark" size="lg" onClick={() => navigate(`/cardapio/${UserParams.CD_EMPRESA}/configuracao`)}><FaCog /> Configurações</Button>
                            </>
                    }
                </FormRodape>
            </Container>
        </>
    );
};

export default CardapioLista;
import { Outlet, useParams } from "react-router-dom"
import useMesa from "../../Hooks/Mesa";

const Mesa = () => {
    const { dtCompetencia, cdMesa } = useParams();
    const Mesa = useMesa(dtCompetencia, cdMesa);

    return <Outlet context={Mesa} />
}

export default Mesa;
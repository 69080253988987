import { IEntrega } from "../../Models/cadEntrega";

const useEntregaUtils = (dados: IEntrega) : IUseEntregaUtils => {
    const Mapa = () => {
        if( (navigator.platform.indexOf("iPhone") != -1) //eslint-disable-line
            || (navigator.platform.indexOf("iPod") != -1) //eslint-disable-line
            || (navigator.platform.indexOf("iPad") != -1)) //eslint-disable-line
            window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${dados.ENDERECO.LATITUDE},${dados.ENDERECO.LONGITUDE}`);
        else
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${dados.ENDERECO.LATITUDE},${dados.ENDERECO.LONGITUDE}`);
    };

    const Ligacao = () => {
        window.open("tel:+55"+dados.CLIENTE.CELULAR);
    };

    const Whatsapp = () => {
        window.open(`https://api.whatsapp.com/send?phone=${dados.CLIENTE.CELULAR}`);
    };

    return {
        Mapa    : Mapa,
        Ligar   : Ligacao,
        Whatsapp: Whatsapp
    }
}

export interface IUseEntregaUtils {
    Ligar               : () => void;
    Whatsapp            : () => void;
    Mapa                : () => void;
}

export default useEntregaUtils;
import * as Yup from 'yup';
import { TAXA, BAIRRO } from './defaultModels';

export interface ICadTaxa {
    CD_TAXA?: number | null;
    CEP: string;
    TAXA: number;
    VALOR?: number;
    BAIRRO: string;
};
export interface ICadTaxas extends Array<ICadTaxa>{};

export enum ETpFiltroTaxa {
    CEP     = 'CEP',
    Bairro  = 'BAIRRO'
}

export const schemaCadTaxa = Yup.object().shape({
    TAXA,
    BAIRRO
});
import ModalConfirmacao from "../../../Components/formularios/ModalConfirmacao";
import { IUsePedidoOffline } from "../../../Hooks/offline/PedidoOffline";
import { useEffect, useState } from "react";
import { IUseCardapio } from "../../../Hooks/Cardapio";
import { Col } from "react-bootstrap";
import StepsIndicator from "../../../Components/formularios/StepsIndicator";
import useMask from "../../../Hooks/utils/useMask";
import * as yup from 'yup';
import { BAIRRO, CELULAR, LOGRADOURO, NOME, NUMERO } from "../../../Models/defaultModels";
import { IPedido } from "../../../Models/pedido";
import CarrinhoPagamento from "./pagamento";
import CarrinhoCliente from "./cliente";
import CarrinhoMesa from "./mesa";
import CarrinhoEntrega from "./entrega";
import CarrinhoItens from "./itens";
import { useOutletContext } from "react-router-dom";

interface Props {
    exibeCarrinho: boolean;
    setExibeCarrinho: React.Dispatch<React.SetStateAction<boolean>>;
    pedido: IUsePedidoOffline;
    cardapio: IUseCardapio;
}

const CardapioCarrinho = ({exibeCarrinho, setExibeCarrinho, pedido, cardapio}: Props) => {
    const formatter = useMask();
    const Cardapio = useOutletContext<IUseCardapio>();
    const isMesa : boolean = cardapio.NrMesa !== undefined;
    const [isEntrega, setIsEntrega] = useState<boolean>(!isMesa);
    const [pagina, setPagina] = useState<number>(1);

    const [validacaoErros, setValidacaoErros] = useState<any>({NOME: null, CELULAR: null, VLR_TROCO: null, FORMA_PAGTO: null});
    const [validacaoErrosEntrega, setValidacaoErrosEntrega] = useState<any>({LOGRADOURO: null, BAIRRO: null, NUMERO: null});

    const schemaMesa = yup.object().shape({
        CELULAR: CELULAR,
        NOME: NOME,
    });      

    const schemaEntrega = yup.object().shape({
        LOGRADOURO: LOGRADOURO,
        BAIRRO: BAIRRO,
        NUMERO: NUMERO
    });

    useEffect(() => {
        if (pedido.pedidoItems.length === 0) {
            setExibeCarrinho(false);
        }
    }, [pedido.pedidoItems, setExibeCarrinho]);

    useEffect(() => {
        if (Cardapio.isGarcom && isMesa && pedido.pedidoCliente.NOME === '' && pedido.pedidoCliente.CELULAR === '') {
            pedido.setPedidoCliente({
                NOME: 'Consumidor',
                CELULAR: '(99) 99999-9999'
            })
        }
    }, [Cardapio.isGarcom, isMesa]); //eslint-disable-line

    const enviar = () => {
        pedido.EnviaPedido();
        setExibeCarrinho(false);
        setPagina(1);
    }

    const erro = (validacao: 'cliente' | 'entrega', e: yup.ValidationError) => {
        const errors: Record<string, string> = {}

        if (e.inner) {
            e.inner.forEach(err => {
                if (err.path) {
                    errors[err.path] = err.message
                }
            })
        }
        
        switch (validacao) {
            case "cliente": 
                setValidacaoErros(errors);
                break;
            default : setValidacaoErrosEntrega(errors);
        };
    }

    return (
        <ModalConfirmacao
            titulo={'Itens no Carrinho'}
            cancelText={pagina === 1 ? 'Fechar' : 'Voltar'}
            confirmText={(isEntrega && pagina === 3) || (!isEntrega && pagina === 2) ? 'Confirmar Pedido' : 'Continuar' }
            disabledConfirm={(isMesa && pagina === 2 && (pedido.pedidoCliente.NOME === '' || pedido.pedidoCliente.CELULAR === ''))}
            show={exibeCarrinho} 
            onHide={() => {
                if (pagina === 1) {
                    setExibeCarrinho(false)                    
                } else {
                    setPagina(formatter.getMinMax(1, isEntrega ? 3 : 2, pagina - 1))
                }
            }}
            variant={"success"}
            onSubmitHandler={() => {
                if (pagina === 1) {
                    if (cardapio.NrMesa !== undefined) {
                        pedido.setPedido((prev) => ({...prev as IPedido, NR_MESA: Number(cardapio.NrMesa)}))
                    }
                    
                    setPagina(2)
                } else if ((pagina === 2)) {
                    schemaMesa.validate(pedido.pedidoCliente, {abortEarly: false})
                    .then(() =>{
                        setValidacaoErros(({NOME: null, CELULAR: null, VLR_TROCO: null, FORMA_PAGTO: null}));

                        if (isEntrega) {
                            schemaEntrega.validate(pedido.pedidoEntrega.ENDERECO, {abortEarly: false})
                            .then(() => {
                                setValidacaoErrosEntrega({LOGRADOURO: null, BAIRRO: null, NUMERO: null});
                                const bairroSelecionado = pedido.pedidoEntrega.ENDERECO.BAIRRO;
                                const taxaSelecionada = cardapio.Controller.EmpresaStatus.data?.TAXAS.find(taxa => taxa.BAIRRO === bairroSelecionado);
                                
                                if (taxaSelecionada && pedido.pedidoEntrega.TAXA !== taxaSelecionada.TAXA) {
                                    pedido.setPedidoEntrega((prev => ({
                                        ...prev, 
                                        ENDERECO: {
                                            ...prev.ENDERECO,
                                            CIDADE: Cardapio.Controller.EmpresaStatus.data?.ENDERECO_CIDADE ?? '',
                                            UF: Cardapio.Controller.EmpresaStatus.data?.ENDERECO_UF ?? '',
                                        },
                                        VLR_PEDIDO: Number(taxaSelecionada.TAXA ?? 0) + Number(pedido.pedido?.OFF_TOTAL ?? 0),
                                        TAXA: taxaSelecionada.TAXA ?? 0
                                    })));

                                    pedido.setPedido((prev => ({
                                        ...prev as IPedido, 
                                        VLR_TOTAL: Number(taxaSelecionada.TAXA ?? 0) + Number(prev!.OFF_TOTAL ?? 0)
                                    })));
                                };

                                setPagina(3);
                            })
                            .catch((e) => erro("entrega", e))
                        } else {
                            enviar();
                        }
                        
                    })
                    .catch((e: yup.ValidationError) => {
                        erro("cliente", e)
                    })                    
                } else {
                    if ((pedido.pedido?.FORMA_PAGTO ?? '') === '') {
                        setValidacaoErros(({...validacaoErros, FORMA_PAGTO: 'Deve ser preenchido'}));
                        return
                    }

                    if (pedido.pedido?.FORMA_PAGTO === 'A Vista' && (Number(pedido.pedido!.VLR_TROCO ?? 0) < Number(pedido.pedido!.VLR_TOTAL ?? 0))) {
                        setValidacaoErros(({...validacaoErros, VLR_TROCO: 'Valor deve ser válido (maior que o total)'}));
                        return
                    }
                    
                    setValidacaoErros(({NOME: null, CELULAR: null, VLR_TROCO: null, FORMA_PAGTO: null}));
                    
                    enviar();
                }
            }}
        >
            <StepsIndicator className="d-flex w-100 align-items-center justify-content-center mb-3" currentStep={pagina} totalSteps={isEntrega ? 3 : 2} variant="lgPill" />
            
            {(() => {
                switch (pagina) {
                    case 2:
                        return <>
                            <div className="w-100">
                                <Col xs={12}>
                                    <CarrinhoCliente pedido={pedido} validacaoErros={validacaoErros} formatter={formatter} />
                                </Col>
                            </div>

                            {
                                isMesa && <CarrinhoMesa cardapio={cardapio} />
                            }

                            {
                                !isMesa && <CarrinhoEntrega 
                                    pedido={pedido}
                                    isEntrega={isEntrega}
                                    setIsEntrega={setIsEntrega}
                                    validacaoErrosEntrega={validacaoErrosEntrega}
                                    cardapio={cardapio}
                                />
                            }
                        </>
                    case 3:
                        return <CarrinhoPagamento 
                            pedido={pedido} 
                            validacaoErros={validacaoErros}
                            formatter={formatter}    
                        />
                    default:
                        return <CarrinhoItens 
                            pedido={pedido}
                        />
                }
            })()}
        </ModalConfirmacao>
    )
}

export default CardapioCarrinho;
import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap"
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useMask from "../../Hooks/utils/useMask";
import MesaArea from "../../Components/mesa";
import { useOutletContext } from "react-router-dom";
import { IUseMesa } from "../../Hooks/Mesa";
import { CtxParams } from "../../Contexts/ctxParams";

const MesaLista = () => {
    const Mesa = useOutletContext<IUseMesa>();
    const AppParams = useContext(CtxAppParams);
    const UserParams = useContext(CtxParams);
    const formatter = useMask();

    useEffect(() => {
        AppParams.setPagina(`Mesas: ${formatter.setDate(new Date(Mesa.DataCompetencia), {day: "2-digit", month: "short", year: "numeric"})}`);
    });

    return (
        <Container className="verticalCenter">                        
            <MesaArea Mesa={Mesa} UserParams={UserParams} />
        </Container>
    )
}

export default MesaLista;
import * as Yup from 'yup';
import { ICadCliente } from './cadCliente';
import { IClienteEndereco } from './cadClienteEndereco';
import { ICadUsuario } from './cadUsuario';
import { CD_CLIENTE, TAXA, CD_CLIENTE_ENDERECO, VLR_PEDIDO, VLR_TROCO, FORMA_PAGTO, STATUS, PAGO } from './defaultModels'
import { IVendaPagamentos } from './VendaPagamento';

export interface ICadEntrega {
    NOME_ENTREGA?       : string;
    ENDERECO_CLIENTE?   : string;
    NOME_USUARIO?       : string;
    CD_ENTREGA?         : number | null;
    CD_EMPRESA?         : number | null;
    CD_CLIENTE_ENDERECO?: number | null;
    CD_CLIENTE?         : number | null;
    CD_USUARIO?         : number | null;
    TAXA                : number;
    VLR_PEDIDO          : number;
    VLR_TROCO           : number;
    FORMA_PAGTO         : string;
    STATUS              : EStatus;
    PAGO                : boolean;
    DT_ENTREGA?         : Date | null | string;
    OBSERVACOES?        : string | null;
    DT_COMPETENCIA?     : string;
    DT_FINALIZACAO?     : string | null;
    VLR_TOTAL?          : number | null;
    ID_PLUS?            : string;
    TIPO_INTEGRACAO?    : string;
    NR_SENHA?           : string;
    VLR_ADICIONAL?      : number;
    RAZAO_CANCELAMENTO? : string;
    CODIGO_CANCELAMENTO?: number;
};
export interface ICadEntregas extends Array<ICadEntrega>{};

export interface IEntrega extends ICadEntrega {
    CLIENTE         : ICadCliente;
    USUARIO         : ICadUsuario;
    ENDERECO        : IClienteEndereco;
    PAGTO?          : IVendaPagamentos;
    ENTREGA_ITENS?  : IEntregaItems;
};
export interface IEntregas extends Array<IEntrega>{};

export enum EStatus {
    Pendente    = 'P',
    Andamento   = 'A',
    Finalizada  = 'F',
    Cancelada   = 'C',
    Preparacao  = 'O'
};

export const LStatus = [
    {NOME: "Pendente",      Status: EStatus.Pendente},
    {NOME: "Preparação",    Status: EStatus.Preparacao},
    {NOME: "Andamento",     Status: EStatus.Andamento},
    {NOME: "Finalizada",    Status: EStatus.Finalizada},
    {NOME: "Cancelada",     Status: EStatus.Cancelada},
]

export enum ETpFiltro {
    Cliente     = 'CLIENTE.NOME',
    Endereco    = 'ENDERECO',
    Entregador  = 'USUARIO.NOME',
    Status      = 'STATUS',
    Pagamento   = 'FORMA_PAGTO',
    Pago        = 'PAGO',
    CEP         = 'CEP',
    Pedido      = 'ID_PLUS',
    Senha       = 'NR_SENHA'
};

export interface IEnderecoEntrega {
    ENDERECO: string;
    CD_CLIENTE_ENDERECO: number | null;
    CEP: string;
};

export interface IEntregaItem {
    CD_ENTREGA?     : number;
    PRODUTO_NOME    : string;
    QTD             : number;
    UNIDADE         : string;
    VLRUN           : number;
    OBS_PRODUTO?    : string;
    ID?             : string;
    CD_ENTREGA_ITEM?: number;
}
export interface IEntregaItems extends Array<IEntregaItem>{}; 

export interface ITotal {
    TxDiaria      : number;
    TxPotencial   : number;
    TxReal        : number;
    Total         : number;
};
export interface ITotais extends Array<ITotal>{};

export const schemaCadEntrega = Yup.object().shape({
    CD_CLIENTE_ENDERECO,
    CD_CLIENTE,         
    CD_USUARIO: Yup.number().nullable(true),      
    TAXA,               
    VLR_PEDIDO,
    VLR_TROCO,          
    FORMA_PAGTO,        
    STATUS,             
    PAGO               
});
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { ILogin } from "../../Models/autenticacao";
import { IUseAutenticacao } from "../../Hooks/autenticacao";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    setConsultouEmpresa: React.Dispatch<React.SetStateAction<boolean>>;
    setToken: any;
    inputSenha: React.MutableRefObject<HTMLInputElement | null>;
    customHook: IUseAutenticacao;
};

const AutenticacaoProvider = (props: IProvider) => {
    const { setFieldValue } = useFormikContext<ILogin>();
    const { customHook, setConsultouEmpresa, inputSenha, setToken } = props;

    useEffect(() => {
        if (customHook.mutLogin.isSuccess) {
            setToken(customHook.mutLogin.data)
        } else if (customHook.mutLogin.isError) {
            alert(customHook.mutLogin.error);
        };
    }, [
        setToken,
        customHook.mutLogin.isSuccess,
        customHook.mutLogin.data,
        customHook.mutLogin.isError,
        customHook.mutLogin.error
    ]);

    useEffect(() => {
        if (customHook.mutConsultaEmpresa.isSuccess) {
            setConsultouEmpresa(true);
            setFieldValue("CD_EMPRESA", customHook.dsListaEmpresas[0].CD_EMPRESA);
            document.getElementById('logoLogin')?.classList.remove('logoLoginTo1');
            document.getElementById('logoLogin')?.classList.add('logoLoginTo2');
            inputSenha.current!.focus();
        } else if (customHook.mutConsultaEmpresa.isError) {
            alert(customHook.mutConsultaEmpresa.error);
            customHook.mutConsultaEmpresa.reset();
        };
    }, [
        setConsultouEmpresa,
        customHook.dsListaEmpresas,
        setFieldValue,
        inputSenha,
        customHook.mutConsultaEmpresa
    ]);

    return (
        <>{props.children}</>
    )
}

export default AutenticacaoProvider;
import { Badge, Button, Card, Nav, Spinner } from "react-bootstrap";
import { IUseFechamento } from "../../../Hooks/Fechamento/lista";
import { ICtxParams } from "../../../Models/ctxParams";
import { useState } from "react";
import useApp from "../../../Hooks/utils/useApp";
import { FaBoxes, FaCheck } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { CgCalculator } from "react-icons/cg";
import FechamentoResumoEntrega from "./entrega";
import FechamentoResumoDetalhe from "./detalhe";
import FechamentoItens from "./itens";

interface Props {
    Fechamento: IUseFechamento;
    formatter : any;
    UserParams: ICtxParams;
}

const FechamentoResumo = ({Fechamento, formatter, UserParams} : Props) => {
    const [resumoAba, setResumoAba] = useState<string>(!UserParams.ENTREGADOR ? 'resumo' : 'entregas');
    const App = useApp();

    return (
        Fechamento.Item.CD_FECHAMENTO ?
            <Card 
                key={Fechamento.Item.CD_FECHAMENTO} 
                className="text-center w-100"
            >
                <Card.Header 
                    as={"h5"}
                    className={"d-flex justify-content-evenly align-items-center"}
                >
                    {
                        formatter.setDate(
                            new Date(Fechamento.Item.DT_COMPETENCIA!),
                            { day: "2-digit", weekday: "long", month: "short", year: "numeric" }
                        )
                    }
                </Card.Header>
                <Card.Body>
                <Nav 
                    justify variant="pills" 
                    style={{width: '100%'}}
                    defaultActiveKey={resumoAba}
                    onSelect={(eventKey) => UserParams.ENTREGADOR ? Fechamento.Ver(Fechamento.Item) : setResumoAba(eventKey ?? "resumo")} 
                    fill
                >
                    {
                        !UserParams.ENTREGADOR && <Nav.Item>
                            <Nav.Link eventKey="resumo"><CgCalculator style={{fontSize: '1.2rem'}} /> Resumo</Nav.Link>
                        </Nav.Item>
                    }
                    <Nav.Item>
                        <Nav.Link eventKey="entregas"><MdDeliveryDining style={{fontSize: '1.4rem'}} /> {`${UserParams.ENTREGADOR ? 'Ver ' : ''}Entregas`}</Nav.Link>
                    </Nav.Item>
                    {
                        !UserParams.ENTREGADOR && <Nav.Item>
                            <Nav.Link eventKey="itens" onClick={() => Fechamento.Controller.ListarItens.mutate({DT_COMPETENCIA: String(Fechamento.Item.DT_COMPETENCIA ?? "")})}><FaBoxes style={{fontSize: '1.2rem'}} /> Produtos</Nav.Link>
                        </Nav.Item>
                    }

                    <div className="w-100 mt-3 d-flex justify-content-center flex-wrap">
                        {
                            resumoAba === "resumo"
                                ? <FechamentoResumoDetalhe Fechamento={Fechamento} UserParams={UserParams} App={App} formatter={formatter} />
                                : resumoAba === "itens"
                                    ? <FechamentoItens Fechamento={Fechamento} UserParams={UserParams} App={App} formatter={formatter} />
                                    : <FechamentoResumoEntrega Fechamento={Fechamento} UserParams={UserParams} App={App} formatter={formatter} />
                                
                        }                        
                    </div>
                </Nav>
                    {
                        !Fechamento.Item.FECHADO && !UserParams.ENTREGADOR
                            ? <><br /> <Button variant="warning" onClick={() => {Fechamento.Modal.Fechar(Fechamento.Item)}}><FaCheck /> Fechar</Button></>
                            : null
                    }                               
                </Card.Body>
                <Card.Footer>
                    {Fechamento.Item.FECHADO ? <Badge pill style={{fontSize: '1.1rem'}} bg="danger">Fechado</Badge> : <Badge pill style={{fontSize: '1.1rem'}} bg="light" text="success">Em Aberto</Badge>}
                </Card.Footer>
            </Card>
        : Fechamento.Controller.Buscar.isLoading 
            ? <Spinner variant="primary" />
            : null
    );
}

export default FechamentoResumo;
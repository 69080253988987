import { UseQueryResult, useQuery } from "react-query";
import useAPI from "../utils/useAPI";
import { ICondicaoPagamentos } from "../../Models/CondicaoPagamento";

const useCondicaoPagamentoController = (updateQueryKey?: string, isArray: boolean = true, enableQuery: boolean = false) : IUseCondicaoPagamentoController => {
    const API = useAPI();
    // const queryClient = useQueryClient();
    // const { Update, Delete, Create } = useControllers();

    // const primaryKey = 'CD_CONDICAO';
    const endpoint = 'pagtoCondicao';

    const qryListar = useQuery<ICondicaoPagamentos>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
        }
    );    

    return {
        Listar: qryListar,
    }
}

export interface IUseCondicaoPagamentoController {
    Listar      : UseQueryResult<ICondicaoPagamentos, unknown>;
}

export default useCondicaoPagamentoController;
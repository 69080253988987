import useCondicaoPagamentoController, { IUseCondicaoPagamentoController } from "./controller";
import useCondicaoPagamentoDatasource, { IUseCondicaoPagamentoDatasource } from "./datasource";

const useCondicaoPagamento = (CdCondicao?: string) : IUseCondicaoPagamento => {
    const Controller    = useCondicaoPagamentoController('condicaoPagamento', true, true);
    const Codigo        = CdCondicao;
    const Datasource    = useCondicaoPagamentoDatasource(Controller);

    return {
        Controller  : Controller,
        Codigo      : Codigo,
        Datasource  : Datasource,
    }
}

export interface IUseCondicaoPagamento {
    Controller  : IUseCondicaoPagamentoController;
    Codigo      : string | undefined;
    Datasource  : IUseCondicaoPagamentoDatasource;
}

export default useCondicaoPagamento;
import { useContext } from "react";
import { CtxAppParams } from "../../Contexts/ctxAppParams";

const useAPI = (url: string = process.env.REACT_APP_API!) => {
    const { setSessaoExpirada } = useContext(CtxAppParams);

    enum HttpMethod {
        Post    = "POST",
        Get     = "GET",
        Put     = "PUT",
        Delete  = "DELETE"
    };

    const generateUrlParams = (method: HttpMethod, values: any) : string => {
        if (method === HttpMethod.Get || method === HttpMethod.Delete) {
            if ((values !== '')) {
                return new URLSearchParams(values).toString();
            } else {
                return '';
            };  
        } else { return '' };
    };

    const verifySessionExpired = (returnedStr: string) : boolean => {
        if (returnedStr === 'Falha na autenticação') {
            return true;
        } else {
            return false;
        }
    };

    const post = async (endpoint: string, values: any, encodeValues: boolean = true, json: boolean = true, customHeaders?: object) => {
        return call(HttpMethod.Post, endpoint, values, encodeValues, json, customHeaders);
    };

    const get = async (endpoint: string, values: any, encodeValues: boolean = true, customHeaders?: object) => {
        return call(HttpMethod.Get, endpoint, values, encodeValues, true, customHeaders);
    };

    const put = async (endpoint: string, values: any, encodeValues: boolean = true) => {
        return call(HttpMethod.Put, endpoint, values, encodeValues);
    };

    const del = async (endpoint: string, values: any, encodeValues: boolean = true) => {
        return call(HttpMethod.Delete, endpoint, values, encodeValues);
    };

    const getEmpresa = () : string => {
        if (localStorage.getItem('params')) {
            return JSON.parse(localStorage.getItem('params')!).CD_EMPRESA
        } else if (sessionStorage.getItem('EMPRESA')) {
            return sessionStorage.getItem('EMPRESA')!
        } else {
            return ''
        }
    }

    const call = async (
        method: HttpMethod, 
        endpoint: string, 
        values: any, 
        encodeValues: boolean, 
        json: boolean = true,
        customHeaders?: object
    ) => {
        let errors = false;
        let returnValue;
        let paramUrl = encodeValues ? generateUrlParams(method, values) : values;
        const token = localStorage.getItem('token');    
        const EMPRESA = getEmpresa();

        if (EMPRESA !== '') {
            customHeaders = {
                ...customHeaders,
                "EMPRESA": EMPRESA,
            }
        }


        if (!navigator.onLine) {
            throw new Error('Você está offline, tente novamente mais tarde.');
        };

        if (method === HttpMethod.Get || method === HttpMethod.Delete) {
            return fetch(`${url}/${endpoint}${encodeValues ? '?' : ''}${paramUrl}`, {
                method: method,
                headers: {
                    'Content-Type': `application/json`,
                    'Authorization': `Bearer ${token}`,
                    ...customHeaders
                }
            })
            .then(data => {
                if(!data.ok) {
                    errors = true;
                }
                if (data.status === 204) return '';
                return data.json();
                
            })
            .then((response) => {
                if(errors) {
                    returnValue = response;
                    if (verifySessionExpired(returnValue)) {
                        setSessaoExpirada(true);
                    }
                    throw new Error(JSON.stringify(returnValue.Erro))
                }
                if (response.status === 204) return '';
                return response;
            })
            .catch((err: TypeError) => {
                console.error(err.message)
                // alert(`Erro: ${err.message}`)
                throw new Error(err.message)
            })
        } else {
            return fetch(`${url}/${endpoint}`, {
                method: method,
                headers: json ?
                    {
                        'Content-Type': `application/json`,
                        'boundary': 'MyBoundary',
                        'Authorization': `Bearer ${token}`,
                        ...customHeaders,
                    }
                :
                    {
                        'boundary': 'MyBoundary',
                        'Authorization': `Bearer ${token}`,
                        ...customHeaders,
                    }
                ,
                body: 
                    json ?
                        values === "" ?
                            null
                        :
                            JSON.stringify(values) 
                    : 
                        values
            })
            .then(data => {
                if(!data.ok) {
                    errors = true;
                }
                if (data.status === 204) return '';
                return data.json();
            })
            .then((response) => {
                if(errors) {
                    returnValue = response;
                    if (verifySessionExpired(returnValue)) {
                        setSessaoExpirada(true);
                    }
                    throw new Error(JSON.stringify(returnValue.Erro))
                }
                if (response.status === 204) return '';
                return response;
            })
            .catch((err: TypeError) => {
                console.error(err.message)
                // alert(`Erro: ${err.message}`)
                throw new Error(err.message)
            })
        };
    };
    return {
        get,
        post,
        put,
        del
    }
}
export default useAPI;
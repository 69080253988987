import { Formik } from "formik";
import { every } from "lodash";
import { useState, useContext, useEffect } from "react";
import { Container, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import FormRodape from "../../Components/formularios/FormRodape";
import useCadTaxa from "../../Hooks/cadTaxa"
import useMask from "../../Hooks/utils/useMask";
import { ICadTaxa, schemaCadTaxa } from "../../Models/cadTaxa";
import { useParams } from "react-router-dom";
import CadTaxaProvider from "./provider";
import { CtxAppParams } from "../../Contexts/ctxAppParams";

const CadTaxa = () => {
    const { cdTaxa } = useParams();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const CadastroTaxa = useCadTaxa(typeof cdTaxa === 'undefined' ? '-1' : cdTaxa?.toString());
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Cadastro de Taxa");
    });

    return (        
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaCadTaxa}
                onSubmit={(e : ICadTaxa) => {
                    const dados = Object.assign({}, e);
                    dados.CEP = formatter.getCep(dados.CEP);

                    CadastroTaxa.Confirmar(dados);
                }}
                initialValues={{
                    CEP: formatter.setMaskCep(CadastroTaxa.dsTaxa.CEP || ""),
                    TAXA: CadastroTaxa.dsTaxa.TAXA,
                    BAIRRO: CadastroTaxa.dsTaxa.BAIRRO
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => CadastroTaxa.Cancelar()}>     
                        <CadTaxaProvider customHook={CadastroTaxa}>
                            <Form.Group className="mt-3">
                                <Form.Label>Buscar pelo CEP:</Form.Label>
                                <BadgeValidacao validacao={errors.CEP} confirmado={confirmado} />
                                <Form.Control 
                                    name={'CEP'}
                                    type={'text'}
                                    value={values.CEP}
                                    onBlur={() => CadastroTaxa.BuscaCep(formatter.getCep(values.CEP)) }
                                    onChange={ e => setFieldValue("CEP", formatter.setMaskCep(e.target.value)) }
                                    isInvalid={every([errors.CEP, confirmado])}
                                    placeholder="CEP" 
                                    size="lg"
                                    maxLength={9}
                                />
                            </Form.Group>     

                            <Form.Group className="mt-3">
                                <Form.Label>Bairro:</Form.Label>
                                <BadgeValidacao validacao={errors.BAIRRO} confirmado={confirmado} />
                                <Form.Control 
                                    name={'BAIRRO'}
                                    type={'text'}
                                    value={values.BAIRRO}
                                    onChange={handleChange}
                                    isInvalid={every([errors.BAIRRO, confirmado])}
                                    placeholder="Bairro..." 
                                    size="lg"
                                />
                            </Form.Group>    

                            <Form.Group className="mt-3">
                                <Form.Label>Taxa de entrega:</Form.Label>
                                <BadgeValidacao validacao={errors.TAXA} confirmado={confirmado} />
                                <InputGroup>
                                    <InputGroup.Text>R$</InputGroup.Text>
                                    <Form.Control 
                                        name={'TAXA'}
                                        type={'number'}
                                        step="0.01"
                                        value={values.TAXA}
                                        onChange={handleChange}
                                        onBlur={() => setFieldValue("TAXA", formatter.setMaskMoney(values.TAXA ?? 0, "", "."))}
                                        onWheel={(e: any) => e.target.blur()}
                                        isInvalid={every([errors.TAXA, confirmado])}
                                        size="lg"
                                    />
                                </InputGroup>
                            </Form.Group>
                        </CadTaxaProvider>

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
export default CadTaxa;
import { Formik } from "formik";
import { every } from "lodash";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import FormRodape from "../../../Components/formularios/FormRodape";
import { IUseEmpresaIntegracao } from "../../../Hooks/Empresa/EmpresaIntegracao";
import { useOutletContext } from "react-router-dom";
import { EMode } from "../../../Models/genericInterfaces";
import { ICadEmpresaIntegracao, schemaAltEmpresaIntegracao, schemaCadEmpresaIntegracao } from "../../../Models/cadEmpresaIntegracao";
import useMask from "../../../Hooks/utils/useMask";

const CadEmpresaIntegracao = () => {
    const EmpresaIntegracao = useOutletContext<IUseEmpresaIntegracao>();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const formatter = useMask();

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={EmpresaIntegracao.Modo === EMode.Include ? schemaCadEmpresaIntegracao : schemaAltEmpresaIntegracao}
                onSubmit={(dados : ICadEmpresaIntegracao) => {
                    EmpresaIntegracao.Salvar(dados);
                }}
                initialValues={{
                    CD_INTEGRACAO: EmpresaIntegracao.Item.CD_INTEGRACAO ?? undefined,
                    ATIVO       : EmpresaIntegracao.Item.ATIVO          ?? true,
                    ENDPOINT    : EmpresaIntegracao.Item.ENDPOINT       || '',
                    NOME        : EmpresaIntegracao.Item.NOME           || '',
                    TIPO        : EmpresaIntegracao.Item.TIPO           || 'P',
                    USUARIO     : EmpresaIntegracao.Item.USUARIO        || '',
                    SENHA       : EmpresaIntegracao.Item.SENHA          || '',
                    ID          : EmpresaIntegracao.Item.ID             || '',
                    TOKEN       : EmpresaIntegracao.Item.TOKEN          || '',
                    ULT_CONSULTA: EmpresaIntegracao.Item.ULT_CONSULTA   || '',
                    SLUG        : EmpresaIntegracao.Item.SLUG           || '',
                    ATUALIZA_STATUS: EmpresaIntegracao.Item.ATUALIZA_STATUS || false,
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => EmpresaIntegracao.Abrir({CD_INTEGRACAO: 0})}>       
                        <Form.Group className="mt-3">
                            <Form.Label>Slug:</Form.Label>
                            <BadgeValidacao validacao={errors.SLUG} confirmado={confirmado} />
                            <Form.Control 
                                name={'SLUG'}
                                type={'text'}
                                value={values.SLUG ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.SLUG, confirmado])}
                                placeholder="Apelido identificador da integração..." 
                                size="lg"
                            />
                        </Form.Group>  

                        <Form.Group className="mt-3">
                            <Form.Label>Nome Integração:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Select 
                                name={'NOME'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                placeholder="" 
                                isInvalid={every([errors.NOME, confirmado])}
                                size="lg"
                            > 
                                <option key={''}                value={''}> </option>
                                <option key={'PLUSDELIVERY'}    value={'PLUSDELIVERY'}>PLUSDELIVERY</option>
                                <option key={'MENUZAP'}         value={'MENUZAP'}>MENUZAP</option>
                                <option key={'VISION'}          value={'VISION'}>VISION</option>
                                <option key={'IFOOD'}           value={'IFOOD'}>IFOOD</option>
                                <option key={'VULPE'}           value={'VULPE'}>VULPE</option>
                            </Form.Select>
                        </Form.Group>  

                        <Form.Group className="mt-3">
                            <Form.Label>Tipo:</Form.Label>
                            <BadgeValidacao validacao={errors.TIPO} confirmado={confirmado} />
                            <Form.Select 
                                name={'TIPO'}
                                value={values.TIPO ?? ""}
                                onChange={handleChange}
                                placeholder="" 
                                isInvalid={every([errors.TIPO, confirmado])}
                                size="lg"
                            > 
                                <option key={'P'} value={'P'}>Proprietário</option>
                                <option key={'T'} value={'T'}>Terceirizado</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Usuario:</Form.Label>
                            <BadgeValidacao validacao={errors.USUARIO} confirmado={confirmado} />
                            <Form.Control 
                                name={'USUARIO'}
                                type={'text'}
                                value={values.USUARIO ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.USUARIO, confirmado])}
                                placeholder="Login do usuário..." 
                                size="lg"
                            />
                        </Form.Group>  

                        <Form.Group className="mt-3">
                            <Form.Label>Senha:</Form.Label>
                            <BadgeValidacao validacao={errors.SENHA} confirmado={confirmado} />
                            <Form.Control 
                                name={'SENHA'}
                                type={'text'}
                                value={values.SENHA ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.SENHA, confirmado])}
                                placeholder="Senha do usuário..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>ID:</Form.Label>
                            <BadgeValidacao validacao={errors.ID} confirmado={confirmado} />
                            <Form.Control 
                                name={'ID'}
                                type={'text'}
                                value={values.ID ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.ID, confirmado])}
                                placeholder="ID do usuário..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Token:</Form.Label>
                            <BadgeValidacao validacao={errors.TOKEN} confirmado={confirmado} />
                            <Form.Control 
                                name={'TOKEN'}
                                type={'text'}
                                value={values.TOKEN ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.TOKEN, confirmado])}
                                placeholder="Token do estabelecimento..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Row>
                            <Col md={5}>
                                <Form.Group className="mt-3 mb-5">
                                    <Form.Label>Situação:</Form.Label>
                                    <BadgeValidacao validacao={errors.ATIVO} confirmado={confirmado} />
                                    <Form.Check
                                        style={{zoom: 1.4}}
                                        name="ATIVO"
                                        id="ATIVO"
                                        type="switch"
                                        checked={values.ATIVO}
                                        isInvalid={every([errors.ATIVO, confirmado])}
                                        label={values.ATIVO ? 'Ativada' : 'Desativada'}
                                        onChange={() => setFieldValue("ATIVO", !values.ATIVO)}
                                    />
                                </Form.Group>  
                            </Col>
                            <Col md={7}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Ultima Consulta:</Form.Label>
                                    <BadgeValidacao validacao={errors.ULT_CONSULTA} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'ULT_CONSULTA'}
                                        type={'text'}
                                        disabled
                                        value={
                                            formatter.setDate(new Date(values.ULT_CONSULTA!), {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit"
                                            }, false) || ""
                                        }
                                        onChange={handleChange}
                                        isInvalid={every([errors.ULT_CONSULTA, confirmado])}
                                        placeholder="Nunca foi consultado..." 
                                        size="lg"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <Form.Group className="mt-3 mb-5">
                                    <Form.Label>Atualiza Status:</Form.Label>
                                    <BadgeValidacao validacao={errors.ATUALIZA_STATUS} confirmado={confirmado} />
                                    <Form.Check
                                        style={{zoom: 1.4}}
                                        name="ATUALIZA_STATUS"
                                        id="ATIVO"
                                        type="switch"
                                        checked={values.ATUALIZA_STATUS}
                                        isInvalid={every([errors.ATUALIZA_STATUS, confirmado])}
                                        label={values.ATUALIZA_STATUS ? 'Sim' : 'Não'}
                                        onChange={() => setFieldValue("ATUALIZA_STATUS", !values.ATUALIZA_STATUS)}
                                    />
                                </Form.Group>  
                            </Col>
                        </Row>

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CadEmpresaIntegracao;
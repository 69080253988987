import { UseQueryResult, useQuery } from "react-query";
import useAPI from "../Hooks/utils/useAPI";
import { useContext } from "react";
import { CtxParams } from "../Contexts/ctxParams";
import { ICadEmpresaIntegracoes } from "../Models/cadEmpresaIntegracao";
import useIntegracoes from "../Hooks/utils/useIntegracoes";

const useQryEmpresaIntegracoes = () : UseQueryResult<ICadEmpresaIntegracoes, unknown> => {
    const API = useAPI();
    const UserParams = useContext(CtxParams);
    const Integracao = useIntegracoes(false, true, 60000, true);

    return (
        useQuery<ICadEmpresaIntegracoes>(
            'qryListaEmpresaIntegracoes',
            (
                () => API.get('empresaintegracao/buscarTodos', '', false)
            ),
            {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false,
                enabled: !UserParams.ENTREGADOR && (UserParams.DT_COMPETENCIA !== '0' && UserParams.DT_COMPETENCIA !== ""),
                refetchInterval: 60000,
                onSuccess: (ret: ICadEmpresaIntegracoes) => {
                    Integracao.executeMutations(ret);
                }
            }
        )
    )
}

export default useQryEmpresaIntegracoes;
import { Outlet, useParams } from "react-router-dom"
import useFormaPagamento from "../../Hooks/FormaPagamento";

const FormaPagamento = () => {
    const { cdForma } = useParams();
    const FormaPagamento = useFormaPagamento(cdForma);

    return <Outlet context={FormaPagamento} />
}

export default FormaPagamento;
import { useCallback, useEffect, useMemo, useState } from "react";
import { ICliente, IPedido, IPedidoItem, IPedidoItems } from "../../Models/pedido";
import { EMode, IReactTable_Grid } from "../../Models/genericInterfaces";
import useMask from "../utils/useMask";
import { Button } from "react-bootstrap";
import { FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import usePedido from "../Pedido";
import useCEP from "../utils/useCEP";
import { IEntrega } from "../../Models/cadEntrega";
import { v4 as uuidv4 } from 'uuid';

interface Props {
    isGarcom    : boolean;
    isSalvar    : boolean;
    isMesa?     : boolean;
    isEntrega?  : boolean;
}

const usePedidoOffline = ({isGarcom = false, isSalvar = true, isMesa, isEntrega} : Props) : IUsePedidoOffline => {
    const CEP = useCEP();
    const [pedido, setPedido] = useState<IPedido>();
    const [pedidoItems, setPedidoItems] = useState<IPedidoItems>([] as IPedidoItems);
    const [pedidoEntrega, setPedidoEntrega] = useState<IEntrega>({ENDERECO: {}} as IEntrega);
    const [pedidoCliente, setPedidoCliente] = useState<ICliente>({NOME: '', CELULAR: ''} as ICliente);
    const Pedido = usePedido();
    const formatter = useMask();

    useEffect(() => {
        Pedido.setModo(EMode.Include);
    });
    
    useEffect(() => { // Converte / Carrega pedido no navegador
        if (pedido === undefined) {
            setPedido(JSON.parse(localStorage.getItem(`cardapioPedido${sessionStorage.getItem('EMPRESA')}`)!) as IPedido)
        } else {
            localStorage.setItem(`cardapioPedido${sessionStorage.getItem('EMPRESA')}`, JSON.stringify(pedido));
        }
    }, [pedido]);

    useEffect(() => { // Converte / Carrega itens no navegador
        if (pedidoItems) {
            const cache = JSON.parse(localStorage.getItem(`cardapioPedidoItems${sessionStorage.getItem('EMPRESA')}`)!);

            if ((pedidoItems.length === 0) && cache !== null && cache.length > 0) {
                if (cache && (String(cache) !== '[]')) {
                    setPedidoItems(cache as IPedidoItems)
                }
            } else {
                localStorage.setItem(`cardapioPedidoItems${sessionStorage.getItem('EMPRESA')}`, JSON.stringify(pedidoItems));
            }
        }
    }, [pedidoItems, setPedidoItems]);

    useEffect(() => { // Converte / Carrega dados do cliente no navegador
        if (isSalvar) {
            if (JSON.stringify(pedidoCliente) === `{"NOME":"","CELULAR":""}`) {
                const cache = JSON.parse(localStorage.getItem(`dadosCliente`)!);

                if (cache !== null && (JSON.stringify(cache) !== JSON.stringify(pedidoCliente))) {
                    if (cache && (String(cache) !== `{"NOME":"","CELULAR":""}`)) {
                        setPedidoCliente(JSON.parse(localStorage.getItem(`dadosCliente`)!) as ICliente);
                    }
                }
            } else {
                localStorage.setItem(`dadosCliente`, JSON.stringify(pedidoCliente));
            }
        }
    }, [pedidoCliente, setPedidoCliente, isSalvar]);

    useEffect(() => {
        let somatorio : number = 0;
        if (pedidoItems && pedidoItems.length > 0) {
            pedidoItems.map((item) => {
                somatorio = somatorio + (item.QTD_PRODUTO * item.VLRUN_PRODUTO)
                return null
            });
        };

        setPedido({...pedido, OFF_TOTAL: somatorio} as IPedido);
    }, [pedidoItems]) //eslint-disable-line

    const AtualizaQuantidadeCarrinho = useCallback((RowOriginal: any, Quantidade: number) => {
        setPedidoItems((prev) => {
            const Items = [...prev];
            const ItemPedido = RowOriginal as IPedidoItem;
            const index = Items.findIndex((item) => item.CD_PRODUTO === ItemPedido.CD_PRODUTO);

            if (index >= 0) {
                const novaQuantidade : number = formatter.getMinMax(0, 20, ItemPedido.QTD_PRODUTO + Quantidade);

                if (novaQuantidade > 0) {
                        Items[index] = {
                            ...Items[index],
                            QTD_PRODUTO: novaQuantidade
                        };
                } else {
                    Items.splice(index, 1);

                    if (Items.length === 0) {
                        localStorage.setItem(`cardapioPedidoItems${sessionStorage.getItem('EMPRESA')}`, '[]');
                    }
                }
            }

            return [
                ...Items
            ]
        })
    }, [formatter]);
    
    const gridTotais = useMemo<IReactTable_Grid>(() => [
        {
            id: "DADO",
            Header: "DADO",
            accessor: 'DADO'
        },
        {
            id: "VALOR",
            Header: "VALOR",
            Cell: (row: any) : any => `${formatter.setMaskMoney((row.row.original['VALOR']) ?? 0, "R$", ',')}`
        },
    ], [formatter])

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "Nome",
            Header: "Produto",
            Cell: (row: any) : any => {
                const produto = row.row.original as IPedidoItem
                return <div>
                    <div style={{fontWeight: 'bold'}}>{produto.OFF_NOME}</div>
                    <div>{produto.OBS_PRODUTO}</div>
                    {
                        produto.COMPLEMENTOS?.map((complemento) => {
                            return <div key={uuidv4()}>{
                                `- (${complemento.QTD_PRODUTO}x) ${complemento.OFF_NOME} ${complemento.VLRUN_PRODUTO === 0 ? '' : `(${formatter.setMaskMoney(complemento.VLRUN_PRODUTO, 'R$')})`}`
                            }</div>
                        })
                    }
                </div>
            }
        },
        {
            id: "Valor",
            Header: "Valor(R$)",
            Cell: (row: any) : any => <div style={{fontWeight: 'bold'}}>{`${formatter.setMaskMoney((row.row.original['VLRUN_PRODUTO'] * row.row.original['QTD_PRODUTO']) ?? 0, "R$")}`}</div>
        },
        {
            id: "Quantidade",
            Header: "Quantidade",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around align-items-center">
                    <Button 
                        size="sm"
                        variant={row.row.original['QTD_PRODUTO'] === 1 ? "danger" : "primary"}
                        onClick={() => AtualizaQuantidadeCarrinho(row.row.original, -1)}
                    >
                        {
                            row.row.original['QTD_PRODUTO'] === 1
                                ? <FaTrashAlt />
                                : <FaMinus />
                        }
                    </Button>
                    <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>
                        {
                            row.row.original['QTD_PRODUTO']
                        }
                    </div>
                    <Button 
                        size="sm"
                        variant={"primary"}
                        onClick={() => AtualizaQuantidadeCarrinho(row.row.original, 1)}
                    >
                        <FaPlus />
                    </Button>
                </div>
        },
    ], [formatter, AtualizaQuantidadeCarrinho]);

    const BuscarLocalizacao = () => {
        navigator.geolocation.getCurrentPosition((position) => {            
            CEP.getByCoordinates(String(position.coords.latitude), String(position.coords.longitude))
            .then((ret) => setPedidoEntrega({
                ...pedidoEntrega,
                ENDERECO: {
                    CEP: String(ret['address']['postcode'] ?? ''),
                    LOGRADOURO: String(ret['address']['road'] ?? ''),
                    BAIRRO: String(ret['address']['suburb'] ?? ''),
                    CIDADE: String(ret['address']['city'] ?? ''),
                    NUMERO: '',
                    UF: '',
                    COMPLEMENTO: '',
                    LATITUDE: String(position.coords.latitude),
                    LONGITUDE: String(position.coords.longitude),
                }
            }))
            .catch((e) => alert('Não foi possível detectar sua localização: ' + e))
        })
    }

    const EnviaPedido = () => {
        const pedidoDados: IPedido = pedido as IPedido;

        Pedido.Salvar(
            pedidoDados, 
            pedidoItems,
            pedidoCliente,
            pedidoEntrega
        ).then((retorno) => {
            setPedidoItems([] as IPedidoItems)
            localStorage.setItem(`cardapioPedidoItems${sessionStorage.getItem('EMPRESA')}`, '[]');
            setPedido(undefined);
            localStorage.removeItem(`cardapioPedido${sessionStorage.getItem('EMPRESA')}`);
            setPedidoEntrega(prev => ({...prev, VLR_PEDIDO: 0, VLR_TROCO: 0, TAXA: 0}));

            alert(`Pedido #${retorno.result} enviado!`)
        }).catch((e) => {
            alert('Problema ao enviar pedido: ' + e)
        });
    };

    return {
        setPedido,
        pedido,
        setPedidoItems,
        pedidoItems,
        setPedidoEntrega,
        pedidoEntrega,
        pedidoCliente,
        setPedidoCliente,
        gridLista,
        EnviaPedido,
        gridTotais,
        BuscarLocalizacao
    }
}

export interface IUsePedidoOffline {
    setPedido       : React.Dispatch<React.SetStateAction<IPedido|undefined>>;
    pedido          : IPedido|undefined;

    setPedidoItems  : React.Dispatch<React.SetStateAction<IPedidoItems>>;
    pedidoItems     : IPedidoItems;

    setPedidoEntrega: React.Dispatch<React.SetStateAction<IEntrega>>;
    pedidoEntrega   : IEntrega;    

    pedidoCliente   : ICliente;
    setPedidoCliente: React.Dispatch<React.SetStateAction<ICliente>>;

    gridLista       : IReactTable_Grid;
    EnviaPedido     : () => void;
    gridTotais      : IReactTable_Grid;
    BuscarLocalizacao: () => void;
}

export default usePedidoOffline;
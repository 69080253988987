import './App.scss';
import Rotas from './routes';
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";

import { CAPDefault_Filtros, CtxAppParams } from './Contexts/ctxAppParams';
import { ICtxAppParams_Filtros, ICtxAppParams_Modal } from './Models/ctxAppParams';
import ModalGlobal from './Components/modal';
import useBeep from './Hooks/utils/useBeep';
import Provider from './Provider';
import useUtils from './Hooks/utils/useUtils';
// import Geolocation from './Geolocation';

const queryDefault = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 300000, // Cache 5 miuntos
			retryDelay: 5000, // 5 Segundos para dar um retry em caso de erro
		},
	},
});

const localStoragePersistor = createWebStoragePersistor({storage: window.sessionStorage});

persistQueryClient({
	queryClient: queryDefault,
	persistor: localStoragePersistor,
});

function App() {
	window.Buffer = window.Buffer || require("buffer").Buffer;
	const { Deslogar } = useUtils();
	const Beep = useBeep();
	const [Pagina, setPagina] = useState<string>('');
	const [EscondeHeader, setEscondeHeader] = useState<boolean>(false);
	const [BloqueiaSincronizacao, setBloqueiaSincronizacao] = useState<boolean>(false);
	const [SessaoExpirada, setSessaoExpirada] = useState<boolean>(false);
	const [Logado, setLogado] = useState<boolean>(false);
	const [Filtros, setFiltros] = useState<ICtxAppParams_Filtros>(CAPDefault_Filtros);
	const [Modal, setModal] = useState<ICtxAppParams_Modal>({
		Visibilidade    : false,
        Titulo          : '',
        Descricao       : '',
		Anexos			: undefined,
        Inputs          : undefined,
        Variante        : 'none',
        onSubmit        : (ICtxAppParams_ModalInputs) => {}
	});
	const [Mesa, setMesa] = useState<{Numero: string}>({Numero: ''});

	useEffect(() => {
		if(SessaoExpirada) {
			alert('Sessão expirada');
            Deslogar();
		}
	}, [SessaoExpirada]); // eslint-disable-line
	
	useEffect(() => {
		Beep.pause();
	})

	return (
		<CtxAppParams.Provider value={{
			Pagina, setPagina,
			EscondeHeader, setEscondeHeader,
			BloqueiaSincronizacao, setBloqueiaSincronizacao,
			Filtros, setFiltros,
			Modal, setModal,
			SessaoExpirada, setSessaoExpirada,
			Logado, setLogado,
			BeepAudio: Beep,
			Mesa, setMesa
		}}>
			<QueryClientProvider client={queryDefault}>
				<Provider>
					<ModalGlobal />
					{/* <Geolocation /> */}
					<Rotas />
				</Provider>
			</QueryClientProvider>
		</CtxAppParams.Provider>
	);
}

export default App;

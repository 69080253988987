import { Form } from "react-bootstrap";
import { UseQueryResult } from "react-query";
import { useMemo, useState } from "react";
import { ICadUsuario, ICadUsuarios } from "../../Models/cadUsuario";
import { IUseEntregaController } from "../../Hooks/cadEntrega/controller";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IEntrega } from "../../Models/cadEntrega";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    CdEntregador: number;
    CdEntrega: number;
    qryListaUsuarios: UseQueryResult<ICadUsuarios, unknown>;
    size?: 'sm' | 'lg';
    disabled: boolean;
    isSuccess?: (data: DeepPartial<IEntrega>) => void;
    controller: IUseEntregaController;
};

const SelectEntregador = (props: IProps) => {
    const { CdEntregador, CdEntrega, qryListaUsuarios, size, disabled, isSuccess, controller } = props;
    const [entregador, setEntregador] = useState<number | null>(CdEntregador); //eslint-disable-line
    // const { Alterar } = useEntregaController('entregaLista2', true, false, undefined);
    const {Alterar} = controller;

    const dsListaEntregadores = useMemo<Partial<ICadUsuarios>>(() =>
        qryListaUsuarios.isSuccess ? 
            qryListaUsuarios.data.filter((e: ICadUsuario) => e.ENTREGADOR === true)
        : 
            [{} as ICadUsuario]
    , [qryListaUsuarios]);

    const entregadorSelecionado = dsListaEntregadores.find!((x) =>
        String(x.CD_USUARIO) === String(entregador)
    );

    const styleCorUsuario = entregadorSelecionado?.COD_COR !== undefined
    ? {backgroundColor: `${entregadorSelecionado.COD_COR ?? "#ced4da"}26`, borderColor: `${entregadorSelecionado.COD_COR ?? "#ced4da"}80`} 
    : {}

    return (
        <Form.Group className="mx-1" style={{flex: '1'}}>
            {size === undefined ? null : <Form.Label>Entregador:</Form.Label>}
            <Form.Select
                name={'STATUS'}
                value={entregador ?? ''}
                style={
                    entregador 
                    ? {minWidth: '10rem', borderWidth: '0.2rem', ...styleCorUsuario}
                    : {backgroundColor: '#f7f7f7', minWidth: '10rem'}}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    Alterar.mutate({
                        CD_ENTREGA: CdEntrega,
                        CD_USUARIO: Number(e.target.value),
                        USUARIO: {
                            NOME: dsListaEntregadores.filter!((x) => String(x.CD_USUARIO) === String(e.target.value) ? x.NOME : '').map((x) => x.NOME).join('')
                        }
                    }, {
                        onSuccess: (ret: any, data: DeepPartial<IEntrega>) => { isSuccess && isSuccess(data) }
                    })
                }}
                placeholder="" 
                size={size}
                disabled={disabled}
            >
                <option 
                    key={`i${String(CdEntrega)}-1`} 
                    // style={{backgroundColor: '#ced4da'}}
                    value={''}
                >                        
                    {size === undefined ? '-' : '--Nenhum--'}
                </option>
                {dsListaEntregadores.map!((item: ICadUsuario) => 
                    <option 
                        key={`i${String(CdEntrega)}${String(item.CD_USUARIO)}`} 
                        // style={{backgroundColor: item.COD_COR ? `${item.COD_COR}26` : '#ced4da'}}
                        value={item.CD_USUARIO ?? ""}
                    >
                        {item.NOME}
                    </option>
                )}
            </Form.Select>
        </Form.Group>  
    )
}

export default SelectEntregador;
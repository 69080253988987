import { Outlet } from "react-router-dom";
import useFechamento from "../../Hooks/Fechamento/lista";

const Fechamento = () => {
    const Fechamento = useFechamento();
    return (
        <Outlet context={Fechamento} />
    );
}

export default Fechamento;
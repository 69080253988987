import styles from './styles.module.scss';

const FormRodape = (props: any) => {
    return (
        <div id='formRodape' className={styles.rodape} {...props}>
            {props.children}
        </div>
    );
}

export default FormRodape;
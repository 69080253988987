import { Printer, WebUSB, Align } from 'escpos-buffer';
import { SupportedModel } from 'escpos-buffer/dist/capabilities';
import { IPedido } from '../../Models/pedido';
import ImpressaoPedido from './ImpressaoPedido';
import { useState } from 'react';
import { IEntrega } from '../../Models/cadEntrega';
import ImpressaoEntrega from './ImpressaoEntrega';
import ImpressaoMesa from './ImpressaoMesa';
import { IMesa } from '../../Models/mesa';

const ImpressoraESCPOS = () => {
    let connection : WebUSB | undefined = undefined;
    let printer : Promise<Printer> | undefined;
    const [tmpPrinter, setTmpPrinter] = useState<Promise<Printer> | undefined>(undefined);

    const Conectar = async () => {
        return await new Promise<void> (async(resolve) => {
            if (tmpPrinter === undefined) {
                try {
                    const device = await navigator.usb.requestDevice({
                        filters: [],
                    });
        
                    if (device.productName) {
                        connection = new WebUSB(device);
                        printer = Printer.CONNECT((device.productName.toUpperCase() as unknown) as SupportedModel, connection);
                        resolve(setTmpPrinter(printer))
                    }                     
                } catch (error) {
                    alert('Não foi possível conectar a impressora: ' + error)
                }
            } else {
                printer = tmpPrinter;
                resolve();
            }
        })
    };

    const Imprimir = async (Dados: any, Tipo: 'PEDIDO' | 'ENTREGA' | 'MESA', UsarImpressora: boolean = true, Colunas: number = 56) => {
        const Impressao = async () => {
            const impressaoPedido = Tipo === 'PEDIDO' 
                ? ImpressaoPedido(Dados as IPedido, Colunas) 
                : Tipo === 'MESA' 
                    ? ImpressaoMesa(Dados as IMesa, Colunas)
                    : ImpressaoEntrega(Dados as IEntrega, Colunas);

            if (UsarImpressora) {
                try {
                    await(await printer)?.setColumns(56);
    
                    await (await printer)?.withStyle({
                        width: 1.1,
                        height: 1.1,
                        align: Align.Left,
                        }, async () => {
                            await (await printer)?.write(impressaoPedido.cabecalho)
                    });
    
                    await (await printer)?.withStyle({
                        width: 1.1,
                        height: 1.1,
                        align: Align.Center,
                        }, async () => {
                            await (await printer)?.write(impressaoPedido.itens)
                    });
    
                    await(await printer)?.feed(1);
                    await(await printer)?.writeln(`└`);
                    await(await printer)?.feed(5);
                    await(await printer)?.writeln(`┌`);
                } catch (error) {
                    alert('Não foi possível imprimir: ' + error);
                }
            } else {
                console.log(`%c${impressaoPedido.cabecalho}${impressaoPedido.itens}`, 'font-family: courier new')
            }
        }

        if (UsarImpressora && (printer === undefined)) {
            await Conectar().then(() => {
                Impressao();
            });
        } else {
            Impressao();
        }    
    }

    return {
        Imprimir
    }
}

export interface IImpressoraESCPOS {
    Imprimir : (Dados: IPedido, Tipo: 'PEDIDO' | 'ENTREGA', UsarImpressora?: boolean, Colunas?: number) => Promise<void>;
}

export default ImpressoraESCPOS;
import { Formik } from "formik";
import { every } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { Card, Col, Container, FormCheck, InputGroup, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import FormRodape from "../../../Components/formularios/FormRodape";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useMask from "../../../Hooks/utils/useMask";
import { EProdutoTipo, ICardapio, IProduto, schemaAltProduto, schemaCadProduto } from "../../../Models/cardapio";
import { IUseProduto } from "../../../Hooks/Produto";
import useCardapio from "../../../Hooks/Cardapio";
import CardapioProdutoComposicao from "./composicao";
import { EMode } from "../../../Models/genericInterfaces";
import CardapioProdutoEstoque from "./estoque";
import CardapioProdutoCaixa from "./caixa";
import CardapioProdutoVariacao from "./variacao";

const CardapioProduto = () => {
    const { pTipoProduto } = useParams();
    const Produto = useOutletContext<IUseProduto>();
    const Categoria = useCardapio(Produto.CdEmpresa);
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);

    const [isMouseOverImg, setIsMouseOverImg] = useState<boolean>(false);
    const [isUploadMode, setIsUploadMode] = useState<boolean>(false);

    const formatter = useMask();
    const navigate = useNavigate();

    const tipoProduto : EProdutoTipo = Object.values(EProdutoTipo).includes(pTipoProduto as EProdutoTipo) ? (pTipoProduto as EProdutoTipo) : EProdutoTipo.Produto;

    useEffect(() => {
        if (Categoria.Controller.ListarCategorias.isLoadingError) {
            alert('Nenhuma categoria ainda foi cadastrada para prosseguir com o cadastro do produto, te redirecionamos para o cadastro de categoria')
            Categoria.Abrir({CD_CATEGORIA: -1})
        }
    }, [Categoria.Controller.ListarCategorias.isError]) //eslint-disable-line

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={Produto.Modo === EMode.Include ? schemaCadProduto : schemaAltProduto}
                onSubmit={(dados : IProduto, {setFieldValue}) => {
                    setSubmit(true);

                    Produto.SalvarImagem(dados)
                    .then(() => 
                        Produto.Salvar(dados).then(() => {
                            if (Produto.continuarCadastro) {
                                alert(dados.NOME + ' cadastrado(a) com sucesso!')
                                setSubmit(false);
                                setConfirmado(false);
                                setFieldValue('NOME', '');
                            } else {
                                setSubmit(false)
                                navigate(`/cardapio/${Produto.CdEmpresa}`)
                            }
                        })
                        .catch(() => setSubmit(false))
                    )
                    .catch(() => setSubmit(false))
                }}
                initialValues={{
                    CD_PRODUTO      : Produto.Item.CD_PRODUTO       || null,
                    NOME            : Produto.Item.NOME             || '',
                    TIPO            : Produto.Item.TIPO             ?? tipoProduto,
                    CD_CATEGORIA    : Produto.Item.CD_CATEGORIA     ?? undefined,
                    VALOR           : Produto.Item.VALOR            || 0,
                    INGREDIENTES    : Produto.Item.INGREDIENTES     || [],
                    OBSERVACAO      : Produto.Item.OBSERVACAO       || '',
                    DISPONIVEL      : Produto.Item.DISPONIVEL       ?? true,
                    USA_ADICIONAL   : Produto.Item.USA_ADICIONAL    ?? false,
                    CD_COMPOSICAO   : Produto.Item.CD_COMPOSICAO    ?? undefined,
                    MIN             : Produto.Item.MIN              ?? 0,
                    MAX             : Produto.Item.MAX              ?? 1,
                    MOVIMENTA_ESTOQUE: Produto.Item.MOVIMENTA_ESTOQUE ?? false,
                    CAMINHO_IMAGEM  : Produto.Item.CAMINHO_IMAGEM   ?? '/xburg.png',
                    IMAGEM          : null,
                    LOCAL_IMAGEM    : "",
                    ESTOQUE         : Produto.Item.ESTOQUE,
                    CAIXA_NOME      : Produto.Item.CAIXA_NOME       ?? "",
                    CAIXA_QTD       : Produto.Item.CAIXA_QTD        ?? 1,
                    VARIACAO        : Produto.Item.VARIACAO         ?? []
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => Produto.Abrir({CD_CATEGORIA: 0})}>       
                        {
                            values.CD_PRODUTO && Produto.Item.TIPO === EProdutoTipo.Produto
                                ?   <Row>
                                    <Col xs={isUploadMode ? 4 : 12}>
                                        {    
                                            values.CAMINHO_IMAGEM !== "" 
                                                ? <div className="w-100 d-flex align-items-center justify-content-center">
                                                    <Card.Img 
                                                        variant="center" 
                                                        loading='lazy'
                                                        onMouseOver={() => setIsMouseOverImg(true)} 
                                                        onMouseLeave={() => setIsMouseOverImg(false)} 
                                                        onClick={() => setIsUploadMode(!isUploadMode)}
                                                        style={isUploadMode 
                                                            ? {width: '8rem', height: '8rem', objectFit: 'cover', borderRadius: '0.5rem', cursor: 'pointer'} 
                                                            : (isMouseOverImg ? {width: '100%', height: '12rem', borderRadius: '0.5rem', cursor: 'pointer'} : {width: '100%', height: '12rem', objectFit: 'cover', borderRadius: '0.5rem', cursor: 'pointer'})
                                                        } 
                                                        src={isMouseOverImg ? '/Icones/iconUpload.svg' : (values.CAMINHO_IMAGEM ?? "")} 
                                                        alt="Pré-Visualização" 
                                                        title="Fazer upload de uma imagem do produto"
                                                    />
                                                </div>
                                                : <></>
                                        }
                                    </Col>
                                    <Col xs={isUploadMode ? 8 : 0}>
                                        <Form.Group className="mt-3" style={isUploadMode ? {} : {display: "none"}}>
                                            <Form.Label>Imagem:</Form.Label>
                                            <BadgeValidacao validacao={errors.CAMINHO_IMAGEM} confirmado={confirmado} />
                                            <Form.Control
                                                id={'LOCAL_IMAGEM'}
                                                name={'LOCAL_IMAGEM'}
                                                type="file"
                                                accept="image/*"
                                                value={values.LOCAL_IMAGEM ?? ""}
                                                onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                                    if (!e.target.files) {
                                                        return
                                                    } 

                                                    if (e.target.files[0].size > 4194304) {
                                                        alert('Não foi possível carregar a imagem: Está excedendo o tamanho máximo (4Mb)')
                                                        return
                                                    }

                                                    setFieldValue("LOCAL_IMAGEM", e.target.value);
                                                    setFieldValue("IMAGEM", e.target.files[0]);
                                                    const fileReader = new FileReader();

                                                    fileReader.onload = () => {
                                                        if (fileReader.readyState === 2) {
                                                            setFieldValue("CAMINHO_IMAGEM", fileReader.result);
                                                        }
                                                    };
                                                    fileReader.readAsDataURL(e.target.files[0]);
                                                }}
                                                isInvalid={every([errors.CAMINHO_IMAGEM, confirmado])}
                                                size="lg"
                                            />
                                        </Form.Group>                                        
                                    </Col>
                                    
                                </Row>
                                : ((values.CD_PRODUTO ?? -1) === -1)
                                    ? <div className="w-100 d-flex justify-content-center"><FormCheck title="Caso marcado, após salvar você continuará cadastrando outro item" style={{zoom: 1.2}} label="Continuar cadastrando" checked={Produto.continuarCadastro} onClick={() => Produto.setContinuarCadastro(!Produto.continuarCadastro)} /></div>
                                    : null
                        }

                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Categoria:</Form.Label>
                                    <BadgeValidacao validacao={errors.CD_CATEGORIA} confirmado={confirmado} />
                                    <Form.Select 
                                        name={'CD_CATEGORIA'}
                                        isInvalid={every([errors.CD_CATEGORIA, confirmado])}
                                        value={values.CD_CATEGORIA}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            setFieldValue("CD_CATEGORIA", Number(e.target.value))
                                        }}
                                        placeholder="" 
                                        size="lg"
                                    > 
                                        <option key={'catNull'} value={undefined}></option>
                                        {
                                            Categoria.ListaCategoria.map((Item: ICardapio, Index: number) => 
                                                <option key={String(Index)} value={String(Item.CD_CATEGORIA)}>{Item.NOME}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>  
                            </Col>

                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Tipo:</Form.Label>
                                    <Form.Select 
                                        name={'TIPO'}
                                        value={values.TIPO}
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            handleChange(e)
                                        }}
                                        placeholder="" 
                                        size="lg"
                                    > 
                                        <option key={'PRODUTO'} value={'PRODUTO'}>Produto</option>
                                        <option key={'INGREDIENTE'} value={'INGREDIENTE'}>Ingrediente</option>
                                    </Form.Select>
                                </Form.Group>  
                            </Col>
                        </Row>
                        
                        <Form.Group className="mt-3">
                            <Form.Label>Nome do {formatter.getCapitalized(values.TIPO)}:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Control 
                                name={'NOME'}
                                type={'text'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.NOME, confirmado])}
                                placeholder={`Nome do ${formatter.getCapitalized(values.TIPO)}...`}
                                size="lg"
                            />
                        </Form.Group>  

                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Preço:</Form.Label>
                                    <BadgeValidacao validacao={errors.VALOR} confirmado={confirmado} />
                                    <InputGroup>
                                        <InputGroup.Text>R$</InputGroup.Text>
                                        <Form.Control 
                                            name={'VALOR'}
                                            type={'number'}
                                            min={0}
                                            value={values.VALOR ?? ""}
                                            onChange={handleChange}
                                            onBlur={() => setFieldValue("VALOR", formatter.setMaskMoney(values.VALOR ?? 0, "", "."))}
                                            isInvalid={every([errors.VALOR, confirmado])}
                                            step={0.01}
                                            onWheel={(e: any) => e.target.blur()}
                                            size="lg"
                                        />
                                    </InputGroup>
                                </Form.Group> 
                            </Col>

                        
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Disponibilidade:</Form.Label>
                                    <BadgeValidacao validacao={errors.DISPONIVEL} confirmado={confirmado} />
                                    <Form.Check
                                        style={{zoom: 1.4}}
                                        name="DISPONIVEL"
                                        id="DISPONIVEL"
                                        type="switch"
                                        checked={values.DISPONIVEL}
                                        isInvalid={every([errors.DISPONIVEL, confirmado])}
                                        label={values.DISPONIVEL ? 'Disponível' : 'Indisponível'}
                                        onChange={() => setFieldValue("DISPONIVEL", !values.DISPONIVEL)}
                                    />
                                </Form.Group>  
                            </Col>
                        </Row>

                        <Form.Group className="mt-3"> 
                            <Form.Label>Observações:</Form.Label>
                            <BadgeValidacao validacao={errors.OBSERVACAO} confirmado={confirmado} />
                            <Form.Control 
                                name={'OBSERVACAO'}
                                type={'text'}
                                value={values.OBSERVACAO ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.OBSERVACAO, confirmado])}
                                placeholder={`Observações do ${formatter.getCapitalized(values.TIPO)}...`}
                                size="lg"
                            />
                        </Form.Group>  

                        <CardapioProdutoComposicao Produto={Produto} Categoria={Categoria} Confirmado={confirmado} />
                        <CardapioProdutoVariacao Produto={Produto} Categoria={Categoria} Confirmado={confirmado} formatter={formatter} />
                        <CardapioProdutoEstoque Produto={Produto} Categoria={Categoria} Confirmado={confirmado} />
                        <CardapioProdutoCaixa Produto={Produto} Categoria={Categoria} Confirmado={confirmado} />

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" onClick={() => Produto.Abrir({CD_PRODUTO: 0})}>Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" disabled={submit} onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CardapioProduto;
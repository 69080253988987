import { Outlet, useParams } from "react-router-dom";
import useCadEntregaLista from "../../Hooks/cadEntrega/lista";

const Entrega = () => {
    const { dtCompetencia, cdEntrega, pgOrigem } = useParams();
    const EntregaLista = useCadEntregaLista(dtCompetencia ?? "", cdEntrega, pgOrigem);

    return (
        <Outlet context={EntregaLista} />
    )
}

export default Entrega;
import { useFormikContext } from "formik";
import { IUseProduto } from "../../../Hooks/Produto";
import { EProdutoTipo, IProduto, IProdutoVariacao } from "../../../Models/cardapio";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import Tabela from "../../../Components/tabela";
import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { IReactTable_Grid } from "../../../Models/genericInterfaces";
import { IUseCardapio } from "../../../Hooks/Cardapio";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    Produto: IUseProduto;
    Categoria: IUseCardapio;
    Confirmado: boolean;
    formatter: any;
};

const CardapioProdutoVariacao = (props: IProvider) => {
    const { Produto, formatter } = props;
    const { values, setFieldValue } = useFormikContext<IProduto>();
    const [variacao, setVariacao] = useState<IProdutoVariacao>({
        NOME: '', 
        VALOR: 0
    } as IProdutoVariacao);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: 'variacao',
            Header: //'Ingredientes',
                <Form.Group id="fldVariacao">
                    <Form.Label>Variação:</Form.Label>
                    <Form.Control
                        name={'VARIACAO'}
                        type={'text'}
                        value={variacao.NOME}
                        onChange={(e) => {
                            setVariacao({
                                ...variacao,
                                NOME: e.target.value
                            })
                        }}
                        size="lg"
                    />
            </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return original['NOME']
            }
        },
        {
            id: 'VALOR',
            Header: //'Quantidade',
                <Form.Group style={{maxWidth: '10rem', margin: '0'}}>
                    <Form.Label>Preço:</Form.Label>
                    <InputGroup>
                        <InputGroup.Text>R$</InputGroup.Text>
                        <Form.Control
                        name={'VARIACAO_VALOR'}
                        type={'number'}
                        min={0}
                        value={Number(variacao.VALOR)}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setVariacao({
                                ...variacao,
                                VALOR: Number(e.target.value)
                            })
                        }}
                        size="lg"
                        />
                    </InputGroup>
            </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return formatter.setMaskMoney(original['VALOR'] ?? 0, 'R$')
            }
        },
        {
            id: "acoes",
            Header: 
                <Button 
                    variant="success"
                    disabled={(variacao.NOME === ('').trim())}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        // VALIDAÇÃO: 
                        // OK - Não pode ter duas variações com mesmo nome
                        // OK - Valor tem que ser maior que zero

                        if (variacao.VALOR === 0) {
                            alert('Preço da variação tem que ser maior que zero.');
                            return
                        }

                        setFieldValue("VARIACAO", [...values.VARIACAO!, {
                            CD_PRODUTO: Produto.Item.CD_PRODUTO,                            
                            NOME: variacao.NOME, 
                            VALOR: variacao.VALOR
                        }])

                        setVariacao({NOME: '', VALOR: 0});
                    }}
                >
                    <FaPlus />
                </Button>,
            Cell: ({row: {original}}: any) : any => 
                <Button 
                    variant="danger" 
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        const variacoesNovas = values.VARIACAO?.filter(item =>
                            item.NOME !== original['NOME']
                        );
                        setFieldValue("VARIACAO", variacoesNovas);
                    }}
                >
                    <FaTrashAlt />
                </Button>
        },
    ], [Produto.Item, values, setFieldValue, variacao, setVariacao, formatter]);

    return (
            values.TIPO === EProdutoTipo.Produto 
            ? <Accordion className="mt-3">
                <Accordion.Item eventKey="0" onClick={() => setTimeout(() => {
                    document.getElementById('fldVariacao')?.scrollIntoView()
                }, 500)}>
                    <Accordion.Header>Variações do Produto</Accordion.Header>
                    <Accordion.Body>
                        <Tabela colunas={gridLista} dados={values.VARIACAO ?? []} />   
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            : null
    )
}

export default CardapioProdutoVariacao;
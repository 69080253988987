import * as Yup from 'yup';
import { LOGRADOURO, BAIRRO, CIDADE, NUMERO, UF } from './defaultModels';

// export interface ICadClienteEndereco {
//     CD_CLIENTE_ENDERECO?: number;
//     CD_CLIENTE?:     number;
//     CEP:             string;
//     ENDERECO:        string;
//     ENDERECO_BAIRRO: string;
//     ENDERECO_CIDADE: string;
//     ENDERECO_NUMERO: string;
//     ENDERECO_UF:     string;
//     COMPLEMENTO:     string | null;
//     LATITUDE?:       string | null;
//     LONGITUDE?:      string | null;
// };
// export interface ICadClienteEnderecos extends Array<ICadClienteEndereco>{};

export interface IEnderecoBuscaCEP {
    bairro?: string;
    cep?: string;
    complemento?: string;
    ddd?: string;
    gia?: string;
    ibge?: string;
    localidade?: string;
    logradouro?: string;
    siafi?: string;
    uf?: string;
    erro?: boolean;
}

export interface IClienteEndereco {
    CD_CLIENTE_ENDERECO?    : number;
    CD_CLIENTE?             : number;
    CEP                     : string;
    LOGRADOURO              : string;
    BAIRRO                  : string;
    CIDADE                  : string;
    NUMERO                  : string;
    UF                      : string;
    COMPLEMENTO             : string | null;
    LATITUDE?               : string | null;
    LONGITUDE?              : string | null;
};
export interface IClienteEnderecos extends Array<IClienteEndereco>{};

export const schemaCadClienteEndereco = Yup.object().shape({
    LOGRADOURO, 
    BAIRRO, 
    CIDADE, 
    NUMERO, 
    UF
});
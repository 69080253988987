import { Button, Col, Form, Row } from "react-bootstrap";
import { IUsePedidoOffline } from "../../../Hooks/offline/PedidoOffline";
import { FaLocationArrow } from "react-icons/fa";
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import { IUseCardapio } from "../../../Hooks/Cardapio";
import { every } from "lodash";
import { IEmpresaStatus } from "../../../Models/cadEmpresa";
import { IEntrega } from "../../../Models/cadEntrega";
import { IPedido } from "../../../Models/pedido";

interface Props {
    pedido: IUsePedidoOffline;
    isEntrega: boolean;
    setIsEntrega: React.Dispatch<React.SetStateAction<boolean>>;
    validacaoErrosEntrega: any;
    cardapio: IUseCardapio;
}

const CarrinhoEntrega = ({pedido, isEntrega, setIsEntrega, validacaoErrosEntrega, cardapio} : Props) => {
    return (
        <>
            <div className="my-3"> 
                <Row>
                    <Col xs={isEntrega ? 7 : 12}>
                        <Form.Switch 
                            checked={isEntrega}
                            style={{zoom: '1.2'}} 
                            onChange={() => {
                                setIsEntrega(!isEntrega); 
                                pedido.setPedidoEntrega({ENDERECO: {}} as IEntrega)
                                pedido.setPedido({...pedido.pedido as IPedido, FORMA_PAGTO: undefined, VLR_TROCO: undefined})
                            }}
                            label={isEntrega ? 'Entregar no meu endereço' : 'O pedido será no estabelecimento'}
                        />
                    </Col>

                    { 
                        isEntrega ? 
                            <Col xs={5}>
                                <Button variant="outline-primary" onClick={() => pedido.BuscarLocalizacao()}><FaLocationArrow /> Usar Endereço GPS</Button>
                            </Col>
                        : 
                            null
                    }
                </Row>
            </div>

            {
                isEntrega && <div className="d-flex justify-content-center mb-3">
                    <Row>
                        <Form.Group className="mt-3">
                            <Form.Label>Bairro:</Form.Label>
                            <BadgeValidacao validacao={validacaoErrosEntrega.BAIRRO} confirmado={validacaoErrosEntrega.BAIRRO !== null} />
                            <Form.Select 
                                name={'BAIRRO'}
                                value={pedido.pedidoEntrega.ENDERECO.BAIRRO ?? ''}
                                onChange={(e) => { 
                                    pedido.setPedidoEntrega((prev => ({
                                        ...prev,
                                        ENDERECO: {...prev.ENDERECO, BAIRRO: e.target.value}
                                    })));                                    
                                }}
                                isInvalid={every([validacaoErrosEntrega.BAIRRO, validacaoErrosEntrega.BAIRRO !== null])}
                                size="lg"
                            >
                                <option key={'-1'} value={''}></option>
                                {
                                    cardapio.Controller.EmpresaStatus.isFetched && cardapio.Controller.EmpresaStatus.isSuccess && (cardapio.Controller.EmpresaStatus.data as IEmpresaStatus).TAXAS.map((taxa) => {
                                        return (
                                            <option key={taxa.CD_TAXA} value={taxa.BAIRRO}>{taxa.BAIRRO}</option>
                                        )
                                    })
                                }                                        
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Endereço:</Form.Label>
                            <BadgeValidacao validacao={validacaoErrosEntrega.LOGRADOURO} confirmado={validacaoErrosEntrega.LOGRADOURO !== null} />
                            <Form.Control 
                                name={'LOGRADOURO'}
                                type={'text'}
                                value={pedido.pedidoEntrega.ENDERECO.LOGRADOURO ?? ''}
                                onChange={(e) => {
                                    pedido.setPedidoEntrega((prev => ({
                                        ...prev,
                                        ENDERECO: {...prev.ENDERECO, LOGRADOURO: e.target.value}
                                    })));
                                }}
                                isInvalid={every([validacaoErrosEntrega.LOGRADOURO, validacaoErrosEntrega.LOGRADOURO !== null])}
                                placeholder="Rua, Avenida, Logradouro..." 
                                size="lg"
                            />
                        </Form.Group> 

                        <Form.Group className="mt-3">
                            <Form.Label>Número:</Form.Label>
                            <BadgeValidacao validacao={validacaoErrosEntrega.NUMERO} confirmado={validacaoErrosEntrega.NUMERO !== null} />
                            <Form.Control 
                                name={'NUMERO'}
                                type={'text'}
                                value={pedido.pedidoEntrega.ENDERECO.NUMERO ?? ''}
                                onChange={(e) => {
                                    pedido.setPedidoEntrega((prev => ({
                                        ...prev,
                                        ENDERECO: {...prev.ENDERECO, NUMERO: e.target.value}
                                    })));
                                }}
                                isInvalid={every([validacaoErrosEntrega.NUMERO, validacaoErrosEntrega.NUMERO !== undefined])}
                                placeholder="Nº, Bloco..." 
                                size="lg"
                            />
                        </Form.Group>  

                        <Form.Group className="mt-3">
                            <Form.Label>Complemento:</Form.Label>
                            <Form.Control 
                                name={'COMPLEMENTO'}
                                type={'text'}
                                value={pedido.pedidoEntrega.ENDERECO.COMPLEMENTO ?? ''}
                                onChange={(e) => {
                                    pedido.setPedidoEntrega((prev => ({
                                        ...prev,
                                        ENDERECO: {...prev.ENDERECO, COMPLEMENTO: e.target.value}
                                    })));
                                }}
                                placeholder="Ponto de referência..." 
                                size="lg"
                            />
                        </Form.Group>
                    </Row>
                </div> 
            }
        </>
    )
}

export default CarrinhoEntrega;
import { Outlet, useParams } from "react-router-dom";
import useCardapio from "../../Hooks/Cardapio";
import { useContext } from "react";
import { CtxParams } from "../../Contexts/ctxParams";

const Cardapio = () => {
    const { cdCategoria, cdEmpresa, nrMesa, salvaDados, garcom } = useParams();
    const UserParams = useContext(CtxParams);
    const Cardapio = useCardapio(
        UserParams.CD_EMPRESA ? String(UserParams.CD_EMPRESA) : cdEmpresa, 
        cdCategoria, 
        nrMesa === '0' ? undefined : nrMesa,
        salvaDados === 'false' ? false : true,
        garcom === 'true' ? true : garcom === undefined ? undefined : false
    );

    return (
        <Outlet context={Cardapio} />
    )
}

export default Cardapio;
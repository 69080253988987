import { useContext } from "react";
import qz, { configs, PrintData } from 'qz-tray';
import { IPedido } from "../../Models/pedido";
import ImpressaoPedido from "./ImpressaoPedido";
import ImpressaoEntrega from "./ImpressaoEntrega";
import ImpressaoMesa from "./ImpressaoMesa";
import { IMesa } from "../../Models/mesa";
import { IEntrega } from "../../Models/cadEntrega";
import useApp from "../../Hooks/utils/useApp";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";
import { IPrinterParams } from "../../Models/ctxParams";
import { CtxParams } from "../../Contexts/ctxParams";
import forge from 'node-forge';

const ImpressoraQzTray = () => {
    const { Modal } = useApp();
    const UserParams = useContext(CtxParams);

    const selecionaImpressora = async (): Promise<string | null> => {
        return new Promise(async (resolve) => {
            const listaImpressoras = await qz.printers.find() as string[];
            //qz.print
            Modal({
                Visibilidade: true,
                Titulo: 'Configuração de Impressora',
                Descricao: `Selecione a impressora:`,
                Anexos: undefined,
                Inputs: [
                    {
                        ID: 'fldImpressora',
                        Label: 'Tipo de Impressora: ',
                        Placeholder: '',
                        Type: 'select',
                        Value: 'QZ-TRAY',
                        Options: listaImpressoras.map((impressora: string) => ({
                            text: impressora,
                            value: impressora
                        }))
                    }
                ],
                Variante: 'primary',
                onSubmit: (fields: ICtxAppParams_ModalInputs) => {
                    const config: IPrinterParams = {
                        CONEXAO: 'WebUSB',
                        TIPO: 'QZ-TRAY',
                        NOME: String(fields[0].Value) as 'QZ-TRAY'
                    };

                    localStorage.setItem('impressora', JSON.stringify(config));

                    UserParams.setIMPRESSORA!(config);

                    resolve(config.NOME ?? '');
                },
                onCancel: () => {
                    resolve(null);  // Caso o usuário cancele a operação
                }
            });
        });
    };

    const conexaoQZTray = async () => {
        try {
            qz.security.setCertificatePromise((resolve, reject) => {
                //Lógica para obter o certificado
                //const cert: string = await loadCertificateFromFile(); // Exemplo
                const cert: string = process.env.REACT_APP_IMPRESSORACRT as string     
                resolve(cert);
            }, { rejectOnFailure: true });
            qz.security.setSignatureAlgorithm('SHA256');
            qz.security.setSignaturePromise((dataToSign) => {
                return (resolve, reject) => {
                    // Crie a assinatura usando node-forge
                    const privateKeyPem = process.env.REACT_APP_IMPRESSORAPK as string;
                    const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
                    const md = forge.md.sha256.create();
                    md.update(dataToSign, 'utf8');
                    const signature = privateKey.sign(md);
                    const signatureBase64 = forge.util.encode64(signature);
                    resolve(signatureBase64); // Retorne a assinatura em Base64
                };
            });
            
            
            const algorithm = qz.security.getSignatureAlgorithm();
            console.log("Algoritmo de assinatura:", algorithm);

            await qz.websocket.connect();
            console.log("Impressora reconhecida!");
        } catch (error) {
            alert("Erro ao conectar ao QZ Tray:" + error);
        }
    };

    const imprimir = async (Dados: any, Tipo: 'PEDIDO' | 'ENTREGA' | 'MESA', UsarImpressora: boolean = true, Colunas: number = 56) => {
        let dados: string;
        const Impressao = async () => {
            const impressaoPedido = Tipo === 'PEDIDO' 
                ? ImpressaoPedido(Dados as IPedido, Colunas) 
                : Tipo === 'MESA' 
                    ? ImpressaoMesa(Dados as IMesa, Colunas)
                    : ImpressaoEntrega(Dados as IEntrega, Colunas);

            if (UsarImpressora) {
                try {
                    console.log(UserParams.IMPRESSORA?.NOME);
                    dados = impressaoPedido.cabecalho  + impressaoPedido.itens + '\n\n\n\n\n\n\n';

                    let config = qz.configs.create(UserParams.IMPRESSORA?.NOME || '', {encoding: 'Cp850', });
                    let dado = ['\x1B' + '\x74' + '\x02' + dados]//'\x1B\x21\x10' + 'testéÁãóõÕáíúÚÓÍ']

                    try {
                        await qz.print(config, [{type: 'raw', data: dado + '', flavor:'plain', format: 'command'}]);
                        console.log("Impressão enviada com sucesso!");
                    } catch (error) {
                        alert("Erro ao imprimir:" + error);
                    }

                } catch (error) {
                    alert('Não foi possível imprimir: ' + error);
                }
            } else {
                console.log(`%c${impressaoPedido.cabecalho}${impressaoPedido.itens}`, 'font-family: courier new');
            }
        };

        

        if (!qz.websocket.isActive()) {

            await conexaoQZTray().then(async () => {
                await selecionaImpressora().then(() => {
                    Impressao()
                })
            })
        }else {
            if(UserParams.IMPRESSORA?.NOME === '' || UserParams.IMPRESSORA?.NOME === undefined){
                await selecionaImpressora().then(() => {
                    Impressao()
                })
            }else{
                Impressao()
            }
        }
    };

    return {
        imprimir
    };
};



export interface IImpressoraQzTray {
    Imprimir : (Dados: IPedido, Tipo: 'PEDIDO' | 'ENTREGA', ambiente?: boolean, Colunas?: number) => Promise<void>;
}

export default ImpressoraQzTray;
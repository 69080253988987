import { useEffect } from "react";
import { IPagina, Paginas } from "../../routes";
import useFormaPagamentoController, { IUseFormaPagamentoController } from "./controller";
import useFormaPagamentoDatasource, { IUseFormaPagamentoDatasource } from "./dataSource";
import useFormaPagamentoGrid, { IUseFormaPagamentoGrid } from "./grid";
import useFormaPagamentoModal, { IUseFormaPagamentoModal } from "./modal";

const useFormaPagamento = (CdForma?: string) : IUseFormaPagamento => {
    const Pagina        = Paginas[0];
    const Controller    = useFormaPagamentoController(Pagina.Alias, true, true);
    const Codigo        = CdForma;
    const Datasource    = useFormaPagamentoDatasource(Controller);
    const Modal         = useFormaPagamentoModal(Controller);
    const Grid          = useFormaPagamentoGrid(Modal);

    useEffect(() => {
        if (Codigo !== undefined) {
            if (Datasource.Item.CD_FORMA !== Number(Codigo)) {
                Controller.Buscar.mutate({CD_FORMA: Number(Codigo)});
            }
        }
    }, [Codigo]); //eslint-disable-line

    return {
        Controller  : Controller,
        Codigo      : Codigo,
        Datasource  : Datasource,
        Grid        : Grid,
        Pagina      : Pagina,
        Modal       : Modal
    }
}

export interface IUseFormaPagamento {
    Controller  : IUseFormaPagamentoController;
    Codigo      : string | undefined;
    Datasource  : IUseFormaPagamentoDatasource;
    Grid        : IUseFormaPagamentoGrid;
    Pagina      : IPagina;
    Modal       : IUseFormaPagamentoModal;
}

export default useFormaPagamento;
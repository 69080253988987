import { useMemo } from "react"
import { IReactTable_Grid } from "../../Models/genericInterfaces";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { IUseFormaPagamentoModal } from "./modal";
import { IFormaPagamento } from "../../Models/FormaPagamento";

const useFormaPagamentoGrid = (Modal: IUseFormaPagamentoModal) : IUseFormaPagamentoGrid => {
    const Lista = useMemo<IReactTable_Grid>(() => [
        {
            id: "NOME",
            Header: "Forma de Pagamento",
            accessor: "NOME"
        },   
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <Button 
                        variant="danger"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            // setSelecionado(row.row.original);
                            Modal.ExcluirForma(row.row.original as IFormaPagamento);
                        }}
                    >
                        <FaTrashAlt />
                    </Button>
                </div>
        },
    ], [Modal]);

    return {
        Lista   : Lista
    }
}

export interface IUseFormaPagamentoGrid {
    Lista   : IReactTable_Grid;
}

export default useFormaPagamentoGrid;
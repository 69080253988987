import { ListGroup } from 'react-bootstrap';
import { useContextMenu } from './useContextMenu';
import styles from './ContextMenu.module.scss';
import { FaCaretRight, FaTimes } from 'react-icons/fa';
import React, { useCallback, useRef, useState } from 'react';
import { ParentInstruction } from './interfaces';
import useMask from '../../../Hooks/utils/useMask';

export interface item {
    name: string;
    icon?: any;
    caption: string;
    action?: (internalContext: any) => void;
    children?: Array<item>;
    disabled?: boolean;
}

export interface Props {
    context?: any;
    header?: (internalContext: any) => string;
    parent?: ParentInstruction;
    items: (internalContext: any) => Array<item | undefined>;
};

const ContextMenu = ({ context, header, items, parent}: Props) => {
    const { anchorPoint, isShown, internalContext, handleClose } = useContextMenu(context, parent);
    const [selectedItemIndex, setSelectedItemIndex] = useState<number | undefined>();
    const formatter = useMask();
    const self = useRef<any>();
    const level : number = !(!!parent) ? 0 : (parent.level + 1);

    const handleItemFocus = useCallback((index: number) => {
        setSelectedItemIndex(index);
    }, []);  

    const positionStyle = () : React.CSSProperties => {
        if (!(!!parent) && anchorPoint.x > (window.innerWidth / 1.4)) {
            return {
                top: anchorPoint.y, 
                right: window.innerWidth - anchorPoint.x
            }
        } else if ((!!parent) && (parent.absolute.x > (window.innerWidth / 1.5))) {
            return {
                top: anchorPoint.y, 
                right: anchorPoint.x
            }
        } else {
            return {
                top: anchorPoint.y, 
                left: anchorPoint.x
            }
        }
    }

    if (!isShown) {
        return null;
    }  

    return (
        <ListGroup 
            ref={self} 
            onMouseDown={(e) => e.stopPropagation()} 
            className={styles.ContextMenu} 
            style={positionStyle()}
        >
            {
                header !== undefined ?
                    <ListGroup.Item 
                        key={'header'} 
                        variant='primary' 
                        onMouseDown={e => e.stopPropagation()}
                        onMouseEnter={(e) => handleItemFocus(-1)}
                        style={{cursor: 'text', userSelect: 'text'}}
                        className="d-flex justify-content-between align-items-center"
                    >
                        {formatter.setOverflow(header(internalContext), 22)}
                        <FaTimes 
                            style={{cursor: 'pointer', zoom: '1.1'}} 
                            onMouseDown={() => handleClose()} 
                        />
                    </ListGroup.Item>
                :
                    null
            }

            {items(internalContext).map((item, index) => {
                if (item) {
                    const disabled = !!item.disabled ? item.disabled : false;

                    return (
                        <React.Fragment key={item.name}>
                            <ListGroup.Item 
                                action={!!item.children ? undefined : true}
                                key={item.name}
                                variant={selectedItemIndex === index ? 'secondary' : ''}
                                style={{
                                    cursor: 'pointer',
                                    display: 'grid',
                                    gridTemplateColumns: '1rem auto 1rem',
                                    gap: '0.5rem',
                                    alignItems: 'center',
                                }}
                                disabled={disabled}
                                onMouseEnter={() => {
                                    if (!!item.children) {
                                        handleItemFocus(index)
                                    } else {
                                        handleItemFocus(-1)
                                    }
                                }}
                                onMouseDown={(e) => {
                                    if (disabled) {
                                        e.stopPropagation();
                                    };

                                    if (item.action !== undefined) {
                                        item.action(internalContext);
                                        handleClose();
                                    } else if (item.children !== undefined) {
                                        e.stopPropagation();
                                        if (selectedItemIndex === -1) {
                                            handleItemFocus(index);
                                        } else {
                                            handleItemFocus(-1);
                                        }
                                    } else {
                                        e.stopPropagation();
                                    };
                                }
                            }>
                                {item.icon !== undefined ? item.icon : <div></div>}
                                {item.caption}
                                {item.children !== undefined && (
                                    <FaCaretRight 
                                        style={{zoom: '1.1', color: 'GrayText'}} 
                                    />
                                )}
                            </ListGroup.Item>   

                            {item.children && (
                                <div>
                                    <ContextMenu 
                                        parent={
                                            {
                                                show: selectedItemIndex === index,
                                                absolute: {
                                                    x: level !== 0 && anchorPoint.x !== parent?.absolute.x ? parent!.absolute.x : anchorPoint.x,
                                                    y: level !== 0 && anchorPoint.y !== parent?.absolute.y ? parent!.absolute.y : anchorPoint.y
                                                },
                                                anchor: {
                                                    x: !!self.current 
                                                        ? self.current.getBoundingClientRect().width 
                                                        : 0,
                                                    y: !!self.current 
                                                        ? ( ((self.current.getBoundingClientRect().height / (self.current.childElementCount - 1))
                                                            * (selectedItemIndex! + 1))
                                                            - (parent?.anchor.y ?? 0) )
                                                        : 0
                                                },
                                                level: level // TODO: level 3 está dando problema no anchor y
                                            }
                                        }
                                        context={internalContext} 
                                        items={internalContext => item.children!} 
                                    />
                                </div>
                            )}
                        </React.Fragment>                 
                    )
                } else {
                    return (null)
                }
            })}
        </ListGroup>
    );
};

export { ContextMenu };

import { useFormikContext } from "formik";
import { IUseProduto } from "../../../Hooks/Produto";
import { EProdutoTipo, IProduto } from "../../../Models/cardapio";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { IUseCardapio } from "../../../Hooks/Cardapio";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    Produto: IUseProduto;
    Categoria: IUseCardapio;
    Confirmado: boolean;
};

const CardapioProdutoCaixa = (props: IProvider) => {
    // const { Produto } = props;
    const { values, handleChange } = useFormikContext<IProduto>();

    return (
        values.TIPO === EProdutoTipo.Produto ?
            <Accordion className="mt-3 mb-5">
                <Accordion.Item eventKey="1" onClick={() => {
                    setTimeout(() => {
                        document.getElementById('fldQtdCaixa')?.scrollIntoView()
                    }, 500);
                }}>
                    <Accordion.Header>{
                        values.TIPO === EProdutoTipo.Produto 
                        ? `Caixa` 
                        : ``
                    }</Accordion.Header>
                    <Accordion.Body>
                        <Row className="mb-3">
                            <Col xs={5}>
                                <Form.Group>
                                    <Form.Label>Quantidade por Caixa:</Form.Label>
                                    {/* <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} /> */}
                                    <Form.Control 
                                        id={'fldQtdCaixa'}
                                        name={'CAIXA_QTD'}
                                        type={'number'}
                                        min={1}
                                        value={values.CAIXA_QTD ?? ""}
                                        onChange={handleChange}
                                        size="lg"
                                    />
                                </Form.Group> 
                            </Col>

                            <Col xs={7}> 
                                <Form.Group>
                                    <Form.Label>Identificador:</Form.Label>
                                    {/* <BadgeValidacao validacao={errors.DISPONIVEL} confirmado={confirmado} /> */}
                                    <Form.Control
                                        name={'CAIXA_NOME'}
                                        type={'text'}
                                        value={values.CAIXA_NOME ?? ""}
                                        onChange={handleChange}
                                        disabled={Number(values.CAIXA_QTD) <= 1}
                                        size="lg"
                                    />
                                </Form.Group>  
                            </Col>

                            
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        : <div className="mb-5"></div>
    )
}

export default CardapioProdutoCaixa;
import { useContext } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdExitToApp } from "react-icons/md";
import { CtxParams } from '../../Contexts/ctxParams';
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { Operacional } from "./operacional";
import { Cadastros } from "./cadastros";
import { Gestor } from "./gestor";
import { CardapioHeaderOptions } from "./cardapio";
import useUtils from "../../Hooks/utils/useUtils";
import useApp from "../../Hooks/utils/useApp";

const Header = () => {
    const AppParams = useContext(CtxAppParams);
    const UserParams = useContext(CtxParams);
    const { Deslogar } = useUtils();
    const App = useApp();

    return (
        <>
            {[false].map((expand: any) => (
                <Navbar key={expand} bg="dark" variant="dark" expand={expand} collapseOnSelect className="mb-3" sticky="top">
                    <Container fluid>
                        <Navbar.Brand>
                            {AppParams.Pagina}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title style={{fontSize: '1.8rem'}} id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Menu
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                    
                            <Offcanvas.Body>
                                {
                                    AppParams.Logado ?
                                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{fontSize: '1.5rem'}}>
                                            <Operacional AppParams={AppParams} UserParams={UserParams} />
                                            <Cadastros AppParams={AppParams} UserParams={UserParams} />
                                            <Gestor AppParams={AppParams} UserParams={UserParams} />
                                            <h1><hr /></h1>
                                            <Nav.Link 
                                                className="d-flex align-items-center justify-content-start"
                                                href="/"
                                                onClick={() => Deslogar()}
                                            >
                                                <MdExitToApp style={{fontSize: '1.9rem', width: '3rem'}} />
                                                Sair
                                            </Nav.Link>
                                        </Nav>
                                    :
                                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{fontSize: '1.5rem'}}>
                                            <CardapioHeaderOptions AppParams={AppParams} UserParams={UserParams} App={App} />
                                        </Nav>
                                }
                                
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
};

export default Header;
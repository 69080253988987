
const useUtils = () => {
    const deslogar = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('params');
        localStorage.removeItem('token');
        localStorage.removeItem('params');

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            };

            if ('serviceWorker' in navigator && 'ready' in navigator.serviceWorker) {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.update();
                })
                .catch((error) => {
                    console.error('Erro ao atualizar aplicação: ', error);
                });
            }

            window.location.reload();
        };

        refreshCacheAndReload();
    };

    return {
        Deslogar: deslogar
    }
}

export default useUtils;
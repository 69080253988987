import { Accordion, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ICtxParams } from "../../Models/ctxParams";
import { ICtxAppParams } from "../../Contexts/ctxAppParams";
import { TbReceiptTax } from "react-icons/tb";
import { FaUserAlt } from "react-icons/fa";
import { IoFastFoodOutline } from "react-icons/io5";
import { BiFoodMenu } from "react-icons/bi";
import { MdOutlinePayments  } from "react-icons/md";

interface Props {
    AppParams   : ICtxAppParams;
    UserParams  : ICtxParams;
};

export const Cadastros = ({AppParams, UserParams}: Props) => {
    const navigate = useNavigate();

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey="menuCadastros">
                    <Accordion.Header>Cadastros</Accordion.Header>
                    <Accordion.Body>
                        <Nav.Link 
                            href="#"
                            onClick={() => navigate("/listaTaxa")} 
                            className="d-flex align-items-center justify-content-start"
                        >
                            <TbReceiptTax style={{fontSize: '2rem', width: '3rem'}} />
                            Taxas de Entrega
                        </Nav.Link>

                        { // Somente GESTOR
                            !UserParams.ENTREGADOR ? 
                                <>
                                    <Nav.Link 
                                        href="#"
                                        onClick={() => navigate(`/cardapio/${UserParams.CD_EMPRESA}`)} 
                                        className="d-flex align-items-center justify-content-start"
                                    >
                                        <BiFoodMenu style={{fontSize: '1.8rem', width: '3rem'}} />
                                        Cardápio
                                    </Nav.Link>

                                    <Nav.Link 
                                        href="#"
                                        onClick={() => navigate(`/produto/${UserParams.CD_EMPRESA}`)} 
                                        className="d-flex align-items-center justify-content-start"
                                    >
                                        <IoFastFoodOutline style={{fontSize: '2rem', width: '3rem'}} />
                                        Produtos
                                    </Nav.Link>

                                    <Nav.Link 
                                        href="#"
                                        onClick={() => navigate(`/formaPagamento`)} 
                                        className="d-flex align-items-center justify-content-start"
                                    >
                                        <MdOutlinePayments style={{fontSize: '1.9rem', width: '3rem'}} />
                                        Formas de Pagto
                                    </Nav.Link>
                                    
                                    <Nav.Link 
                                        href="#"
                                        onClick={() => navigate("/listaCliente")} 
                                        className="d-flex align-items-center justify-content-start"
                                    >
                                        <FaUserAlt style={{fontSize: '1.5rem', width: '3rem'}} />
                                        Clientes
                                    </Nav.Link>
                                </>
                            :
                                null
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};
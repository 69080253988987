
import { UseMutationResult, UseQueryResult, useMutation, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import useControllers from "../utils/useControllers";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IFormaPagamentos } from "../../Models/FormaPagamento";
import { IVendaPagamento, IVendaPagamentos } from "../../Models/VendaPagamento";
import useFormaPagamentoController from "../FormaPagamento/controller";


const useVendaPagamentoController = (updateQueryKey?: string, isArray: boolean = true, enableQuery: boolean = false) : IUseVendaPagamentoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Delete, Create } = useControllers();
    const { Listar } = useFormaPagamentoController(updateQueryKey! + 'Forma', true, true)

    const primaryKey = 'CD_PAGTO';
    const endpoint = 'vendaPagto';

    const mutDeletar = useMutation(
        (data : DeepPartial<IVendaPagamento>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
        }, {
            onSuccess: (ret: any, data: DeepPartial<IVendaPagamento>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IVendaPagamentos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IVendaPagamento>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IVendaPagamento>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IVendaPagamento>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['Criado']);
                                //TODO: detectar se é uma entrega, mesa ou pedido e atualizar a query
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IVendaPagamento>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IVendaPagamento) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    return {
        Deletar: mutDeletar,
        Cadastrar: mutCadastrar,
        Buscar: mutBuscar,
        ListarFormas: Listar,
    }
}

export interface IUseVendaPagamentoController {
    Deletar     : UseMutationResult<any, any, DeepPartial<IVendaPagamento>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IVendaPagamento>, unknown>;
    Buscar      : UseMutationResult<IVendaPagamento, any, DeepPartial<IVendaPagamento>, unknown>
    ListarFormas: UseQueryResult<IFormaPagamentos, unknown>;
}

export default useVendaPagamentoController;
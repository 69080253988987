import useAPI from "../utils/useAPI";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { ICadCliente, ICadClientes } from "../../Models/cadCliente";
import { useNavigate } from "react-router-dom";
import useMask from "../utils/useMask";
import { IReactTable_Grid } from "../../Models/genericInterfaces";
import { CtxAppParams } from "../../Contexts/ctxAppParams";

const useCadClienteLista = () : IUseCadClienteLista => {
    const API = useAPI();
    const navigate = useNavigate();
    const formatter = useMask();
    const { Filtros } = useContext(CtxAppParams);

    const qryLista = useQuery<ICadClientes>(
        'listaCliente',(
        () => API.get('clientes/buscarTodos/', '', false)),
        {}
    );

    const dsLista = useMemo<ICadClientes>(() => 
        qryLista.isSuccess ? 
            qryLista.data.filter((data: ICadCliente) => {
                return formatter.getNormalized(data[Filtros.Cliente.tipoPesquisa] ?? "")?.toLowerCase().indexOf(formatter.getNormalized(Filtros.Cliente.pesquisa)?.toLowerCase()) !== -1
            })
        : 
            [{} as ICadCliente]
    , [qryLista, Filtros.Cliente, formatter]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            Header: "Nome",
            accessor: "NOME"
        },
        {
            Header: "Celular",
            accessor: "CELULAR",
            Cell: (row: any) : any => `${formatter.setMaskPhone(row.row.original['CELULAR'])}`
        }
    ], [formatter]);

    const editar = (Cliente: ICadCliente) => {
        navigate(`/cadCliente/${Cliente.CELULAR}`)
    };

    const cadastrar = () => {
        navigate('/cadCliente');
    };

    return {
        Lista: dsLista,
        Grid: gridLista,
        Editar: editar,
        Cadastrar: cadastrar
    };
};

export interface IUseCadClienteLista {
    Lista               : ICadClientes;
    Grid                : IReactTable_Grid;
    Editar              : (Cliente: ICadCliente) => void;
    Cadastrar           : () => void;
};

export default useCadClienteLista;
import { Formik } from "formik";
import { every } from "lodash";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import FormRodape from "../../../Components/formularios/FormRodape";
import { IUseCadUsuario } from "../../../Hooks/Empresa/EmpresaUsuario";
import useMask from "../../../Hooks/utils/useMask";
import { ICadUsuario, schemaAltUsuario, schemaCadUsuario } from "../../../Models/cadUsuario";
import { useContext, useEffect } from "react";
import { CtxParams } from "../../../Contexts/ctxParams";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { useOutletContext } from "react-router-dom";

const CadUsuario = () => {
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const UserParams = useContext(CtxParams);
    const CadastroUsuario = useOutletContext<IUseCadUsuario>();
    const formatter = useMask();
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Cadastro de Usuário");
    });

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={CadastroUsuario.CdUsuario === "-1" ? schemaCadUsuario : schemaAltUsuario}
                onSubmit={(dados : ICadUsuario) => {
                    CadastroUsuario.Confirmar(dados);
                }}
                initialValues={{
                    CD_EMPRESA      : UserParams.CD_EMPRESA,
                    NOME            : CadastroUsuario.dsUsuario.NOME            ?? "",
                    CELULAR         : formatter.setMaskPhone(CadastroUsuario.dsUsuario.CELULAR) ?? "",
                    SENHA           : '',
                    SENHA_CONFIRMA  : '',
                    TAXA            : CadastroUsuario.dsUsuario.TAXA            ?? 0,
                    ENTREGADOR      : CadastroUsuario.dsUsuario.ENTREGADOR      ?? true,
                    COD_COR         : CadastroUsuario.dsUsuario.COD_COR         ?? "#ced4da",
                    TX_ENTREGA      : CadastroUsuario.dsUsuario.TX_ENTREGA      ?? 0,
                    RECEBE_TAXA     : CadastroUsuario.dsUsuario.RECEBE_TAXA     ?? true,
                    GARCOM          : CadastroUsuario.dsUsuario.GARCOM          ?? !CadastroUsuario.dsUsuario.ENTREGADOR ?? true,
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => CadastroUsuario.Cancelar()}>                        
                        <Form.Group className="mt-3">
                            <Form.Label>Nome:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Control 
                                name={'NOME'}
                                type={'text'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.NOME, confirmado])}
                                placeholder="Nome..." 
                                size="lg"
                            />
                        </Form.Group>  

                        <Form.Group className="mt-3">
                            <Form.Label>Celular:</Form.Label>
                            <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} />
                            <InputGroup>
                                <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                <Form.Control 
                                    name={'CELULAR'}
                                    type={'text'}
                                    value={values.CELULAR}
                                    onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                    isInvalid={every([errors.CELULAR, confirmado])}
                                    placeholder="Celular..." 
                                    size="lg"
                                />
                            </InputGroup>
                        </Form.Group>                    

                        <Form.Group className="mt-3">
                            <Form.Label>Senha:</Form.Label>
                            <BadgeValidacao validacao={errors.SENHA} confirmado={confirmado} />
                            <BadgeValidacao validacao={errors.SENHA_CONFIRMA} confirmado={confirmado} />
                            <Form.Control 
                                name={'SENHA'}
                                type={'password'}
                                value={values.SENHA}
                                onChange={handleChange}
                                isInvalid={every([errors.SENHA, confirmado])}
                                placeholder="Senha..." 
                                size="lg"
                            />
                            <Form.Control 
                                className="mt-2"
                                name={'SENHA_CONFIRMA'}
                                type={'password'}
                                value={values.SENHA_CONFIRMA}
                                onChange={handleChange}
                                isInvalid={every([errors.SENHA_CONFIRMA, confirmado])}
                                placeholder="Confirmação..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3 mb-3">

                            <Form.Label>Entregador:</Form.Label>
                            <BadgeValidacao validacao={errors.ENTREGADOR} confirmado={confirmado} />
                            <Form.Check
                                style={{zoom: 1.4}}
                                name="ENTREGADOR"
                                id="ENTREGADOR"
                                type="switch"
                                checked={values.ENTREGADOR}
                                isInvalid={every([errors.ENTREGADOR, confirmado])}
                                //label={values.ENTREGADOR ? 'O Usuário será um Entregador' : ''}
                                onChange={() => {
                                    setFieldValue("ENTREGADOR", !values.ENTREGADOR);
                                    if (!values.ENTREGADOR) {
                                        setFieldValue("GARCOM", false);
                                    }
                                }}
                            />
                            <Form.Label>Garçom:</Form.Label>
                            <BadgeValidacao validacao={errors.GARCOM} confirmado={confirmado} />
                            <Form.Check
                                style={{zoom: 1.4}}
                                name="GARCOM"
                                id="GARCOM"
                                type="switch"
                                checked={values.GARCOM}
                                isInvalid={every([errors.ENTREGADOR, confirmado])}
                                //label={values.GARCOM ? 'O Usuário será um Garçom' : ''}
                                onChange={() => {
                                    setFieldValue("GARCOM", !values.GARCOM)
                                    if (!values.GARCOM) {
                                        setFieldValue("ENTREGADOR", false);
                                    }
                                }}
                            />
                            <Form.Label>
                                O Usuário será um 
                                {
                                    values.GARCOM ? ' Garçom' : values.ENTREGADOR ? ' Entregador' : ' Gestor'
                                }
                            </Form.Label>
                        </Form.Group> 
                        {
                            values.ENTREGADOR ?
                                <>
                                    <Row>
                                        <Col xs={8}>
                                            
                                            <Form.Group className="mt-3">
                                                <Form.Label>Taxa do dia:</Form.Label>
                                                <BadgeValidacao validacao={errors.TAXA} confirmado={confirmado} />
                                                <InputGroup>
                                                    <InputGroup.Text>R$</InputGroup.Text>
                                                    <Form.Control 
                                                        name={'TAXA'}
                                                        type={'number'}
                                                        step="0.5"
                                                        value={values.TAXA}
                                                        onChange={handleChange}
                                                        onBlur={() => setFieldValue("TAXA", formatter.setMaskMoney(values.TAXA ?? 0, "", "."))}
                                                        isInvalid={every([errors.TAXA, confirmado])}
                                                        placeholder="0" 
                                                        size="lg"
                                                    />
                                                </InputGroup>
                                            </Form.Group> 
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Cor do Usuário:</Form.Label>
                                                <BadgeValidacao validacao={errors.COD_COR} confirmado={confirmado} />
                                                <InputGroup>
                                                    <Form.Control 
                                                        name={'COD_COR'}
                                                        type={'color'}
                                                        value={values.COD_COR}
                                                        onChange={handleChange}
                                                        isInvalid={every([errors.COD_COR, confirmado])}
                                                        placeholder="0" 
                                                        size="lg"
                                                    />
                                                </InputGroup>
                                            </Form.Group> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Taxa da Entrega:</Form.Label>
                                                <BadgeValidacao validacao={errors.RECEBE_TAXA} confirmado={confirmado} />
                                                <Form.Check
                                                    style={{zoom: 1.4}}
                                                    name="RECEBE_TAXA"
                                                    id="RECEBE_TAXA"
                                                    type="switch"
                                                    checked={values.RECEBE_TAXA}
                                                    isInvalid={every([errors.RECEBE_TAXA, confirmado])}
                                                    label={values.RECEBE_TAXA ? 'O Usuário receberá a taxa da entrega' : 'O Usuário receberá uma taxa por entrega'}
                                                    onChange={() => setFieldValue("RECEBE_TAXA", !values.RECEBE_TAXA)}
                                                />
                                            </Form.Group>  
                                        </Col>
                                    </Row>
                                    {
                                        !values.RECEBE_TAXA ?
                                            <>
                                                <Row>
                                                    <Col xs={8}>
                                                        <Form.Group className="mt-3">
                                                            <Form.Label>Taxa por entrega:</Form.Label>
                                                            <BadgeValidacao validacao={errors.TX_ENTREGA} confirmado={confirmado} />
                                                            <InputGroup>
                                                                <InputGroup.Text>R$</InputGroup.Text>
                                                                <Form.Control 
                                                                    name={'TX_ENTREGA'}
                                                                    type={'number'}
                                                                    step="0.5"
                                                                    value={values.TX_ENTREGA}
                                                                    onChange={handleChange}
                                                                    onBlur={() => setFieldValue("TX_ENTREGA", formatter.setMaskMoney(values.TX_ENTREGA ?? 0, "", "."))}
                                                                    isInvalid={every([errors.TX_ENTREGA, confirmado])}
                                                                    placeholder="0" 
                                                                    size="lg"
                                                                />
                                                            </InputGroup>
                                                        </Form.Group> 
                                                    </Col>
                                                </Row>
                                            </>
                                        :
                                            null
                                    }
                                </>
                            :
                                null
                        }

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CadUsuario;
import { useMemo } from "react";
import { IUseFormaPagamentoController } from "./controller"
import { IFormaPagamento, IFormaPagamentos } from "../../Models/FormaPagamento";

const useFormaPagamentoDatasource = (Controller: IUseFormaPagamentoController) : IUseFormaPagamentoDatasource => {
    const Lista = useMemo<IFormaPagamentos>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
            return Controller.Listar.data;
        } else {
            return [{} as IFormaPagamento];
        };
    }, [Controller.Listar]);

    const Item = useMemo<IFormaPagamento>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            const itemEncontrado = Lista.find((item) => item.CD_FORMA === Controller.Buscar.data?.CD_FORMA);
        
            if (itemEncontrado) {
                return {
                    ...Controller.Buscar.data,
                    NOME: itemEncontrado.NOME,
                    PAGTO_INTEGRACAO: itemEncontrado.PAGTO_INTEGRACAO && itemEncontrado.PAGTO_INTEGRACAO?.length > 0 ? itemEncontrado.PAGTO_INTEGRACAO : Controller.Buscar.data.PAGTO_INTEGRACAO
                }
            }
            return Controller.Buscar.data;
        } else {
            return {} as IFormaPagamento;
        };
    }, [Controller.Buscar, Lista]);

    return {
        Lista   : Lista,
        Item    : Item
    }
};

export interface IUseFormaPagamentoDatasource {
    Lista   : IFormaPagamentos;
    Item    : IFormaPagamento;
}

export default useFormaPagamentoDatasource;
import { useMemo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMask from "../utils/useMask";
import { CtxParams } from "../../Contexts/ctxParams";
import { IReactTable_Grid } from "../../Models/genericInterfaces";
import { TableState } from "react-table";
import { Badge } from "react-bootstrap";
import useFechamentoController, { IUseFechamentoController } from "./controller";
import useFechamentoModal, { IUseFechamentoModal } from "./modal";
import { IFechamento, IFechamentoItem, IFechamentoItems, IFechamentos } from "../../Models/Fechamento";

const useFechamento = () : IUseFechamento => {
    const navigate = useNavigate();
    const UserParams = useContext(CtxParams);
    const formatter = useMask();
    const Controller = useFechamentoController('listaFechamento', true, true);
    const Modal = useFechamentoModal(Controller);

    const dsLista = useMemo<IFechamentos>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data && Controller.Listar.data.length > 0) {
            return Controller.Listar.data
                .filter(item => item && item.DT_COMPETENCIA) // Filtra itens sem DT_COMPETENCIA
                .sort((a: IFechamento, b: IFechamento) => {
                    const dateA = new Date(a.DT_COMPETENCIA ?? "").getTime();
                    const dateB = new Date(b.DT_COMPETENCIA ?? "").getTime();
                    return dateB - dateA;
            });
        } else {
            return [{} as IFechamento]
        }
    }, [Controller.Listar]);

    const dsItem = useMemo<IFechamento>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            const itemEncontrado = dsLista.find((item: IFechamento) => item.CD_FECHAMENTO === Controller.Buscar.data?.CD_FECHAMENTO);
            if (itemEncontrado) {
                return {
                    ...Controller.Buscar.data,
                    FECHADO: itemEncontrado.FECHADO
                }
            }
            return Controller.Buscar.data
        }
        
        return {} as IFechamento
    }, [Controller.Buscar, dsLista]);

    const dsListaItens = useMemo<IFechamentoItems>(() => {
        if (Controller.ListarItens.isSuccess && Controller.ListarItens.isSuccess) {
            return Controller.ListarItens.data
        } else {
            return [{} as IFechamentoItem]
        }
    }, [Controller.ListarItens]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "DtCompetencia",
            Header: "Resumo",
            Cell: (row: any) => formatter.setDate(
                new Date(
                    row.row.original["DT_COMPETENCIA"] ?? ""
                ), {
                    day: "2-digit",
                    weekday: "short",
                    month: "short",
                    year: "numeric"
                }
            )
        },
        {
            id: "Qtd",
            Header: "Vendas",
            Cell: (row: any) => (
                <div className="w-100">
                    <Badge pill bg="light" text="primary">
                        {row.row.original && row.row.original["PEDIDOS"] ? `Pedidos: ${(row.row.original["PEDIDOS"]["QTD_PEDIDOS"] ?? 0)}` : ''}
                    </Badge>
                    <Badge pill bg="light" text="primary">
                        {row.row.original && row.row.original["MESAS"] ? `Mesas: ${(row.row.original["MESAS"]["QTD_MESAS"] ?? 0)}` : ''}
                    </Badge>
                    <Badge pill bg="light" text="primary">
                        {row.row.original && row.row.original["ENTREGAS"] ? `Entregas: ${(row.row.original["ENTREGAS"]["QTD_ENTREGAS"] ?? 0)}`: ''}
                    </Badge>     
                </div>
            )
        },
        {
            id: "VlrTotal",
            Header: "Recebido",
            Cell: (row: any) => (
                <div className="w-100 d-flex justify-content-end">
                    {row.row.original && row.row.original["PEDIDOS"] && row.row.original["MESAS"] && row.row.original["ENTREGAS"] ? formatter.setMaskMoney(
                        ((row.row.original["PEDIDOS"]["VLR_TOTAL"] ?? 0) +
                        (row.row.original["MESAS"]["VLR_TOTAL"] ?? 0) +
                        (row.row.original["ENTREGAS"]["VLR_TOTAL"] ?? 0)) -
                        ((row.row.original["ENTREGAS"]["TAXA"] ?? 0))
                        ?? 0
                    , 'R$') : ''}
                </div>
            )
        },
    ], [formatter]);

    const ver = (Fechamento: IFechamento) => {
        navigate(`/entrega/${Fechamento.DT_COMPETENCIA}`);
    };
    
    const tableInitialState : Partial<TableState<object>> = {
        hiddenColumns: UserParams.ENTREGADOR 
            ? ['QtdEntregas', 'VlrTotal', 'Taxa', 'TaxaEntregas', 'TaxaUsuarios'] 
            : []
    }

    useEffect(() => {
        if (Controller.Listar.isFetchedAfterMount && Controller.Listar.isSuccess && Controller.Buscar.isIdle && dsLista[0].CD_FECHAMENTO) {
            Controller.Buscar.mutate({DT_COMPETENCIA: dsLista[0].DT_COMPETENCIA})
        }
    }, [Controller.Listar, Controller.Buscar, dsLista]);

    return {
        Ver             : ver,
        Lista           : dsLista,
        Grid            : gridLista,
        Item            : dsItem,
        Controller      : Controller,
        EstadoInicial   : tableInitialState,
        Modal           : Modal,
        ListaItens      : dsListaItens
    }
}

export interface IUseFechamento {
    Ver                     : (Fechamento: IFechamento) => void;
    Lista                   : IFechamentos;
    Grid                    : IReactTable_Grid;
    Item                    : IFechamento;
    Controller              : IUseFechamentoController;
    EstadoInicial           : Partial<TableState<object>>;
    Modal                   : IUseFechamentoModal;
    ListaItens              : IFechamentoItems;
}

export default useFechamento;
import useAPI from "../utils/useAPI";
import { useQuery } from 'react-query';
import { useMemo, useContext } from "react";
import useMask from "../utils/useMask";
import { IRanking, IRankings } from "../../Models/ranking";
import { CtxParams } from "../../Contexts/ctxParams";
import { IReactTable_Grid } from "../../Models/genericInterfaces";
import { TableState } from "react-table";
import { Badge } from "react-bootstrap";
import moment from "moment";

const useRanking = (DtCompetencia: string) : IUseRanking => {
    const DataValida = moment(DtCompetencia, "YYYY-MM-DD", true).isValid();
    const API = useAPI();
    const formatter = useMask();
    const UserParams = useContext(CtxParams);

    const qryLista = useQuery(
        'listaRanking',
        (() => API.get('ranking/buscar/', DtCompetencia, false)),
        {
            enabled: DataValida
        }
    );

    const dsLista = useMemo<IRankings>(() => 
        qryLista.isSuccess ? 
            qryLista.data.map((rank: IRanking) => {
                return {
                    ...rank,
                    OFF_REACT_TABLE_PROPS: {
                        color: rank.COD_COR
                    }
                }
            })
        : 
            [{
                "CD_USUARIO": 0,
                "NOME": "",
                "QTD_ENTREGAS": 0,
                "VLR_TOTAL": 0,
                "VLR_ENTREGAS": 0,
                "VLR_USUARIO": 0
            }]
    , [qryLista]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "Posicao",
            Header: "#",
            Cell: (row: any) : any => (row.row.index + 1)+'º'
        },
        {
            id: "Nome",
            Header: "Nome",
            accessor: "NOME"
        },
        {
            id: "QtdEntregas",
            Header: "Entregas",
            Cell: (row: any) : any => row.row.original['QTD_ENTREGAS'] ?? 0
        },
        {
            id: "Valores",
            Header: "Taxas",
            Cell: (row: any) : any => (
                <div className="d-flex flex-column align-items-between justify-content-center">
                    <Badge pill bg="dark">Entregas: {formatter.setMaskMoney(row.row.original['VLR_ENTREGAS'] ?? 0, 'R$')}</Badge>
                    <Badge pill bg="warning" text="dark">Usuario: {formatter.setMaskMoney(row.row.original['VLR_USUARIO'] ?? 0, 'R$')}</Badge>
                    <Badge pill bg="light" text="dark">Adicional: {formatter.setMaskMoney(row.row.original['VLR_ADICIONAL'] ?? 0, 'R$')}</Badge>
                </div>
            ),
        },
        {
            id: "VlrTotal",
            Header: "Total",
            Cell: (row: any) : any => (
                <div className="w-100 d-flex justify-content-end text-nowrap">
                    {formatter.setMaskMoney(row.row.original['VLR_TOTAL'] ?? 0, 'R$')}
                </div>
            ),
        },
    ], [formatter]);

    const tableInitialState : Partial<TableState<object>> = { 
        hiddenColumns: UserParams.ENTREGADOR 
            ? ['VlrTotal', 'Valores'] 
            : [] 
    };

    return {
        Lista: dsLista,
        Grid: gridLista,
        EstadoInicial: tableInitialState
    }
}

export interface IUseRanking {
    Lista           : IRankings;
    Grid            : IReactTable_Grid;
    EstadoInicial   : Partial<TableState<object>>;
};

export default useRanking;
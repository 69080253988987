import useApp from "./Hooks/utils/useApp";
import { ErrorBoundary } from "react-error-boundary";

const Provider = (props: any) => {
    const App = useApp();

    return (
        <ErrorBoundary FallbackComponent={App.Fallback}>
            {props.children}
        </ErrorBoundary>
    )
}

export default Provider;
import { useEffect, Dispatch, SetStateAction } from "react";
import { useFormikContext } from "formik";
import { ICadCliente } from "../../Models/cadCliente";
import { IClienteEndereco } from "../../Models/cadClienteEndereco";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import { IUseCadCliente } from "../../Hooks/cadCliente";
import useMask from "../../Hooks/utils/useMask";

interface IProvider extends React.HTMLAttributes<HTMLDivElement> {
    editandoEndereco: boolean;
    setEditandoEndereco: Dispatch<SetStateAction<boolean>>;

    confirmado: boolean;
    setConfirmado: Dispatch<SetStateAction<boolean>>;

    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;

    handleEnderecoPadrao: IClienteEndereco;
    setHandleEnderecoPadrao?: Dispatch<SetStateAction<IClienteEndereco>>;

    customHook: IUseCadCliente;
};

const CadClienteProvider = (props: IProvider) => {
    const { values, errors, setFieldValue } = useFormikContext<ICadCliente>();
    const { confirmado, editandoEndereco, customHook, setConfirmado } = props;
    const formatter = useMask();

    useEffect(() => { // Se foi confirmado e está válido e clicou pra editar o endereço, vai confirmar o cadastro
        //debugger
        if (confirmado && (Object.keys(errors).length === 0) && editandoEndereco) {
            customHook.Confirmar(values);
            setConfirmado(false); // Volta o confirmado pra não salvar de novo e entrar em loop
        };
    }, [confirmado, editandoEndereco, setConfirmado, values, errors]) //eslint-disable-line

    return (
        <>
            <ModalConfirmacao 
                titulo="Alterando endereço padrão"
                show={props.showModal} 
                onHide={props.setShowModal}
                variant={"primary"}
                onSubmitHandler={() => {
                    setFieldValue("CD_ENDERECO_PADRAO", props.handleEnderecoPadrao.CD_CLIENTE_ENDERECO);
                    props.setShowModal(false);
                }}
            >
                Deseja alterar o endereço padrão para {formatter.getEnderecoString(props.handleEnderecoPadrao)} ?
            </ModalConfirmacao>

            {props.children}
        </>
    )
}

export default CadClienteProvider;
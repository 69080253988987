import { IPedido } from '../../../Models/pedido';
import ImpressaoPedido from './ImpressaoPedido';
import { IEntrega } from '../../../Models/cadEntrega';
import ImpressaoEntrega from './ImpressaoEntrega';
import ImpressaoMesa from './ImpressaoMesa';
import { IMesa } from '../../../Models/mesa';

const ImpressoraESCPOSMobile = () => {
    // Mapeamento de UTF-8 para CP437 usando Alt + número
    const utf8ToCP437Map: Record<string, number> = {
        'ç': 135, 'Ç': 128,
        'á': 160, 'Á': 181,
        'é': 130, 'É': 144,
        'í': 161, 'Í': 214,
        'ó': 162, 'Ó': 224,
        'ú': 163, 'Ú': 233,
        'ã': 198, 'Ã': 199,
        'õ': 228, 'Õ': 229,
        'â': 131, 'Â': 182,
        'ê': 136, 'Ê': 210,
        'ô': 147, 'Ô': 153,
        'à': 133, 'À': 183,
        'ü': 129, 'Ü': 154,
        'ñ': 164, 'Ñ': 165,
        'Ÿ': 159,
        '€': 128, '©': 169, '®': 174, '°': 248, '½': 190, '¼': 189
    };

    const Imprimir = async (Dados: any, Tipo: 'PEDIDO' | 'ENTREGA' | 'MESA', UsarImpressora: boolean = true, Colunas: number = 40) => {
        const Impressao = async () => {
            const impressaoPedido = Tipo === 'PEDIDO' 
                ? ImpressaoPedido(Dados as IPedido, Colunas) 
                : Tipo === 'MESA' 
                    ? ImpressaoMesa(Dados as IMesa, Colunas)
                    : ImpressaoEntrega(Dados as IEntrega, Colunas);

            if (UsarImpressora) {
                try {
                    const ESC_POS_FONT_SMALL = new Uint8Array([0x1B, 0x4D, 0x01]);

                    // Função para converter string UTF-8 para CP437 usando Alt + número
                    const stringToCP437Bytes = (str: string): Uint8Array => {
                        const cp437Array = new Uint8Array(str.length);
                        for (let i = 0; i < str.length; i++) {
                            const char = str[i];
                            cp437Array[i] = utf8ToCP437Map[char] !== undefined ? utf8ToCP437Map[char] : char.charCodeAt(0);
                        }
                        return cp437Array;
                    };

                    const mergeArrays = (...arrays: Uint8Array[]): Uint8Array => {
                        let totalLength = arrays.reduce((acc, curr) => acc + curr.length, 0);
                        let mergedArray = new Uint8Array(totalLength);
                        let offset = 0;

                        arrays.forEach(array => {
                            mergedArray.set(array, offset);
                            offset += array.length;
                        });

                        return mergedArray;
                    };

                    const encodeToBase64 = (bytes: Uint8Array): string => {
                        return Buffer.from(bytes).toString('base64');
                    };

                    const printWithRawBT = (text: string) => {
                        const cp437BytesToPrint = stringToCP437Bytes(text);
                        const printData = mergeArrays(ESC_POS_FONT_SMALL, cp437BytesToPrint);
                        const base64ToPrint = encodeToBase64(printData);
                        const url = `rawbt:base64,${base64ToPrint}`;
                        window.location.href = url;
                    };

                    printWithRawBT(impressaoPedido.cabecalho + impressaoPedido.itens);

                } catch (error) {
                    alert('Não foi possível imprimir: ' + error);
                }
            } else {
                console.log(`%c${impressaoPedido.cabecalho}${impressaoPedido.itens}`, 'font-family: courier new');
            }
        };

        Impressao();
    };

    return {
        Imprimir
    };
};

export interface IImpressoraESCPOS {
    Imprimir: (Dados: IPedido, Tipo: 'PEDIDO' | 'ENTREGA', UsarImpressora?: boolean, Colunas?: number) => Promise<void>;
}

export default ImpressoraESCPOSMobile;

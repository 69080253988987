import useAPI from "../utils/useAPI";
import { useMutation, useQuery } from 'react-query';
import { useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ICadPagtoQr, ICadPagtoQrs } from "../../Models/cadPagtoQr";
import { Button, Card } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { CtxParams } from "../../Contexts/ctxParams";

const useCadPagtoQrLista = () : IUseCadPagtoQrLista => {
    const API = useAPI();
    const navigate = useNavigate();
    const UserParams = useContext(CtxParams);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<ICadPagtoQr>({"CD_IMAGEM": null, "NOME": "", "CAMINHO_IMAGEM": ""});

    const qryLista = useQuery(
        'listaPagtoQr',(
        () => API.get('qrcode/buscarTodos/', '', false)),
        {}
    );

    const dsLista = useMemo<ICadPagtoQrs>(() => 
        qryLista.isSuccess ? 
            qryLista.data.map((item: ICadPagtoQr) => ({
                CD_IMAGEM: item.CD_IMAGEM,
                NOME: item.NOME,
                CAMINHO_IMAGEM: `${process.env.REACT_APP_API!}/qrcode/buscar/${item.CD_IMAGEM}`
            })) 
        : 
            [{
                CD_IMAGEM: 0, 
                NOME: "Exemplo",
                CAMINHO_IMAGEM : "/PicPay.jpg"
            }]
    , [qryLista]);

    const gridLista = useMemo(() => <>
        {
            dsLista.map((dado: ICadPagtoQr) =>
                    <Card 
                        key={dado.CD_IMAGEM} 
                        className="text-center mt-3 mx-3"
                    >
                        <Card.Header 
                            as={"h2"}
                            className={"d-flex justify-content-around align-items-center"}
                        >
                            <div>{dado.NOME}</div>
                            {
                                !UserParams.ENTREGADOR ?
                                        <Button 
                                            variant="danger"
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                setSelecionado(dado);
                                                setShowModal(true);
                                            }}
                                        >
                                            <FaTrashAlt /> Excluir
                                        </Button>
                                    :
                                        null
                            }
                        </Card.Header>
                        <Card.Img 
                            variant="top" 
                            style={{maxHeight: '600px'}}
                            src={dado.CAMINHO_IMAGEM} 
                            onClick={() => window.open(dado.CAMINHO_IMAGEM, 'Imagem QRCode')}
                        />
                    </Card>
            )
        }
    </>, [dsLista, UserParams.ENTREGADOR]);

    const mutDelQrCode = useMutation((data : ICadPagtoQr) => API.del(`qrcode/deletar/${data.CD_IMAGEM}`, "", false), {
        onSuccess: () => qryLista.refetch(),
        onError: (ret: any) => alert(JSON.stringify(ret))
    });

    const cadastrar = () => {
        navigate(`/cadPagtoQr`);
    };

    const editar = (QrCode: ICadPagtoQr) => {
        navigate(`/cadPagtoQr/${QrCode.CD_IMAGEM}`);
    };

    const excluir = (QrCode: ICadPagtoQr) => {
        mutDelQrCode.mutate(QrCode);
    };

    return {
        Cadastrar: cadastrar,
        Editar: editar,
        Excluir: excluir,

        Lista: dsLista,
        Grid: gridLista,

        ShowModal: showModal,
        setShowModal: setShowModal,
        Selecionado: selecionado,
    }
}

export interface IUseCadPagtoQrLista {
    Cadastrar   : () => void;
    Editar      : (QrCode: ICadPagtoQr) => void;
    Excluir     : (QrCode: ICadPagtoQr) => void;
    Lista       : ICadPagtoQrs;
    Grid        : JSX.Element;
    ShowModal   : boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado : ICadPagtoQr;
}

export default useCadPagtoQrLista;
import { Button, ButtonGroup, Col, Container, Dropdown, Form, Row} from "react-bootstrap";
import Tabela from "../../Components/tabela";
import { CtxParams } from "../../Contexts/ctxParams";
import FormRodape from "../../Components/formularios/FormRodape";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaArrowLeft, FaCheck, FaMapMarkedAlt, FaPen, FaPhoneAlt, FaPrint, FaQrcode, FaWhatsapp } from "react-icons/fa";
import { useState, useContext, useEffect, ChangeEvent } from "react";
import { EStatus, LStatus } from "../../Models/cadEntrega";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { ICadUsuario, ICadUsuarios } from "../../Models/cadUsuario";
import { IClienteEndereco } from "../../Models/cadClienteEndereco";
import { IUseCadEntregaLista } from "../../Hooks/cadEntrega/lista";
import useApp from "../../Hooks/utils/useApp";

const CadEntregaVisualizacao = () => {
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);
    const navigate = useNavigate();
    const Entrega = useOutletContext<IUseCadEntregaLista>();
    const App = useApp();

    useEffect(() => {
        AppParams.setPagina("Resumo da Entrega");
        // window.scrollTo(0, 0);
    });

    const [showModal, setShowModal] = useState<boolean>(false);
    const [statusAlteracao, setstatusAlteracao] = useState<EStatus>(Entrega.Item.STATUS);

    const statusMapa = (Endereco: IClienteEndereco) : boolean => {
        const LAT = Endereco.LATITUDE;
        const LON = Endereco.LONGITUDE;
        return !(((LAT !== undefined) && (LAT !== null) && (LAT !== '')) && ((LON !== undefined) && (LON !== null) && (LON !== '')));
    };

    return (
        <>
            <ModalConfirmacao 
                titulo={statusAlteracao === "C" ? "Cancelando Entrega" : "Alterando Status"}
                show={showModal} 
                onHide={setShowModal}
                variant={statusAlteracao === "C" ? "danger" : "primary"}
                onSubmitHandler={() => {
                    const novoStatus = statusAlteracao as EStatus;
                    if (novoStatus === EStatus.Cancelada) {
                        Entrega.Controller.Deletar.mutateAsync({CD_ENTREGA: Entrega.Item.CD_ENTREGA, STATUS: novoStatus}).then(() =>
                            setShowModal(false)
                        )
                    } else {
                        Entrega.Controller.Alterar.mutateAsync({CD_ENTREGA: Entrega.Item.CD_ENTREGA, STATUS: novoStatus}).then(() =>
                            setShowModal(false)
                        )
                    }
                }}
            >
                Deseja alterar o status da entrega para {LStatus.find(e => e.Status === statusAlteracao)?.NOME}?
            </ModalConfirmacao>

            <Container className="verticalCenter">
                <p className="text-center w-100 mt-3">{`Código da entrega: ${Entrega.Item.CD_ENTREGA}`}</p>

                <div className="w-100 d-flex align-items-center justify-content-center flex-wrap">
                    <div className="w-100 d-flex justify-content-center">
                        {
                            UserParams.ENTREGADOR ?
                                <Button 
                                    size="lg"
                                    variant="success"
                                    disabled={Entrega.Item.STATUS === EStatus.Finalizada}
                                    onClick={() => {
                                        if (Entrega.Item.PAGO) {
                                            Entrega.Controller.Alterar.mutate({CD_ENTREGA: Entrega.Item.CD_ENTREGA, STATUS: EStatus.Finalizada})
                                        } else {
                                            Entrega.VendaPagamento.Modal.Pagamento({
                                                CD_ENTREGA: (Entrega.Item.CD_ENTREGA as number), 
                                                OFF_TOTAL: Number(Entrega.Item.VLR_PEDIDO ?? 0),
                                                OFF_FORMA: Entrega.Item.FORMA_PAGTO,
                                                OFF_TROCO: Entrega.Item.VLR_TROCO,
                                                FINALIZA_VENDA: true,
                                            })
                                        }
                                    }}
                                >
                                    <FaCheck /> Finalizar Entrega
                                </Button>
                            :
                                <Button 
                                    size="lg"
                                    variant="success"
                                    className="mb-3"
                                    disabled={Entrega.Item.PAGO}
                                    onClick={() => {
                                        Entrega.VendaPagamento.Modal.Pagamento({
                                            CD_ENTREGA: (Entrega.Item.CD_ENTREGA as number), 
                                            OFF_TOTAL: Number(Entrega.Item.VLR_PEDIDO ?? 0),
                                            OFF_FORMA: Entrega.Item.FORMA_PAGTO,
                                            OFF_TROCO: Entrega.Item.VLR_TROCO,
                                            FINALIZA_VENDA: false
                                        })
                                    }}
                                >
                                    <FaCheck /> Confirmar Pagamento
                                </Button>
                        }
                    </div>

                    <Form.Group className="mx-1" style={{flex: '1'}}>
                        <Form.Label>Status:</Form.Label>
                        <Form.Select 
                            name={'STATUS'}
                            value={Entrega.Item.STATUS}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setstatusAlteracao(Object.values(EStatus).find(status => status === e.target.value) as EStatus);
                                setShowModal(true);
                            }}
                            placeholder="" 
                            size="lg"
                            disabled={
                                Entrega.Item.STATUS === EStatus.Finalizada
                                || UserParams.ENTREGADOR
                            }
                        > 
                            <option key={'P'} value={'P'}>Pendente</option>
                            <option key={'O'} value={'O'}>Preparação</option>
                            <option key={'A'} value={'A'}>Andamento</option>
                            <option key={'F'} value={'F'}>Finalizada</option>
                            <option key={'C'} value={'C'}>Cancelada</option>
                        </Form.Select>
                    </Form.Group>  

                    <Form.Group className="mx-1" style={UserParams.ENTREGADOR ? {display: 'none'} : {flex: '1'}}>
                        <Form.Label>Entregador:</Form.Label>
                        <Form.Select 
                            name={'STATUS'}
                            value={Entrega.Item.CD_USUARIO ?? ''}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                Entrega.Controller.Alterar.mutate({
                                    CD_ENTREGA: Number(Entrega.Item.CD_ENTREGA),
                                    CD_USUARIO: Number(e.target.value)
                                });
                            }}
                            placeholder="" 
                            size="lg"
                        >
                            <option key={'-1'} value={''}>--Nenhum--</option>
                            {(Entrega.dsEntregadores as ICadUsuarios).map!((item: ICadUsuario) => 
                                <option key={item.CD_USUARIO} value={item.CD_USUARIO ?? ""}>{item.NOME}</option>
                            )}
                        </Form.Select>
                    </Form.Group>  
                </div>

                <div className="w-100">
                    <Row>
                        <Col lg={8}>
                            <h5 className="mt-3 text-center">Dados da Entrega</h5>
                            <Tabela colunas={App.Grid} dados={Entrega.dsDados} hideHeader={true} />
                        </Col>

                        <Col lg={4}>
                            <div className="h-100 d-flex flex-wrap align-content-center">
                                <Button className="w-100 my-3" size="lg" variant="primary" onClick={Entrega.Utils.Ligar}>
                                    <FaPhoneAlt /> Fazer Ligação
                                </Button>
                                <Button className="w-100 my-3" size="lg" variant="success" onClick={Entrega.Utils.Whatsapp}>
                                    <FaWhatsapp /> Whatsapp
                                </Button>
                                <Button className="w-100 my-3" size="lg" variant="warning" onClick={() => navigate("/listaPagtoQr/verEntrega")}>
                                    <FaQrcode /> QR Code - Pagamentos
                                </Button>
                                <Button className="w-100 my-3" size="lg" variant="danger" disabled={statusMapa(Entrega.Cadastro.dsEntrega.ENDERECO)} onClick={Entrega.Utils.Mapa}>
                                    <FaMapMarkedAlt /> Abrir no Mapa
                                </Button>
                                <Dropdown as={ButtonGroup} size="lg" className="w-100 my-3">
                                    <Button variant="dark" style={{width: '90%'}} onClick={() => 
                                        Entrega.Report.Print('ENTREGA', Entrega.Item)
                                    }
                                    >
                                        <FaPrint /> Imprimir
                                    </Button>
                                    <Dropdown.Toggle split variant="dark" id="dropdown-impressao" />
                                    
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => Entrega.Report.Reset()}>Resetar configurações de impressão</Dropdown.Item>
                                    </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </div>   

                {
                    Entrega.Item.ENTREGA_ITENS ?
                        <>
                            <h5 className="mt-3">Itens</h5>
                            <Tabela colunas={Entrega.GridItens} dados={Entrega.Item.ENTREGA_ITENS ?? []} />
                        </>
                    :
                        null
                }

                <div className="w-100 mt-3">
                    <h5 className="mt-3 text-center">Outras informações</h5>
                    <Tabela colunas={App.Grid} dados={Entrega.dsOutrasInfo} hideHeader={true} />
                </div>

                <FormRodape >
                    <Button variant="outline-dark" size="lg" onClick={Entrega.Cadastro.Cancelar}><FaArrowLeft /> Retornar</Button>
                    <Button variant="warning" size="lg" style={UserParams.ENTREGADOR ? {display: 'none'} : {}} onClick={Entrega.Cadastro.Abrir}><FaPen /> Alterar</Button>
                </FormRodape>
            </Container>
        </>
    );
};

export default CadEntregaVisualizacao;
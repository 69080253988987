import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IMesa, IMesaPrincipal, IMesaPrincipals } from "../../Models/mesa";

const useMesaController = (updateQueryKey: string, isArray: boolean = true, enableQuery: boolean = false, dataCompetencia: string) : IUseMesaController => {
    const API = useAPI();
    const queryClient = useQueryClient();

    const primaryKey = 'CD_MESA';
    const campoPesquisa = 'NR_MESA';
    const endpoint = 'mesa';

    const mutAlterar = useMutation(
        (data : DeepPartial<IMesa>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: DeepPartial<IMesa>, data: DeepPartial<IMesa>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IMesaPrincipals>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                const mesasAtualizadas = atualizarListaMesas(oldData, data.CD_MESA!, data)
                                return mesasAtualizadas
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IMesa>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IMesa) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscarNumero = useMutation(
        (data : DeepPartial<IMesa>) => 
            API.get(`${endpoint}/buscar/numero/${data[campoPesquisa]}`, "")
        , {
            onSuccess: (ret: IMesa) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IMesaPrincipals>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos/${dataCompetencia}`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 60000
        }
    );    

    const atualizarListaMesas = (mesas: any[], codigo: number, alteracao: any): any[] => {
        return mesas.map((mesaPrincipal: IMesaPrincipal) => {
            mesaPrincipal.HISTORICO = mesaPrincipal.HISTORICO.map((mesa) => {
                if (mesa.CD_MESA === codigo) {
                    mesa = {...mesa, STATUS: alteracao.STATUS}
                    mesaPrincipal.STATUS = alteracao.STATUS
                }

                return mesa
            })

            return mesaPrincipal
        });
    }

    return {
        Alterar     : mutAlterar,
        Buscar      : mutBuscar,
        BuscarNumero: mutBuscarNumero,
        Listar      : qryListar,
    }
}

export interface IUseMesaController {
    Alterar     : UseMutationResult<any, any, DeepPartial<IMesa>, unknown>;
    Buscar      : UseMutationResult<IMesa, any, DeepPartial<IMesa>, unknown>;
    BuscarNumero: UseMutationResult<IMesa, any, DeepPartial<IMesa>, unknown>;
    Listar      : UseQueryResult<IMesaPrincipals, unknown>;
}

export default useMesaController;
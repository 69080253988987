import useMask from "../../../Hooks/utils/useMask";
import { IEntrega } from "../../../Models/cadEntrega";

const ImpressaoEntrega = (Pedido: IEntrega, Colunas: number) => {
    const { setMaskMoney, getEnderecoString, setDate } = useMask();
    const _margem = 10;
    const SIZE = (TamCampo: number) : number => {
        return Math.trunc((TamCampo * (Colunas - _margem)) / 100)
    }

    const BREAK_ITEM = (Texto: string, TamMax: number = 70) : string => {
        const maxsize = SIZE(TamMax);
        let text = "";
    
        if (Texto && Texto !== "") {
            let start = 0;
            while (start < Texto.length) {
                let end = start + maxsize;
                // Se houver uma quebra de linha no segmento atual, ajuste 'end' para ser a posição da quebra de linha
                const newlinePos = Texto.indexOf('\n', start);
                if (newlinePos !== -1 && newlinePos < end) {
                    end = newlinePos;
                }
    
                text += `\n│ ${' '.padStart(SIZE(10), ' ')} │ ${Texto.slice(start, end).padEnd(maxsize, ' ')} │ ${' '.padStart(SIZE(20), ' ')} │`;
    
                // Se 'end' é uma quebra de linha, avance 'start' para o caractere após a quebra de linha
                if (Texto[end] === '\n') {
                    start = end + 1;
                } else {
                    start = end;
                }
            }
        } else {
            text = Texto;
        }
    
        return text;
    };
    

    const cabecalho = 
`${' '.padEnd(SIZE(50), ' ')}${`**** FROTA DELIVERY ****`}

${`Cliente: ${Pedido.CLIENTE.NOME}`.padStart((_margem), ' ')}
${`Celular: ${Pedido.CLIENTE.CELULAR}`.padStart((_margem), ' ')}
${`Endereço: ${getEnderecoString(Pedido.ENDERECO)}`.padStart((_margem), ' ')}
`

    const itens = 
`
${`${setDate(new Date(Pedido.DT_ENTREGA!), {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}, false, false)}`}
${`Entrega: #${Pedido.CD_ENTREGA}`}
┌─${'─'.padEnd(SIZE(10), '─')}───${'─'.padEnd(SIZE(70), '─')}───${'─'.padEnd(SIZE(20), '─')}─┐
│ ${' '.padEnd(SIZE(40), ' ')}   ${'ITENS'.padEnd(SIZE(20), ' ')}   ${' '.padEnd(SIZE(40), ' ')} │
├─${'─'.padEnd(SIZE(10), '─')}─┬─${'─'.padEnd(SIZE(70), '─')}─┬─${'─'.padEnd(SIZE(20), '─')}─┤
${
Pedido.ENTREGA_ITENS
? Pedido.ENTREGA_ITENS.map((item, index) => {
    return (
`${index !== 0 ? '\n' : ''}│ ${String(Number(Math.trunc(item.QTD)) === Number(item.QTD) ? Math.trunc(item.QTD) : item.QTD.toFixed(2)).padStart(SIZE(10), ' ')} │ ${String(item.PRODUTO_NOME).slice(0, 32).padEnd(SIZE(70), ' ')} │ ${setMaskMoney((item.VLRUN*item.QTD), 'R$', ',').padStart(SIZE(20), ' ')} │`
+ `${BREAK_ITEM(item.OBS_PRODUTO ?? "", 70)}`)}).join('')
: ''
}
├─${'─'.padEnd(SIZE(10), '─')}─┴─${'─'.padEnd(SIZE(70), '─')}─┴─${'─'.padEnd(SIZE(20), '─')}─┤
│ ${'*'.padStart(SIZE(10), ' ')}   ${'ENTREGA'.padEnd(SIZE(70), '.')}...${setMaskMoney(Pedido.TAXA ?? 0, 'R$', ',').padStart(SIZE(20), '.')} │
│ ${'*'.padStart(SIZE(10), ' ')}   ${'TOTAL'.padEnd(SIZE(70), '.')}...${setMaskMoney(Pedido.VLR_PEDIDO ?? 0, 'R$', ',').padStart(SIZE(20), '.')} │
├─${'───────────────────────────────────────────────────'.padEnd(SIZE(100), '─')}─┤
│ ${'*'.padStart(SIZE(10), ' ')}   ${`PAGAMENTO: ${Pedido.FORMA_PAGTO ?? '-'}`.padEnd(SIZE(70), '.')}...${setMaskMoney(Number(Pedido.VLR_PEDIDO ?? 0) + Number(Pedido.VLR_TROCO ?? 0), 'R$', ',').padStart(SIZE(20), '.')} │
│ ${'*'.padStart(SIZE(10), ' ')}   ${'TROCO'.padEnd(SIZE(70), '.')}...${setMaskMoney(Number(Pedido.VLR_TROCO ?? 0), 'R$', ',').padStart(SIZE(20), '.')} │
└─${'─'.padEnd(SIZE(10), '─')}───${'─'.padEnd(SIZE(70), '─')}───${'─'.padEnd(SIZE(20), '─')}─┘
`
    return {
        cabecalho,
        itens
    }
}

export default ImpressaoEntrega;
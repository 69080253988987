import { TableState, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { Pagination, Table } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import ReactHammer from 'react-hammerjs';
import { IOFF_REACT_TABLE_PROPS } from '../../Models/genericInterfaces';
import styles from './styles.module.scss';
import { v4 as uuidv4 } from "uuid";

interface selectKey {
    key: string;
    value: string;
}

interface sizes {
    minWidth?: string;
}

interface Props {
    colunas         : any;
    dados           : Array<object>;
    handleClick?    : (prop: any) => void;
    estadoInicial?  : Partial<TableState<object>>;
    hideHeader?     : boolean;
    handleContext?  : (prop: any) => void;
    pagination?     : boolean;
    selectedKey?    : selectKey;
    horizontal?     : boolean;
    sizes?          : sizes;
};

const Tabela = ({colunas, dados, handleClick, estadoInicial, hideHeader, handleContext, pagination, selectedKey, horizontal, sizes} : Props)  => {
    const [rowSelected, setRowSelected] = useState<number | null>(null);
    const [pressPos, setPressPos] = useState<any>({x: 0, y: 0});
    const baseHooks = [useGlobalFilter, useSortBy];
    const hooks = pagination ? [...baseHooks, usePagination] : baseHooks;
    
    const handleContextPress = useCallback((pos: any) => {
        document.dispatchEvent(
            new MouseEvent('contextmenu', {
                view: window,
                bubbles: true, 
                cancelable: true, 
                clientX: pos.x, 
                clientY: pos.y
            })
        )
    },  []);

    useEffect(() => {
        handleContextPress(pressPos);
    }, [handleContextPress, pressPos]);

    useEffect(() => {
        document.addEventListener('mousedown', () => setRowSelected(null));

        return () => {
            document.removeEventListener('mousedown', () => setRowSelected(null));
        };
    });
        
    const {
        getTableProps,
        getTableBodyProps, 
        headerGroups, 
        rows, 
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        state
    } = useTable(
        {
            columns: colunas, 
            data: dados,
            initialState: pagination ? { pageIndex: 0, pageSize: 50, ...estadoInicial } : estadoInicial,
        },
        ...hooks
    );
    const { pageIndex } = state;

    return(
        <div className='tabelaWrapper'>
            <Table 
                striped bordered hover 
                className={horizontal ? 'tabelaHorizontal' : 'tabela'}
                onMouseLeave={() => !!handleContext ? (handleContext(undefined)) : null}
                {...getTableProps()}
            >
                <thead>{headerGroups.map((headerGroup) => (
                    hideHeader ?
                        null
                    :
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    { column.render("Header") }
                                </th>
                            ))}
                        </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(pagination ? page : rows).map((row, index) => {
                        prepareRow(row);
                        const REACT_TABLE_PROPS = (row.original as any)['OFF_REACT_TABLE_PROPS'] as IOFF_REACT_TABLE_PROPS;
                        const isSelected = selectedKey && String((row.original as any)[selectedKey.key]) === selectedKey.value;
                        const rowSelection = (index === rowSelected)
                            ? {boxShadow: '0.2rem 0.2rem 1rem 0.2rem rgba(0,0,0,0.21)'} 
                            : {};
                        const propAlert : boolean = REACT_TABLE_PROPS !== undefined ? REACT_TABLE_PROPS.alert : false;
                        const propColor : string = REACT_TABLE_PROPS !== undefined ? REACT_TABLE_PROPS.color : '';

                        const backgroundPropColor = (propColor !== '')
                            ? {backgroundColor: `${propColor}26`, borderColor: `${propColor}80`}
                            : {};

                        return (
                            <ReactHammer 
                                key={uuidv4()}
                                options={{touchAction: 'auto'}}
                                onPress={(e) => !!handleContext 
                                    ? (
                                            handleContext(row.original),
                                            setRowSelected(index),
                                            setPressPos({x: e.center.x, y: e.center.y})
                                    ) : null
                                }
                            >
                                <tr 
                                    className={`${propAlert ? styles.alert : ''} ${isSelected ? styles.selection : ''}`}
                                    onClick={() => !!handleClick 
                                        ? handleClick!(row.original) 
                                        : null
                                    } 
                                    onContextMenu={() => !!handleContext 
                                        ? (handleContext(row.original), setRowSelected(index)) 
                                        : null
                                    }
                                    style={{
                                        cursor: 'pointer',
                                        // minWidth: horizontal ? '65vw' : 'auto',
                                        ...sizes,
                                        ...rowSelection,
                                        ...backgroundPropColor
                                    }}
                                    {...row.getRowProps()}                                 
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{ cell.render("Cell") }</td>
                                        )
                                    })}
                                </tr>
                            </ReactHammer>
                        )
                    })}
                </tbody>
            </Table>

            {
                (dados.length >= (estadoInicial && estadoInicial.pageSize ? estadoInicial.pageSize : 50)) && (pagination) ?
                    <div className='w-100 d-flex justify-content-center'>
                        <Pagination>
                            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                            <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                            {pageIndex > 0 ? <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item> : null}
                            {pageIndex === 2 ? <Pagination.Item onClick={() => gotoPage(1)}>{pageIndex}</Pagination.Item> : null}
                            {pageIndex > 3 ? <Pagination.Ellipsis /> : null}
                            {pageIndex > 2 ? <><Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</Pagination.Item> <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</Pagination.Item></> : null}   
                            
                            <Pagination.Item active>{pageIndex + 1}</Pagination.Item> 

                            {pageIndex + 1 < pageOptions.length - 1 ? <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</Pagination.Item> : null}
                            {pageIndex + 2 < pageOptions.length - 1 ? <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</Pagination.Item> : null}
                            {pageIndex + 1 < pageOptions.length - 1 ? <Pagination.Ellipsis /> : null}
                            {pageIndex + 1 !== pageOptions.length ? <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>{pageOptions.length}</Pagination.Item> : null}
                            <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                        </Pagination>
                    </div>
                :
                    <></>
            }
        </div>
    );
}

export default Tabela;
import { Formik } from "formik";
import { every } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import FormRodape from "../../Components/formularios/FormRodape";
import { useOutletContext, useParams } from "react-router-dom";
import { IUseCardapio } from "../../Hooks/Cardapio";
import { ECategoriaTipo, ICardapio, schemaAltCategoria, schemaAltComposicao, schemaCadCategoria, schemaCadComposicao } from "../../Models/cardapio";
import useMask from "../../Hooks/utils/useMask";
import Tabela from "../../Components/tabela";

const CardapioCadastro = () => {
    const { pTipoCategoria } = useParams();
    const Cardapio = useOutletContext<IUseCardapio>();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const formatter = useMask();
    const tipoCategoria : ECategoriaTipo = Object.values(ECategoriaTipo).includes(pTipoCategoria as ECategoriaTipo) ? (pTipoCategoria as ECategoriaTipo) : ECategoriaTipo.Cardapio;
    const schema = {
        CARDAPIO: {
            include:    schemaCadCategoria,
            edit:       schemaAltCategoria,
            browse:     undefined
        },
        COMPOSICAO: {
            include:    schemaCadComposicao,
            edit:       schemaAltComposicao,
            browse:     undefined
        }
    }

    useEffect(() => {
        if (Cardapio.Controller.ListarCategorias.isLoadingError && tipoCategoria === ECategoriaTipo.Composicao) {
            alert('Nenhuma categoria ainda foi cadastrada para prosseguir com o cadastro da composição, estamos te redirecionando para o cadastro de categoria')
            Cardapio.Abrir({CD_CATEGORIA: -1})
        }
    }, [Cardapio.Controller.ListarCategorias.isError, tipoCategoria]) //eslint-disable-line

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schema[tipoCategoria][Cardapio.Modo]}
                onSubmit={(dados : ICardapio) => {
                    Cardapio.Salvar(dados);
                }}
                initialValues={{
                    CD_CATEGORIA        : Cardapio.Item.CD_CATEGORIA        || undefined,
                    ATIVO               : Cardapio.Item.ATIVO               ?? true,
                    NOME                : Cardapio.Item.NOME                || '',
                    TIPO                : Cardapio.Item.TIPO                ?? tipoCategoria,
                    CD_CATEGORIA_MESTRE : Cardapio.Item.CD_CATEGORIA_MESTRE ?? undefined,
                    MAX                 : Cardapio.Item.MAX                 ?? 0,
                    MIN                 : Cardapio.Item.MIN                 ?? 1,
                    ORDEM               : Cardapio.Item.ORDEM               ?? 999,
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => Cardapio.Abrir({CD_CATEGORIA: 0})}>       
                        <Row>
                            <Form.Group className="mt-3">
                                <Form.Label>{`Nome da ${formatter.getCapitalized(tipoCategoria === ECategoriaTipo.Cardapio ? 'Categoria' : tipoCategoria as string)}:`}</Form.Label>
                                <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                                <Form.Control 
                                    name={'NOME'}
                                    type={'text'}
                                    value={values.NOME ?? ""}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NOME, confirmado])}
                                    placeholder={tipoCategoria === ECategoriaTipo.Composicao ? "Frutas, Cobertura, Condimentos...": "Porções, Bebidas, Lanches..."}
                                    size="lg"
                                />
                            </Form.Group>  
                        </Row>

                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mt-3 mb-5">
                                    <Form.Label>Situação:</Form.Label>
                                    <BadgeValidacao validacao={errors.ATIVO} confirmado={confirmado} />
                                    <Form.Check
                                        style={{zoom: 1.4}}
                                        name="ATIVO"
                                        id="ATIVO"
                                        type="switch"
                                        checked={values.ATIVO}
                                        isInvalid={every([errors.ATIVO, confirmado])}
                                        label={values.ATIVO ? 'Ativada' : 'Desativada'}
                                        onChange={() => setFieldValue("ATIVO", !values.ATIVO)}
                                    />
                                </Form.Group>  
                            </Col>
                            
                            {
                                pTipoCategoria === ECategoriaTipo.Composicao
                                ? <>
                                    <Col xs={6}>
                                        <Form.Group className="mt-3">
                                            <Form.Label>Categoria:</Form.Label>
                                            <BadgeValidacao validacao={errors.CD_CATEGORIA_MESTRE} confirmado={confirmado} />
                                            <Form.Select 
                                                name={'CD_CATEGORIA_MESTRE'}
                                                isInvalid={every([errors.CD_CATEGORIA_MESTRE, confirmado])}
                                                value={String(values.CD_CATEGORIA_MESTRE)}
                                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                                    setFieldValue("CD_CATEGORIA_MESTRE", Number(e.target.value))
                                                }}
                                                placeholder="" 
                                                size="lg"
                                            > 
                                                <option key={'catNull'} value={undefined}></option>
                                                {
                                                    Cardapio.ListaCategoria.map((Item: ICardapio, Index: number) => 
                                                        <option key={String(Index)} value={String(Item.CD_CATEGORIA)}>{Item.NOME}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Form.Group>  
                                    </Col>
                                </>
                                : <>
                                    <Col xs={6}>
                                        <Form.Group className="mt-3">
                                            <Form.Label title="Quanto menor, a exibição será ordenada antes no cardápio.">{`Ordem:`}</Form.Label>
                                            <BadgeValidacao validacao={errors.ORDEM} confirmado={confirmado} />
                                            <Form.Control 
                                                name={'ORDEM'}
                                                type={'number'}
                                                min={0}
                                                max={999}
                                                step={1}
                                                value={values.ORDEM ?? '1'}
                                                onWheel={(e: any) => e.target.blur()}
                                                onChange={handleChange}
                                                isInvalid={every([errors.ORDEM, confirmado])}
                                                size="lg"
                                            />
                                        </Form.Group>  
                                    </Col>
                                </>
                            }
                        </Row>

                        {
                                pTipoCategoria === ECategoriaTipo.Composicao
                                ? <>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>{`Qtd Miníma de Seleções:`}</Form.Label>
                                                <BadgeValidacao validacao={errors.MIN} confirmado={confirmado} />
                                                <Form.Control 
                                                    name={'MIN'}
                                                    type={'number'}
                                                    min={0}
                                                    max={20}
                                                    value={values.MIN ?? '0'}
                                                    onWheel={(e: any) => e.target.blur()}
                                                    onChange={handleChange}
                                                    isInvalid={every([errors.MIN, confirmado])}
                                                    size="lg"
                                                />
                                            </Form.Group>  
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>{`Qtd Máxima de Seleções:`}</Form.Label>
                                                <BadgeValidacao validacao={errors.MAX} confirmado={confirmado} />
                                                <Form.Control 
                                                    name={'MAX'}
                                                    type={'number'}
                                                    value={values.MAX ?? '1'}
                                                    min={1}
                                                    max={20}
                                                    onWheel={(e: any) => e.target.blur()}
                                                    onChange={handleChange}
                                                    isInvalid={every([errors.MAX, confirmado])}
                                                    size="lg"
                                                />
                                            </Form.Group>  
                                        </Col>
                                    </Row>
                                </>
                                : ((Cardapio.Item.CD_CATEGORIA ?? 0) > 0)
                                    ? <Tabela
                                        colunas={[
                                            {
                                                id: 'NomeComposicaoFilho',
                                                Header: 'Composições',
                                                accessor: 'NOME' 
                                            }
                                        ]}
                                        dados={Cardapio.ListaComposicao.filter((item) => item.CD_CATEGORIA_MESTRE === Cardapio.Item.CD_CATEGORIA)}
                                        handleClick={Cardapio.Abrir}
                                    />
                                    : null                                
                        }

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CardapioCadastro;
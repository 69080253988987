import React, {useContext, useEffect, useRef, useState } from "react"
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap"
import { CtxParams } from "../../Contexts/ctxParams"
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import FormRodape from "../../Components/formularios/FormRodape";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaRegCopy, FaShareAlt } from "react-icons/fa";
import useImage from "../../Hooks/utils/useImage";
import QRCode from "easyqrcodejs";
import useMask from "../../Hooks/utils/useMask";


const CardapioConfiguracao = () => {
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);
    const navigate = useNavigate();
    const image = useImage();
    const {setZeros} = useMask();

    const [imgNumeroMesa, setImgNumeroMesa] = useState<string>();
    const qrcodeRef = useRef<HTMLDivElement | null>(null);
    const qrcodeInstance = useRef<QRCode | null>(null);

    const [isMesa, setIsMesa] = useState<boolean>(false);
    const [nrMesa, setNrMesa] = useState<string|undefined>();
    const [link, setLink] = useState<string>(`https://cardapio.escopo.app/${UserParams.CD_EMPRESA}`);

    useEffect(() => {
        AppParams.setPagina('Configuração do Cardápio');
    })

    useEffect(() => {
        if (isMesa && nrMesa) {
            setImgNumeroMesa(image.textoParaImagem(setZeros("00", -2, nrMesa), 128, 128));
        } else {
            setImgNumeroMesa(undefined);
        }
    }, [isMesa, nrMesa, image, setZeros]);

    useEffect(() => {
        setLink(`https://cardapio.escopo.app/${UserParams.CD_EMPRESA}${isMesa && nrMesa ? '/'+nrMesa : ''}`)
    }, [nrMesa, isMesa, UserParams.CD_EMPRESA]);

    useEffect(() => {
        if (qrcodeRef.current) {
            if (qrcodeInstance.current) {
                qrcodeInstance.current.clear();
            };
            qrcodeInstance.current = new QRCode(qrcodeRef.current, {
                text: link,
                quietZone: 24,
                logo: imgNumeroMesa || undefined,
                // backgroundImage: '/favicon.png',
                // backgroundImageAlpha: 0.2,
            });
        }
    }, [UserParams.CD_EMPRESA, imgNumeroMesa, link]);

    return (
        <Container className="verticalCenter">
            <div>
                <Row>
                    <Col xs={12}>                        
                        <Form.Group>
                            <Form.Label>Tipo de Link:</Form.Label>
                            <Form.Check
                                style={{zoom: 1.4, minWidth: '30vw'}}
                                name="MESA"
                                id="MESA"
                                type="switch"
                                checked={isMesa}
                                label={isMesa ? 'O Link será de uma mesa' : 'O Link será para fazer pedido'}
                                onChange={() => setIsMesa(!isMesa)}
                            />
                            {
                                isMesa
                                    ? <Form.Control className="my-1" size="lg" type="number" placeholder="Informe o número da mesa..." value={nrMesa} onChange={(e) => setNrMesa(e.target.value)} />
                                    : null
                            }
                        </Form.Group>  
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <div className="d-flex w-100 justify-content-center">
                            <div ref={qrcodeRef}></div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <InputGroup className="mb-3">
                            <Form.Control size="lg" type="text" value={link} readOnly />
                            <Button size="lg" variant="secondary" onClick={() => navigator.clipboard.writeText(link).then(() => {alert('Copiado!')})}><FaRegCopy /></Button>
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Button className="w-100 mb-3" size="lg" onClick={() => qrcodeInstance.current!.download(`Qr-Frota-Cardapio-${isMesa && nrMesa ? nrMesa : 'X'}`)}>
                            Download QrCode
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Button
                            className="w-100 mb-3"
                            variant="dark"
                            size="lg"
                            onClick={() => {
                                const data = {
                                    title: 'Cardápio Online - Escopo',
                                    text: 'Acesse o cardápio online',
                                    url: link
                                }

                                if (navigator.canShare(data)) {
                                    navigator.share(data)
                                    .then(() => {})
                                    .catch(err => alert(err))
                                } else {
                                    alert('Não é possível compartilhar')
                                }
                            }}
                        >
                            <FaShareAlt /> Compartilhar
                        </Button>
                    </Col>
                </Row>
            </div>

            <FormRodape>
                <Button size="lg" variant="outline-dark" onClick={() => navigate(`/cardapio/${UserParams.CD_EMPRESA}`)}><FaArrowLeft /> Retornar</Button>
            </FormRodape>
        </Container>
    )
}

export default CardapioConfiguracao;
import * as Yup from 'yup';
import { NOME } from './defaultModels';

export interface ICadPagtoQr {
    CD_IMAGEM?      : number | null;
    LOCAL_IMAGEM?   : string | null;
    NOME            : string;
    CAMINHO_IMAGEM  : any;
    IMAGEM?          : File | null;
}
export interface ICadPagtoQrs extends Array<ICadPagtoQr>{};

export const schemaCadPagtoQR = Yup.object().shape({
    NOME
});
import { Badge } from "react-bootstrap"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    currentStep: number;
    totalSteps: number;
    variant?: 'pill' | 'lgPill';
}

const StepsIndicator = ({currentStep, totalSteps, variant, ...rest }: Props) => {
    const stepsArray = [];
    const variantStyle = variant === "lgPill" ? {width: '100%'} : {}

    for (let index = 1; index <= totalSteps; index++) {
        stepsArray.push(<Badge pill key={index} style={{marginLeft: '0.1rem', marginRight: '0.1rem', display: "inline-flex", justifyContent: "center", alignItems: "center", minHeight: '100%', ...variantStyle}} bg={index <= currentStep ? 'primary' : 'light'} text={index <= currentStep ? 'light' : 'primary'}>{index}</Badge>);
    }

    return <div className="d-flex w-100 align-items-center justify-content-center" {...rest}>{stepsArray}</div>;
};

export default StepsIndicator;
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import { DeepPartial } from "../../Models/genericInterfaces";
import useControllers from "../utils/useControllers";
import { useContext } from "react";
import { CtxParams } from "../../Contexts/ctxParams";
import { ICardapio, IProduto, IProdutos } from "../../Models/cardapio";

const useProdutoController = (updateQueryKey?: string, isArray: boolean = true) : IUseProdutoController => {
    const API = useAPI();
    const APIImagem = useAPI(process.env.REACT_APP_APIIMAGEM);
    const queryClient = useQueryClient();
    const UserParams = useContext(CtxParams);
    const { Update, Delete, Create } = useControllers();

    const primaryKey = 'CD_PRODUTO';
    const endpoint = 'produto';
    
    const mutDeletar = useMutation(
        (data : DeepPartial<IProduto>) => {
            if (data['OtherQuery']) {
                const datax = Object.assign({}, data);
                delete datax.OtherQuery;
                return API.del(`${endpoint}/deletar/${datax[primaryKey]}`, "")
            } else {
                return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
            }
        }, {
            onSuccess: (ret: any, data: DeepPartial<IProduto>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IProdutos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
                if (!!data.OtherQuery) {
                    queryClient.setQueryData(
                        data.OtherQuery.QueryKey!,
                        (oldData: any) => {
                            if ((oldData) && (data.OtherQuery?.QueryKey === 'listaCardapio')) {
                                return removerProdutoCardapioPorCodigo(oldData, Number(data.OtherQuery!.Params));
                            }
                            return oldData;
                        }
                    )
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutAlterar = useMutation(
        (data : DeepPartial<IProduto>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IProduto>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IProdutos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
                if (!!data.OtherQuery) {
                    queryClient.setQueryData(
                        data.OtherQuery.QueryKey!,
                        (oldData: any) => {
                            if ((oldData) && (data.OtherQuery?.QueryKey === 'listaCardapio')) {
                                return atualizarDisponibilidadeProdutoCardapioPorCodigo(oldData, Number(data.OtherQuery!.Params));
                            }
                            return oldData;
                        }
                    )
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IProduto>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IProduto>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IProdutos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['Sucesso']);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrarImagem = useMutation(
        (data : FormData) => {
            return APIImagem.post(`imagem/cadastrar/${data.get(primaryKey)}`, data, false, false)
        }, {
            onSuccess: (ret: any) => {
                
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IProduto>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IProduto) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IProdutos>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !UserParams.ENTREGADOR,
        }
    );    

    const removerProdutoCardapioPorCodigo = (cardapio: any[], codigo: number): any[] => {
        return cardapio.map((categoria) => {
        if (categoria.PRODUTOS) {
            categoria.PRODUTOS = categoria.PRODUTOS.filter((produto: any) => produto.CD_PRODUTO !== codigo);
        }
        return categoria;
        }).filter((categoria) => categoria.PRODUTOS.length > 0);
    };

    const atualizarDisponibilidadeProdutoCardapioPorCodigo = (cardapio: any[], codigo: number): any[] => {
        return cardapio.map((categoria: ICardapio) => {
            if (categoria.PRODUTOS) {
                categoria.PRODUTOS = categoria.PRODUTOS.map((produto: IProduto) => {
                    if (produto.CD_PRODUTO === codigo) {
                        produto.DISPONIVEL = !produto.DISPONIVEL;
                    }

                    return produto;
                })
            }
            return categoria;
        });
    }

    return {
        Deletar: mutDeletar,
        Alterar: mutAlterar,
        Cadastrar: mutCadastrar,
        CadastrarImagem: mutCadastrarImagem,
        Buscar: mutBuscar,
        Listar: qryListar,
    }
}

interface IUseProdutoController {
    Deletar     : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    Buscar      : UseMutationResult<IProduto, any, DeepPartial<IProduto>, unknown>
    Listar      : UseQueryResult<IProdutos, unknown>;
    CadastrarImagem : UseMutationResult<any, any, FormData, unknown>;
}

export default useProdutoController;
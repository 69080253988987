import { Container, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap"
import Tabela from "../../../Components/tabela";
import { IUseEmpresaParametro } from "../../../Hooks/Empresa/EmpresaParametro";
import { useOutletContext } from "react-router-dom";
import { useContext } from "react";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import useApp from "../../../Hooks/utils/useApp";
import { ETpFiltroEmpresaParametro } from "../../../Models/EmpresaParametro";

const EmpresaParametroLista = () => {
    const EmpresaParametro = useOutletContext<IUseEmpresaParametro>();
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();

    return (
        <Container className="verticalCenter mt-3">
            <code>Atenção: faça as alterações com cuidado, em caso de dúvidas entre em contato com o suporte!</code>

            <div className="d-flex w-100 m-3 justify-content-around">
                <InputGroup>
                    <DropdownButton
                        variant="outline-secondary"
                        title={
                            (() => {
                                switch (AppParams.Filtros.EmpresaParametro.tipoPesquisa) {
                                case ETpFiltroEmpresaParametro.Parametro:
                                    return 'Parâmetro'
                                default:
                                    return 'erro'
                                }
                            })()
                        }
                        id="TPFiltro"
                    >
                        <Dropdown.Item key={'1'} onClick={() => Filtrar('EmpresaParametro', {tipoPesquisa: ETpFiltroEmpresaParametro.Parametro})}>Parâmetro</Dropdown.Item>
                    </DropdownButton>

                    <Form.Control
                        placeholder="Filtro..."
                        aria-label="Filtro"
                        size="lg"
                        value={AppParams.Filtros.EmpresaParametro.pesquisa}
                        onChange={(e : React.ChangeEvent<HTMLInputElement>) => Filtrar('EmpresaParametro', {pesquisa: e.target.value}) }
                    />
                </InputGroup>
            </div>

            <Tabela colunas={EmpresaParametro.Grid} dados={EmpresaParametro.Lista} handleClick={e => EmpresaParametro.Abrir({CD_PARAMETRO: e.CD_PARAMETRO})} />
        </Container>
    )
}

export default EmpresaParametroLista;
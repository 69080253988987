import useAPI from "../utils/useAPI";
import { useMutation, useQuery } from 'react-query';
import { useState, useMemo, useContext } from "react";
import { ICadTaxa, ICadTaxas } from "../../Models/cadTaxa";
import { useNavigate } from "react-router-dom";
import useMask from "../utils/useMask";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { CtxParams } from "../../Contexts/ctxParams";
import { IReactTable_Grid, IReactTable_InitState } from "../../Models/genericInterfaces";
import { CtxAppParams } from "../../Contexts/ctxAppParams";

const useCadTaxaLista = () : IUseCadTaxaLista => {
    const API = useAPI();
    const navigate = useNavigate();
    const formatter = useMask();
    const UserParams = useContext(CtxParams);
    const { Filtros } = useContext(CtxAppParams);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<ICadTaxa>({"CD_TAXA": null, "CEP": "", "TAXA": 0, "BAIRRO": ""});

    const qryLista = useQuery(
        'listaTaxa',(
        () => API.get('taxas/buscarTodos/', '', false)),
        {}
    );

    const dsLista = useMemo<ICadTaxas>(() => 
        qryLista.isSuccess ? 
            qryLista.data.filter((data: any) => {
                return data[Filtros.Taxa.tipoPesquisa]?.toLowerCase().indexOf(Filtros.Taxa.pesquisa.toLowerCase()) !== -1
            })
        : 
            [{
                "CD_TAXA": null,
                "CEP": "",
                "TAXA": 0,
                "BAIRRO": ""
            }]
    , [qryLista, Filtros.Taxa]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "Bairro",
            Header: "Bairro",
            accessor: "BAIRRO"
        },
        {
            id: "Cep",
            Header: "Cep",
            Cell: (row: any) : any => `${formatter.setMaskCep(row.row.original['CEP'] ?? "")}`
        },
        {
            id: "VlrTaxa",
            Header: "Valor Taxa",
            Cell: (row: any) : any => `${formatter.setMaskMoney(row.row.original['TAXA'] ?? 0, "R$")}`
        },
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                <Button 
                    variant="danger"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        setSelecionado(row.row.original);
                        setShowModal(true);
                    }}
                >
                    <FaTrashAlt />
                </Button>
                </div>
        },
    ], [formatter]);

    const mutDelTaxa = useMutation((data : ICadTaxa) => API.del(`taxas/deletar/${data.CD_TAXA}`, ""), {
        onSuccess: () => qryLista.refetch(),
        onError: (ret: any) => alert(JSON.stringify(ret))
    });

    const cadastrar = () => {
        navigate(`/cadTaxa`);
    };

    const editar = (Taxa: ICadTaxa) => {
        navigate(`/cadTaxa/${Taxa.CD_TAXA}`);
    };

    const excluir = (Taxa: ICadTaxa) => {
        mutDelTaxa.mutate(Taxa);
    };

    const tableInitialState : IReactTable_InitState = { hiddenColumns: UserParams.ENTREGADOR ? ['BtnExcluir'] : [] }

    return {
        Cadastrar: cadastrar,
        Editar: editar,
        Excluir: excluir,

        Lista: dsLista,
        Grid: gridLista,
        EstadoInicial: tableInitialState,

        ShowModal: showModal,
        setShowModal: setShowModal,
        Selecionado: selecionado,
    }
}

export interface IUseCadTaxaLista {
    Cadastrar           : () => void;
    Editar              : (Taxa: ICadTaxa) => void;
    Excluir             : (Taxa: ICadTaxa) => void;
    Lista               : ICadTaxas;
    Grid                : IReactTable_Grid;
    EstadoInicial       : IReactTable_InitState;
    ShowModal           : boolean;
    setShowModal        : React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado         : ICadTaxa;
};

export default useCadTaxaLista;
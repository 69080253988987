import { Card } from "react-bootstrap";
import styles from './styles.module.scss';
import { IUseMesa } from "../../Hooks/Mesa";
import { EStatus } from "../../Models/cadEntrega";
import { IMesaPrincipals } from "../../Models/mesa";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { ICtxParams } from "../../Models/ctxParams";

interface Props {
    Mesa: IUseMesa;
    UserParams: ICtxParams;
}

const MesaArea = ({Mesa, UserParams} : Props) => {
    const [mesasPendentes, setMesasPendentes] = useState<Array<number>>([]);
    const navigate = useNavigate();

    const encontrarMesasPendentes = (mesas: IMesaPrincipals) => {
        const numerosUsados = mesas.filter(item => item.USANDO === true).map(item => Number(item.NR_MESA));
        const numerosAusentes = [];

        for (let i = 1; i <= 99; i++) {
            if (!numerosUsados.includes(i)) {
                numerosAusentes.push(i);
            }
        }

        return numerosAusentes;
    };

    useEffect(() => {
        setMesasPendentes(encontrarMesasPendentes(Mesa.Listar));
    }, [Mesa.Listar])

    return (
        <div className={styles.mesasArea}>
            <div className={styles.area}>
                <h4 className={styles.titulo}>Em uso</h4>
                {
                    Mesa.Controller.Listar.isFetched && Mesa.Controller.Listar.data && Mesa.Controller.Listar.data.length > 0 ?
                        Mesa.Listar.map((item) => {
                            if (item.USANDO !== false) {
                                return (
                                    <Card
                                        bg={'primary'}
                                        key={uuidv4()}
                                        text="light"
                                        className={styles.mesa}
                                        onClick={() => Mesa.Abrir({CD_MESA: Number(item.HISTORICO.find((mesa) => mesa.STATUS !== EStatus.Finalizada)?.CD_MESA)})}
                                    >
                                        <Card.Body className={item.OFF_REACT_TABLE_PROPS?.alert ? styles.alert : ''}>
                                            <Card.Title>{item.NR_MESA}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                )
                            } else {
                                return null
                            }
                        })
                    :
                        'Nenhuma mesa está em uso'
                }
            </div>

            <div className={styles.area}>
                <h4 className={styles.titulo}>Livres</h4>
                {
                    mesasPendentes.map((item) => {
                        return (
                            <Card
                                bg={'secondary'}
                                key={uuidv4()}
                                text="light"
                                className={styles.mesa}
                                onClick={() => navigate(`/cardapio/${UserParams.CD_EMPRESA}/${item}/false/true`)}
                            >
                                <Card.Body>
                                    <Card.Title>{item}</Card.Title>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
                
            </div>
        </div>
    )
}

export default MesaArea;
import { Outlet, useParams } from "react-router-dom";
import useProduto from "../../Hooks/Produto";
import { useContext } from "react";
import { CtxParams } from "../../Contexts/ctxParams";

const Produto = () => {
    const { cdEmpresa, cdProduto } = useParams();
    const UserParams = useContext(CtxParams);
    const Produto = useProduto(UserParams.CD_EMPRESA ? String(UserParams.CD_EMPRESA) : cdEmpresa, cdProduto);

    return (
        <Outlet context={Produto} />
    )
}

export default Produto;
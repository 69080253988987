import { Outlet, useParams } from "react-router-dom";
import useEmpresaIntegracao from "../../../Hooks/Empresa/EmpresaIntegracao";

const EmpresaIntegracao = () => {
    const { cdEmpresaIntegracao } = useParams();
    const EmpresaIntegracao = useEmpresaIntegracao(cdEmpresaIntegracao);

    return (
        <Outlet context={EmpresaIntegracao} />
    )
}

export default EmpresaIntegracao;
import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import { DeepPartial } from "../../Models/genericInterfaces";
import { IEstoqueMovimento, IEstoqueMovimentos, IProduto, IProdutos } from "../../Models/cardapio";

const useEstoqueMovimentoController = (updateQueryKey?: string, isArray: boolean = true) : IUseEstoqueMovimentoController => {
    const API = useAPI();
    const queryClient = useQueryClient();

    const primaryKey = 'CD_MOVIMENTO';
    const secondaryKey = 'CD_PRODUTO';
    const endpoint = 'estoqueMovimento';

    const mutAlterar = useMutation(
        (data : DeepPartial<IEstoqueMovimento>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IEstoqueMovimento>) => {
            
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutDeletar = useMutation(
        (data : DeepPartial<IEstoqueMovimento>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IEstoqueMovimento>) => {
                queryClient.setQueryData<IProdutos>(
                    'listaProduto',
                    (oldData: any) => {
                        if (oldData) {
                            return (oldData as IProdutos).map((item: IProduto) => {
                                return {
                                    ...item,
                                    ESTOQUE: (Number(item.ESTOQUE) + (Number(data.QUANTIDADE) * -1))
                                }
                            })
                        }
                        return oldData;
                    }
                );
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IEstoqueMovimento>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IEstoqueMovimento) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscarProduto = useMutation(
        (data : DeepPartial<IEstoqueMovimento>) => 
            API.get(`${endpoint}/buscarTodos/${data[secondaryKey]}`, "")
        , {
            onSuccess: (ret: IEstoqueMovimentos) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IEstoqueMovimento>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IEstoqueMovimento>) => {
                queryClient.setQueryData<IProdutos>(
                    'listaProduto',
                    (oldData: any) => {
                        if (oldData) {
                            return (oldData as IProdutos).map((item: IProduto) => {
                                return {
                                    ...item,
                                    ESTOQUE: data.MOVIMENTO === 'E' ? (Number(item.ESTOQUE) + Number(data.QUANTIDADE)) : (Number(item.ESTOQUE) + (Number(data.QUANTIDADE) * -1))
                                }
                            })
                        }
                        return oldData;
                    }
                );
            },
            onError: (error: any) => {
                alert(error)
            }
    });


    return {
        Deletar     : mutDeletar,
        Alterar     : mutAlterar,
        Cadastrar   : mutCadastrar,
        Buscar      : mutBuscar,
        BuscarProduto: mutBuscarProduto,
    }
}

export interface IUseEstoqueMovimentoController {
    Deletar     : UseMutationResult<any, any, DeepPartial<IEstoqueMovimento>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<IEstoqueMovimento>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IEstoqueMovimento>, unknown>;
    Buscar      : UseMutationResult<IEstoqueMovimento, any, DeepPartial<IEstoqueMovimento>, unknown>
    BuscarProduto: UseMutationResult<IEstoqueMovimentos, any, DeepPartial<IEstoqueMovimento>, unknown>
}

export default useEstoqueMovimentoController;
import { useMemo, useState } from 'react';
import { UseMutationResult, useMutation } from 'react-query';
import { IConsultaEmpresa, ILogin } from '../../Models/autenticacao';
import useAPI from '../utils/useAPI';
import { DeepPartial } from '../../Models/genericInterfaces';

const useAutenticacao = () : IUseAutenticacao => {
    const API = useAPI();

    const getToken = () : string => {
        const tokenString = localStorage.getItem('token');
        return tokenString!;
    };

    const [token, setToken] = useState(getToken());

    const mutLogin = useMutation((data : ILogin) => API.post('usuarios/autenticacao', data));

    const mutConsultaEmpresa = useMutation((data : DeepPartial<ILogin>) => {
            return API.post('usuarios/consultarEmpresas', data)
        }, {
            onSuccess: (ret: any) => {
            }
        }
    );

    const dsListaEmpresas = useMemo<IConsultaEmpresa[]>(() => 
        mutConsultaEmpresa.isSuccess ? 
            mutConsultaEmpresa.data
        :
            [{} as IConsultaEmpresa]
    , [mutConsultaEmpresa]);

    const saveToken = (userToken : ILogin) => {
        const objLogin = JSON.parse(JSON.stringify(userToken));
        
        if (typeof objLogin.token !== 'undefined') {
            localStorage.setItem('token', userToken.token!);
            localStorage.setItem('params', JSON.stringify({
                ...JSON.parse(localStorage.getItem('params') ?? '{}'),
                CD_USUARIO  : userToken.CD_USUARIO,
                CD_EMPRESA  : userToken.CD_EMPRESA,
                ENTREGADOR  : userToken.ENTREGADOR,
                GARCOM      : userToken.GARCOM,
                TAXA        : userToken.TAXA,
                TAXA_ENTREGA: userToken.TAXA_ENTREGA,
                RECEBE_TAXA : userToken.RECEBE_TAXA,
                UF          : userToken.UF,
                CIDADE      : userToken.CIDADE
            }));
            setToken(userToken.token!);
        }
    };

    return {
        setToken: saveToken,
        token,
        getToken,
        mutLogin,
        mutConsultaEmpresa,
        dsListaEmpresas
    };
}

export interface IUseAutenticacao {
    setToken            : (userToken: ILogin) => void;
    token               : string;
    getToken            : () => string;
    mutLogin            : UseMutationResult<any, unknown, ILogin, unknown>;
    mutConsultaEmpresa  : UseMutationResult<IConsultaEmpresa[], unknown, DeepPartial<ILogin>, unknown>
    dsListaEmpresas     : any;
}

export default useAutenticacao;
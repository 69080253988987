export interface IReactTable_Data {
    id?: string;
    Header?: any;
    Cell?: any;
    accessor?: string;
};
export interface IReactTable_Grid extends Array<IReactTable_Data>{};

export interface IReactTable_InitState {
    hiddenColumns?: Array<any>;
    pageSize?: number;
};

export interface IGenericInformation {
    CHAVE: string;
    VALOR: any;
};
export interface IGenericInformations extends Array<IGenericInformation>{};

export enum EMode {
    Browse  = 'browse',
    Edit    = 'edit',
    Include = 'include'
};

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>> | undefined
    : T[P] extends ReadonlyArray<infer V>
    ? ReadonlyArray<DeepPartial<V>> | undefined
    : DeepPartial<T[P]> | undefined;
};

export interface IOtherQuery {
    QueryKey: string;
    Params?: object | number | string; 
}

export interface IOFF_REACT_TABLE_PROPS {
    alert: boolean;
    color: string;
}

export interface ILog {
    CD_EMPRESA  : number;
    INFO        : string;
    ERRO        : string;
    ORIGEM      : string;
    CD_LOG?     : number;
    DT_LOG?     : string;
}
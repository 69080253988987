import { useNavigate, useOutletContext } from "react-router-dom";
import { IUseFormaPagamento } from "../../Hooks/FormaPagamento";
import { IFormaPagamento } from "../../Models/FormaPagamento";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { useContext, useEffect, useState } from "react";
import useApp from "../../Hooks/utils/useApp";
import { Button, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import BadgeValidacao from "../../Components/formularios/BadgeValidacao";
import { every } from "lodash";
import FormRodape from "../../Components/formularios/FormRodape";
import useCondicaoPagamento from "../../Hooks/CondicaoPagamento";
import { v4 as uuidv4 } from "uuid";
import FormaPagamentoCadastroIntegracao from "./cadastroIntegracao";

const FormaPagamentoCadastro = () => {
    const FormaPagamento = useOutletContext<IUseFormaPagamento>();
    const AppParams = useContext(CtxAppParams);
    const { RouteManager } = useApp();
    const navigate = useNavigate();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const CondicaoPagamento = useCondicaoPagamento();

    useEffect(() => {
        AppParams.setPagina(FormaPagamento.Pagina.Pages[0].Description);
    })

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                // validationSchema={schemaCadTaxa}
                onSubmit={(e : IFormaPagamento) => {
                    if (FormaPagamento.Codigo) {
                        FormaPagamento.Controller.Alterar.mutateAsync(e)
                        .then(() => {
                            navigate(RouteManager(FormaPagamento.Pagina.Path, []));
                        });
                    } else {
                        FormaPagamento.Controller.Cadastrar.mutateAsync(e)
                        .then(() => {
                            navigate(RouteManager(FormaPagamento.Pagina.Path, []));
                        });
                    }
                }}
                initialValues={{
                    CD_FORMA             : FormaPagamento.Datasource.Item.CD_FORMA          ?? undefined,
                    CD_CONDICAO_PAGTO    : FormaPagamento.Datasource.Item.CD_CONDICAO_PAGTO ?? CondicaoPagamento.Datasource.Lista[0].CD_CONDICAO ?? undefined,
                    ATIVO                : FormaPagamento.Datasource.Item.ATIVO             ?? true,
                    NOME                 : FormaPagamento.Datasource.Item.NOME              ?? '',
                    PAGTO_INTEGRACAO     : FormaPagamento.Datasource.Item.PAGTO_INTEGRACAO  ?? []
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit}>
                            <Form.Group className="mt-3">
                                <Form.Label>Forma de Pagamento:</Form.Label>
                                <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                                <Form.Control 
                                    name={'NOME'}
                                    type={'text'}
                                    value={values.NOME}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NOME, confirmado])}
                                    placeholder="A Vista, PicPay..." 
                                    size="lg"
                                />
                            </Form.Group>    

                            <Form.Group className="mt-3">
                                <Form.Label>Condição (Tipo):</Form.Label>
                                <BadgeValidacao validacao={errors.CD_CONDICAO_PAGTO} confirmado={confirmado} />
                                <Form.Select 
                                    name={'CD_CONDICAO_PAGTO'}
                                    value={values.CD_CONDICAO_PAGTO ?? ''}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NOME, confirmado])}
                                    size="lg"
                                >
                                    {
                                        CondicaoPagamento.Datasource.Lista.map(Condicao => 
                                            <option key={uuidv4()} value={Condicao.CD_CONDICAO}>{Condicao.NOME}</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>

                            {
                                FormaPagamento.Codigo
                                    ? <FormaPagamentoCadastroIntegracao />
                                    : null
                            }                            
                        <FormRodape>
                            <Button variant="outline-danger" size="lg" onClick={() => navigate(RouteManager(FormaPagamento.Pagina.Path, []))}>Cancelar</Button>
                            <Button variant="success" size="lg" type="submit" onClick={ () => setConfirmado(true) }>Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default FormaPagamentoCadastro;
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DeepPartial, IReactTable_Grid } from "../../Models/genericInterfaces";
import useApp from "../utils/useApp";
import { IUseVendaPagamentoController } from "./controller";
import { ICtxAppParams_ModalInputs } from "../../Models/ctxAppParams";
import { IPagamento, IPagamentos, IVendaPagamento } from "../../Models/VendaPagamento";
import { v4 as uuidv4 } from "uuid";
import { Badge, Button, Form, InputGroup } from "react-bootstrap";
import useMask from "../utils/useMask";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import Tabela from "../../Components/tabela";
import { IUsePedidoController } from "../Pedido/controller";
import { IUseEntregaController } from "../cadEntrega/controller";
import { IUseMesaController } from "../Mesa/controller";
import { EStatus } from "../../Models/cadEntrega";
import { IUseFormaPagamentoIntegracaoController } from "../FormaPagamento/controllerIntegracao";

const useVendaPagamentoModal = (Controller: IUseVendaPagamentoController, ControllerVenda?: IUsePedidoController | IUseMesaController | IUseEntregaController, ControllerIntegracao?: IUseFormaPagamentoIntegracaoController) : IUseVendaPagamentoModal => {
    const { Modal } = useApp();
    const formatter = useMask();

    const [data, setData] = useState<DeepPartial<IVendaPagamento>>({PAGAMENTO: []});
    const [dataItem, setDataItem] = useState<IPagamento>({CD_FORMA: (undefined ?? Number(Controller.ListarFormas.data && Controller.ListarFormas.data[0].CD_FORMA)), VALOR: 0});
    const [totalizador, setTotalizador] = useState<number>(0);

    const [integrou, setIntegrou] = useState<boolean>(false);

    const refData = useRef<DeepPartial<IVendaPagamento>>(data);

    useEffect(() => {
        if (data.PAGAMENTO !== undefined) {
            const total = (data.PAGAMENTO as IPagamentos).reduce((acc, value) =>    
                acc + Number(value.VALOR), 0
            );

            setTotalizador(Number(Number(data.OFF_TOTAL ?? 0).toFixed(2)) - Number(total.toFixed(2)));
        }
    }, [data.OFF_TOTAL, data.PAGAMENTO]);

    const addItem = useCallback(() => {
        setData(prev => ({
            ...prev,
            PAGAMENTO: [...prev.PAGAMENTO as IPagamentos, {
                OFF_UUID    : uuidv4(),
                OFF_NOME    : Controller.ListarFormas.data?.find(value => value.CD_FORMA === dataItem.CD_FORMA)?.NOME,
                CD_FORMA    : dataItem.CD_FORMA ?? (Controller.ListarFormas.data![0].CD_FORMA),
                VALOR       : dataItem.VALOR
            }]
        }));

        setDataItem({CD_FORMA: (undefined ?? Number(Controller.ListarFormas.data && Controller.ListarFormas.data[0].CD_FORMA)), VALOR: 0});
    }, [Controller, dataItem]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: 'FORMA',
            Header:
            <Form.Group>
            <Form.Label>Forma de Pagamento:</Form.Label>
            {/* <BadgeValidacao validacao={errors.CD_CONDICAO_PAGTO} confirmado={confirmado} /> */}
            <Form.Select 
                id={'VendaPagamentoModalFieldCdFormaPagamento'}
                name={'CD_FORMA_PAGAMENTO'}
                value={dataItem.CD_FORMA}
                onChange={e => setDataItem(prev => ({...prev, CD_FORMA: Number(e.target.value)}))}
                // isInvalid={every([errors.NOME, confirmado])}
                size="lg"
            >
                {
                    Controller.ListarFormas.data && Controller.ListarFormas.data.map(Forma => 
                        <option key={uuidv4()} value={Forma.CD_FORMA}>{Forma.NOME}</option>
                    )
                }
            </Form.Select>
        </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return original['OFF_NOME']
            }
        },
        {
            id: 'VALOR',
            Header: //'Quantidade',
            <Form.Group>
            <Form.Label>Valor:</Form.Label>
            {/* <BadgeValidacao validacao={validacaoErros.VLR_TROCO} confirmado={validacaoErros.VLR_TROCO !== null} /> */}
            <InputGroup>
                <InputGroup.Text id="coin">R$</InputGroup.Text>
                <Form.Control 
                    autoComplete="off"
                    id={'VendaPagamentoModalFieldValor'}
                    name={'VALOR'}
                    type={'number'}
                    step={'0.05'}
                    min={'0'}
                    max={totalizador >= 0 ? String(totalizador) : '0'}
                    onFocus={(e) => e.currentTarget.select()}
                    onBlur={() => {
                        setDataItem((prev) => ({
                            ...prev as IPagamento, 
                            VALOR: Number(prev.VALOR) > Number(totalizador) ? formatter.setMaskMoney(Number(totalizador ?? 0), "", ".") : formatter.setMaskMoney(Number(prev.VALOR ?? 0), "", ".")
                        })) 
                    }}
                    onWheel={(e: any) => e.target.blur()}
                    value={dataItem.VALOR}
                    onKeyUp={(k) => {
                        if (k.key === 'Enter') {
                            if (!((Number(dataItem.VALOR ?? 0) <= 0) || (totalizador <= 0))) {
                                addItem();
                                k.currentTarget.focus();
                            } else {
                                document.getElementById("ModalBtnOk")?.focus()
                            }                            
                        }
                    }}
                    onKeyDown={(k) => {
                        if (k.key === '.') {
                            k.preventDefault();

                            if (!String(dataItem.VALOR).includes(',')) {
                                (document.getElementById("VendaPagamentoModalFieldValor") as HTMLInputElement).type = 'text';
                                (document.getElementById("VendaPagamentoModalFieldValor") as HTMLInputElement).value = String(dataItem.VALOR + '.')
                            };                            
                        } 
                    }}
                    onChange={(e) => {
                        setDataItem((prev) => ({
                            ...prev,
                            VALOR: Number(e.target.value)
                        }));                        
                        (document.getElementById("VendaPagamentoModalFieldValor") as HTMLInputElement).type = 'number';
                    }}                
                    // isInvalid={every([validacaoErros.VLR_TROCO, validacaoErros.VLR_TROCO !== null])} 
                    size="lg"
                />
            </InputGroup>
        </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return formatter.setMaskMoney(original['VALOR'], 'R$')
            }
        },
        {
            id: "acoes",
            Header: 
                <div className="w-100 h-100 d-flex align-items-end justify-content-center">
                    <Button 
                        className="mb-1"
                        disabled={ ((Number(dataItem.VALOR ?? 0) <= 0) || (totalizador <= 0)) }
                        onClick={() => addItem()}
                    >
                        <FaPlus />
                    </Button>
                </div>,
            Cell: ({row: {original}}: any) : any => 
                <Button 
                    variant="danger"
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        const novo = (data.PAGAMENTO! as IPagamentos).filter(item =>
                            item.OFF_UUID !== original['OFF_UUID']
                        );
                        
                        setData(prev => ({
                            ...prev,
                            PAGAMENTO: novo
                        }));
                    }}
                >
                    <FaTrashAlt />
                </Button>
        },
    ], [dataItem, setDataItem, setData, Controller, formatter, data.PAGAMENTO, totalizador, addItem]);

    const Pagamento = useCallback(async (Dados: DeepPartial<IVendaPagamento>) => {
        return await new Promise<{success: boolean}> ((resolve, reject) => {
            if ((Dados.CD_ENTREGA !== refData.current.CD_ENTREGA) || (Dados.CD_MESA !== refData.current.CD_MESA) || (Dados.CD_PEDIDO !== refData.current.CD_PEDIDO)) {    
                (refData.current as unknown) = {CD_PEDIDO: Dados.CD_PEDIDO, CD_ENTREGA: Dados.CD_ENTREGA, CD_MESA: Dados.CD_MESA, OFF_TOTAL: Dados.OFF_TOTAL, OFF_FORMA: Dados.OFF_FORMA, OFF_TROCO: Dados.OFF_TROCO, FINALIZA_VENDA: Dados.FINALIZA_VENDA, PAGAMENTO: []} as IVendaPagamento
                setData(prev => ({...Dados, ...prev, CD_PEDIDO: Dados.CD_PEDIDO, CD_ENTREGA: Dados.CD_ENTREGA, CD_MESA: Dados.CD_MESA, OFF_TOTAL: Dados.OFF_TOTAL, OFF_FORMA: Dados.OFF_FORMA, OFF_TROCO: Dados.OFF_TROCO, FINALIZA_VENDA: Dados.FINALIZA_VENDA}));
            };
    
            const gravar = async (Values: ICtxAppParams_ModalInputs) => {
                setIntegrou(false);

                if (totalizador === 0) {
                    Controller.Cadastrar.mutateAsync(data)
                        .then(() => {
                            if (data.CD_MESA) {
                                ControllerVenda?.Alterar.mutateAsync({CD_MESA: data.CD_MESA, STATUS: EStatus.Finalizada})
                                .then(() => ControllerVenda?.Listar.refetch())
                            } else {
                                ControllerVenda?.Listar.refetch();
                                // ControllerVenda?.Buscar.reset();
                            }
                            
                            // setData((prev) => ({...prev, PAGAMENTO: []}));
                            setData({PAGAMENTO: []})
                            refData.current = {PAGAMENTO: []} as IVendaPagamento
                            resolve({success: true});
                        })
                        .catch(e => {
                            // alert(e);
                            reject();
                        })
                } else {
                    if ((Number(Number(dataItem.VALOR ?? 0).toFixed(2)) === Number(Number(data.OFF_TOTAL ?? 0).toFixed(2))) && ((data.PAGAMENTO?.length === 0) || data.PAGAMENTO === undefined)) {
                        Controller.Cadastrar.mutateAsync({...data, PAGAMENTO: [{
                            OFF_UUID    : uuidv4(),
                            CD_FORMA    : dataItem.CD_FORMA,
                            VALOR       : Number(dataItem.VALOR)
                        }]})
                            .then(() => {
                                if (data.CD_MESA) {
                                    ControllerVenda?.Alterar.mutateAsync({CD_MESA: data.CD_MESA, STATUS: EStatus.Finalizada})
                                    .then(() => ControllerVenda?.Listar.refetch())
                                } else {
                                    ControllerVenda?.Listar.refetch();
                                    ControllerVenda?.Buscar.reset();
                                }
                                // setDataItem({CD_FORMA: (undefined ?? Number(Controller.ListarFormas.data && Controller.ListarFormas.data[0].CD_FORMA)), VALOR: 0})
                                resolve({success: true});
                            })
                            .catch(e => {
                                // alert(e);
                                reject();
                            })
                    } else {
                        alert('O valor restante do pagamento deve ser zero.')
                        reject();
                    }
                }
            };     
    
            Modal({
                Visibilidade    : true,
                Titulo          : 'Pagamento',
                Descricao       : ``,
                Anexos			: <>
                    <div className="w-100 d-flex justify-content-center mb-3 flex-wrap">
                        <div className="w-100 d-flex justify-content-center mb-3 flex-wrap">
                            <Badge 
                                className="d-flex align-items-end mx-3" 
                                style={{fontSize: '1.2rem'}} 
                                bg={'light'}
                                text="success"
                            >
                                Total: {formatter.setMaskMoney(Number(data.OFF_TOTAL ?? 0) + Number(data.OFF_TROCO ?? 0), 'R$')}
                            </Badge>

                            <Badge 
                                className="d-flex align-items-end mx-3" 
                                style={{fontSize: '1.2rem'}} 
                                bg={'light'}
                                text="danger"
                            >
                                    {
                                        `${Number(data.OFF_TROCO ?? 0) >= 0 
                                            ? `Troco: ${formatter.setMaskMoney(Number(data.OFF_TROCO ?? 0), 'R$')}`
                                            : ''
                                        }`
                                    }
                            </Badge>
                        </div>

                        <Badge 
                            className="d-flex align-items-end" 
                            style={{fontSize: '1.2rem'}} 
                            bg={
                                totalizador < 0
                                    ? 'danger'
                                    : totalizador === 0
                                        ? 'success'
                                        : 'primary'
                            }
                        >
                                {
                                    `${totalizador < 0 
                                        ? `Inválido: ${formatter.setMaskMoney(Number(totalizador < 0 ? (totalizador * -1) : totalizador), 'R$')}`
                                        : totalizador === 0
                                            ? `A receber: ${formatter.setMaskMoney(Number(data.OFF_TOTAL ?? 0), 'R$')}` 
                                            : `Valor a receber: ${formatter.setMaskMoney(Number(totalizador < 0 ? (totalizador * -1) : totalizador), 'R$')}`}`
                                }
                        </Badge>
                    </div>
                    <Tabela dados={data.PAGAMENTO as IPagamentos} colunas={gridLista}/>
                </>,
                Inputs          : undefined,
                Variante        : 'success',
                onSubmit        : gravar,
                onCancel        : () => setIntegrou(false)
            })
        })
    }, [Modal, Controller, setData, data, gridLista, totalizador, formatter, ControllerVenda?.Listar, ControllerVenda?.Alterar, dataItem, ControllerVenda?.Buscar]);

    useEffect(() => {
        if (data.CD_ENTREGA || data.CD_MESA || data.CD_PEDIDO) {
            if ((data.CD_ENTREGA !== refData.current.CD_ENTREGA) || (data.CD_MESA !== refData.current.CD_MESA) || (data.CD_PEDIDO !== refData.current.CD_PEDIDO)) {
                setData(refData.current);
                Pagamento(refData.current);
            } else { // primeira vez
                Pagamento(data);

                if (!integrou) {
                    setIntegrou(true);
                    // document.getElementById('VendaPagamentoModalFieldCdFormaPagamento')?.focus();
                    
                    const forma = ControllerIntegracao && ControllerIntegracao.Listar.data && ControllerIntegracao.Listar.data.find((item) => 
                        item.STRING_FORMA.toLowerCase().trim() === (refData.current.OFF_FORMA ?? "").toLowerCase().trim()
                    );
                    
                    if (forma) {
                        setData({
                            ...refData.current,
                            PAGAMENTO: [
                                {
                                    OFF_NOME: formatter.getCapitalized(forma.STRING_FORMA).trim(),
                                    CD_FORMA: forma.CD_FORMA,
                                    VALOR: refData.current.OFF_TOTAL
                                } as IPagamento                   
                            ]
                        });
                    } else if (data.PAGAMENTO && ((data.PAGAMENTO.length ?? 0) > 0)) {
                        setData({...refData.current, PAGAMENTO: []})
                    }
                }
            }
        };
    }, [dataItem, totalizador, data]) //eslint-disable-line

    const Deletar = useCallback((Dados: DeepPartial<IVendaPagamento>) => {
        const gravar = (Values: ICtxAppParams_ModalInputs) => {
            Controller.Deletar.mutateAsync({CD_PAGTO: Dados.CD_PAGTO})
            .then(() => ControllerVenda?.Listar.refetch())
            .catch((e) => alert(e))
        };

        Modal({
            Visibilidade    : true,
            Titulo          : 'Exclusão forma de pagamento',
            Descricao       : `Tem certeza que deseja desfazer o pagamento? Isso irá excluir os dados da forma de pagamento atrelada a venda.`,
            Anexos			: undefined,
            Inputs          : undefined,
            Variante        : 'danger',
            onSubmit        : gravar
        })
    }, [Modal, Controller.Deletar, ControllerVenda?.Listar]);

    return {
        Pagamento   : Pagamento,
        Deletar     : Deletar
    }
}

export interface IUseVendaPagamentoModal {
    Pagamento   : (Dados: DeepPartial<IVendaPagamento>) => Promise<{success: boolean}>;
    Deletar     : (Dados: DeepPartial<IVendaPagamento>) => void;
}

export default useVendaPagamentoModal;
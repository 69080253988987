import useAPI from "../utils/useAPI";
import { useMemo, useContext, useState, useEffect, useCallback } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EStatus, IEntrega, IEntregaItem, IEntregas, ITotais } from "../../Models/cadEntrega";
import { CtxParams } from "../../Contexts/ctxParams";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { MdDeliveryDining } from "react-icons/md";
import { TbCashBanknote, TbCashBanknoteOff } from "react-icons/tb";
import { ETpFiltro } from "../../Models/cadEntrega";
import useMask from "../utils/useMask";
import { DeepPartial, IGenericInformations, IReactTable_Grid, IReactTable_InitState } from "../../Models/genericInterfaces";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import SelectEntregador from "../../Components/formularios/SelectEntregador";
import useQryUsuarios from "../../Data/Usuarios";
import useIntegracoes from "../utils/useIntegracoes";
import { ICadEmpresaIntegracoes } from "../../Models/cadEmpresaIntegracao";
import useQryEmpresaIntegracoes from "../../Data/EmpresaIntegracoes";
import useEntregaModais, { IUseEntregaModais } from "./modais";
import useEntregaController, { IUseEntregaController } from "./controller";
import moment from 'moment';
import useEntregaUtils, { IUseEntregaUtils } from "./utils";
import useCadEntrega, { IUseCadEntrega } from ".";
import { ICadUsuario, ICadUsuarios } from "../../Models/cadUsuario";
import useVendaPagamento, { IUseVendaPagamento } from "../VendaPagamento";
import useReports, { IUseReports } from "../../Reports";
import { IoFastFoodOutline } from "react-icons/io5";

const useCadEntregaLista = (DtCompetencia: string, CdEntrega?: string, PgOrigem?: string) : IUseCadEntregaLista => {
    const DataValida = moment(DtCompetencia, "YYYY-MM-DD", true).isValid();
    const API = useAPI();
    const navigate = useNavigate();
    const UserParams = useContext(CtxParams);
    const { Filtros, BloqueiaSincronizacao } = useContext(CtxAppParams);
    const formatter = useMask();
    const Usuarios = useQryUsuarios();
    const EmpresaIntegracoes = useQryEmpresaIntegracoes();
    const Integracoes = useIntegracoes(true, true);
    const Controller = useEntregaController('entregaLista', true, DataValida, DtCompetencia);
    const Cadastro = useCadEntrega(CdEntrega ?? "-1", Controller);

    const Modal = useEntregaModais(Controller);
    const VendaPagamento = useVendaPagamento(Controller);

    const Reports = useReports();

    const [totalEntregador, setTotalEntregador] = useState<number>(0);
    const [totalPotencial, setTotalPotencial] = useState<number>(0);
    const [totalEntregasFinalizadas, setTotalEntregasFinalizadas] = useState<number>(0);

    const dsLista = useMemo<IEntregas>(() => 
        Controller.Listar.isSuccess && Controller.Listar.data ? 
            Controller.Listar.data.filter((dado: IEntrega) => {
                if (Filtros.Entrega.tipoPesquisa === ETpFiltro.Cliente) {
                    return formatter.getNormalized((dado.CLIENTE && dado.CLIENTE.NOME) ?? "").toLowerCase().indexOf(formatter.getNormalized(Filtros.Entrega.pesquisa).toLowerCase()) !== -1
                } else if (Filtros.Entrega.tipoPesquisa === ETpFiltro.Entregador) {
                    if (Filtros.Entrega.pesquisa.trim() === '' && !(!!(dado.USUARIO && dado.USUARIO.NOME))) {
                        return dado
                    } else if (Filtros.Entrega.pesquisa.trim() !== '') {
                        return dado.USUARIO.NOME && formatter.getNormalized((dado.USUARIO && dado.USUARIO.NOME) ?? "").toLowerCase().indexOf(formatter.getNormalized(Filtros.Entrega.pesquisa).toLowerCase()) !== -1
                    } else {
                        return undefined
                    }
                } else if (Filtros.Entrega.tipoPesquisa === ETpFiltro.Endereco) {
                    return formatter.getNormalized(formatter.getEnderecoString(dado.ENDERECO)!?.toLowerCase() ?? "").indexOf(formatter.getNormalized(Filtros.Entrega.pesquisa.toLowerCase())) !== -1
                } else if (Filtros.Entrega.tipoPesquisa === ETpFiltro.Pagamento) {
                    return formatter.getNormalized(dado.FORMA_PAGTO!?.toLowerCase() ?? "").indexOf(formatter.getNormalized(Filtros.Entrega.pesquisa).toLowerCase()) !== -1
                } else if (Filtros.Entrega.tipoPesquisa === ETpFiltro.Pedido) {
                    return formatter.getNormalized(dado.ID_PLUS!?.toLowerCase() ?? "").indexOf(formatter.getNormalized(Filtros.Entrega.pesquisa).toLowerCase()) !== -1
                } else if (Filtros.Entrega.tipoPesquisa === ETpFiltro.Senha) {
                    return formatter.getNormalized(dado.NR_SENHA!?.toLowerCase() ?? "").indexOf(formatter.getNormalized(Filtros.Entrega.pesquisa).toLowerCase()) !== -1
                } else {
                    return dado
                }
            }).filter((dado: IEntrega) => {
                if (Filtros.Entrega.filtroPagamento === 'S') {
                    return dado.PAGO === true
                } else if (Filtros.Entrega.filtroPagamento === 'N') {
                    return dado.PAGO === false
                } else {
                    return dado
                }
            }).filter((dado: IEntrega) => {
                if (Filtros.Entrega.filtroStatus === 'T') {
                    return dado
                } else {
                    return dado.STATUS === Filtros.Entrega.filtroStatus
                }
            })
        : 
            [{
                "CD_ENTREGA": null,
                "CD_EMPRESA": null,
                "CD_CLIENTE_ENDERECO": null,
                "CD_CLIENTE": null,
                "CD_USUARIO": null,
                "TAXA": null,
                "CEP": null,
                "VLR_PEDIDO": null,
                "VLR_TROCO": null,
                "FORMA_PAGTO": "",
                "STATUS": "",
                "DT_ENTREGA": null,
                "OBSERVACOES": "",
                "PAGO": null,
                "CLIENTE": {
                    "CD_CLIENTE": null,
                    "CD_EMPRESA": null,
                    "NOME": "",
                    "CELULAR": "",
                    "CD_ENDERECO_PADRAO": null
                },
                "USUARIO": {
                    "CD_USUARIO": null,
                    "NOME": "",
                    "CD_EMPRESA": null,
                    "TAXA": null,
                    "ENTREGADOR": null,
                    "CELULAR": ""
                },
                "ENDERECO": {
                    "CD_CLIENTE_ENDERECO": null,
                    "CD_CLIENTE": null,
                    "CD_EMPRESA": null,
                    "CEP": "",
                    "ENDERECO": "",
                    "ENDERECO_NUMERO": "",
                    "ENDERECO_BAIRRO": "",
                    "ENDERECO_CIDADE": "",
                    "ENDERECO_UF": ""
                }
            }]
    , [Controller.Listar, Filtros.Entrega, formatter]);

    const dsItem = useMemo<IEntrega>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data ) {
            const itemEncontrado = dsLista.find((item: IEntrega) => item.CD_ENTREGA === Controller.Buscar.data?.CD_ENTREGA);

            if (itemEncontrado) {
                return {
                    ...Controller.Buscar.data,
                    STATUS      : itemEncontrado.STATUS,
                    PAGO        : itemEncontrado.PAGO,
                    FORMA_PAGTO : itemEncontrado.FORMA_PAGTO,
                    CD_USUARIO  : itemEncontrado.CD_USUARIO
                }
            } else {
                return Controller.Buscar.data
            }
        }
        
        return {} as IEntrega
    }, [Controller.Buscar, dsLista]);
    const Utils = useEntregaUtils(dsItem);

    const retornaIdentificacaoPedido = (entrega: IEntrega) => {
        if (entrega.NR_SENHA) {
            return <Badge pill bg="light" text="danger">{`${entrega.NR_SENHA}`}</Badge>
        } else if (entrega.ID_PLUS) {
            return <Badge pill bg="light" text="danger">{`${((entrega.TIPO_INTEGRACAO?.slice(0, 5) === 'PLUSD') && (process.env.REACT_APP_DEMOMODE === 'S')) ? 'IFOOD' : entrega.TIPO_INTEGRACAO?.slice(0, 5)}-${entrega.ID_PLUS}`}</Badge>
        } else {
            return <></>
        }
    }

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            Header: "Pedido",
            // accessor: "CLIENTE.NOME_PEDIDO"
            Cell: ({row: {original}}: any) => {
                const entrega : IEntrega = original;
                return (
                    <>
                        <Row>
                            <div>
                                {
                                    process.env.REACT_APP_DEMOMODE === 'S'
                                    ? (entrega.CLIENTE.NOME_PEDIDO ?? entrega.CLIENTE.NOME).split(" ")[0]
                                    : (entrega.CLIENTE.NOME_PEDIDO ?? entrega.CLIENTE.NOME)
                                }
                            </div>
                        </Row>
                        <Row>
                            <div>{retornaIdentificacaoPedido(entrega)}</div>
                        </Row>
                    </>
                )
            }
        },
        {
            Header: "Endereço",
            // accessor: 'CLIENTE.ENDERECO'
            Cell: ({row: {original}}: any) : string => formatter.getEnderecoString(original.ENDERECO)
        },
        {
            Header: "Pagamento",
            Cell: (row: any) : any => 
                <div>
                    <Badge pill bg="primary">
                        {`${formatter.setOverflow(row.row.original.FORMA_PAGTO, 14)}`}
                    </Badge> <br />
                    <Badge pill bg="success">
                        {formatter.setMaskMoney(Number(row.row.original.VLR_PEDIDO ?? 0) + Number(row.row.original.VLR_TROCO ?? 0), "R$")}
                    </Badge>
                    {
                        row.row.original.VLR_TROCO > 0 ?
                            <Badge pill bg="warning" text="dark">
                                {`Troco: ${formatter.setMaskMoney(row.row.original.VLR_TROCO ?? 0, "R$")}`}
                            </Badge>
                        :
                            null
                    } <br />
                    <Badge pill bg="dark">
                        {`Taxa: ${formatter.setMaskMoney(row.row.original.TAXA ?? 0, "R$")}`}
                    </Badge>
                    <br />
                    {
                        row.row.original.VLR_ADICIONAL > 0 ?
                            <Badge pill bg="light" text="dark">
                                {`Adicional: ${formatter.setMaskMoney(row.row.original.VLR_ADICIONAL ?? 0, "R$")}`}
                            </Badge>
                        :
                            null
                    }
                </div>    
        },
        {
            id: "Entregador",
            Header: "Entregador",
            Cell: ({row: {original}}: any) : any =>
                <SelectEntregador 
                    key={String(original.CD_ENTREGA)} 
                    disabled={original.STATUS === EStatus.Finalizada || original.STATUS === EStatus.Cancelada || BloqueiaSincronizacao} 
                    CdEntrega={original.CD_ENTREGA} 
                    CdEntregador={original.CD_USUARIO} 
                    qryListaUsuarios={Usuarios} 
                    controller={Controller}
                />
        },
        {
            id: "Info",
            Header: <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                Status: <br/>
                                <BiTimeFive /> = Pendente <br/>
                                <IoFastFoodOutline /> = Em Preparação <br />
                                <MdDeliveryDining /> = Andamento <br/>
                                <FaCheck /> = Finalizado <br/>
                                <FaTimes /> = Cancelado
                                <hr/>
                                Pagamento: <br/>
                                <TbCashBanknote color="#198754" /> = Pago <br/>
                                <TbCashBanknoteOff color="#DC3545" /> = Não Pago
                            </Tooltip>
                        }
                    >
                        <h5><FaInfoCircle /></h5>
                    </OverlayTrigger>,
            Cell: (row : any) =>
                <h4>
                    {(() => {
                        switch (row.row.original.STATUS) {
                        case EStatus.Pendente:
                            return <BiTimeFive />
                        case EStatus.Preparacao:
                            return <IoFastFoodOutline />
                        case EStatus.Andamento:
                            return <MdDeliveryDining />
                        case EStatus.Finalizada:
                            return <FaCheck />
                        case EStatus.Cancelada:
                            return <FaTimes />
                        default:
                            return 'erro'
                        }
                    })()}
                    {
                        row.row.original.PAGO ?
                            <TbCashBanknote color="#198754" />
                        :
                            <TbCashBanknoteOff color="#DC3545" />
                    }
                </h4>
        },
    ], [formatter, Usuarios, BloqueiaSincronizacao, Controller]);

    const tableInitialState : IReactTable_InitState = { hiddenColumns: UserParams.ENTREGADOR ? ['Entregador'] : [] }

    useEffect(() => {
        let somaEntregador : number = 0;
        let somaPotencial  : number = 0;
        let somaEntregasFin: number = 0;
        dsLista.map((item: IEntrega) => {
            if(item.STATUS === 'F') {
                somaEntregador = somaEntregador + Number(item.TAXA) + Number(item.VLR_ADICIONAL ?? 0);
                somaEntregasFin = somaEntregasFin + 1;
            };

            return item;
        });
        dsLista.map((item: IEntrega) => item.STATUS !== 'C' ? somaPotencial = somaPotencial + Number(item.TAXA) + Number(item.VLR_ADICIONAL ?? 0) : null);

        setTotalEntregador(somaEntregador);
        setTotalPotencial(somaPotencial);
        setTotalEntregasFinalizadas(somaEntregasFin);
    }, [dsLista, setTotalEntregador, setTotalPotencial, setTotalEntregasFinalizadas]);

    const dsTotais = useMemo<ITotais>(() => 
        [{
            TxDiaria:       UserParams.TAXA ?? 0,
            TxPotencial:    UserParams.RECEBE_TAXA 
                                ? (totalPotencial ?? 0) 
                                : (dsLista.length * UserParams.TAXA_ENTREGA),
            TxReal:         UserParams.RECEBE_TAXA 
                                ? (totalEntregador ?? 0)
                                : (totalEntregasFinalizadas * UserParams.TAXA_ENTREGA),
            Total:          UserParams.RECEBE_TAXA
                                ? Number(UserParams.TAXA ?? 0) + Number(totalEntregador ?? 0)
                                : Number(UserParams.TAXA ?? 0) + Number((totalEntregasFinalizadas * UserParams.TAXA_ENTREGA))
        }]
    , [UserParams, totalPotencial, totalEntregador, totalEntregasFinalizadas, dsLista.length]);

    const gridTotais = useMemo<IReactTable_Grid>(() => [
        {
            Header: "Diária",
            Cell: (row: any) : any => formatter.setMaskMoney(row.row.original['TxDiaria'], 'R$')
        },
        {
            Header: "Potencial",
            Cell: (row: any) : any => formatter.setMaskMoney(row.row.original['TxPotencial'], 'R$')
        },
        {
            Header: "Real",
            Cell: (row: any) : any => formatter.setMaskMoney(row.row.original['TxReal'], 'R$')
        },
        {
            Header: "Total",
            Cell: (row: any) : any => formatter.setMaskMoney(row.row.original['Total'], 'R$')
        },
    ], [formatter]);

    const visualizarEntrega = (Entrega: IEntrega) => {
        navigate(`verEntrega/${Entrega.CD_ENTREGA}`);
    };

    const dsIntegracoes = useMemo<ICadEmpresaIntegracoes>(() => 
        EmpresaIntegracoes.isSuccess ? 
            EmpresaIntegracoes.data
        : 
            [{}] as ICadEmpresaIntegracoes
    , [EmpresaIntegracoes]);

    const sincronizarEntrega = () => {
        if ((String(DtCompetencia) !== "0") && (String(DtCompetencia) !== "")) {
            Integracoes.executeMutations(dsIntegracoes);
        };
    };

    const finalizarLote = useMutation(() => API.put(`entregas/alterarLote/${DtCompetencia}`, {"STATUS": 'F'}), {
        onSuccess: () => Controller.Listar.refetch(),
        onError: (ret: any) => alert(ret)
    });

    const dsEntregadores = useMemo<DeepPartial<ICadUsuarios>>(() =>
        Usuarios.isSuccess 
        ? Usuarios.data.filter((e: ICadUsuario) => e.ENTREGADOR === true && e.ATIVO === true)
        : [{
            CD_USUARIO: null,
            NOME: ""
        }]
    , [Usuarios]);

    const dsEndereco = useMemo<IGenericInformations>(() => [
        {
            CHAVE: "Bairro",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? 'Bairro teste' : (dsItem.ENDERECO && dsItem.ENDERECO.BAIRRO) ?? ""
        },
        {
            CHAVE: "Rua",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? 'Rua demonstração' : (dsItem.ENDERECO && dsItem.ENDERECO.LOGRADOURO) ?? ""
        },
        {
            CHAVE: "Número",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? '127' : (dsItem.ENDERECO && dsItem.ENDERECO.NUMERO) ?? ""
        },
        {
            CHAVE: "Cidade",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? 'CIDAD DEL BRASIL' : dsItem.ENDERECO && dsItem.ENDERECO.CIDADE + ' - ' + dsItem.ENDERECO.UF
        },
        {
            CHAVE: "CEP",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? '99999-999' : dsItem.ENDERECO && formatter.setMaskCep(dsItem.ENDERECO.CEP)
        },
        {
            CHAVE: "Complemento",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? 'Perto da referencia' : (dsItem.ENDERECO && dsItem.ENDERECO.COMPLEMENTO) ?? ""
        }
    ], [dsItem, formatter]);

    const dsDados = useMemo<IGenericInformations>(() => [
        {
            CHAVE: "Cliente",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? ((dsItem.CLIENTE && (dsItem.CLIENTE.NOME_PEDIDO ?? dsItem.CLIENTE.NOME)) ?? "").split(" ")[0] : ((dsItem.CLIENTE && (dsItem.CLIENTE.NOME_PEDIDO ?? dsItem.CLIENTE.NOME)) ?? "") 
        },
        {
            CHAVE: "Celular",
            VALOR: process.env.REACT_APP_DEMOMODE === 'S' ? '(99) 99999-9999' : dsItem.CLIENTE && formatter.setMaskPhone(dsItem.CLIENTE.CELULAR)
        },
        {
            CHAVE: "Pagamento",
            VALOR: (dsItem.PAGO ? 'Já foi pago ✅' : 'Pagamento pendente 👎')
        },
        {
            CHAVE: "Forma",
            VALOR: dsItem.FORMA_PAGTO ?? ""
        },
        {
            CHAVE: "Valor a Receber",
            VALOR: formatter.setMaskMoney(Number(dsItem.VLR_PEDIDO ?? 0) + Number(dsItem.VLR_TROCO ?? 0), "R$")
        },
        {
            CHAVE: "Valor do Pedido",
            VALOR: formatter.setMaskMoney(dsItem.VLR_PEDIDO ?? 0, "R$")
        },
        {
            CHAVE: "Troco",
            VALOR: formatter.setMaskMoney(dsItem.VLR_TROCO ?? 0, "R$")
        },
        {
            CHAVE: "ID Pedido/Senha",
            VALOR: dsItem.NR_SENHA ? `${dsItem.NR_SENHA ?? ""}` : `${((dsItem.TIPO_INTEGRACAO?.slice(0, 5) === 'PLUSD') && (process.env.REACT_APP_DEMOMODE === 'S')) ? 'IFOOD' : dsItem.TIPO_INTEGRACAO?.slice(0, 5)}-${dsItem.ID_PLUS}`
        },
        ...dsEndereco
    ], [dsItem, formatter, dsEndereco]);

    const dsOutrasInfo = useMemo<IGenericInformations>(() => [
        {
            CHAVE: "Data do Pedido",
            VALOR: formatter.setDate(new Date(dsItem.DT_ENTREGA!), {
                day: "2-digit",
                weekday: "short",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            }, false)
        },
        {
            CHAVE: "Entregador",
            VALOR: dsItem.USUARIO ? dsItem.USUARIO.NOME : ""
        },
        {
            CHAVE: "Taxa",
            VALOR: formatter.setMaskMoney(dsItem.TAXA ?? 0, "R$")
        },
        {
            CHAVE: "Finalização da Entrega",
            VALOR: formatter.setDate(!!dsItem.DT_FINALIZACAO ? new Date(dsItem.DT_FINALIZACAO) : null, {
                day: "2-digit",
                weekday: "short",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            }, false)
        },
        {
            CHAVE: "Observações",
            VALOR: dsItem.OBSERVACOES ?? ""
        }
    ], [dsItem, formatter]);

    const Buscar = useCallback((Entrega: DeepPartial<IEntrega>) => {
        const Codigo = Entrega.CD_ENTREGA || Number(CdEntrega);

        if (Codigo === -1 || Codigo === undefined ) {
            Controller.Buscar.reset();
        }

        if (dsItem.CD_ENTREGA !== Codigo) {
            Controller.Buscar.mutate({CD_ENTREGA: Codigo});
        }
    }, [Controller.Buscar, dsItem.CD_ENTREGA, CdEntrega]);

    useEffect(() => {
        if (CdEntrega !== undefined) {
            Buscar({CD_ENTREGA: Number(CdEntrega!)})
        }
    }, [CdEntrega]); //eslint-disable-line

    const gridItens = useMemo<IReactTable_Grid>(() => [
        {
            Header: "Qtd",
            Cell: (row: any) : any => {
                const item = row.row.original as IEntregaItem

                if (Number(Math.trunc(item.QTD)) === Number(item.QTD)) {
                    return Math.trunc(item.QTD)
                } else {
                    return formatter.setMaskMoney(item.QTD, '', ',');
                }                
            }
        },
        {
            Header: "Produto",
            Cell: (row: any) : any => {
                const item = row.row.original as IEntregaItem

                return <div className="px-3">
                    <Col xs={12}>
                        <Row style={{fontWeight: 'bold'}}>
                            {item.PRODUTO_NOME}
                        </Row> 
                        <Row style={{whiteSpace: 'pre-line'}}>
                            {item.OBS_PRODUTO}
                        </Row>
                    </Col>
                </div>
            }
        },        
        {
            Header: "Total",
            Cell: (row: any) : any => {
                const item = row.row.original as IEntregaItem
                return formatter.setMaskMoney(item.VLRUN * item.QTD, 'R$', ',');
            }
        },
        // {
        //     Header: "Total",
        //     Cell: (row: any) : any => formatter.setMaskMoney(row.row.original['Total'], 'R$')
        // },
    ], [formatter]);

    return {
        Lista: dsLista,
        Grid: gridLista,
        Item: dsItem,
        EstadoInicial: tableInitialState,

        ListaTotal: dsTotais,
        GridTotal: gridTotais,
        dsDados: dsDados,
        dsEndereco: dsEndereco,
        dsOutrasInfo: dsOutrasInfo,
        dsEntregadores: dsEntregadores,

        VerEntrega: visualizarEntrega,
        SincronizarEntrega: sincronizarEntrega,
        fazendoIntegracao: !Integracoes.isSuspended,
        FinalizarLote: finalizarLote,

        TotalEntregador: totalEntregador,
        Modal: Modal,
        Controller: Controller,
        Utils: Utils,
        Cadastro: Cadastro,
        VendaPagamento: VendaPagamento,
        GridItens: gridItens,
        Report: Reports
    };
};

export interface IUseCadEntregaLista {
    Lista               : IEntregas;
    Grid                : IReactTable_Grid;
    Item                : IEntrega;
    EstadoInicial       : IReactTable_InitState;
    ListaTotal          : ITotais;
    GridTotal           : IReactTable_Grid;
    dsDados             : IGenericInformations;
    dsEndereco          : IGenericInformations;
    dsOutrasInfo        : IGenericInformations;
    dsEntregadores      : DeepPartial<ICadUsuarios>;
    VerEntrega          : (Entrega: IEntrega) => void;
    SincronizarEntrega  : () => void;
    fazendoIntegracao   : boolean;
    FinalizarLote       : UseMutationResult<any, any, void, unknown>;
    TotalEntregador     : number;
    Modal               : IUseEntregaModais;
    Controller          : IUseEntregaController;
    Utils               : IUseEntregaUtils;
    Cadastro            : IUseCadEntrega;
    VendaPagamento      : IUseVendaPagamento;
    GridItens           : IReactTable_Grid;
    Report              : IUseReports;
}

export default useCadEntregaLista;
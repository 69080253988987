import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IReactTable_Grid } from "../../Models/genericInterfaces";
import { useFormikContext } from "formik";
import { IFormaPagamento, IFormaPagamentoIntegracao } from "../../Models/FormaPagamento";
import Tabela from "../../Components/tabela";
import { FaPlus, FaTrashAlt } from "react-icons/fa";

const FormaPagamentoCadastroIntegracao = () => {
    const { values, setFieldValue } = useFormikContext<IFormaPagamento>();
    const [descricao, setDescricao] = useState<string>('');

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: 'Descricao',
            Header: //'Nome',
                <Form.Group style={{width: 'auto', margin: '0'}}>
                    <Form.Label>Descrição:</Form.Label>
                    <Form.Control
                        name={'DESCRICAO'}
                        type={'text'}
                        value={descricao}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setDescricao(e.target.value)
                        }}
                        size="lg"
                    />
            </Form.Group>,
            Cell: ({row: {original}}: any) : string => {
                return original['STRING_FORMA']
            }
        },
        {
            id: "acoes",
            Header: 
                <Button 
                    variant="success"
                    // size="sm"
                    disabled={descricao.length <= 1}
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        if (values.PAGTO_INTEGRACAO && values.PAGTO_INTEGRACAO.find(item => item.STRING_FORMA.toLowerCase() === descricao.toLowerCase())) {
                            alert('Já existe uma descrição com esse nome para integração dessa forma de pagamento.')
                            return
                        }

                        setFieldValue("PAGTO_INTEGRACAO", [...values.PAGTO_INTEGRACAO!, {
                            STRING_FORMA: descricao
                        } as IFormaPagamentoIntegracao])

                        setDescricao('');
                    }}
                >
                    <FaPlus />
                </Button>,
            Cell: ({row: {original}}: any) : any => 
                <Button 
                    variant="danger" 
                    // size="sm" 
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        const novo = values.PAGTO_INTEGRACAO!.filter(item =>
                            item.STRING_FORMA.toLowerCase() !== original['STRING_FORMA'].toLowerCase()
                        );
                        setFieldValue("PAGTO_INTEGRACAO", novo);
                    }}
                >
                    <FaTrashAlt />
                </Button>
        },
    ], [descricao, setFieldValue, values]);

    return (
        <div className="mt-3">
            <Form.Label>Formas de pagamento de integrações:</Form.Label>
            <Tabela colunas={gridLista} dados={values.PAGTO_INTEGRACAO ?? []} />   
        </div>
    )
}

export default FormaPagamentoCadastroIntegracao;
import { useMemo } from "react";
import Tabela from "../../../Components/tabela";
import { IUseFechamento } from "../../../Hooks/Fechamento/lista";
import { IUseApp } from "../../../Hooks/utils/useApp";
import { ICtxParams } from "../../../Models/ctxParams";
import { Button } from "react-bootstrap";
import { FaReceipt } from "react-icons/fa";
import { MdDeliveryDining, MdOutlineTableBar } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface Props {
    Fechamento: IUseFechamento;
    formatter : any;
    UserParams: ICtxParams;
    App       : IUseApp;
}

const FechamentoResumoDetalhe = ({Fechamento, formatter, UserParams, App} : Props) => {
    const navigate = useNavigate();
    const TotalPagtos = useMemo(() => [
        ...Fechamento.Item.TOTAL_PAGTOS.map((item) => ({
            CHAVE: item.OFF_FORMA,
            VALOR: formatter.setMaskMoney((item.VLR_TOTAL ?? 0), "R$")
        }))
    ], [Fechamento, formatter]);

    return (
        <>
            <Tabela 
                hideHeader
                colunas={App.Grid} 
                dados={[
                    {
                        CHAVE: <div className="w-100 d-flex justify-content-evenly align-items-center">
                            <div style={{width: '2rem'}}>{Fechamento.Item.PEDIDOS.QTD_PEDIDOS ?? 0}</div>
                            <div style={{width: '8rem'}}>Pedidos</div>
                            <div>(+)</div>
                        </div>,
                        VALOR: <div className="w-100 d-flex justify-content-end align-items-center">
                            {formatter.setMaskMoney(Fechamento.Item.PEDIDOS.VLR_TOTAL ?? 0, 'R$')}
                            <Button className="mx-3" style={{width: '7rem'}} size="sm" onClick={() => navigate(`/pedido/${Fechamento.Item.DT_COMPETENCIA}`)}>
                                <FaReceipt style={{fontSize: '1rem', width: '1.1rem'}} /> Visualizar
                            </Button>
                        </div>
                        
                    },
                    {
                        CHAVE: <div className="w-100 d-flex justify-content-evenly align-items-center">
                            <div style={{width: '2rem'}}>{Fechamento.Item.MESAS.QTD_MESAS ?? 0}</div>
                            <div style={{width: '8rem'}}>Pedidos de Mesa</div>
                            <div>(+)</div>
                        </div>,
                        VALOR: <div className="w-100 d-flex justify-content-end align-items-center">
                            {formatter.setMaskMoney(Fechamento.Item.MESAS.VLR_TOTAL ?? 0, 'R$')}
                            <Button className="mx-3" style={{width: '7rem'}} size="sm" onClick={() => navigate(`/mesa/${Fechamento.Item.DT_COMPETENCIA}`)}>
                                <MdOutlineTableBar style={{fontSize: '1.2rem', width: '1.1rem'}} /> Visualizar
                            </Button>
                        </div>
                    },
                    {
                        CHAVE: <div className="w-100 d-flex justify-content-evenly align-items-center">
                            <div style={{width: '2rem'}}>{Fechamento.Item.ENTREGAS.QTD_ENTREGAS ?? 0}</div>
                            <div style={{width: '8rem'}}>Entregas</div>
                            <div>(+)</div>
                        </div>,
                        VALOR: <div className="w-100 d-flex justify-content-end align-items-center">
                            {formatter.setMaskMoney(Fechamento.Item.ENTREGAS.VLR_TOTAL ?? 0, 'R$')}
                            <Button className="mx-3" style={{width: '7rem'}} size="sm" onClick={() => navigate(`/entrega/${Fechamento.Item.DT_COMPETENCIA}`)}>
                                <MdDeliveryDining style={{fontSize: '1.2rem', width: '1.1rem'}} /> Visualizar
                            </Button>
                        </div>
                    },
                    {
                        CHAVE: <div className="w-100 d-flex justify-content-evenly align-items-center">
                            <div style={{width: '2rem'}}></div>
                            <div style={{width: '8rem'}}>Bruto</div>
                            <div>(=)</div>
                        </div>,
                        VALOR: <div className="w-100 d-flex justify-content-end align-items-center">
                            {formatter.setMaskMoney(
                                (
                                    (Fechamento.Item.PEDIDOS.VLR_TOTAL ?? 0) +
                                    (Fechamento.Item.MESAS.VLR_TOTAL ?? 0) + 
                                    (Fechamento.Item.ENTREGAS.VLR_TOTAL ?? 0)
                                ), 'R$'
                            )}
                            <div className="mx-3" style={{width: '7rem'}}></div>
                        </div>
                    },
                    {
                        CHAVE: <div className="w-100 d-flex justify-content-evenly align-items-center">
                            <div style={{width: '2rem'}}></div>
                            <div style={{width: '8rem'}}>Taxas</div>
                            <div>(-)</div>
                        </div>,
                        VALOR: <div className="w-100 d-flex justify-content-end align-items-center">
                            {formatter.setMaskMoney(Fechamento.Item.ENTREGAS.TAXA ?? 0, 'R$')}
                            <div className="mx-3" style={{width: '7rem'}}></div>
                        </div>
                    },
                    {
                        CHAVE: <div className="w-100 d-flex justify-content-evenly align-items-center">
                            <div style={{width: '2rem'}}></div>
                            <div style={{width: '8rem'}}>Líquido</div>
                            <div>(=)</div>
                        </div>,
                        VALOR: <div className="w-100 d-flex justify-content-end align-items-center">
                            {
                                formatter.setMaskMoney(
                                    (
                                        (Fechamento.Item.PEDIDOS.VLR_TOTAL ?? 0) +
                                        (Fechamento.Item.MESAS.VLR_TOTAL ?? 0) + 
                                        (Fechamento.Item.ENTREGAS.VLR_TOTAL ?? 0)
                                    ) - (
                                        Fechamento.Item.ENTREGAS.TAXA ?? 0
                                    ), 'R$'
                                )
                            }
                            <div className="mx-3" style={{width: '7rem'}}></div>
                        </div>
                    },
                ]}
            />

            <Tabela 
                hideHeader
                colunas={App.Grid} 
                dados={TotalPagtos}
            />
        </>
    );
};

export default FechamentoResumoDetalhe;
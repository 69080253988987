import { useContext, useEffect } from "react";
import { Button, Container, InputGroup, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import Tabela from "../../Components/tabela";
import { CtxParams } from "../../Contexts/ctxParams";
import FormRodape from "../../Components/formularios/FormRodape";
import useCadTaxaLista from "../../Hooks/cadTaxa/lista";
import { ETpFiltroTaxa } from "../../Models/cadTaxa";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useApp from "../../Hooks/utils/useApp";

const CadTaxaLista = () => {
    const TaxaLista = useCadTaxaLista();
    const UserParams = useContext(CtxParams);
    const AppParams = useContext(CtxAppParams);
    const {Filtrar} = useApp();

    useEffect(() => {
        AppParams.setPagina("Taxas de Entrega");
    });

    return (
        <>
            <ModalConfirmacao 
                titulo="Confirmação de Exclusão"
                show={TaxaLista.ShowModal} 
                onHide={TaxaLista.setShowModal}
                variant={"danger"}
                onSubmitHandler={() => {
                    TaxaLista.Excluir(TaxaLista.Selecionado);
                    TaxaLista.setShowModal(false);
                }}
            >
                Tem certeza que deseja excluir a taxa de entrega para o bairro {TaxaLista.Selecionado.BAIRRO}?
            </ModalConfirmacao>

            <Container className="verticalCenter">

                <div className="d-flex w-100 m-3 justify-content-around">
                    <InputGroup>
                        <DropdownButton
                            variant="outline-secondary"
                            title={
                                (() => {
                                    switch (AppParams.Filtros.Taxa.tipoPesquisa) {
                                    case ETpFiltroTaxa.Bairro:
                                        return 'Bairro'
                                    case ETpFiltroTaxa.CEP:
                                        return 'CEP'
                                    default:
                                        return 'erro'
                                    }
                                })()
                            }
                            id="TPFiltro"
                        >
                            <Dropdown.Item key={'1'} onClick={() => Filtrar('Taxa', { tipoPesquisa: ETpFiltroTaxa.Bairro })}>Bairro</Dropdown.Item>
                            <Dropdown.Item key={'2'} onClick={() => Filtrar('Taxa', { tipoPesquisa: ETpFiltroTaxa.CEP })}>CEP</Dropdown.Item>
                        </DropdownButton>
                        <Form.Control
                            placeholder="Filtro..."
                            aria-label="Filtro"
                            size="lg"
                            value={AppParams.Filtros.Taxa.pesquisa}
                            onChange={(e : React.ChangeEvent<HTMLInputElement>) => Filtrar('Taxa', { pesquisa: e.target.value}) }
                        />
                    </InputGroup>
                </div>

                <Tabela colunas={TaxaLista.Grid} dados={TaxaLista.Lista} handleClick={!UserParams.ENTREGADOR ? TaxaLista.Editar : undefined} estadoInicial={TaxaLista.EstadoInicial} />
                
                <FormRodape style={UserParams.ENTREGADOR ? {display: 'none'} : {}}>
                    <Button variant="success" size="lg" onClick={TaxaLista.Cadastrar}><TiPlus /> Cadastrar</Button>
                </FormRodape>
            </Container>
        </>
    );
};

export default CadTaxaLista;
import useAPI from "../../utils/useAPI";
import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { useMemo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMask from "../../utils/useMask";
import { ICadUsuario, ICadUsuarios } from "../../../Models/cadUsuario";
import { IReactTable_Grid } from "../../../Models/genericInterfaces";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";

const useCadUsuarioLista = () : IUseCadUsuarioLista => {
    const API = useAPI();
    const navigate = useNavigate();
    const formatter = useMask();
    const { Filtros } = useContext(CtxAppParams);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<ICadUsuario>({} as ICadUsuario);

    const qryLista = useQuery(
        'listaUsuario',(
        () => API.get('usuarios/buscarTodos/', '', false)),
        {}
    );

    const dsLista = useMemo<ICadUsuarios>(() => 
        qryLista.isSuccess ? 
            qryLista.data.filter((data: ICadUsuario) => {
                return data[Filtros.Usuario.tipoPesquisa]!.toLowerCase().indexOf(Filtros.Usuario.pesquisa.toLowerCase()) !== -1
            })
        : 
            [{
                "CD_USUARIO": null,
                "NOME": '',
                "CELULAR": '',
                "SENHA": '',
                "SENHA_CONFIRMA": '',
                "TAXA": 0,
                "ENTREGADOR": true
            }]
    , [qryLista, Filtros.Usuario]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "Nome",
            Header: "Nome",
            accessor: "NOME"
        },
        {
            id: "Celular",
            Header: "Celular",
            Cell: (row: any) : any => `${formatter.setMaskPhone(row.row.original['CELULAR'])}`
        },
        {
            id: "Tipo",
            Header: "Função",
            Cell: (row: any) : any => `${row.row.original['ENTREGADOR'] ? "Entregador" : "Gestor"}`
        },
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                <Button 
                    variant="danger"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        setSelecionado(row.row.original);
                        setShowModal(true);
                    }}
                >
                    <FaTrashAlt />
                </Button>
                </div>
        },
    ], [formatter]);

    const cadastrar = () => {
        navigate(`/empresa/usuarios/cadastro`);
    };

    const mutDelUsuario = useMutation((data : ICadUsuario) => API.del(`usuarios/deletar/${data.CD_USUARIO!}`, ""), {
        onSuccess: () => {qryLista.refetch()},
        onError: (error: any) => {alert(error)}
    });

    const editar = (Usuario: ICadUsuario) => {
        navigate(`/empresa/usuarios/cadastro/${Usuario.CD_USUARIO}`);
    };

    return {
        Cadastrar: cadastrar,
        Editar: editar,
        Excluir: mutDelUsuario,

        Modal: showModal,
        setModal: setShowModal,
        Selecionado: selecionado,

        Lista: dsLista,
        Grid: gridLista
    }
}

export interface IUseCadUsuarioLista {
    Cadastrar       : () => void;
    Editar          : (Usuario: ICadUsuario) => void;
    Excluir         : UseMutationResult<any, any, ICadUsuario, unknown>;
    Modal           : boolean;
    setModal        : React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado     : ICadUsuario;
    Lista           : ICadUsuarios;
    Grid            : IReactTable_Grid;
};

export default useCadUsuarioLista;
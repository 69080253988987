import Tabela from "../../../Components/tabela";
import { IUsePedidoOffline } from "../../../Hooks/offline/PedidoOffline";

interface Props {
    pedido: IUsePedidoOffline;
}

const CarrinhoItens = ({pedido} : Props) => {
    return (
        <>
            <Tabela 
                dados={pedido.pedidoItems} 
                colunas={pedido.gridLista} 
            />
            <Tabela 
                dados={[
                    {DADO: 'Subtotal', VALOR: pedido.pedido?.OFF_TOTAL}
                ]} 
                colunas={pedido.gridTotais}
                hideHeader
            />
        </>
    )
}

export default CarrinhoItens;
const useCEP = () => {
    enum HttpMethod {
        Get     = "GET"
    };

    const get = async (cep: string) => {
        return call(HttpMethod.Get, {link: `https://viacep.com.br/ws/${cep}/json`});
    };

    const getByCoordinates = async (lat: string, long: string) => {
        return call(HttpMethod.Get, {link: `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`});
    };

    const call = async (method: HttpMethod, config: any) => {
        let errors = false;
        let returnValue;   

        if (method === HttpMethod.Get) {
            return fetch(config.link, {
                method: method,
                headers: {
                    'Content-Type': `application/json`
                }
            })
            .then(data => {
                if(!data.ok) {
                    errors = true;
                }
                return data.json();
            })
            .then((response) => {
                if(errors) {
                    returnValue = response;
                    throw new Error(JSON.stringify(returnValue));
                }
                return response;
            })
            .catch(err => {throw new Error(err)})
        } 
    };
    return {
        get,
        getByCoordinates
    }
}
export default useCEP;
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../utils/useAPI";
import { DeepPartial } from "../../Models/genericInterfaces";
import useControllers from "../utils/useControllers";
import { ICardapio, ICardapios, IProduto } from "../../Models/cardapio";
import useProdutoController from "../Produto/controller";
import { IEmpresaStatus } from "../../Models/cadEmpresa";

const useCardapioController = (updateQueryKey?: string, isArray: boolean = true, cdEmpresa?: string) : IUseCardapioController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Delete, Create } = useControllers();

    const ControllerProduto = useProdutoController('listaProduto', true);

    const primaryKey = 'CD_CATEGORIA';
    const endpoint = 'categoria';

    const mutAlterarProduto = ControllerProduto.Alterar;
    const mutDeletarProduto = ControllerProduto.Deletar;

    const mutDeletar = useMutation(
        (data : DeepPartial<ICardapio>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICardapio>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<ICardapios>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutAlterar = useMutation(
        (data : DeepPartial<ICardapio>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICardapio>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<ICardapios>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<ICardapio>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<ICardapio>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<ICardapios>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['Sucesso']);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<ICardapio>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: ICardapio) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<ICardapios>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/buscarTodos/CARDAPIO`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 2
        }
    );    

    const qryListarCategorias = useQuery<ICardapios>(
        (updateQueryKey!+'Categorias'),
        (
            () => API.get(`${endpoint}/buscarTodos/`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 2
        }
    );   

    const qryEmpresaStatus = useQuery<IEmpresaStatus>(
        updateQueryKey!+'consultaEmpresa',
        (
            () => API.get(`empresa/buscar/consultarEmpresa/${cdEmpresa}`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 2,
            enabled: typeof cdEmpresa !== "undefined"
            // refetchInterval: 60000
        }
    );  

    return {
        AlterarProduto: mutAlterarProduto,
        DeletarProduto: mutDeletarProduto,
        Deletar: mutDeletar,
        Alterar: mutAlterar,
        Cadastrar: mutCadastrar,
        Buscar: mutBuscar,
        Listar: qryListar,
        ListarCategorias: qryListarCategorias,
        EmpresaStatus: qryEmpresaStatus
    }
}

export interface IUseCardapioController {
    AlterarProduto : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    DeletarProduto : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    Deletar     : UseMutationResult<any, any, DeepPartial<ICardapio>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<ICardapio>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<ICardapio>, unknown>;
    Buscar      : UseMutationResult<ICardapio, any, DeepPartial<ICardapio>, unknown>
    Listar      : UseQueryResult<ICardapios, unknown>;
    ListarCategorias: UseQueryResult<ICardapios, unknown>;
    EmpresaStatus: UseQueryResult<IEmpresaStatus, unknown>;
}

export default useCardapioController;